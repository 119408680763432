import React, { useEffect, useState, FC } from "react";
import { useTranslation } from "react-i18next";
import "./Modules.css";
import {
  Paper,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Tabs,
  Tab,
  Box,
  Divider,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import { languages, getLanguage } from "../../i18n";
import Spacer from "../shared/Spacer";
import api from "../../utils/api/v1";
import Loader from "../shared/loader/Loader";
import Activities from "./activities/Activities";
import { BackButton } from "../../UI-Components/Buttons/Buttons";
import type {
  ApiResponseNestedModuleActivitiesModel,
  ApiResponseQuestionCategoriesModel,
} from "../../utils/api/apiInterfaces";
import { MissingRequiredParamError } from "../errorHandling/MissingRequiredParamError";

interface State {
  loading: boolean;
  error: boolean;
  module: ApiResponseNestedModuleActivitiesModel | null;
}

const categories = ["isPhysical", "isStress", "isDevelopment"];

const ModuleDetails = () => {
  const { t } = useTranslation();
  const { moduleId } = useParams();
  const [openAlert, setOpenAlert] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [tab, setTab] = useState(0);
  const navigate = useNavigate();

  const [state, setState] = useState<State>({
    loading: false,
    error: false,
    module: null,
  });

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleChange = (
    _: React.ChangeEvent<unknown | string>,
    newValue: number
  ) => {
    setTab(newValue);
  };

  useEffect(() => {
    getModule();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!moduleId) {
    return <MissingRequiredParamError missingParam="moduleId" />;
  }

  const getModule = () => {
    setState({ ...state, loading: true });
    api
      .getModule(moduleId)
      .then((result) => {
        setState({ ...state, module: result.data, loading: false });
      })
      .catch(() => {
        setState({ ...state, error: true, loading: false });
      });
  };

  const handleDeleteModule = () => {
    setDeleting(true);
    api
      .deleteModule(moduleId)
      .then(() => {
        setDeleting(false);
        setOpenAlert(false);
        navigate("/modules");
      })
      .catch((err) => {
        console.error(err);
        setDeleting(false);
        setOpenAlert(false);
        setState({ ...state, error: true });
      });
  };

  const mainGui = () => {
    if (state.loading || deleting)
      return (
        <Loader text={t("ModuleDetails.loading")} style={{ marginTop: 40 }} />
      );
    if (!state.loading && state.module && !deleting) {
      return (
        <>
          <div className="section-toolbar">
            <BackButton navigateTo="/modules" />
            <Typography className="module-title" variant="h6">
              {getLanguage() === "sv"
                ? state.module.textData.nameSv
                : state.module.textData.nameEn}
            </Typography>
            <Spacer />
            <Link to="edit" className="surveys-link-style">
              <Button startIcon={<Edit />} color="primary" variant="contained">
                {t("CycleDetails.edit")}
              </Button>
            </Link>

            <Button
              startIcon={<Delete />}
              color="secondary"
              variant="contained"
              onClick={() => {
                handleOpenAlert();
              }}
              style={{ margin: 5 }}
            >
              {t("CycleDetails.delete")}
            </Button>

            <Dialog
              open={openAlert}
              onClose={handleCloseAlert}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {t("ModuleDetails.deleteAlert")}
              </DialogTitle>

              <DialogActions>
                <Button onClick={() => handleDeleteModule()} color="primary">
                  {t("CycleDetails.acceptDeleteAlert")}
                </Button>
                <Button onClick={handleCloseAlert} color="primary" autoFocus>
                  {t("CycleDetails.denyDeleteAlert")}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <Tabs className="modules-tabs" value={tab} onChange={handleChange}>
            <LinkTab
              label={t("ModuleDetails.details")}
              href={`/modules/${moduleId}`}
            />
            <LinkTab
              label={t("ModuleDetails.activities")}
              href={`/modules/${moduleId}/activities`}
            />
          </Tabs>
          <Divider />
          <div>
            <Paper className="section-detail-layout">
              <TabPanel value={tab} index={0}>
                <div>
                  <Typography className="section-detail-title" variant="h5">
                    {t("SurveyDetails.categories.categories")}
                  </Typography>
                  {categories.map((category) => (
                    <FormControlLabel
                      className="formcontrol-style"
                      key={category}
                      control={
                        <Radio
                          checked={
                            state.module?.questionCategories[
                              category as keyof ApiResponseQuestionCategoriesModel
                            ] === true
                          }
                          color="primary"
                          value={category}
                          name="category"
                        />
                      }
                      label={t(`ModuleDetails.${category}`)}
                    />
                  ))}
                </div>
                <div>
                  {languages.map((lang) => (
                    <div
                      key={lang}
                      className="text-description-container-modules"
                    >
                      <div className="text-container-module-details">
                        <h4>
                          {t("ModuleDetails.name")}{" "}
                          {t(`Language.languages.${lang}`)}
                        </h4>
                        <p>
                          {state.module &&
                            state.module.textData[
                              `name${
                                lang.charAt(0).toUpperCase() + lang.slice(1)
                              }`
                            ]}
                        </p>
                      </div>
                      <div className="text-container-module-details">
                        <h4>
                          {t("ModuleDetails.shortDescription")}{" "}
                          {t(`Language.languages.${lang}`)}
                        </h4>
                        <p>
                          {state.module &&
                            state.module.textData[
                              `shortDescription${
                                lang.charAt(0).toUpperCase() + lang.slice(1)
                              }`
                            ]}
                        </p>
                      </div>
                      <div className="text-container-module-details text-container-overflow-module">
                        <h4>
                          {t("ModuleDetails.description")}{" "}
                          {t(`Language.languages.${lang}`)}
                        </h4>
                        <p>
                          {state.module &&
                            state.module.textData[
                              `description${
                                lang.charAt(0).toUpperCase() + lang.slice(1)
                              }`
                            ]}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
                <div>
                  <p className="section-detail-header">
                    {t("ModuleDetails.image")}
                  </p>
                  {state.module.imageUrl ? (
                    <img
                      src={state.module.imageUrl}
                      alt="module"
                      className="module-details-image-style"
                    />
                  ) : (
                    t("ModuleDetails.noImage")
                  )}
                </div>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <Activities />
              </TabPanel>
            </Paper>
          </div>
        </>
      );
    }

    return (
      <Loader text={t("ModuleDetails.loading")} style={{ marginTop: 40 }} />
    );
  };

  return <div className="surveys-wrapper">{mainGui()}</div>;
};

export default ModuleDetails;

interface LinkTabProps {
  label: string;
  href: string;
}

const LinkTab = (props: LinkTabProps) => (
  <Tab
    component="a"
    onClick={(event) => event.preventDefault()}
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  />
);

interface TabPanelProps {
  value: number;
  index: number;
}

const TabPanel: FC<TabPanelProps> = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`nav-tabpanel-${index}`}
    aria-labelledby={`nav-tab-${index}`}
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...other}
  >
    {value === index && <Box p={0}>{children}</Box>}
  </div>
);
