import { useState, useEffect, VFC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router";
import "./TeacherDetails.css";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Paper,
  Typography,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import api from "../../utils/api/v1";
import Loader from "../shared/loader/Loader";
import type { ApiResponseTeacher } from "../../utils/api/apiInterfaces";
import { MissingRequiredParamError } from "../errorHandling/MissingRequiredParamError";

interface State {
  loading: boolean;
  error: boolean;
  teacher: null | ApiResponseTeacher;
}

const TeacherDetails: VFC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { teacherId } = useParams();
  const [openAlert, setOpenAlert] = useState(false);
  const [state, setState] = useState<State>({
    loading: true,
    error: false,
    teacher: null,
  });

  useEffect(() => {
    getTeacher();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!teacherId) {
    return <MissingRequiredParamError missingParam="teacherId" />;
  }

  const getTeacher = () => {
    setState({ ...state, loading: true });
    api
      .getTeacher(teacherId)
      .then(({ data }) => {
        setState({ ...state, teacher: data, loading: false });
      })
      .catch((error) => {
        console.log(error);
        setState({ ...state, loading: false, error: true });
      });
  };

  const deleteTeacher = () => {
    setState({ ...state, loading: true });
    api.deleteTeacher(teacherId).then(() => {
      setState({ ...state, loading: false });
      navigate("/teachers");
    });
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleDeleteTeacher = () => {
    deleteTeacher();
  };

  if (state.loading || !state.teacher) {
    return (
      <Loader text={t("Teachers.loadingSingle")} style={{ marginTop: 40 }} />
    );
  }

  return (
    <Paper className="teacher-details-layout">
      <div className="teacher-cover">
        <div className="img-outer-container">
          <div className="img-inner-container">
            {state.teacher.imageUrl ? (
              <img
                src={state.teacher.imageUrl}
                alt={state.teacher.name ?? ""}
                className="teacher-img"
              />
            ) : (
              <svg height="200" width="200">
                <circle cx="92" cy="92" r="95" fill="#dfe3e8" />
              </svg>
            )}
          </div>
        </div>
      </div>
      <div className="teacher-info">
        <div className="teacher-data teacher-name">
          <Typography className="section-title" variant="h6">
            {state.teacher.name}
          </Typography>
        </div>
        <div className="teacher-data teacher-label">
          {t("Teachers.labels.titleSv")}:
        </div>
        <div className="teacher-data teacher-title">
          {state.teacher.titleSv}
        </div>
        <div className="teacher-data teacher-label">
          {t("Teachers.labels.titleEn")}:
        </div>
        <div className="teacher-data teacher-title">
          {state.teacher.titleEn}
        </div>
        <div className="teacher-data teacher-label">
          {t("Teachers.labels.descriptionSv")}:
        </div>
        <div className="teacher-data teacher-description">
          {state.teacher.descriptionSv}
        </div>
        <div className="teacher-data teacher-label">
          {t("Teachers.labels.descriptionEn")}:
        </div>
        <div className="teacher-data teacher-description">
          {state.teacher.descriptionEn}
        </div>
        <div className="teacher-data">
          <Link to="edit" className="surveys-link-style">
            <Button startIcon={<Edit />} color="primary" variant="contained">
              {t("Teachers.edit")}
            </Button>
          </Link>
          <Button
            startIcon={<Delete />}
            color="secondary"
            variant="contained"
            onClick={() => {
              handleOpenAlert();
            }}
            style={{ margin: 5 }}
          >
            {t("Teachers.delete")}
          </Button>
          <Button onClick={() => navigate("/teachers")} variant="contained">
            {t("ButtonGeneral.goBack")}
          </Button>

          <Dialog
            open={openAlert}
            onClose={handleCloseAlert}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t("Teachers.deleteAlert")}
            </DialogTitle>

            <DialogActions>
              <Button onClick={() => handleDeleteTeacher()} color="primary">
                {t("Teachers.acceptDeleteAlert")}
              </Button>
              <Button onClick={handleCloseAlert} color="primary" autoFocus>
                {t("Teachers.denyDeleteAlert")}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </Paper>
  );
};

export default TeacherDetails;
