import { View, Text } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import type { VFC } from "react";
import { AutomaticAnalysisBarChart } from "../AutomaticAnalysisBarChart";
import type { ApiResponseAutomaticAnalysisResult } from "../../../../../utils/api/apiInterfaces";
import { automaticAnalysisPageStyle } from "../styles/AutomaticAnalysisPageStyle";
import {
  getAmountOfTenForSingleQuestion,
  // getAmountOfTenResults,
  getPercentageResults,
} from "../UseAutomaticAnalysisCalculationHelper";
import { lowestQuestionIndex } from "../LowestQuestionIndex";
import { getLanguage } from "../../../../../i18n";

interface Props {
  titleResult: ApiResponseAutomaticAnalysisResult;
}

export const TitleResult: VFC<Props> = ({ titleResult }) => {
  const { t } = useTranslation();
  // const companyOutOfTenResults = getAmountOfTenResults(titleResult);
  const currentLanguage = getLanguage();
  const lowestDevelopmentQuestion = lowestQuestionIndex(
    titleResult.automaticAnalyzeQuestions.developmentQuestions,
    getLanguage(),
    1
  );
  const lowestPhysicalQuestions = lowestQuestionIndex(
    titleResult.automaticAnalyzeQuestions.physicalQuestions,
    getLanguage(),
    3
  );
  const lowestStressQuestions = lowestQuestionIndex(
    titleResult.automaticAnalyzeQuestions.stressQuestions,
    getLanguage(),
    2
  );
  const titlePercantageResults = getPercentageResults(titleResult);

  const amountOfTenForSingleQuestion = getAmountOfTenForSingleQuestion(
    titleResult.automaticAnalyzeQuestions.physicalQuestions,
    "Jag rör mig mycket under en dag."
  );

  return (
    <View style={automaticAnalysisPageStyle.mainContainer}>
      <View style={automaticAnalysisPageStyle.textParentContainer}>
        <Text style={automaticAnalysisPageStyle.heading}>
          {currentLanguage === "sv" ? titleResult.nameSv : titleResult.nameEn}
        </Text>
        <Text style={automaticAnalysisPageStyle.resultText}>
          {t("TitleResult.averageResult")} {titleResult.totalAverage}.
        </Text>
        <Text style={automaticAnalysisPageStyle.resultText}>
          {titlePercantageResults.developmentPercentages.high === 0
            ? `${t(`BossResult.developmentAverageP1v2`)}`
            : `${titlePercantageResults.developmentPercentages.high} ${t(
                `TitleResult.developmentAverageP1`
              )}`}{" "}
          {t("TitleResult.developmentAverageP2")}
          &quot;{lowestDevelopmentQuestion[0]}&quot;.
        </Text>

        <Text style={automaticAnalysisPageStyle.resultText}>
          {titlePercantageResults.physicalPercentages.high === 0
            ? `${t(`TitleResult.physicalAverageP1v2`)}`
            : `${titlePercantageResults.physicalPercentages.high} ${t(
                `TitleResult.physicalAverageP1`
              )}`}{" "}
          {amountOfTenForSingleQuestion &&
            `${amountOfTenForSingleQuestion.high} ${t(
              `TitleResult.physicalAverageP2`
            )}`}
          {t("TitleResult.physicalAverageP3")}
          {lowestPhysicalQuestions.map((lpq, i) =>
            i === 0 ? (
              <Text key="1">&quot;{lpq.slice(0, -1)}&quot;, </Text>
            ) : i === 1 ? (
              <Text key="2">
                &quot;{lpq.slice(0, -1)}&quot; {t("CompanyResult.and")}
              </Text>
            ) : (
              <Text key="3">&quot;{lpq}&quot;.</Text>
            )
          )}
        </Text>

        <Text style={automaticAnalysisPageStyle.resultText}>
          {titlePercantageResults.stressPercentages.medium +
            titlePercantageResults.stressPercentages.low ===
          0
            ? `${t(`TitleResult.stressAverageP1v2`)}`
            : `${
                titlePercantageResults.stressPercentages.medium +
                titlePercantageResults.stressPercentages.low
              } ${t(`TitleResult.stressAverageP1`)}`}{" "}
          {lowestStressQuestions.map((lsq, i) =>
            i === 0 ? (
              <Text key="1">
                &quot;{lsq.slice(0, -1)}&quot; {t("CompanyResult.and")}
              </Text>
            ) : (
              <Text key="2">&quot;{lsq}&quot;.</Text>
            )
          )}
          {titleResult.stressResults.low > 0 && (
            <Text style={automaticAnalysisPageStyle.resultText}>
              {t("DepartmentResult.highStressP1")}{" "}
              {titleResult.stressResults.low}{" "}
              {t("DepartmentResult.highStressP2")}
            </Text>
          )}
        </Text>
      </View>
      <View style={automaticAnalysisPageStyle.barChartContainer}>
        <View style={automaticAnalysisPageStyle.barchartTextContainer}>
          <Text style={automaticAnalysisPageStyle.barchartText}>
            {t("CompanyStatistics.wellBeingDevelopmentDistribution")}
          </Text>
          <Text style={automaticAnalysisPageStyle.barchartText}>
            {t("CompanyStatistics.wellBeingPhysicalDistribution")}
          </Text>
          <Text style={automaticAnalysisPageStyle.barchartText}>
            {t("CompanyStatistics.wellBeingStressDistribution")}
          </Text>
        </View>
        <View style={automaticAnalysisPageStyle.barchartChildDiv}>
          <AutomaticAnalysisBarChart
            developmentAverage={titleResult.developmentAverage}
            stressAverage={titleResult.stressAverage}
            physicalAverage={titleResult.physicalAverage}
          />
        </View>
      </View>
    </View>
  );
};
