import { useEffect, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { Button } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { getLanguage } from "../../../i18n";
import {
  MicroCourseResourcesTable,
  microCourseResourceTableSorting,
} from "./MicroCourseResourcesTable";
import api from "../../../utils/api/v1";
import type { ApiResponseMicroCourseResource } from "../../../utils/api/apiInterfaces";
import { MissingRequiredParamError } from "../../errorHandling/MissingRequiredParamError";

interface Props {
  microCourseResources: Array<ApiResponseMicroCourseResource>;
}

export const MicroCourseResources: VFC<Props> = ({ microCourseResources }) => {
  const { t } = useTranslation();
  const { microCourseId } = useParams();
  const lang = getLanguage();
  const [, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const [state, setState] = useState({
    error: false,
    loading: false,
  });
  const [resources, setResources] = useState(
    microCourseResources
      .sort((a, b) => a.order - b.order)
      .map(({ id, timeRequired, nameEn, nameSv }, i) => ({
        id,
        order: i + 1,
        name: `${t("MicroCourses.details.resourceList.part")} ${i + 1} ${
          lang === "sv" ? nameSv : nameEn
        }`,
        timeRequired,
      }))
  );

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!microCourseId) {
    return <MissingRequiredParamError missingParam="microCourseId" />;
  }

  const updateSortOrder = async () => {
    const newSortOrderData = resources.map(({ id }, index) => ({
      microCourseResourceId: id,
      order: index + 1,
    }));

    setState((prevState) => ({ ...prevState, loading: true, error: false }));
    try {
      const {
        data: { microCourseResources: updatedMicroCourseResources },
      } = await api.updateMicroCourseResourcesSortOrder(microCourseId, {
        newMicroCourseResourcesOrder: newSortOrderData,
      });
      setResources(
        updatedMicroCourseResources.map(
          ({ id, order, nameSv, nameEn, timeRequired }) => ({
            id,
            order,
            name: `${t("MicroCourses.details.resourceList.part")} ${order} ${
              lang === "sv" ? nameSv : nameEn
            }`,
            timeRequired,
          })
        )
      );
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (err) {
      console.error(err);
      setState((prevState) => ({ ...prevState, loading: false, error: true }));
    }
  };

  const handleSortOrder = (id: string, _: number, sortDirection: string) => {
    if (
      resources[0]?.id === id &&
      sortDirection === microCourseResourceTableSorting.up
    ) {
      return;
    }
    if (
      resources[resources.length - 1]?.id === id &&
      sortDirection === microCourseResourceTableSorting.down
    ) {
      return;
    }
    if (sortDirection === microCourseResourceTableSorting.up) {
      const resourceCopy = [...resources];
      const fromIndex = resourceCopy.findIndex((r) => r.id === id);
      const toIndex = fromIndex - 1;
      const element = resourceCopy.splice(fromIndex, 1)[0];
      if (element) {
        resourceCopy.splice(toIndex, 0, element);
        setResources(resourceCopy);
      }
    }
    if (sortDirection === microCourseResourceTableSorting.down) {
      const resourceCopy = [...resources];
      const fromIndex = resourceCopy.findIndex((r) => r.id === id);
      const toIndex = fromIndex + 1;
      const element = resourceCopy.splice(fromIndex, 1)[0];
      if (element) {
        resourceCopy.splice(toIndex, 0, element);
        setResources(resourceCopy);
      }
    }
  };

  const columns = [
    {
      field: "order",
      headerName: t("MicroCourses.resources.order"),
      width:
        window.innerWidth >= 960
          ? window.innerWidth / 11
          : window.innerWidth / 10,
    },
    {
      field: "name",
      headerName: t("MicroCourses.resources.name"),
      width:
        window.innerWidth >= 960
          ? window.innerWidth / 3
          : window.innerWidth / 2.5,
    },
    {
      field: "timeRequired",
      headerName: t("MicroCourses.resources.timeRequired"),
      width:
        window.innerWidth >= 960
          ? window.innerWidth / 6
          : window.innerWidth / 5,
    },
  ];

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  return (
    <>
      <Button
        className="micro-course-resources-add-button-style"
        color="primary"
        variant="contained"
        onClick={() =>
          navigate(`/micro-courses/${microCourseId}/micro-course-resources/new`)
        }
      >
        <Add fontSize="small" /> {t("MicroCourses.resources.add")}
      </Button>
      <MicroCourseResourcesTable
        columns={columns}
        rows={resources}
        handleSortOrder={handleSortOrder}
        path="micro-courses/micro-course-resource"
        updateSortOrder={updateSortOrder}
        loading={state.loading}
      />
    </>
  );
};
