import React, { useState, useEffect } from "react";
import "./TeacherEdit.css";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { CloudinaryContext } from "cloudinary-react";
import PropTypes from "prop-types";
import {
  Typography,
  Button,
  Paper,
  TextField,
  Grid,
  Fab,
} from "@material-ui/core";
import { Save, PhotoCamera } from "@material-ui/icons";
import { createGuid } from "../../utils/helpers/createGuid";
import api from "../../utils/api/v1";
import Loader from "../shared/loader/Loader";
import Spacer from "../shared/Spacer";
import { openUploadWidget } from "../../utils/cloudinary/cloudinaryService";
import { BackButton } from "../../UI-Components/Buttons/Buttons";

const formFields = [
  "name",
  "titleSv",
  "titleEn",
  "descriptionSv",
  "descriptionEn",
];

const getRowsNumber = (field) =>
  field === "descriptionSv" || field === "descriptionEn" ? 4 : 1;

const getMaxRowsNumber = (field) =>
  field === "descriptionSv" || field === "descriptionEn" ? 15 : 1;

const TeacherEdit = ({ create }) => {
  const { t } = useTranslation();
  const { teacherId } = useParams();
  const navigate = useNavigate();

  const [state, setState] = useState({
    loading: true,
    error: false,
    teacher: null,
  });

  const [form, setForm] = useState({
    name: "",
    titleSv: "",
    titleEn: "",
    descriptionSv: "",
    descriptionEn: "",
  });

  const [attributesForUpdate, setAttributesForUpdate] = useState({
    imagePublicId: null,
    imageUrl: null,
  });

  const [requiredFields, setRequiredFields] = useState({
    name: "",
    titleSv: "",
    titleEn: "",
    descriptionSv: "",
    descriptionEn: "",
  });

  const [showErrorAll, setShowErrorAll] = useState(false);
  const [showError, setShowError] = useState({
    name: false,
    titleSv: false,
    titleEn: false,
    descriptionSv: false,
    descriptionEn: false,
  });

  const getTeacher = () => {
    setState({ ...state, loading: true, error: false });
    api
      .getTeacher(teacherId)
      .then(({ data }) => {
        console.log(data);
        setForm({
          name: data.name,
          titleSv: data.titleSv,
          titleEn: data.titleEn,
          descriptionSv: data.descriptionSv,
          descriptionEn: data.descriptionEn,
        });
        setRequiredFields({
          name: data.name,
          titleSv: data.titleSv,
          titleEn: data.titleEn,
          descriptionSv: data.descriptionSv,
          descriptionEn: data.descriptionEn,
        });
        setAttributesForUpdate({
          imagePublicId: data.imagePublicId,
          imageUrl: data.imageUrl,
        });
        setState({
          ...state,
          teacher: data,
          loading: false,
        });
      })
      .catch((error) => {
        console.error(error);
        setState({ ...state, error: true, loading: false });
      });
  };

  useEffect(() => {
    if (!create) {
      getTeacher();
    } else {
      setState({ ...state, loading: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = ({ target: { name, value } }) => {
    setShowError({ ...showError, [name]: true });

    setForm({
      ...form,
      [name]: value,
    });
    setRequiredFields({ ...requiredFields, [name]: value });
  };

  const handleSave = () => {
    if (Object.values(requiredFields).some((value) => value === "")) {
      setShowErrorAll(true);
      return;
    }
    setState({ ...state, loading: true, error: false });

    const formData = new FormData();
    if (create) {
      formData.append("form", JSON.stringify(form));

      api
        .createTeacher(formData, { "content-type": "multipart/form-data" })
        .then(({ data }) => {
          setState({ ...state, loading: false, error: false });
          navigate(`/teachers/${data.id}`);
        })
        .catch((error) => {
          console.error(error);
          setState({ ...state, loading: false, error: true });
        });
    } else {
      const formStateCopy = { ...form };
      const formDataForEdit = Object.assign(formStateCopy, attributesForUpdate);
      formData.append("form", JSON.stringify(formDataForEdit));

      api
        .updateTeacher(teacherId, formData, {
          "content-type": "multipart/form-data",
        })
        .then(() => {
          setState({ ...state, loading: false, error: false });
          navigate(`/teachers/${teacherId}`);
        })
        .catch((error) => {
          console.error(error);
          setState({ ...state, loading: false, error: true });
        });
    }
  };

  const checkRequiredInput = (input) =>
    (showError[input] || showErrorAll) &&
    (form[input] === "" || form[input] === undefined);

  const generateHelperText = (input, fieldName) =>
    checkRequiredInput(input)
      ? t("missingMandatoryInput", { field: fieldName })
      : "";

  const uploadImageToCloudinary = async () => {
    const publicUploadId = createGuid();

    const uploadOptions = {
      cloudName: process.env.REACT_APP_CLOUD_NAME,
      uploadPreset: process.env.REACT_APP_CLOUD_UPLOAD_PRESET,
      publicId: `teachers/${teacherId}/${publicUploadId}`,
      maxFiles: 1,
      preBatch: (cb, data) => {
        if (
          !data.files[0].name.endsWith("jpg") &&
          !data.files[0].name.endsWith("jpeg") &&
          !data.files[0].name.endsWith("png") &&
          !data.files[0].name.endsWith("jfif")
        ) {
          console.error("Wrong file type.");
          // TODO: Add a modal to inform user about the error.
          cb({ cancel: true });
        } else {
          cb();
        }
      },
    };

    openUploadWidget(uploadOptions, async (error, res) => {
      if (res.event === "success") {
        setState({ ...state, loading: true, error: false });

        const data = {
          assetPublicId: publicUploadId,
          resourceOrActivityUrl: res.info.secure_url,
        };

        try {
          if (attributesForUpdate.imagePublicId) {
            await api
              .deleteTeacherImageAsset(attributesForUpdate.imagePublicId)
              .then(async () => {
                await api.uploadTeacherImageAsset(data, teacherId);
              });
          } else {
            await api.uploadTeacherImageAsset(data, teacherId);
          }

          setAttributesForUpdate({
            imagePublicId: data.assetPublicId,
            imageUrl: res.info.secure_url,
          });
          setState({ ...state, loading: false, error: false });
        } catch (err) {
          console.log(err);
          setState({ ...state, loading: false, error: true });
        }
      } else {
        console.error(error);
      }
    });
  };

  if (state.loading) {
    return (
      <Loader text={t("Teachers.loadingSingle")} style={{ marginTop: 40 }} />
    );
  }

  return (
    <>
      <div className="section-toolbar">
        <BackButton />
        <Typography className="section-title" variant="h6">
          {create ? t("Teachers.titleCreate") : t("Teachers.titleEdit")}
        </Typography>
        <Spacer />
        <Button
          color="primary"
          variant="contained"
          startIcon={<Save />}
          onClick={handleSave}
        >
          {t("CompanyDetails.save")}
        </Button>
      </div>
      <Paper className="company-details">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3} className="img-wrapper">
            <div className="img-outer-container">
              <div className="upload-button-container">
                <div className="img-inner-container">
                  {state.teacher && attributesForUpdate.imageUrl ? (
                    <img
                      src={attributesForUpdate.imageUrl}
                      alt={state.teacher.name}
                      className="teacher-img"
                    />
                  ) : (
                    <svg height="200" width="200">
                      <circle cx="92" cy="92" r="95" fill="#dfe3e8" />
                    </svg>
                  )}
                </div>
                <CloudinaryContext cloudName={process.env.REACT_APP_CLOUD_NAME}>
                  <Fab
                    color="primary"
                    size="small"
                    className="foreground"
                    onClick={() => uploadImageToCloudinary()}
                    disabled={create}
                  >
                    <PhotoCamera />
                  </Fab>
                </CloudinaryContext>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={9}>
            {formFields.map((formField) => (
              <div className="text-form" key={formField}>
                <TextField
                  name={formField}
                  label={t(`Teachers.labels.${formField}`)}
                  variant="outlined"
                  fullWidth
                  required
                  multiline
                  rows={getRowsNumber(formField)}
                  rowsMax={getMaxRowsNumber(formField)}
                  value={form[formField]}
                  onChange={handleChange}
                  error={checkRequiredInput(`${formField}`)}
                  helperText={generateHelperText(
                    `${formField}`,
                    t(`Teachers.labels.${formField}`)
                  )}
                />
              </div>
            ))}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

TeacherEdit.propTypes = {
  create: PropTypes.bool,
};

TeacherEdit.defaultProps = {
  create: false,
};

export default TeacherEdit;
