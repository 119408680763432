import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./Surveys.css";
import {
  Checkbox,
  Paper,
  Typography,
  FormControlLabel,
  FormLabel,
  FormControl,
  TextField,
  FormGroup,
  Button,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import {
  Save,
  KeyboardArrowUp,
  KeyboardArrowDown,
  Cancel,
  Add,
} from "@material-ui/icons";
import { useNavigate, useParams } from "react-router";
import PropTypes from "prop-types";
import { languages } from "../../i18n";
import Spacer from "../shared/Spacer";
import api from "../../utils/api/v1";
import Loader from "../shared/loader/Loader";
import { BackButton } from "../../UI-Components/Buttons/Buttons";

const categories = ["isPhysical", "isStress", "isDevelopment"];

const SurveyEdit = ({ create }) => {
  const { t } = useTranslation();
  const { surveyId } = useParams();
  const navigate = useNavigate();
  const [removeQuestionLoading, setRemoveQuestionLoading] = useState(false);
  const [addQuestionLoading, setAddQuestionLoading] = useState(false);
  const [state, setState] = useState({
    loading: false,
    error: "",
    survey: null,
  });
  const [invalidForm, setInvalidForm] = useState(false);

  const getSurvey = () => {
    setState({ ...state, loading: true });
    api
      .getSurvey(surveyId)
      .then((result) => {
        const questions = result.data.questions.map((q) => ({
          ...q,
        }));
        setState({
          ...state,
          survey: { ...result.data, questions },
          loading: false,
          error: "",
        });
      })
      .catch((err) => {
        console.error(err);
        setState({ ...state, error: "get", loading: false });
      });
  };

  useEffect(() => {
    if (!create) {
      getSurvey();
    } else {
      setState({
        ...state,
        survey: { name: "", questions: [] },
        loading: false,
        error: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setQuestions = (questions) => {
    setState({
      ...state,
      survey: { ...state.survey, questions },
    });
  };

  const addQuestion = () => {
    const maxNum = state.survey.questions
      .map((q) => q.order)
      .reduce((a, b) => Math.max(a, b), 0);
    const newQuestions = [...state.survey.questions];
    const newQuestion = {
      order: maxNum + 1,
      categories: {
        isPhysical: false,
        isStress: false,
        isDevelopment: false,
      },
      weight: 1,
      isPositive: true,
      textEn: "",
      textSv: "",
    };
    if (!create) {
      setAddQuestionLoading(true);
      api
        .addSurveyQuestion(surveyId, newQuestion)
        .then((res) => {
          newQuestion.id = res.data;
          newQuestions.push(newQuestion);
          setQuestions(newQuestions);
          setAddQuestionLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setState({ ...state, error: "create" });
          setAddQuestionLoading(false);
        });
    } else {
      newQuestions.push(newQuestion);
      setQuestions(newQuestions);
    }
  };

  const removeQuestion = (index, questionId) => {
    const newQuestions = state.survey.questions
      .slice(0, index)
      .concat(
        state.survey.questions.slice(index + 1, state.survey.questions.length)
      );
    if (!create) {
      setRemoveQuestionLoading(true);
      api
        .deleteSurveyQuestion(surveyId, questionId)
        .then(() => {
          setQuestions(newQuestions);
          setRemoveQuestionLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setState({ ...state, error: "delete" });
          setRemoveQuestionLoading(false);
        });
    } else {
      setQuestions(newQuestions);
    }
  };

  const updateQuestion = (index, question) => {
    const newQuestions = [...state.survey.questions];
    newQuestions[index] = question;
    setQuestions(newQuestions);
  };

  const updateQuestionWeight = (index, event) => {
    const { value } = event.target;
    const question = state.survey.questions[index];
    question.weight = value;
    updateQuestion(index, question);
  };

  const updateQuestionData = (index, event) => {
    const { name, value } = event.target;
    const question = state.survey.questions[index];
    question[name] = value;
    updateQuestion(index, question);
  };

  const updateQuestionCategory = (index, event) => {
    const { name, checked } = event.target;
    const question = state.survey.questions[index];
    question.categories[name] = checked;
    updateQuestion(index, question);
  };

  /**
   * Updates the question order on create page.
   *
   * @param {number} index - The index of the current question.
   * @param {number} offset - The change in question position, either positive or negative.
   */
  const updateQuestionOrder = (index, offset) => {
    if (
      (index === 0 && Math.sign(offset) === -1) ||
      (index === state.survey.questions.length - 1 && Math.sign(offset) === 1)
    ) {
      return;
    }
    const firstQuestionOrder = state.survey.questions[index].order;
    const secondQuestionOrder = state.survey.questions[index + offset].order;
    const question1 = state.survey.questions[index];
    const question2 = state.survey.questions[index + offset];
    question1.order = secondQuestionOrder;
    question2.order = firstQuestionOrder;
    if (question1 && question2) {
      const newQuestions = [...state.survey.questions];
      newQuestions[index] = question2;
      newQuestions[index + offset] = question1;
      setQuestions(newQuestions);
    }
  };

  const updateSurveyName = (event) => {
    setState({
      ...state,
      survey: { ...state.survey, name: event.target.value },
    });
    setInvalidForm(event.target.value === "");
  };

  const updatePositive = (index, event) => {
    const question = state.survey.questions[index];
    question.isPositive = event.target.checked;
    updateQuestion(index, question);
  };

  const handleSave = () => {
    if (state.survey.name.trim() === "") {
      setInvalidForm(true);
      return;
    }
    setState({ ...state, loading: true, error: "" });
    const surveyData = { ...state.survey };
    surveyData.questions.forEach((question, index) => {
      surveyData.questions[index].weight = Number(question.weight) || 1;
    });
    (create ? api.createSurvey(surveyData) : api.updateSurvey(surveyData))
      .then(({ data }) => {
        navigate(`/surveys/${create ? data : data.id}`);
      })
      .catch((error) => {
        console.error(error);
        setState({ ...state, loading: false, error: "saving" });
      });
  };

  const mainGui = () => {
    if (state.loading)
      return (
        <Loader text={t("SurveyDetails.loading")} style={{ marginTop: 40 }} />
      );
    if (!state.loading && state.survey) {
      return (
        <form>
          <div className="section-toolbar">
            <BackButton />
            <TextField
              className="section-title"
              label={t("SurveyDetails.name")}
              value={state.survey.name}
              required
              error={invalidForm}
              helperText={
                invalidForm
                  ? t("missingMandatoryInput", {
                      field: t("SurveyDetails.name"),
                    })
                  : ""
              }
              onChange={updateSurveyName}
            />
            <Spacer />
            <Button
              data-testid="save_button"
              color="primary"
              variant="contained"
              startIcon={<Save />}
              disabled={state.survey.questions.length === 0}
              onClick={handleSave}
            >
              {t("SurveyDetails.save")}
            </Button>
            {state.survey.questions.length === 0 && (
              <div className="survey-question-warning">
                <Typography className="survey-question-warning-text">
                  {t("SurveyDetails.questionWarning")}
                </Typography>
              </div>
            )}
          </div>
          <div>
            {state.survey.questions.map(
              (
                { id, categories: cats, weight, isPositive, order },
                index,
                questions
              ) => (
                <Paper
                  key={`question-${order}`}
                  className="section-detail-layout"
                >
                  <div className="survey-form-header">
                    <Typography className="section-detail-title" variant="h5">
                      {t("SurveyDetails.question")} {index + 1}
                    </Typography>
                    <Spacer />

                    {create && (
                      <>
                        <IconButton
                          size="small"
                          onClick={() => updateQuestionOrder(index, -1)}
                          disabled={index === 0}
                        >
                          <KeyboardArrowUp />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => updateQuestionOrder(index, 1)}
                          disabled={index === state.survey.questions.length - 1}
                        >
                          <KeyboardArrowDown />
                        </IconButton>
                      </>
                    )}

                    {removeQuestionLoading ? (
                      <CircularProgress color="primary" />
                    ) : (
                      <IconButton
                        size="small"
                        onClick={() => removeQuestion(index, id)}
                        className="error"
                      >
                        <Cancel />
                      </IconButton>
                    )}
                  </div>
                  <FormControl className="survey-question-categories">
                    <FormLabel>
                      {t("SurveyDetails.categories.categories")}
                    </FormLabel>
                    <FormGroup row>
                      {categories.map((category) => (
                        <FormControlLabel
                          key={`question-${order}-category-${category}`}
                          label={t(`SurveyDetails.categories.${category}`)}
                          control={
                            <Checkbox
                              data-testid={`checkbox_${category}`}
                              name={category}
                              checked={cats[category]}
                              onChange={(event) =>
                                updateQuestionCategory(index, event)
                              }
                            />
                          }
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                  <FormControl className="survey-question-weight-categories">
                    <FormGroup row>
                      <TextField
                        className="survey-form-weight"
                        name="weight"
                        label={t("SurveyDetails.weight")}
                        variant="outlined"
                        value={weight}
                        type="number"
                        onChange={(event) => updateQuestionWeight(index, event)}
                      />
                      <FormControlLabel
                        label={t("SurveyDetails.positive")}
                        control={
                          <Checkbox
                            checked={isPositive}
                            onChange={(event) => updatePositive(index, event)}
                          />
                        }
                      />
                    </FormGroup>
                  </FormControl>
                  {languages.map((lang) => (
                    <div
                      className="survey-form-text"
                      key={`question-${order}-text-${lang}`}
                    >
                      <TextField
                        data-testid={`textfield_${lang}`}
                        name={`text${lang.charAt(0).toUpperCase()}${lang.slice(
                          1
                        )}`}
                        label={`${t(`SurveyDetails.question`)} ${t(
                          `Language.languages.${lang}`
                        )}`}
                        variant="outlined"
                        fullWidth
                        multiline
                        value={
                          questions[index][
                            `text${lang.charAt(0).toUpperCase()}${lang.slice(
                              1
                            )}`
                          ]
                        }
                        onChange={(event) => updateQuestionData(index, event)}
                      />
                    </div>
                  ))}
                  {languages.map((lang) => (
                    <div
                      className="survey-form-text"
                      key={`question-${order}-text-${lang}`}
                    >
                      <TextField
                        data-testid={`textfield_${lang}`}
                        name={`action${lang
                          .charAt(0)
                          .toUpperCase()}${lang.slice(1)}`}
                        label={`${t(`SurveyDetails.title`)} ${t(
                          `Language.languages.${lang}`
                        )}`}
                        variant="outlined"
                        fullWidth
                        multiline
                        value={
                          questions[index][
                            `action${lang.charAt(0).toUpperCase()}${lang.slice(
                              1
                            )}`
                          ]
                        }
                        onChange={(event) => updateQuestionData(index, event)}
                      />
                    </div>
                  ))}
                  {languages.map((lang) => (
                    <div
                      className="survey-form-text"
                      key={`question-${order}-text-${lang}`}
                    >
                      <TextField
                        data-testid={`textfield_${lang}`}
                        name={`recommendation${lang
                          .charAt(0)
                          .toUpperCase()}${lang.slice(1)}`}
                        label={`${t(
                          `SurveyDetails.factsAndRecommendations`
                        )} ${t(`Language.languages.${lang}`)}`}
                        variant="outlined"
                        fullWidth
                        multiline
                        value={
                          questions[index][
                            `recommendation${lang
                              .charAt(0)
                              .toUpperCase()}${lang.slice(1)}`
                          ]
                        }
                        onChange={(event) => updateQuestionData(index, event)}
                      />
                    </div>
                  ))}
                </Paper>
              )
            )}
          </div>
          <div>
            {addQuestionLoading ? (
              <CircularProgress
                color="primary"
                style={{ marginLeft: "1rem" }}
              />
            ) : (
              <Button
                startIcon={<Add />}
                data-testid="add_question_button"
                color="primary"
                variant="contained"
                onClick={() => addQuestion()}
                style={{ margin: 12 }}
              >
                {t("SurveyDetails.addQuestion")}
              </Button>
            )}
          </div>
        </form>
      );
    }

    return (
      <Loader text={t("SurveyDetails.loading")} style={{ marginTop: 40 }} />
    );
  };

  return <div className="surveys-wrapper">{mainGui()}</div>;
};

SurveyEdit.propTypes = {
  create: PropTypes.bool,
};

SurveyEdit.defaultProps = {
  create: false,
};

export default SurveyEdit;
