import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Tabs, Divider, Tab, Typography, Box, Button } from "@material-ui/core";
import PropTypes from "prop-types";
import Spacer from "../shared/Spacer";
import ReminderSettings from "./ReminderParts/ReminderSettings";
import CompaniesSettings from "./ReminderParts/CompaniesSettings";

const Reminders = () => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      <div className="section-toolbar">
        <Typography className="section-title" variant="h6">
          {t("Navigation.emailReminders")}
        </Typography>
        <Spacer />
      </div>
      <Tabs className="company-tabs" value={tab} onChange={handleChange}>
        <LinkTab label={t("Reminder.Settings")} />
        <LinkTab
          label={t("Reminder.Companies")}
          data-testid="reminderCompaniesTab"
        />
        <LinkTab label="TO IMPLEMENT" />
      </Tabs>
      <Divider />
      <TabPanel value={tab} index={0}>
        <ReminderSettings />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <CompaniesSettings />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <Button>
          SEND OUT A notification to all users (form field + send button)
        </Button>
      </TabPanel>
    </>
  );
};

export default Reminders;

const LinkTab = (props) => (
  <Tab
    component="a"
    onClick={(event) => event.preventDefault()}
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  />
);

const TabPanel = (props) => {
  // eslint-disable-next-line react/prop-types
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};
