const TOKEN_KEY = "token";

export function getToken() {
  try {
    return JSON.parse(window.localStorage.getItem(TOKEN_KEY));
  } catch {
    return null;
  }
}

export function setToken(token) {
  return window.localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
}

export function clearToken() {
  return window.localStorage.removeItem(TOKEN_KEY);
}
