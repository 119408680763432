import { useCallback, useEffect, useState, VFC, memo } from "react";
import { useTranslation } from "react-i18next";
import "./Surveys.css";
import {
  Checkbox,
  Paper,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { languages } from "../../i18n";
import Spacer from "../shared/Spacer";
import api from "../../utils/api/v1";
import Loader from "../shared/loader/Loader";
import { BackButton } from "../../UI-Components/Buttons/Buttons";
import type { ApiResponseSurveyModel } from "../../utils/api/apiInterfaces";
import { MissingRequiredParamError } from "../errorHandling/MissingRequiredParamError";

const categories = ["isPhysical", "isStress", "isDevelopment"];

interface State {
  loading: boolean;
  error: boolean;
  survey: null | ApiResponseSurveyModel;
}

const SurveyDetails: VFC = () => {
  const { t } = useTranslation();
  const { surveyId } = useParams();
  const [openAlert, setOpenAlert] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const navigate = useNavigate();
  const [state, setState] = useState<State>({
    loading: false,
    error: false,
    survey: null,
  });
  const [questionIndexToExpand, setQuestionIndexToExpand] = useState<
    string | null
  >(null);

  const getSurvey = useCallback(() => {
    if (!surveyId) {
      return;
    }
    setState((prevState) => ({ ...prevState, loading: true }));
    api
      .getSurvey(surveyId)
      .then(({ data }) => {
        setState((prevState) => ({
          ...prevState,
          survey: data,
          loading: false,
        }));
      })
      .catch((err) => {
        console.error(err);
        setState((prevState) => ({
          ...prevState,
          error: true,
          loading: false,
        }));
      });
  }, [surveyId]);

  useEffect(() => {
    getSurvey();
  }, [getSurvey]);

  if (!surveyId) {
    return <MissingRequiredParamError missingParam="surveyId" />;
  }

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleDeleteSurvey = () => {
    setDeleting(true);

    api
      .deleteSurvey(surveyId)
      .then(() => {
        setDeleting(false);
        setOpenAlert(false);
        navigate("/surveys");
      })
      .catch((err) => {
        console.error(err);
        setDeleting(false);
        setOpenAlert(false);
        setState({ ...state, error: true });
      });
  };

  const mainGui = () => {
    if (state.loading || deleting)
      return (
        <Loader text={t("SurveyDetails.loading")} style={{ marginTop: 40 }} />
      );
    if (!state.loading && !deleting && state.survey) {
      return (
        <>
          <div className="section-toolbar">
            <BackButton navigateTo="/surveys" />
            <Typography className="section-title" variant="h6">
              {state.survey.name}
            </Typography>
            <Spacer />
            <Link to="edit" className="surveys-link-style">
              <Button startIcon={<Edit />} color="primary" variant="contained">
                {t("CycleDetails.edit")}
              </Button>
            </Link>

            <Button
              startIcon={<Delete />}
              data-testid="delete_button"
              color="secondary"
              variant="contained"
              onClick={() => {
                handleOpenAlert();
              }}
              style={{ margin: 5 }}
            >
              {t("CycleDetails.delete")}
            </Button>
            <Dialog
              open={openAlert}
              onClose={handleCloseAlert}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {t("SurveyDetails.deleteAlert")}
              </DialogTitle>

              <DialogActions>
                <Button
                  data-testid="confirm_delete"
                  onClick={() => handleDeleteSurvey()}
                  color="primary"
                >
                  {t("CycleDetails.acceptDeleteAlert")}
                </Button>
                <Button
                  data-testid="deny_delete"
                  onClick={handleCloseAlert}
                  color="primary"
                  autoFocus
                >
                  {t("CycleDetails.denyDeleteAlert")}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <div>
            {state.survey.questions.map(
              (
                { isPositive, weight, categories: cats, id },
                index,
                questions
              ) => (
                <Paper className="section-detail-layout" key={`question-${id}`}>
                  <Typography className="section-detail-title" variant="h5">
                    {t("SurveyDetails.question")} {index + 1}
                  </Typography>
                  <div>
                    <p className="survey-paragraph-style">
                      {t("SurveyDetails.categories.categories")}
                    </p>
                    <div className="survey-question-category">
                      {categories.map((category) => (
                        <div key={category}>
                          {t(`SurveyDetails.categories.${category}`)}
                          <Checkbox checked={cats[category]} />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div>
                    <p className="survey-paragraph-style">
                      {t("SurveyDetails.weight")}: {weight}
                    </p>
                  </div>
                  <div>
                    <p className="survey-paragraph-style">
                      {t("SurveyDetails.positive")}
                      <Checkbox checked={isPositive} />
                    </p>
                  </div>
                  {languages.map((lang) => (
                    <div key={lang}>
                      <p className="section-detail-header">
                        {`${t(`SurveyDetails.question`)} ${t(
                          `Language.languages.${lang}`
                        )}`}
                      </p>
                      <p className="survey-question-text">
                        {
                          questions[index]?.[
                            `text${lang.charAt(0).toUpperCase()}${lang.slice(
                              1
                            )}`
                          ]
                        }
                      </p>
                    </div>
                  ))}
                  {languages.map((lang) => (
                    <div key={lang}>
                      <p className="section-detail-header">
                        {`${t(`SurveyDetails.title`)} ${t(
                          `Language.languages.${lang}`
                        )}`}
                      </p>
                      <p className="survey-question-text">
                        {
                          questions[index]?.[
                            `action${lang.charAt(0).toUpperCase()}${lang.slice(
                              1
                            )}`
                          ]
                        }
                      </p>
                    </div>
                  ))}
                  {languages.map((lang) => (
                    <div
                      className={questionIndexToExpand === id ? "" : "minified"}
                      key={lang}
                    >
                      <p className="section-detail-header">
                        {`${t(`SurveyDetails.factsAndRecommendations`)} ${t(
                          `Language.languages.${lang}`
                        )}`}
                      </p>
                      <p
                        style={{ whiteSpace: "pre-line" }}
                        className="survey-question-text"
                      >
                        {
                          questions[index]?.[
                            `recommendation${lang
                              .charAt(0)
                              .toUpperCase()}${lang.slice(1)}`
                          ]
                        }
                      </p>
                    </div>
                  ))}
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setQuestionIndexToExpand(
                        questionIndexToExpand === id ? null : id
                      );
                    }}
                    style={{ marginTop: 10 }}
                  >
                    {questionIndexToExpand === id
                      ? `${t("SurveyDetails.shrinkText")}`
                      : `${t("SurveyDetails.expandText")}`}
                  </Button>
                </Paper>
              )
            )}
          </div>
        </>
      );
    }

    return (
      <Loader text={t("SurveyDetails.loading")} style={{ marginTop: 40 }} />
    );
  };

  return <div className="surveys-wrapper">{mainGui()}</div>;
};

export default memo(SurveyDetails);
