import React, { useState, useEffect } from "react";
import "./SingleRowSelectionGrid.css";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";
import { DataGrid } from "@mui/x-data-grid";

const SingleRowSelectionGrid = ({
  rows,
  columns,
  pageSize,
  loading,
  extraWidth,
  path,
  sortModel,
}) => {
  const navigate = useNavigate();
  const [width, setWidth] = useState("100%");

  const handleResize = () => {
    if (window.innerWidth >= 960) {
      setWidth(window.innerWidth - extraWidth - 260);
    } else {
      setWidth(window.innerWidth - extraWidth - 20);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ display: "flex", width }}>
      <DataGrid
        className="data-grid-style"
        columns={columns}
        rows={rows}
        pageSize={pageSize}
        loading={loading}
        autoHeight
        initialState={{
          sorting: {
            sortModel,
          },
        }}
        onRowClick={(e) => navigate(`/${path}/${e.row.id}`)}
      />
    </div>
  );
};

SingleRowSelectionGrid.defaultProps = {
  extraWidth: 0,
  sortModel: [],
};

SingleRowSelectionGrid.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  rows: PropTypes.array.isRequired,
  pageSize: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  path: PropTypes.string.isRequired,
  extraWidth: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  sortModel: PropTypes.array,
};

SingleRowSelectionGrid.defaultProps = {
  loading: false,
};

export default SingleRowSelectionGrid;
