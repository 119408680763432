import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  Typography,
  DialogActions,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Delete } from "@material-ui/icons";
import { CloudinaryContext } from "cloudinary-react";
import { openUploadWidget } from "../../../utils/cloudinary/cloudinaryService";
import "./CycleUpload.css";
import Loader from "../../shared/loader/Loader";
import api from "../../../utils/api/v1";
import { createGuid } from "../../../utils/helpers/createGuid";

const CycleUpload = ({ companyId, cycleId, userId, pdfPublicId, pdfUrl }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [state, setState] = useState({
    cycle: {
      companyId: "",
      emailLastSent: "",
      endDate: "",
      id: "",
      startDate: "",
      surveyId: "",
    },
    loading: false,
    error: false,
  });
  const [pdfInfo, setPdfInfo] = useState({
    pdfPublicId: null,
    pdfUrl: null,
  });

  useEffect(() => {
    setPdfInfo({
      pdfPublicId,
      pdfUrl,
    });
  }, [pdfPublicId, pdfUrl]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const getCycleData = async () => {
    setState({ ...state, loading: true, error: false });
    try {
      const { data } = await api.getCycle(companyId, cycleId);
      setState({ ...state, cycle: data, loading: false });
    } catch (err) {
      setState({ ...state, loading: false, error: true });
      console.error(err);
    }
  };

  const beginCloudinaryPdfUpload = async () => {
    const guidForPublicId = createGuid();
    const uploadOptions = {
      cloudName: process.env.REACT_APP_CLOUD_NAME,
      uploadPreset: process.env.REACT_APP_CLOUD_UPLOAD_PRESET,
      publicId: `cycleResources/${cycleId}/${
        pdfInfo.pdfPublicId || guidForPublicId
      }`,
      maxVideoFileSize: 500000000,
      maxFiles: 1,
      preBatch: (cb, data) => {
        if (!data.files[0].name.includes("pdf")) {
          console.error("Please upload one of the following filetypes: pdf");
          cb({ cancel: true });
        } else {
          cb();
        }
      },
    };

    openUploadWidget(uploadOptions, async (error, res) => {
      if (!error) {
        console.log(res);
      }

      if (res.event === "success") {
        setState({ ...state, loading: true, error: false });
        const data = {
          assetPublicId: pdfInfo.pdfPublicId || guidForPublicId,
          resourceOrActivityUrl: res.info.secure_url,
        };

        await api
          .uploadPdfCycleAsset(data, cycleId, userId)
          .then(() => {
            setState({
              ...state,
              loading: false,
              error: false,
            });
            setPdfInfo({
              pdfPublicId: data.assetPublicId,
              pdfUrl: res.info.secure_url,
            });
          })
          .catch((err) => {
            console.error(err);
            setState({ ...state, loading: false, error: true });
          });
      } else {
        console.error(error);
      }
    });
  };

  const handleDeletePdfAsset = () => {
    setState({ ...state, loading: true });
    api
      .deleteCyclePdfAsset(pdfInfo.pdfPublicId)
      .then(() => {
        setState({
          ...state,
          cycle: {
            ...state.cycle,
          },
          loading: false,
        });
        setPdfInfo({
          pdfPublicId: null,
          pdfUrl: null,
        });
        handleCloseModal();
      })
      .catch((err) => {
        console.error(err);
        setState({ ...state, loading: false, error: true });
        handleCloseModal();
      });
  };

  const handleOpenDeletePdf = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    getCycleData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mainGui = () => {
    if (state.loading)
      return <Loader text={t("Cycles.loading")} style={{ marginTop: 40 }} />;
    if (!state.loading) {
      return (
        <div className="cycle-pdf-upload-wrapper" data-testid="cycleUploadTab">
          <Grid
            container
            className="cycle-upload-grid-container"
            direction="column"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Typography className="cycle-upload-instructions">
              {t("CycleUpload.instructions")}
            </Typography>
            <Dialog
              open={isModalOpen}
              onClose={handleCloseModal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <Typography className="reinforced-delete-alert-style-cycle-pdf ">
                  {t("CycleUpload.deleteAlertReinforced")}
                </Typography>
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleDeletePdfAsset} color="primary">
                  {t("ButtonGeneral.acceptDeleteAlert")}
                </Button>
                <Button onClick={handleCloseModal} color="primary">
                  {t("ButtonGeneral.denyDeleteAlert")}
                </Button>
              </DialogActions>
            </Dialog>
            {/* Upload File Button */}
            <div className="file-upload-wrapper-cycle-pdf">
              <CloudinaryContext
                cloudName={process.env.REACT_APP_CLOUD_NAME}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <p className="upload-paragraph-header-cycle-pdf">
                  {t(`CycleUpload.resourceFile`)}
                </p>
                <Button
                  color="primary"
                  className="asset-upload-button-style-cycle-pdf"
                  variant="contained"
                  onClick={beginCloudinaryPdfUpload}
                  disabled={pdfInfo.pdfPublicId !== null}
                >
                  {t(`CycleUpload.upload`)}
                </Button>
              </CloudinaryContext>
              {t(``)}
              {pdfInfo.pdfUrl ? (
                <div className="cycle-pdf-edit-upload-wrapper ">
                  <iframe
                    scrolling="no"
                    title="pdf display"
                    src={pdfInfo.pdfUrl}
                    className="cycle-pdf-edit-pdf-display-style"
                  />
                </div>
              ) : (
                t(`CycleUpload.noUpload`)
              )}

              <Button
                color="secondary"
                variant="contained"
                name="deleteResource"
                startIcon={<Delete />}
                disabled={!pdfInfo.pdfPublicId}
                onClick={() => handleOpenDeletePdf()}
                className="delete-button-style"
              >
                {t("CycleUpload.delete")}
              </Button>
            </div>
          </Grid>
        </div>
      );
    }

    return <Loader text={t("Cycles.loading")} style={{ marginTop: 40 }} />;
  };

  return <div className="cycles-wrapper">{mainGui()}</div>;
};

export default CycleUpload;
