import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./Cycles.css";
import {
  Typography,
  Button,
  Paper,
  Dialog,
  DialogActions,
  DialogTitle,
  Tab,
  Box,
  Tabs,
  Tooltip,
} from "@material-ui/core";
import { Edit, Delete, Email } from "@material-ui/icons";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import moment from "moment";
import Spacer from "../../shared/Spacer";
import api from "../../../utils/api/v1";
import Loader from "../../shared/loader/Loader";
import CycleResults from "./results/CycleResults";
import CycleResultsQuestions from "./results/CycleResultsQuestions";
import { BackButton } from "../../../UI-Components/Buttons/Buttons";
import { MissingRequiredParamError } from "../../errorHandling/MissingRequiredParamError";
import type {
  ApiCompanyResponse,
  ApiResponseCompanyCycle,
  ApiResponseSurveyModel,
} from "../../../utils/api/apiInterfaces";
import { AutomaticAnalysis } from "./AutomaticAnalysis";

let timer: number | undefined | NodeJS.Timeout;

const CycleDetails = () => {
  const { t } = useTranslation();
  const { companyId, cycleId } = useParams();
  const [state, setState] = useState({
    loading: false,
    error: false,
  });
  const [deleting, setDeleting] = useState(false);
  const [cycle, setCycle] = useState<ApiResponseCompanyCycle>();
  const [survey, setSurvey] = useState<ApiResponseSurveyModel>();
  const [company, setCompany] = useState<ApiCompanyResponse>();
  const [openAlert, setOpenAlert] = useState(false);
  const [openAlertSendEmail, setOpenAlertSendEmail] = useState(false);
  const [startDatePassed, setStartDatePassed] = useState(false);
  const [timerEnded, setTimerEnded] = useState(false);

  const [tab, setTab] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    // Fix this implementation in the future (loading state is not correct).
    getCycle();
    getCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!cycle || !cycle.surveyId) return;
    getSurvey(cycle.surveyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cycle]);

  if (!companyId || !cycleId) {
    return <MissingRequiredParamError missingParam="companyId" />;
  }

  const getCycle = () => {
    setState({ error: false, loading: true });
    api
      .getCycle(companyId, cycleId)
      .then((result) => {
        setState({ error: false, loading: false });
        setCycle(result.data);
        setStartDatePassed(Date.parse(result.data.startDate) <= Date.now());
      })
      .catch((err) => {
        console.log(err);
        setState({ error: true, loading: false });
      });
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
    timer = setTimeout(() => {
      setTimerEnded(true);
    }, 5000);
  };

  const handleOpenAlertSendEmail = () => {
    setOpenAlertSendEmail(true);
  };

  const handleCloseAlertSendEmail = () => {
    setOpenAlertSendEmail(false);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
    clearTimeout(timer);
    setTimeout(() => {
      setTimerEnded(false);
    }, 250);
  };

  const handleSendEmail = () => {
    handleCloseAlertSendEmail();
    setState({ ...state, loading: true, error: false });

    api
      .sendCycleEmail(companyId, cycleId)
      .then(() => {
        setState({ ...state, loading: false, error: false });
        handleCloseAlertSendEmail();
      })
      .catch((err) => {
        setState({ ...state, error: true });
        console.error(err);
      });
  };

  const handleDeleteCycle = (companyIdParam: string, cycleIdParam: string) => {
    setDeleting(true);

    api
      .deleteCycle(companyIdParam, cycleIdParam)
      .then(() => {
        setDeleting(false);
        setOpenAlert(false);
        navigate(`/companies/${companyIdParam}`);
      })
      .catch((err) => {
        setDeleting(false);
        setOpenAlert(false);
        setState({ ...state, error: true });
        console.error(err);
      });
  };

  const getCompany = () => {
    api
      .getCompany(companyId)
      .then((result) => {
        setCompany(result.data);
      })
      .catch((err) => {
        console.log(err);
        setState({ ...state, error: true, loading: false });
      });
  };

  const getSurvey = (surveyId: string) => {
    setState({ ...state, loading: true });
    api
      .getSurvey(surveyId)
      .then((result) => {
        setState({ error: false, loading: false });
        setSurvey(result.data);
      })
      .catch((err) => {
        console.log(err);
        setState({ ...state, error: true, loading: false });
      });
  };

  const handleChange = (_: ChangeEvent<unknown>, newValue: number) => {
    setTab(newValue);
  };

  const mainGui = () => {
    if (state.loading || deleting)
      return (
        <Loader
          text={
            state.loading
              ? t("CycleDetails.loading")
              : t("CycleDetails.deletingCycle")
          }
          style={{ marginTop: 40 }}
        />
      );
    if (!state.loading && cycle) {
      return (
        <>
          <div className="cycles-toolbar">
            <BackButton navigateTo={`/companies/${companyId}`} />
            <Typography className="section-title" variant="h6">
              {company ? company.name : t("CycleDetails.company")}
            </Typography>

            <Spacer />

            <Link to="edit" className="cycles-link-style">
              <Button
                startIcon={<Edit />}
                color="primary"
                variant="contained"
                style={{ marginRight: "0.5rem" }}
                data-testid="editCycleButton"
              >
                {t("CycleDetails.edit")}
              </Button>
            </Link>
            <Tooltip
              title={startDatePassed ? t("CycleDetails.deleteBtnTooltip") : ""}
              placement="bottom-start"
            >
              <span>
                <Button
                  startIcon={<Delete />}
                  color="secondary"
                  variant="contained"
                  disabled={startDatePassed}
                  onClick={handleOpenAlert}
                >
                  {t("CycleDetails.delete")}
                </Button>
              </span>
            </Tooltip>

            <Dialog
              open={openAlert}
              onClose={handleCloseAlert}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {startDatePassed ? (
                  <Typography className="reinforced-delete-alert-style">
                    {t("CycleDetails.deleteAlertReinforced")}
                  </Typography>
                ) : (
                  <Typography className="delete-alert-style">
                    {t("CycleDetails.deleteAlert")}
                  </Typography>
                )}
              </DialogTitle>

              <DialogActions>
                <Button
                  onClick={() => handleDeleteCycle(companyId, cycleId)}
                  disabled={startDatePassed && !timerEnded}
                  className={
                    startDatePassed && !timerEnded
                      ? "deny-delete-accept-style"
                      : ""
                  }
                  color="primary"
                >
                  {t("CycleDetails.acceptDeleteAlert")}
                </Button>
                <Button onClick={handleCloseAlert} color="primary" autoFocus>
                  {t("CycleDetails.denyDeleteAlert")}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <Tabs className="company-tabs" value={tab} onChange={handleChange}>
            <LinkTab
              label={t("CompanyDetails.details")}
              href={`/companies/${companyId}/cycles/${cycleId}`}
              data-testid="cycleDetailsTabButton"
            />
            <LinkTab
              label={t("CompanyDetails.resultOverview")}
              href={`/companies/${companyId}/cycles/${cycleId}/results-overview`}
              data-testid="cycleResultsTabButton"
            />
            <LinkTab
              label={t("CompanyDetails.resultQuestions")}
              href={`/companies/${companyId}/cycles/${cycleId}/results-questions`}
            />
            <LinkTab
              label={t("CompanyDetails.automaticAnalysis")}
              href={`/companies/${companyId}/cycles/${cycleId}/automatic-analysis`}
              data-testid="automaticAnalysisTab"
            />
          </Tabs>
          <TabPanel value={tab} index={0}>
            <Paper
              className="section-detail-layout"
              data-testid="cycleDetailsTab"
            >
              <div className="cycle-details-section">
                <Button
                  startIcon={<Email />}
                  className="send-email"
                  onClick={() => {
                    handleOpenAlertSendEmail();
                  }}
                >
                  {t("CycleDetails.sendSurvey")}
                </Button>

                <Dialog
                  open={openAlertSendEmail}
                  onClose={handleCloseAlertSendEmail}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {t("CycleDetails.emailSurvey")}
                  </DialogTitle>

                  <DialogActions>
                    <Button onClick={handleSendEmail} color="primary">
                      {t("CycleDetails.acceptDeleteAlert")}
                    </Button>
                    <Button
                      onClick={handleCloseAlertSendEmail}
                      color="primary"
                      autoFocus
                    >
                      {t("CycleDetails.denyDeleteAlert")}
                    </Button>
                  </DialogActions>
                </Dialog>

                <Typography className="section-detail-header" variant="h5">
                  {t("CycleDetails.survey")}
                </Typography>
                <span>{(survey && survey.name) || "-"}</span>
              </div>
              <div className="cycle-details-section">
                <Typography className="section-detail-header" variant="h5">
                  {t("CycleDetails.startDate")}
                </Typography>
                <span>{moment(cycle.startDate).format("ll")}</span>
              </div>
              <div className="cycle-details-section">
                <Typography className="section-detail-header" variant="h5">
                  {t("CycleDetails.endDate")}
                </Typography>
                <span>{moment(cycle.endDate).format("ll")}</span>
              </div>
            </Paper>
          </TabPanel>

          <TabPanel value={tab} index={1}>
            <CycleResults />
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <CycleResultsQuestions />
          </TabPanel>
          <TabPanel value={tab} index={3}>
            <AutomaticAnalysis cycle={cycle} />
          </TabPanel>
        </>
      );
    }

    return (
      <Loader text={t("CycleDetails.loading")} style={{ marginTop: 40 }} />
    );
  };

  return <div className="cycles-wrapper">{mainGui()}</div>;
};

export default CycleDetails;

interface LinkTabProps {
  label: string;
  href: string;
}

const LinkTab = (props: LinkTabProps) => (
  <Tab
    component="a"
    onClick={(event) => event.preventDefault()}
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  />
);

const TabPanel = (props: {
  children: JSX.Element;
  value: number;
  index: number;
}) => {
  // eslint-disable-next-line react/prop-types
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
};
