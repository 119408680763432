import { useRef, VFC } from "react";
import "./ProgBar.css";

interface Props {
  width: string;
}

const ProgBar: VFC<Props> = ({ width }) => {
  const progBar = useRef<HTMLDivElement>(null);

  return <div className="progress-bar" ref={progBar} style={{ width }} />;
};

export default ProgBar;
