import { StyleSheet } from "@react-pdf/renderer";

export const companyResultStyling = StyleSheet.create({
  page: {
    backgroundColor: "white",
    width: "100%",
    height: "100vh",
    orientation: "portrait",
    backgroundSize: "cover",
    alignItems: "center",
    position: "relative",
    lineHeight: 1.5,
  },
  companyResultParentDiv: {
    position: "relative",
    alignItems: "center",
    marginTop: "40px",
    backgroundColor: "#068466",
    width: "460px",
    height: "110px",
  },
  companyResultHeadlineText: {
    marginTop: "25px",
    color: "white",
    textAlign: "center",
    fontSize: "24px",
    letterSpacing: "5px",
    fontFamily: "Helvetica-Bold",
    textTransform: "uppercase",
  },
  companyResultHorizontalLine: {
    position: "absolute",
    top: 0,
    bottom: 35,
    textAlign: "center",
    width: "440px",
    borderBottom: "3px",
    borderBottomColor: "white",
    borderBottomStyle: "solid",
    borderBottomWidth: "2px",
  },
  companyResultTextParentDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "460px",
    height: "450px",
    position: "relative",
  },
  companyResultTextColumn: {
    width: "220px",
    position: "relative",
  },
  companyResultParagraphs: {
    fontSize: "11px",
    fontFamily: "Times-Roman",
    marginTop: "20px",
    width: "218px",
  },
  companyResultList: {
    display: "flex",
    flexDirection: "column",
  },
  companyResultCircleContainer: {
    position: "relative",
  },
  companyResultCircleDiv: {
    backgroundColor: "#068466",
    width: "220px",
    height: "220px",
    borderRadius: "50%",
    position: "absolute",
    top: -270,
    right: -310,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: 1.1,
  },
  companyResultCircleHeadline: {
    color: "white",
    textAlign: "center",
    fontSize: "40px",
    letterSpacing: "1px",
    fontFamily: "Helvetica-Bold",
  },
  companyResultCircleText: {
    fontSize: "17px",
    letterSpacing: "0px",
    fontFamily: "Helvetica",
    color: "white",
  },
  companyResultImageContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "77vh",
    backgroundColor: "#000",
    position: "relative",
  },
  companyResultImageText: {
    fontSize: "27px",
    letterSpacing: "1px",
    fontFamily: "Helvetica",
    color: "white",
    lineHeight: "1.1px",
    position: "absolute",
    top: 100,
    left: 100,
    width: "140px",
    textAlign: "center",
  },
  companyResultImage: {
    zIndex: 1,
    opacity: 0.5,
  },
});
