export const getCycleYear = (dateToConvert: string) => {
  const date = new Date(dateToConvert);
  return `${date.getFullYear()}`;
};

export const getCycleSeason = (dateToConvert: string) => {
  const date = new Date(dateToConvert);
  const cycleMonth = `${date.getMonth() + 1}`;
  return Number(cycleMonth) <= 6 ? "springSeason" : "fallSeason";
};

export const getCurrentDate = () => {
  const date = new Date();
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};
