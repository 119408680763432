import React, { useState, useEffect } from "react";
import { Typography, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { CloudinaryContext } from "cloudinary-react";
import SaveIcon from "@material-ui/icons/Save";
import Loader from "../../../../shared/loader/Loader";
import { openUploadWidget } from "../../../../../utils/cloudinary/cloudinaryService";
import { createGuid } from "../../../../../utils/helpers/createGuid";
import api from "../../../../../utils/api/v1";
import { baseGuid } from "../../../../../common/constants/guid";

const DefaultCompanyMail = () => {
  const { t } = useTranslation();
  const [reminderCreated, setReminderCreated] = useState(false);
  const [state, setState] = useState({
    loading: false,
    error: false,
  });
  const { companyId } = useParams();
  const CompanyId = {
    CompanyId: companyId,
  };

  const [requiredFields, setRequiredFields] = useState({
    welcomeMailEnglishUrl: null,
    welcomeMailSwedishUrl: null,
    reminderSurveyMailEnglishUrl: null,
    reminderSurveyMailSwedishUrl: null,
    reminderInactiveMailEnglishUrl: null,
    reminderInactiveMailSwedishUrl: null,
  });

  const [pdfPublicIds, setPdfPublicIds] = useState({
    welcomeMailEnglishPublicId: baseGuid.empty,
    welcomeMailSwedishPublicId: baseGuid.empty,
    reminderSurveyMailEnglishPublicId: baseGuid.empty,
    reminderSurveyMailSwedishPublicId: baseGuid.empty,
    reminderInactiveMailEnglishPublicId: baseGuid.empty,
    reminderInactiveMailSwedishPublicId: baseGuid.empty,
  });

  const getBasicReminderEmailsForCompany = () => {
    setState({ ...state, loading: true, error: false });
    try {
      api.getCompanyEmailBasicReminders(companyId).then(({ data }) => {
        if (data.id !== undefined && data.id !== null) {
          setRequiredFields({
            ...requiredFields,
            welcomeMailEnglishUrl: data.welcomeMailEnglishUrl,
            welcomeMailSwedishUrl: data.welcomeMailSwedishUrl,
            reminderSurveyMailEnglishUrl: data.reminderSurveyMailEnglishUrl,
            reminderSurveyMailSwedishUrl: data.reminderSurveyMailSwedishUrl,
            reminderInactiveMailEnglishUrl: data.reminderInactiveMailEnglishUrl,
            reminderInactiveMailSwedishUrl: data.reminderInactiveMailSwedishUrl,
          });
          setPdfPublicIds({
            ...pdfPublicIds,
            welcomeMailEnglishPublicId: data.welcomeMailEnglishPublicId,
            welcomeMailSwedishPublicId: data.welcomeMailSwedishPublicId,
            reminderSurveyMailSwedishPublicId:
              data.reminderSurveyMailSwedishPublicId,
            reminderSurveyMailEnglishPublicId:
              data.reminderSurveyMailEnglishPublicId,
            reminderInactiveMailEnglishPublicId:
              data.reminderInactiveMailEnglishPublicId,
            reminderInactiveMailSwedishPublicId:
              data.reminderInactiveMailSwedishPublicId,
          });
          setReminderCreated(true);
          setState({ ...state, loading: false });
        }
        setState({ ...state, loading: false });
      });
    } catch (error) {
      console.log(error);
      setState({ ...state, error: true, loading: false });
    }
  };

  useEffect(() => {
    if (!reminderCreated) {
      getBasicReminderEmailsForCompany();
    } else {
      setState({ ...state, loading: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deletePdfDocumentAsBasicReminderEmailForCompany = (field, index) => {
    setState({ ...state, loading: true, error: false });
    try {
      setRequiredFields({ ...requiredFields, [field]: null });
      const fieldPublicIdToChange = Object.keys(pdfPublicIds)[index];
      setPdfPublicIds({
        ...pdfPublicIds,
        [fieldPublicIdToChange]: baseGuid.empty,
      });
      setState({ ...state, loading: false });
    } catch (error) {
      console.log(error);
      setState({ ...state, loading: true, error: false });
    }
  };

  const uploadImageToCloudinary = async (field, index) => {
    const newPDFPublicId = createGuid();

    const uploadOptions = {
      cloudName: process.env.REACT_APP_CLOUD_NAME,
      uploadPreset: process.env.REACT_APP_CLOUD_UPLOAD_PRESET,
      publicId: `companyEmailsPDF/${companyId}/${newPDFPublicId}`,
      maxFiles: 1,
      sources: ["local"],
      preBatch: (cb, data) => {
        if (
          !data.files[0].name.endsWith("pdf") &&
          !data.files[0].name.endsWith("jpeg") &&
          !data.files[0].name.endsWith("png") &&
          !data.files[0].name.endsWith("jfif")
        ) {
          console.log("Wrong file type.");
          // TODO: Add a modal to inform user about the error.
          cb({ cancel: true });
        } else {
          cb();
        }
      },
    };

    openUploadWidget(uploadOptions, async (error, res) => {
      if (res.event === "success") {
        setState({ ...state, loading: true, error: false });
        setRequiredFields((OldState) => ({
          ...OldState,
          [field]: res.info.secure_url,
        }));
        const fieldPublicIdToChange = Object.keys(pdfPublicIds)[index];
        setPdfPublicIds((OldState) => ({
          ...OldState,
          [fieldPublicIdToChange]: newPDFPublicId,
        }));
        setState({ ...state, loading: false, error: false });
      } else {
        console.log(error);
      }
    });
  };

  const SaveBasicReminderEmailsForCompany = async () => {
    const companyEmailBasicReminders = {
      ...CompanyId,
      ...pdfPublicIds,
      ...requiredFields,
    };
    setState({ ...state, loading: true, error: false });
    if (!reminderCreated) {
      try {
        await api
          .createCompanyEmailBasicReminders(companyEmailBasicReminders)
          .then(() => {
            setReminderCreated(true);
            setState({ ...state, loading: false });
          });
      } catch (error) {
        console.log(error);
        setState({ ...state, error: true, loading: false });
      }
    }
    try {
      await api
        .updateCompanyEmailBasicReminders(companyEmailBasicReminders)
        .then(() => {
          setState({ ...state, loading: false });
        });
    } catch (error) {
      console.log(error);
      setState({ ...state, error: true, loading: false });
    }
  };

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
      width: "200px",
    },
    headlines: {
      margin: theme.spacing(2),
    },
    row: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: "100%",
      float: "left",
      padding: "20px",
      borderBottom: "5px inset #eeb862",
    },
    buttonrow: {
      display: "flex",
      flexDirection: "row",
      margin: "15px 0 15px 0",
    },
    pdfImage: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      marginLeft: "10px",
    },
    saveButtonDiv: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      padding: "20px",
    },
  }));
  const classes = useStyles();

  if (state.loading) {
    return (
      <Loader
        text={t("DefaultCompanyMail.SavingChanges")}
        style={{ marginTop: 40 }}
      />
    );
  }

  return (
    <div>
      <Grid container spacing={3}>
        {Object.keys(requiredFields).map((field, index) => (
          <Grid key={field} id={index} item xs={6}>
            <div id={field} className={classes.row}>
              <Typography className={classes.headlines} variant="h5">
                {t(`DefaultCompanyMail.${field}`)}
              </Typography>
              <Typography className={classes.headlines} variant="h6">
                {t(`DefaultCompanyMail.${index}`)}
              </Typography>
              <div className={classes.pdfImage}>
                {Object.values(requiredFields)[index] !== null &&
                Object.values(requiredFields)[index] !== undefined ? (
                  <iframe
                    scrolling="no"
                    title="pdf display"
                    src={Object.values(requiredFields)[index]}
                    width="fit-content"
                    height="300px"
                  />
                ) : null}
              </div>
              <div className={classes.buttonrow}>
                <CloudinaryContext cloudName={process.env.REACT_APP_CLOUD_NAME}>
                  <Button
                    variant="contained"
                    color="default"
                    className={classes.button}
                    startIcon={<CloudUploadIcon />}
                    onClick={() => uploadImageToCloudinary(field, index)}
                  >
                    {t("DefaultCompanyMail.Upload")}
                  </Button>
                </CloudinaryContext>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  startIcon={<DeleteIcon />}
                  onClick={() =>
                    deletePdfDocumentAsBasicReminderEmailForCompany(
                      field,
                      index
                    )
                  }
                >
                  {t("DefaultCompanyMail.Delete")}
                </Button>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
      <div className={classes.saveButtonDiv}>
        <Typography className={classes.headlines} variant="h4">
          {t("DefaultCompanyMail.SaveChanges")}
        </Typography>
        {reminderCreated ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => SaveBasicReminderEmailsForCompany()}
            startIcon={<SaveIcon />}
            className={classes.button}
          >
            {t("DefaultCompanyMail.SaveChanges")}
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={() => SaveBasicReminderEmailsForCompany()}
            startIcon={<SaveIcon />}
            className={classes.button}
          >
            {t("DefaultCompanyMail.CreateReminders")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default DefaultCompanyMail;
