import type { VFC } from "react";
import "./Error.css";
import { useTranslation } from "react-i18next";

interface Props {
  missingParam: string;
}

export const MissingRequiredParamError: VFC<Props> = ({ missingParam }) => {
  const { t } = useTranslation();
  return (
    <div className="missing-required-param-wrapper">
      <h4>
        {t("MissingRequiredParamError.missingParam")}
        {missingParam}
      </h4>
    </div>
  );
};
