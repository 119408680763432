import { createTheme } from "@material-ui/core/styles";

export const defaultColors = {
  colorPrimary: "#068466",
  colorPrimary_light: "#00af85",
  colorPrimary_dark: "#116b56",
  colorSecondary: "#eeb862",
  colorSecondary_light: "#fcc265",
  colorSecondary_dark: "#daa654",
  color3: "#005450",
  color3_light: "#007f79",
  color3_dark: "#084441",
  color4: "#2d2a35",
  color4_light: "#403b4e",
  color4_dark: "#29272f",
  error: "#f44336",
  colorWarning: "#FF0000",
};

const theme = createTheme({
  palette: {
    primary: {
      main: defaultColors.colorPrimary,
    },
    secondary: {
      main: defaultColors.colorSecondary,
    },
    color3: {
      main: defaultColors.color3,
    },
    color4: {
      main: defaultColors.color4,
    },
    error: {
      main: defaultColors.error,
    },
  },
});

window.document.documentElement.style.setProperty(
  "--color-primary",
  theme.palette.primary.main
);
window.document.documentElement.style.setProperty(
  "--color-primary-light",
  theme.palette.primary.light
);
window.document.documentElement.style.setProperty(
  "--color-primary-dark",
  theme.palette.primary.dark
);
window.document.documentElement.style.setProperty(
  "--color-secondary",
  theme.palette.secondary.main
);
window.document.documentElement.style.setProperty(
  "--color-secondary-light",
  theme.palette.secondary.light
);
window.document.documentElement.style.setProperty(
  "--color-secondary-dark",
  theme.palette.secondary.dark
);
window.document.documentElement.style.setProperty(
  "--color-3",
  theme.palette.color3.main
);
window.document.documentElement.style.setProperty(
  "--color-3-light",
  theme.palette.color3.light
);
window.document.documentElement.style.setProperty(
  "--color-3-dark",
  theme.palette.color3.dark
);
window.document.documentElement.style.setProperty(
  "--color-4",
  theme.palette.color4.main
);
window.document.documentElement.style.setProperty(
  "--color-4-light",
  theme.palette.color4.light
);
window.document.documentElement.style.setProperty(
  "--color-4-dark",
  theme.palette.color4.dark
);

export default theme;
