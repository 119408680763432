import { View, Circle, Svg, StyleSheet, Text } from "@react-pdf/renderer";
import type { VFC } from "react";

const styles = StyleSheet.create({
  circleText: {
    transform: "rotate(-90)",
    fontSize: "2.5px",
  },
  svg: {
    transform: "rotate(-270, 100, 100)",
  },
});

interface Props {
  low: number;
  medium: number;
  high: number;
}

export const CompanyStatisticsCircleDiagram: VFC<Props> = ({
  low,
  medium,
  high,
}) => (
  <View>
    <Svg
      viewBox="0 0 33.83098862 33.83098862"
      width="100"
      height="100"
      style={styles.svg}
    >
      <Circle
        stroke="#efefef"
        strokeWidth="6"
        fill="none"
        cx="16.91549431"
        cy="16.91549431"
        r="10.91549431"
      />
      {low && (
        <Circle
          stroke="#EE7362"
          strokeWidth="6"
          strokeDasharray={`${68.6 * ((high + medium + low) / 100)},100`}
          fill="none"
          cx="16.91549431"
          cy="16.91549431"
          r="10.91549431"
        />
      )}
      {medium && (
        <Circle
          stroke="#FDCF5B"
          strokeWidth="6"
          strokeDasharray={`${68.6 * ((high + medium) / 100)},100`}
          fill="none"
          cx="16.91549431"
          cy="16.91549431"
          r="10.91549431"
        />
      )}
      {high && (
        <Circle
          stroke="#068466"
          strokeWidth="6"
          strokeDasharray={`${68.6 * (high / 100)},100`}
          fill="none"
          cx="16.91549431"
          cy="16.91549431"
          r="10.91549431"
        />
      )}
      <Circle stroke="#EE7362" cx="20" cy="20" r="0.4" fill="#EE7362" />
      <Circle stroke="#FDCF5B" cx="17" cy="20" r="0.4" fill="#FDCF5B" />
      <Circle stroke="#068466" cx="14" cy="20" r="0.4" fill="#068466" />
      <Text x="-17.5" y="15" style={styles.circleText}>
        {`${Math.round(high)}%`}
      </Text>
      <Text x="-17.5" y="18" style={styles.circleText}>
        {`${Math.round(medium)}%`}
      </Text>
      <Text x="-17.5" y="21" style={styles.circleText}>
        {`${Math.round(low)}%`}
      </Text>
    </Svg>
  </View>
);
