import React, { useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import "./CompanyModal.css";
import {
  Button,
  CircularProgress,
  FormControl,
  Modal,
  Paper,
  TextField,
  Checkbox,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import api from "../../../utils/api/v1";
import Spacer from "../../shared/Spacer";
import Alert from "../../shared/Alert";

interface Props {
  open: boolean;
  onClose: (id: string | null) => void;
}

const CompanyModal: VFC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const [form, setForm] = useState({
    name: "",
    description: "",
    address: "",
    hasDepartments: false,
    hasTitles: false,
    hasBosses: false,
  });
  const [state, setState] = useState({
    error: "",
    loading: false,
  });
  const [invalidForm, setInvalidForm] = useState(false);

  const handleChange = ({
    target: { name, value },
  }: {
    target: { name: string; value: string };
  }) => {
    setForm({
      ...form,
      [name]: value,
    });

    setInvalidForm(name === "name" && value === "");
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSave = () => {
    if (form.name.trim() === "") {
      setInvalidForm(true);
      return;
    }

    setState({ ...state, loading: true });
    api
      .createCompany(form)
      .then(({ data }) => {
        onClose(data.id);
      })
      .catch((e) => {
        let message = "";
        switch (e.response.status) {
          case 409:
            message = t("CompanyModal.takenName");
            break;
          default:
            message = t("CompanyModal.unknownError");
        }
        setState({ loading: false, error: message });
      });
  };

  return (
    <Modal
      open={open}
      onClose={() => onClose && onClose(null)}
      aria-labelledby="company-modal-title"
      aria-describedby="company-modal-description"
    >
      <Paper className="company-wrapper">
        <h2 id="company-modal-title">{t("CompanyModal.title")}</h2>
        <FormControl className="company-form">
          <TextField
            label={t("CompanyModal.name")}
            inputMode="text"
            value={form.name}
            name="name"
            required
            error={invalidForm}
            helperText={
              invalidForm
                ? t("missingMandatoryInput", {
                    field: t("CompanyDetails.name"),
                  })
                : ""
            }
            onChange={handleChange}
          />
          <TextField
            label={t("CompanyModal.description")}
            inputMode="text"
            value={form.description}
            name="description"
            rows={4}
            multiline
            variant="outlined"
            onChange={handleChange}
          />
          <TextField
            label={t("CompanyModal.address")}
            inputMode="text"
            value={form.address}
            name="address"
            multiline
            rows={4}
            variant="outlined"
            onChange={handleChange}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={form.hasDepartments}
                onChange={handleCheckboxChange}
                color="primary"
                name="hasDepartments"
              />
            }
            label={t("CompanyDetails.departments")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={form.hasTitles}
                onChange={handleCheckboxChange}
                color="primary"
                name="hasTitles"
              />
            }
            label={t("CompanyDetails.titles")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={form.hasBosses}
                onChange={handleCheckboxChange}
                color="primary"
                name="hasBosses"
              />
            }
            label={t("CompanyDetails.bosses")}
          />
        </FormControl>
        {state.loading ? <CircularProgress style={{ marginTop: 20 }} /> : null}
        {state.error ? (
          <Alert style={{ marginTop: 20 }}>{state.error}</Alert>
        ) : null}
        <div className="buttons">
          <Spacer />
          <Button
            disabled={state.loading}
            variant="outlined"
            className="cancel"
            onClick={() => onClose && onClose(null)}
          >
            {t("CompanyModal.cancel")}
          </Button>
          <Button
            disabled={state.loading}
            color="primary"
            variant="contained"
            onClick={handleSave}
          >
            {t("CompanyModal.save")}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

export default CompanyModal;
