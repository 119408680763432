import React from "react";
import { AppBar, Hidden, Toolbar, IconButton } from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import "./TopBar.css";
import PropTypes from "prop-types";
import logo from "../../assets/logo_white.png";
import LanguagePicker from "./LanguagePicker";

const TopBar = ({ menuCallback }) => (
  <AppBar position="static" className="top-bar">
    <Toolbar>
      <Hidden mdUp>
        <IconButton style={{ color: "white" }} onClick={() => menuCallback()}>
          <Menu />
        </IconButton>
      </Hidden>
      <img src={logo} alt="dear change." className="dc-logo" />
      <div style={{ flex: 1 }} />
      <LanguagePicker />
    </Toolbar>
  </AppBar>
);

TopBar.propTypes = {
  menuCallback: PropTypes.func.isRequired,
};

export default TopBar;
