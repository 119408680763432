/* eslint-disable no-nested-ternary */
import { useEffect, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import "./MicroCourseResources.css";
import {
  Paper,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { languages, getLanguage } from "../../../i18n";
import Spacer from "../../shared/Spacer";
import api from "../../../utils/api/v1";
import Loader from "../../shared/loader/Loader";
import { BackButton } from "../../../UI-Components/Buttons/Buttons";
import type { ApiResponseMicroCourseResource } from "../../../utils/api/apiInterfaces";
import { MissingRequiredParamError } from "../../errorHandling/MissingRequiredParamError";

interface State {
  loading: boolean;
  error: boolean;
  microCourseResource: ApiResponseMicroCourseResource | null;
}

export const MicroCourseResourceDetails: VFC = () => {
  const { t } = useTranslation();
  const { microCourseResourceId } = useParams();
  const [openAlert, setOpenAlert] = useState(false);
  const currentLanguage = getLanguage();
  const navigate = useNavigate();

  const [state, setState] = useState<State>({
    loading: false,
    error: false,
    microCourseResource: null,
  });

  useEffect(() => {
    (async () => {
      await getMicroCourseResource();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!microCourseResourceId) {
    return <MissingRequiredParamError missingParam="microCourseResourceId" />;
  }

  const deleteMicroCourseResource = async () => {
    setState({ ...state, loading: true });
    try {
      await api.deleteMicroCourseResource(microCourseResourceId);
      setState({ ...state, loading: false });
      navigate(`/micro-courses/${state.microCourseResource?.microCourseId}`);
    } catch (err) {
      console.error(err);
      setState({ ...state, loading: false, error: true });
    }
  };

  const getMicroCourseResource = async () => {
    setState({ ...state, loading: true });
    try {
      const {
        data: { microCourseResource },
      } = await api.getMicroCourseResource(microCourseResourceId);
      setState({
        ...state,
        microCourseResource,
        loading: false,
      });
    } catch (err) {
      console.error(err);
      setState({ ...state, loading: false, error: true });
    }
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const mainGui = () => {
    if (state.loading)
      return (
        <Loader
          text={t("MicroCourses.microCourseDetails.loading")}
          style={{ marginTop: 40 }}
        />
      );
    if (!state.loading && state.microCourseResource) {
      return (
        <>
          <div className="micro-course-resource-details-toolbar">
            <BackButton />
            <Typography
              className="micro-course-resource-details-title"
              variant="h6"
            >
              {currentLanguage === "sv"
                ? state.microCourseResource.nameSv
                : state.microCourseResource.nameEn}
            </Typography>
            <Spacer />
            <Link
              to="edit"
              className="micro-course-resource-details-link-style"
            >
              <Button startIcon={<Edit />} color="primary" variant="contained">
                {t("ButtonGeneral.edit")}
              </Button>
            </Link>

            <Button
              startIcon={<Delete />}
              color="secondary"
              variant="contained"
              onClick={() => {
                handleOpenAlert();
              }}
              style={{ margin: 5 }}
            >
              {t("ButtonGeneral.delete")}
            </Button>

            <Dialog
              open={openAlert}
              onClose={handleCloseAlert}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {t("MicroCourses.microCourseDetails.deleteAlert")}
              </DialogTitle>
              <DialogActions>
                <Button onClick={deleteMicroCourseResource} color="primary">
                  {t("ButtonGeneral.acceptDeleteAlert")}
                </Button>
                <Button onClick={handleCloseAlert} color="primary" autoFocus>
                  {t("ButtonGeneral.denyDeleteAlert")}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <div>
            <Paper className="section-detail-layout">
              <Typography variant="h5" className="section-detail-title">
                {t(`MicroCourses.microCourseDetails.detailsHeader`)}
              </Typography>
              <div />
              <div>
                {languages.map((lang) => (
                  <div
                    key={lang}
                    className="micro-course-resource-details-text-description-container"
                  >
                    <div className="micro-course-resource-details-text-container">
                      <h4>
                        {t(
                          `MicroCourses.microCourseDetails.name${
                            lang.charAt(0).toUpperCase() + lang.slice(1)
                          }`
                        )}
                      </h4>
                      <p>
                        {state.microCourseResource &&
                          state.microCourseResource[
                            `name${
                              lang.charAt(0).toUpperCase() + lang.slice(1)
                            }`
                          ]}
                      </p>
                    </div>
                  </div>
                ))}
                <div className="micro-course-resource-details-text-description-container">
                  <div className="micro-course-resource-details-text-container-style">
                    <h4>{t(`MicroCourses.microCourseDetails.timeRequired`)}</h4>
                    <p>
                      {state.microCourseResource.timeRequired}{" "}
                      {state.microCourseResource.timeRequired === 1
                        ? t(`MicroCourses.microCourseDetails.minute`)
                        : t(`MicroCourses.microCourseDetails.minutes`)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="micro-course-resource-details-assets-container">
                <div className="micro-course-resource-details-uploaded-asset-container">
                  <p className="micro-course-resource-details-presentation-image-text">
                    {t(`MicroCourses.microCourseDetails.overviewImage`)}
                  </p>
                  {state.microCourseResource.imageUrl ? (
                    <img
                      src={state.microCourseResource.imageUrl}
                      alt="resourceImageUrl"
                      className="micro-course-resource-details-image-url"
                    />
                  ) : (
                    t(
                      `MicroCourses.microCourseDetails.overviewImageNotUploaded`
                    )
                  )}
                </div>
                <div className="micro-course-resource-details-uploaded-asset-container">
                  <p className="micro-course-resource-details-presentation-image-text">
                    {t(`MicroCourses.microCourseDetails.resourceVideoAsset`)}
                  </p>
                  {state.microCourseResource.resourceVideoUrl ? (
                    <div>
                      {state.microCourseResource.resourceVideoUrl.includes(
                        "video"
                      ) ? (
                        <video
                          width="320"
                          height="240"
                          controls
                          className="video-style"
                        >
                          <source
                            src={state.microCourseResource.resourceVideoUrl}
                            type="video/mp4"
                          />
                          <track
                            src="captions_en.vtt"
                            kind="captions"
                            srcLang="en"
                            label="english_captions"
                          />
                        </video>
                      ) : (
                        state.microCourseResource.resourceVideoUrl.includes(
                          "youtube"
                        ) && (
                          <iframe
                            width="400"
                            height="250"
                            title="youtube-video"
                            src={state.microCourseResource.resourceVideoUrl}
                          />
                        )
                      )}
                    </div>
                  ) : (
                    t(
                      `MicroCourses.microCourseDetails.resourceVideoAssetNotUploaded`
                    )
                  )}
                </div>
                <div className="micro-course-resource-details-uploaded-asset-container">
                  <p className="micro-course-resource-details-presentation-image-text">
                    {t(`MicroCourses.microCourseDetails.resourceVideoAssetEn`)}
                  </p>
                  {state.microCourseResource.resourceVideoUrlEn ? (
                    <div>
                      {state.microCourseResource.resourceVideoUrlEn.includes(
                        "video"
                      ) ? (
                        <video
                          width="320"
                          height="240"
                          controls
                          className="video-style"
                        >
                          <source
                            src={state.microCourseResource.resourceVideoUrlEn}
                            type="video/mp4"
                          />
                          <track
                            src="captions_en.vtt"
                            kind="captions"
                            srcLang="en"
                            label="english_captions"
                          />
                        </video>
                      ) : (
                        state.microCourseResource.resourceVideoUrlEn.includes(
                          "youtube"
                        ) && (
                          <iframe
                            width="400"
                            height="250"
                            title="youtube-video"
                            src={state.microCourseResource.resourceVideoUrlEn}
                          />
                        )
                      )}
                    </div>
                  ) : (
                    t(
                      `MicroCourses.microCourseDetails.resourceVideoAssetEnNotUploaded`
                    )
                  )}
                </div>
                <div className="micro-course-resource-details-uploaded-asset-container">
                  <p className="micro-course-resource-details-presentation-image-text">
                    {t(`MicroCourses.microCourseDetails.resourcePdfAsset`)}
                  </p>
                  {state.microCourseResource.resourcePdfUrl ? (
                    <a href={state.microCourseResource.resourcePdfUrl}>
                      <div className="micro-course-resource-details-pdf-image-url" />
                      <p className="micro-course-resource-details-pdf-text">
                        {t(`MicroCourses.microCourseDetails.download`)}
                      </p>
                    </a>
                  ) : (
                    t(
                      `MicroCourses.microCourseDetails.resourcePdfAssetNotUploaded`
                    )
                  )}
                </div>
                <div className="micro-course-resource-details-uploaded-asset-container">
                  <p className="micro-course-resource-details-presentation-image-text">
                    {t(`MicroCourses.microCourseDetails.resourcePdfAssetEn`)}
                  </p>
                  {state.microCourseResource.resourcePdfUrlEn ? (
                    <a href={state.microCourseResource.resourcePdfUrlEn}>
                      <div className="micro-course-resource-details-pdf-image-url" />
                      <p className="micro-course-resource-details-pdf-text">
                        {t(`MicroCourses.microCourseDetails.download`)}
                      </p>
                    </a>
                  ) : (
                    t(
                      `MicroCourses.microCourseDetails.resourcePdfAssetEnNotUploaded`
                    )
                  )}
                </div>
              </div>
            </Paper>
          </div>
        </>
      );
    }

    return (
      <Loader
        text={t("MicroCourses.microCourseDetails.loading")}
        style={{ marginTop: 40 }}
      />
    );
  };

  return <div className="surveys-wrapper">{mainGui()}</div>;
};
