import "./Admins.css";
import { Typography } from "@material-ui/core";
import { useParams } from "react-router";

const AdminDetails = () => {
  const { adminId } = useParams();

  return (
    <div className="admins-wrapper">
      <div className="admins-toolbar">
        <Typography className="admins-title" variant="h6">
          {adminId}
        </Typography>
      </div>
      <div className="">This is an admin</div>
    </div>
  );
};

export default AdminDetails;
