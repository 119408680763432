import { useTranslation } from "react-i18next";
import "./Admins.css";
import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@material-ui/core";
import { useNavigate } from "react-router";
import { Add } from "@material-ui/icons";
import Spacer from "../shared/Spacer";

const Admins = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="admins-wrapper">
      <div className="admins-toolbar">
        <Typography className="admins-title" variant="h6">
          {t("Admins.title")}
        </Typography>
        <Spacer />
        <Button startIcon={<Add />} color="primary" variant="contained">
          {t("Admins.create")}
        </Button>
      </div>

      <div className="admins-table">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("Admins.table.name")}</TableCell>
                <TableCell align="right">{t("Admins.table.email")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                className="clickable"
                onClick={() => navigate("admins/admin1")}
              >
                <TableCell>John Doe</TableCell>
                <TableCell align="right">john.doe@email.com</TableCell>
              </TableRow>
              <TableRow
                className="clickable"
                onClick={() => navigate("admins/admin2")}
              >
                <TableCell>Jane Doe</TableCell>
                <TableCell align="right">jane.doe@email.com</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default Admins;
