import { ChangeEvent, FormEvent, useState, VFC } from "react";
import {
  TextField,
  Grid,
  Typography,
  Button,
  FormControl,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import useGlobal from "../../store/store";
import api from "../../utils/api/v1";
import "./SignInPage.css";

interface State {
  email: string;
  password: string;
  error: boolean;
  loading: boolean;
}

const SignInPage: VFC = () => {
  const { t } = useTranslation();
  const [state, setState] = useState<State>({
    email: "",
    password: "",
    error: false,
    loading: false,
  });
  const [, globalActions] = useGlobal();
  const navigate = useNavigate();

  const onSignIn = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setState({ ...state, loading: true });
    try {
      const { data } = await api.signIn(state.email, state.password);
      globalActions.auth.signIn(data);
      navigate("/");
    } catch (err) {
      console.log(err);
      setState({ ...state, error: true, loading: false });
    }
  };

  const onChange = ({
    target: { name, value },
  }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <form onSubmit={onSignIn}>
        <FormControl className="login-form">
          <Typography variant="h6" className="login-title">
            {t("SignInPage.signIn")}
          </Typography>
          <Box className="login-box">
            <TextField
              className="login-text-field"
              id="email"
              label={t("SignInPage.email")}
              type="email"
              name="email"
              onChange={onChange}
              fullWidth
              autoFocus
              required
              disabled={state.loading}
            />
            <TextField
              className="login-text-field"
              id="password"
              label={t("SignInPage.password")}
              type="password"
              name="password"
              onChange={onChange}
              fullWidth
              required
              disabled={state.loading}
            />
            {state.loading ? (
              <CircularProgress style={{ marginTop: 20 }} />
            ) : null}
            {state.error ? (
              <div className="alert error" style={{ marginTop: 20 }}>
                Fel användarnamn eller lösenord
              </div>
            ) : null}
            <Button
              type="submit"
              style={{ marginTop: 40 }}
              variant="outlined"
              color="primary"
              disabled={state.loading}
            >
              {t("SignInPage.signIn")}
            </Button>
          </Box>
        </FormControl>
      </form>
    </Grid>
  );
};

export default SignInPage;
