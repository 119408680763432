import { useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import "./TermTeacherDetails.css";
import { useNavigate, useParams } from "react-router";
import { Typography, Button } from "@material-ui/core";
import api from "../../../utils/api/v1";
import Loader from "../../shared/loader/Loader";
import Spacer from "../../shared/Spacer";
import { MissingRequiredParamError } from "../../errorHandling/MissingRequiredParamError";

interface State {
  loading: boolean;
  error: boolean;
  errorMessage: string;
}

const TermTeacherDetails: VFC = () => {
  const { t } = useTranslation();
  const { termTeacherId, termId } = useParams();
  const [state, setState] = useState<State>({
    loading: false,
    error: false,
    errorMessage: "",
  });

  const navigate = useNavigate();

  if (!termId || !termTeacherId) {
    return <MissingRequiredParamError missingParam="termId or termTeacherId" />;
  }

  const deleteTermTeacher = async () => {
    setState({ ...state, error: false, loading: true });
    try {
      await api.deleteTermTeacher(termTeacherId).then(() => {
        setState({ ...state, loading: false });
        navigate(`/terms/${termId}`);
      });
    } catch (err) {
      console.error(err);
      setState({ ...state, error: true, loading: false });
    }
  };

  const mainGui = () => {
    if (state.loading)
      return (
        <Loader text={t("TermDetails.loading")} style={{ marginTop: 40 }} />
      );
    if (!state.loading) {
      return (
        <>
          <div className="section-toolbar">
            <Typography className="section-title" variant="h6">
              {t("TermTeacherDetails.DeleteTermTeacher")}
            </Typography>
            <Spacer />
          </div>
          <Typography className="teachersheading" variant="h6">
            {t("TermTeacherDetails.Sure")}
          </Typography>
          <Spacer />
          <div className="teachersheading">
            <Button
              variant="contained"
              size="medium"
              color="primary"
              onClick={deleteTermTeacher}
              style={{ padding: "5px", margin: "10px" }}
            >
              {t("TermTeacherDetails.YesDelete")}
            </Button>
            <Button
              variant="contained"
              size="medium"
              color="secondary"
              onClick={() => navigate(`/terms/${termId}`)}
            >
              {t("TermTeacherDetails.NoDelete")}
            </Button>
          </div>
        </>
      );
    }
    return <Loader text={t("TermDetails.loading")} style={{ marginTop: 40 }} />;
  };

  return <div className="companies-wrapper">{mainGui()}</div>;
};

export default TermTeacherDetails;
