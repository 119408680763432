import React from "react";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import "./Buttons.css";
import { KeyboardArrowLeft } from "@material-ui/icons";
import { Button } from "@mui/material";

export const BackButton = ({ navigateTo, size, style }) => {
  const navigate = useNavigate();
  return (
    <Button
      onClick={() => navigate(navigateTo)}
      size={size}
      style={style}
      className="back-button"
      startIcon={<KeyboardArrowLeft />}
    />
  );
};

BackButton.propTypes = {
  navigateTo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.string,
  style: PropTypes.string,
};

BackButton.defaultProps = {
  navigateTo: -1,
  size: "large",
  style: { color: "black" },
};
