/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { CloudinaryContext } from "cloudinary-react";
import "./CounselingResources.css";
import {
  Typography,
  Divider,
  Button,
  Paper,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogActions,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { Save, Delete } from "@material-ui/icons";
import Spacer from "../shared/Spacer";
import api from "../../utils/api/v1";
import Loader from "../shared/loader/Loader";
import { languages } from "../../i18n";
import { createGuid } from "../../utils/helpers/createGuid";
import { openUploadWidget } from "../../utils/cloudinary/cloudinaryService";
import { BackButton } from "../../UI-Components/Buttons/Buttons";

const CounselingResourceEdit = ({ create }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { counselingResourceId } = useParams();
  const [isNotNumber, setIsNotNumber] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [publicIdForDelete, setPublicIdForDelete] = useState("");
  const [state, setState] = useState({
    loading: false,
    error: "",
    resource: null,
  });
  const [form, setForm] = useState({
    Type: "",
    State: "",
    TitleEn: "",
    TitleSv: "",
    TimeRequired: 1,
    AnswerEn: "",
    AnswerSv: "",
  });
  const [attributesForUpdate, setAttributesForUpdate] = useState({
    ImagePublicId: "00000000-0000-0000-0000-000000000000",
    ResourcePublicId: "00000000-0000-0000-0000-000000000000",
    ResourcePublicIdEn: "00000000-0000-0000-0000-000000000000",
    ImageUrl: "",
    ResourceUrl: "",
    ResourceUrlEn: "",
  });

  const [requiredFields, setRequiredFields] = useState({
    TitleEn: "",
    TitleSv: "",
    AnswerEn: "",
    AnswerSv: "",
    timeRequired: 1,
  });

  const [showErrorAll, setShowErrorAll] = useState(false);
  const [showError, setShowError] = useState({
    TitleEn: false,
    TitleSv: false,
    AnswerEn: false,
    AnswerSv: false,
    timeRequired: false,
  });

  const handleOpenDeleteModalResource = () => {
    setPublicIdForDelete(attributesForUpdate.ResourcePublicId);

    setIsModalOpen(true);
  };

  const handleOpenDeleteModalEnglishResource = () => {
    setPublicIdForDelete(attributesForUpdate.ResourcePublicIdEn);

    setIsModalOpen(true);
  };

  const handleOpenDeleteModalImage = () => {
    setPublicIdForDelete(attributesForUpdate.ImagePublicId);

    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const beginCloudinaryImageUpload = async () => {
    const guidForPublicId = createGuid();
    const uploadOptions = {
      cloudName: process.env.REACT_APP_CLOUD_NAME,
      uploadPreset: process.env.REACT_APP_CLOUD_UPLOAD_PRESET,
      publicId: `counselingresources/${counselingResourceId}/${
        attributesForUpdate.ImagePublicId !==
          "00000000-0000-0000-0000-000000000000" &&
        attributesForUpdate.ImagePublicId !== null
          ? attributesForUpdate.ImagePublicId
          : guidForPublicId
      }`,
      maxFiles: 1,
      preBatch: (cb, data) => {
        if (
          data.files[0].name.includes("video") ||
          data.files[0].name.includes("pdf")
        ) {
          console.error("Only images are allowed!");
          cb({ cancel: true });
        } else {
          cb();
        }
      },
    };

    openUploadWidget(uploadOptions, async (error, res) => {
      if (!error) {
        console.log(res);
      }

      if (res.event === "success") {
        setState({ ...state, loading: true, error: false });
        const data = {
          assetPublicId:
            attributesForUpdate.ImagePublicId !==
              "00000000-0000-0000-0000-000000000000" &&
            attributesForUpdate.ImagePublicId !== null
              ? attributesForUpdate.ImagePublicId
              : guidForPublicId,
          resourceOrActivityUrl: res.info.secure_url,
        };

        await api
          .uploadCounselingResourceImageAsset(data, counselingResourceId)
          .then(() => {
            setAttributesForUpdate({
              ...attributesForUpdate,
              ImagePublicId: data.assetPublicId,
              ImageUrl: res.info.secure_url,
            });
            setState({ ...state, loading: false, error: false });
          })
          .catch((err) => {
            console.error(err);
            setState({ ...state, loading: false, error: true });
          });
      } else {
        console.error(error);
      }
    });
  };

  const beginCloudinaryImagePdfOrVideoUpload = async () => {
    const guidForPublicId = createGuid();
    const uploadOptions = {
      cloudName: process.env.REACT_APP_CLOUD_NAME,
      uploadPreset: process.env.REACT_APP_CLOUD_UPLOAD_PRESET,
      publicId: `counselingresources/${counselingResourceId}/${
        attributesForUpdate.ResourcePublicId !==
          "00000000-0000-0000-0000-000000000000" &&
        attributesForUpdate.ResourcePublicId !== null
          ? attributesForUpdate.ResourcePublicId
          : guidForPublicId
      }`,
      maxVideoFileSize: 500000000,
      maxFiles: 1,
      preBatch: (cb, data) => {
        if (
          !data.files[0].name.includes("pdf") &&
          !data.files[0].name.includes("jpg") &&
          !data.files[0].name.includes("png") &&
          !data.files[0].name.includes("jpeg") &&
          !data.files[0].name.includes("mp4")
        ) {
          console.error(
            "Please upload one of the following filetypes: jpg, jpeg, png, mp4, pdf"
          );
          cb({ cancel: true });
        } else {
          cb();
        }
      },
    };

    openUploadWidget(uploadOptions, async (error, res) => {
      if (!error) {
        console.log(res);
      }

      if (res.event === "success") {
        setState({ ...state, loading: true, error: false });
        const data = {
          assetPublicId:
            attributesForUpdate.ResourcePublicId !==
              "00000000-0000-0000-0000-000000000000" &&
            attributesForUpdate.ResourcePublicId !== null
              ? attributesForUpdate.ResourcePublicId
              : guidForPublicId,
          resourceOrActivityUrl: res.info.secure_url,
        };

        await api
          .uploadCounselingResourceImagePdfOrVideoAsset(
            data,
            counselingResourceId
          )
          .then(() => {
            setAttributesForUpdate({
              ...attributesForUpdate,
              ResourcePublicId: data.assetPublicId,
              ResourceUrl: res.info.secure_url,
            });
            setState({ ...state, loading: false, error: false });
          })
          .catch((err) => {
            console.error(err);
            setState({ ...state, loading: false, error: true });
          });
      } else {
        console.error(error);
      }
    });
  };

  const beginEnglishCloudinaryImagePdfOrVideoUpload = async () => {
    const guidForPublicId = createGuid();
    const uploadOptions = {
      cloudName: process.env.REACT_APP_CLOUD_NAME,
      uploadPreset: process.env.REACT_APP_CLOUD_UPLOAD_PRESET,
      publicId: `counselingresources/${counselingResourceId}/${
        attributesForUpdate.ResourcePublicIdEn !==
          "00000000-0000-0000-0000-000000000000" &&
        attributesForUpdate.ResourcePublicIdEn !== null
          ? attributesForUpdate.ResourcePublicIdEn
          : guidForPublicId
      }`,
      maxVideoFileSize: 500000000,
      maxFiles: 1,
      preBatch: (cb, data) => {
        if (
          !data.files[0].name.includes("pdf") &&
          !data.files[0].name.includes("jpg") &&
          !data.files[0].name.includes("png") &&
          !data.files[0].name.includes("jpeg") &&
          !data.files[0].name.includes("mp4")
        ) {
          console.error(
            "Please upload one of the following filetypes: jpg, jpeg, png, mp4, pdf"
          );
          cb({ cancel: true });
        } else {
          cb();
        }
      },
    };

    openUploadWidget(uploadOptions, async (error, res) => {
      if (res.event === "success") {
        setState({ ...state, loading: true, error: false });
        const data = {
          assetPublicId:
            attributesForUpdate.ResourcePublicIdEn !==
              "00000000-0000-0000-0000-000000000000" &&
            attributesForUpdate.ResourcePublicIdEn !== null
              ? attributesForUpdate.ResourcePublicIdEn
              : guidForPublicId,
          resourceOrActivityUrl: res.info.secure_url,
        };

        await api
          .uploadEnglishCounselingResourceImagePdfOrVideoAsset(
            data,
            counselingResourceId
          )
          .then(() => {
            setAttributesForUpdate({
              ...attributesForUpdate,
              ResourcePublicIdEn: data.assetPublicId,
              ResourceUrlEn: res.info.secure_url,
            });
            setState({ ...state, loading: false, error: false });
          })
          .catch((err) => {
            console.error(err);
            setState({ ...state, loading: false, error: true });
          });
      } else {
        console.error(error);
      }
    });
  };

  const getCounselingResource = () => {
    setState({ ...state, loading: true, error: false });
    api
      .getCounselingResource(counselingResourceId)
      .then(({ data }) => {
        setForm({
          Type: data.type,
          State: data.state,
          TitleEn: data.titleEn,
          TitleSv: data.titleSv,
          AnswerEn: data.answerEn,
          AnswerSv: data.answerSv,
          timeRequired: data.timeRequired,
        });
        setRequiredFields({
          TitleSv: data.titleSv,
          AnswerEn: data.answerEn,
          AnswerSv: data.answerSv,
          timeRequired: data.timeRequired,
        });
        setAttributesForUpdate({
          ImagePublicId: data.imagePublicId,
          ResourcePublicId: data.resourcePublicId,
          ResourcePublicIdEn: data.resourcePublicIdEn,
          ImageUrl: data.imageUrl,
          ResourceUrl: data.resourceUrl,
          ResourceUrlEn: data.resourceUrlEn,
        });
        setState({
          ...state,
          resource: data.data,
          loading: false,
          error: false,
        });
      })
      .catch((err) => {
        console.error(err);
        setState({ ...state, error: true, loading: false });
      });
  };

  useEffect(() => {
    if (!create) {
      getCounselingResource();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = () => {
    if (Object.values(requiredFields).some((value) => value === "")) {
      setShowErrorAll(true);
      return;
    }
    setState({ ...state, loading: true, error: false });

    if (create) {
      const formData = new FormData();
      formData.append("form", JSON.stringify(form));

      if (formData.timeRequired === "" || null) {
        formData.timeRequired = 0;
      }

      api
        .createCounselingResource(formData, {
          "content-type": "multipart/form-data",
        })
        .then(({ data }) => {
          setState({ ...state, loading: false, error: false });
          navigate(`/counseling-resources/${data.id}`);
        })
        .catch((err) => {
          console.error(err);
          setState({ ...state, loading: false, error: true });
        });
    } else if (!create) {
      const formData = new FormData();
      const formStateCopy = { ...form };

      if (formStateCopy.timeRequired === "" || null) {
        formStateCopy.timeRequired = 0;
      }

      const formDataForEdit = Object.assign(formStateCopy, attributesForUpdate);

      formData.append("form", JSON.stringify(formDataForEdit));

      api
        .updateCounselingResource(counselingResourceId, formData, {
          "content-type": "multipart/form-data",
        })
        .then(() => {
          setState({ ...state, loading: false, error: false });
          navigate(`/counseling-resources/${counselingResourceId}`);
        })
        .catch((err) => {
          console.error(err);
          setState({ ...state, loading: false, error: true });
        });
    }
  };

  const handleSelectChange = ({ target: { name, value } }) => {
    let valueForForm = value;
    if (value === "false") {
      valueForForm = false;
    }
    if (value === "true") {
      valueForForm = true;
    }

    setForm({
      ...form,
      [name]: valueForForm,
    });
  };

  const handleChange = ({ target: { name, value } }) => {
    setShowError({ ...showError, [name]: true });
    if (
      name === "TimeRequired" &&
      // eslint-disable-next-line no-restricted-globals
      (isNaN(parseInt(value, 10)) || !/^\d+$/.test(value))
    ) {
      setIsNotNumber(true);
      if (value.length > 0) {
        return;
      }
    } else {
      setIsNotNumber(false);
    }

    setForm({
      ...form,
      [name]: value,
    });
    setRequiredFields({ ...requiredFields, [name]: value });
  };

  const handleDeleteCounselingResourceAsset = async () => {
    if (publicIdForDelete === attributesForUpdate.ResourcePublicId) {
      setState({ ...state, loading: true });
      await api
        .deleteCounselingResourceAsset(publicIdForDelete)
        .then(() => {
          setAttributesForUpdate({
            ...attributesForUpdate,
            ResourcePublicId: "00000000-0000-0000-0000-000000000000",
            ResourceUrl: "",
          });
          setState({ ...state, loading: false });
          handleCloseModal();
        })
        .catch((err) => {
          console.error(err);
          setState({ ...state, loading: false, error: true });
          handleCloseModal();
        });
    }

    if (publicIdForDelete === attributesForUpdate.ResourcePublicIdEn) {
      setState({ ...state, loading: true });
      await api
        .deleteEnglishCounselingResourceAsset(publicIdForDelete)
        .then(() => {
          setAttributesForUpdate({
            ...attributesForUpdate,
            ResourcePublicIdEn: "00000000-0000-0000-0000-000000000000",
            ResourceUrlEn: "",
          });
          setState({ ...state, loading: false });
          handleCloseModal();
        })
        .catch((err) => {
          console.error(err);
          setState({ ...state, loading: false, error: true });
          handleCloseModal();
        });
    }

    if (publicIdForDelete === attributesForUpdate.ImagePublicId) {
      setState({ ...state, loading: true, error: false });
      await api
        .deleteCounselingResourceImageAsset(publicIdForDelete)
        .then(() => {
          setAttributesForUpdate({
            ...attributesForUpdate,
            ImagePublicId: "00000000-0000-0000-0000-000000000000",
            ImageUrl: "",
          });
          setState({ ...state, loading: false });
          handleCloseModal();
        })
        .catch((err) => {
          console.error(err);
          setState({ ...state, loading: false, error: true });
        });
    }
  };

  const checkRequiredInput = (input) =>
    (showError[input] || showErrorAll) &&
    (form[input] === "" || form[input] === undefined);

  const generateHelperText = (input, fieldName) =>
    checkRequiredInput(input)
      ? t("missingMandatoryInput", { field: fieldName })
      : "";

  const mainGui = () => {
    if (state.loading)
      return (
        <Loader
          text={t("CounselingResourceEdit.loading")}
          style={{ marginTop: 40 }}
        />
      );
    if (!state.loading && !state.error) {
      return (
        <>
          <div className="section-toolbar">
            <BackButton />
            <Typography className="section-title" variant="h6">
              {create
                ? t("CounselingResourceEdit.titleCreate")
                : t("CounselingResourceEdit.titleEdit")}
            </Typography>
            <Spacer />
            <Button
              color="primary"
              variant="contained"
              startIcon={<Save />}
              onClick={handleSave}
            >
              {t("CounselingResourceEdit.save")}
            </Button>
          </div>
          <Divider />

          <div>
            <Paper className="counseling-resource-edit-details">
              <div>
                <div className="select-wrapper">
                  <div className="select-spacer">
                    <InputLabel id="label">
                      {t("CounselingResourceEdit.type")}
                    </InputLabel>
                    <Select
                      labelId="label"
                      name="Type"
                      id="select"
                      value={form.Type}
                      onChange={(e) => handleSelectChange(e)}
                    >
                      <MenuItem value="Stress Management">
                        {t("CounselingResourceEdit.stressManagement")}
                      </MenuItem>
                      <MenuItem value="Physical Activity">
                        {t("CounselingResourceEdit.physicalActivity")}
                      </MenuItem>
                      <MenuItem value="Personal Development">
                        {t("CounselingResourceEdit.personalDevelopment")}
                      </MenuItem>
                    </Select>
                  </div>
                  <div>
                    <InputLabel id="label">
                      {t("CounselingResourceEdit.availability")}
                    </InputLabel>

                    <RadioGroup
                      aria-label="available"
                      name="State"
                      value={form.State.toString()}
                      onChange={(e) => handleSelectChange(e)}
                      className="radiogroup-style"
                    >
                      <FormControlLabel
                        value="Open"
                        control={<Radio />}
                        label={t("CounselingResourceEdit.stateOpen")}
                      />
                      <FormControlLabel
                        value="Closed"
                        control={<Radio />}
                        label={t("CounselingResourceEdit.stateClosed")}
                      />
                    </RadioGroup>
                  </div>
                </div>
                {languages.map((lang) => [
                  <div key={lang}>
                    <div className="counseling-resource-form-text">
                      <TextField
                        name={`Title${
                          lang.charAt(0).toUpperCase() + lang.slice(1)
                        }`}
                        label={`${t(`CounselingResourceEdit.title`)} ${t(
                          `Language.languages.${lang}`
                        )}`}
                        variant="outlined"
                        fullWidth
                        multiline
                        required
                        value={
                          form[
                            `Title${
                              lang.charAt(0).toUpperCase() + lang.slice(1)
                            }`
                          ]
                        }
                        onChange={handleChange}
                        error={checkRequiredInput(
                          `Title${lang.charAt(0).toUpperCase() + lang.slice(1)}`
                        )}
                        helperText={generateHelperText(
                          `Title${
                            lang.charAt(0).toUpperCase() + lang.slice(1)
                          }`,
                          t("CounselingResourceEdit.title")
                        )}
                      />
                    </div>
                    <div className="counseling-resource-form-text">
                      <TextField
                        name={`Answer${
                          lang.charAt(0).toUpperCase() + lang.slice(1)
                        }`}
                        label={`${t(`CounselingResourceEdit.answer`)} ${t(
                          `Language.languages.${lang}`
                        )}`}
                        variant="outlined"
                        fullWidth
                        multiline
                        required
                        rows={2}
                        rowsMax={15}
                        value={
                          form[
                            `Answer${
                              lang.charAt(0).toUpperCase() + lang.slice(1)
                            }`
                          ]
                        }
                        onChange={handleChange}
                        error={checkRequiredInput(
                          `Answer${
                            lang.charAt(0).toUpperCase() + lang.slice(1)
                          }`
                        )}
                        helperText={generateHelperText(
                          `Answer${
                            lang.charAt(0).toUpperCase() + lang.slice(1)
                          }`,
                          t("CounselingResourceEdit.answer")
                        )}
                      />
                    </div>
                  </div>,
                ])}
                <div className="counseling-resource-form-text">
                  <TextField
                    error={isNotNumber}
                    name="TimeRequired"
                    label={`${t(`CounselingResourceEdit.timeRequired`)}`}
                    variant="outlined"
                    fullWidth
                    multiline
                    required
                    helperText={
                      isNotNumber
                        ? t(`CounselingResourceEdit.numberErrorText`)
                        : ""
                    }
                    value={form.TimeRequired}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {!create ? (
                <div className="input-wrapper">
                  <div className="file-upload-wrapper">
                    <CloudinaryContext
                      cloudName={process.env.REACT_APP_CLOUD_NAME}
                    >
                      <p className="upload-paragraph-header">
                        {t(`CounselingResourceEdit.presentationImage`)}
                      </p>
                      <Button
                        color="primary"
                        className="asset-upload-button-style"
                        variant="contained"
                        onClick={() => beginCloudinaryImageUpload()}
                        disabled={
                          attributesForUpdate.ImagePublicId !== "" &&
                          attributesForUpdate.ImagePublicId !==
                            "00000000-0000-0000-0000-000000000000" &&
                          attributesForUpdate.ResourcePublicIdEn !== null
                        }
                      >
                        {t(`CounselingResourceEdit.upload`)}
                      </Button>
                    </CloudinaryContext>
                    {t(`CounselingResourceEdit.uploaded`)}
                    {attributesForUpdate.ImageUrl ? (
                      <div className="resource-edit-upload-wrapper">
                        <img
                          src={attributesForUpdate.ImageUrl}
                          height="150px"
                          width="200px"
                          alt="imageUrl"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="button-text-wrapper">
                      <Dialog
                        open={isModalOpen}
                        onClose={handleCloseModal}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">
                          <Typography className="reinforced-delete-alert-style">
                            {t("CounselingResourceEdit.deleteAlertReinforced")}
                          </Typography>
                        </DialogTitle>

                        <DialogActions>
                          <Button
                            onClick={() =>
                              handleDeleteCounselingResourceAsset()
                            }
                            color="primary"
                          >
                            {t("ButtonGeneral.acceptDeleteAlert")}
                          </Button>
                          <Button onClick={handleCloseModal} color="primary">
                            {t("ButtonGeneral.denyDeleteAlert")}
                          </Button>
                        </DialogActions>
                      </Dialog>
                      <Button
                        color="secondary"
                        variant="contained"
                        name="deleteImage"
                        startIcon={<Delete />}
                        disabled={
                          !attributesForUpdate.ImagePublicId ||
                          attributesForUpdate.ImagePublicId ===
                            "00000000-0000-0000-0000-000000000000"
                        }
                        className="delete-button-style"
                        onClick={() => handleOpenDeleteModalImage()}
                      >
                        {t("CounselingResourceEdit.delete")}
                      </Button>
                    </div>
                  </div>
                  <div className="file-upload-wrapper">
                    <CloudinaryContext
                      cloudName={process.env.REACT_APP_CLOUD_NAME}
                    >
                      <p className="upload-paragraph-header">
                        {t(`CounselingResourceEdit.resourceFile`)}
                      </p>
                      <Button
                        color="primary"
                        className="asset-upload-button-style"
                        variant="contained"
                        onClick={() => beginCloudinaryImagePdfOrVideoUpload()}
                        disabled={
                          attributesForUpdate.ResourcePublicId !== "" &&
                          attributesForUpdate.ResourcePublicId !==
                            "00000000-0000-0000-0000-000000000000" &&
                          attributesForUpdate.ResourcePublicIdEn !== null
                        }
                      >
                        {t(`CounselingResourceEdit.upload`)}
                      </Button>
                    </CloudinaryContext>
                    {t(`CounselingResourceEdit.uploaded`)}
                    <div className="button-text-wrapper">
                      {
                        // eslint-disable-next-line no-nested-ternary
                        attributesForUpdate.ResourceUrl &&
                        attributesForUpdate.ResourceUrl.includes("image") &&
                        !attributesForUpdate.ResourceUrl.includes("pdf") ? (
                          <div className="resource-edit-upload-wrapper">
                            <img
                              src={attributesForUpdate.ResourceUrl}
                              height="150px"
                              width="200px"
                              alt="resourceUrl"
                            />
                          </div>
                        ) : // eslint-disable-next-line no-nested-ternary
                        attributesForUpdate.ResourceUrl &&
                          attributesForUpdate.ResourceUrl.includes("video") ? (
                          <div className="resource-edit-upload-wrapper">
                            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                            <video controls width="250">
                              <source
                                src={attributesForUpdate.ResourceUrl}
                                type="video/webm"
                              />
                              Sorry, your browser doesn&apos;t support embedded
                              videos.
                            </video>
                          </div>
                        ) : attributesForUpdate.ResourceUrl &&
                          attributesForUpdate.ResourceUrl.includes("image") &&
                          attributesForUpdate.ResourceUrl.endsWith(".pdf") ? (
                          <div className="resource-edit-upload-wrapper">
                            <iframe
                              scrolling="no"
                              title="pdf display"
                              src={attributesForUpdate.ResourceUrl}
                              className="resource-edit-pdf-display-style"
                            />
                          </div>
                        ) : (
                          ""
                        )
                      }
                      <Button
                        color="secondary"
                        variant="contained"
                        name="deleteResource"
                        startIcon={<Delete />}
                        disabled={
                          !attributesForUpdate.ResourcePublicId ||
                          attributesForUpdate.ResourcePublicId ===
                            "00000000-0000-0000-0000-000000000000"
                        }
                        onClick={() => handleOpenDeleteModalResource()}
                        className="delete-button-style"
                      >
                        {t("CounselingResourceEdit.delete")}
                      </Button>
                    </div>
                  </div>
                  <div className="file-upload-wrapper">
                    <CloudinaryContext
                      cloudName={process.env.REACT_APP_CLOUD_NAME}
                    >
                      <p className="upload-paragraph-header">
                        {t(`CounselingResourceEdit.resourceFileEn`)}
                      </p>
                      <Button
                        color="primary"
                        className="asset-upload-button-style"
                        variant="contained"
                        onClick={() =>
                          beginEnglishCloudinaryImagePdfOrVideoUpload()
                        }
                        disabled={
                          attributesForUpdate.ResourcePublicIdEn !== "" &&
                          attributesForUpdate.ResourcePublicIdEn !==
                            "00000000-0000-0000-0000-000000000000" &&
                          attributesForUpdate.ResourcePublicIdEn !== null
                        }
                      >
                        {t(`CounselingResourceEdit.upload`)}
                      </Button>
                    </CloudinaryContext>
                    {t(`CounselingResourceEdit.uploaded`)}
                    <div className="button-text-wrapper">
                      {
                        // eslint-disable-next-line no-nested-ternary
                        attributesForUpdate.ResourceUrlEn &&
                        attributesForUpdate.ResourceUrlEn.includes("image") &&
                        !attributesForUpdate.ResourceUrlEn.includes("pdf") ? (
                          <div className="resource-edit-upload-wrapper">
                            <img
                              src={attributesForUpdate.ResourceUrlEn}
                              height="150px"
                              width="200px"
                              alt="resourceUrl"
                            />
                          </div>
                        ) : // eslint-disable-next-line no-nested-ternary
                        attributesForUpdate.ResourceUrlEn &&
                          attributesForUpdate.ResourceUrlEn.includes(
                            "video"
                          ) ? (
                          <div className="resource-edit-upload-wrapper">
                            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                            <video controls width="250">
                              <source
                                src={attributesForUpdate.ResourceUrlEn}
                                type="video/webm"
                              />
                              Sorry, your browser doesn&apos;t support embedded
                              videos.
                            </video>
                          </div>
                        ) : attributesForUpdate.ResourceUrlEn &&
                          attributesForUpdate.ResourceUrlEn.includes("image") &&
                          attributesForUpdate.ResourceUrlEn.endsWith(".pdf") ? (
                          <div className="resource-edit-upload-wrapper">
                            <iframe
                              scrolling="no"
                              title="pdf display"
                              src={attributesForUpdate.ResourceUrlEn}
                              className="resource-edit-pdf-display-style"
                            />
                          </div>
                        ) : (
                          ""
                        )
                      }
                      <Button
                        color="secondary"
                        variant="contained"
                        name="deleteResourceEn"
                        startIcon={<Delete />}
                        disabled={
                          !attributesForUpdate.ResourcePublicIdEn ||
                          attributesForUpdate.ResourcePublicIdEn ===
                            "00000000-0000-0000-0000-000000000000"
                        }
                        onClick={() => handleOpenDeleteModalEnglishResource()}
                        className="delete-button-style"
                      >
                        {t("CounselingResourceEdit.delete")}
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </Paper>
          </div>
        </>
      );
    }
    return (
      <Loader
        text={t("CounselingResourceEdit.loading")}
        style={{ marginTop: 40 }}
      />
    );
  };

  return <div className="counseling-wrapper">{mainGui()}</div>;
};

CounselingResourceEdit.propTypes = {
  create: PropTypes.bool,
};

CounselingResourceEdit.defaultProps = {
  create: false,
};

export default CounselingResourceEdit;
