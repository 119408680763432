import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./Activities.css";
import { Button } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { useNavigate, useParams } from "react-router";
import { getLanguage } from "../../../i18n";
import SingleSelectionGrid from "../../../UI-Components/SingleRowSelectionGrid/SingleRowSelectionGrid";
import api from "../../../utils/api/v1";
import Loader from "../../shared/loader/Loader";
import type { ApiResponseModuleActivityWithTextDataModel } from "../../../utils/api/apiInterfaces";
import { MissingRequiredParamError } from "../../errorHandling/MissingRequiredParamError";

interface State {
  loading: boolean;
  error: boolean;
  moduleActivities: Array<ApiResponseModuleActivityWithTextDataModel | null>;
}

interface RowProps {
  order: number;
  id: string;
  name: string | null;
  type: string | null;
  timeRequired: string;
}

const Activities = () => {
  const { t } = useTranslation();
  const { moduleId } = useParams();
  const [moduleActivities, setModuleActivities] = useState<Array<RowProps>>([]);
  const [state, setState] = useState<State>({
    loading: false,
    error: false,
    moduleActivities: [],
  });
  const [, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  useEffect(() => {
    getModuleActivities();
    window.addEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!moduleId) {
    return <MissingRequiredParamError missingParam="moduleId" />;
  }

  const getModuleActivities = () => {
    setState({ ...state, error: false, loading: true });
    api
      .getModuleActivities(moduleId)
      .then(({ data }) => {
        const rows: Array<RowProps> = [];
        const currentLanguage = getLanguage();
        data.forEach((moduleActivity) => {
          const moduleActivityObject = {
            order: moduleActivity.order,
            id: moduleActivity.id,
            name:
              currentLanguage === "en"
                ? moduleActivity.nameEn
                : moduleActivity.nameSv,
            type: moduleActivity.type,
            timeRequired:
              moduleActivity.timeRequired > 1 || 0
                ? moduleActivity.timeRequired + t("Activities.minutes")
                : moduleActivity.timeRequired + t("Activities.minute"),
          };
          rows.push(moduleActivityObject);
        });
        setModuleActivities(rows);
        setState({ ...state, loading: false });
      })
      .catch((err) => {
        console.error(err);
        setState({ ...state, error: true, loading: false });
      });
  };

  const sortModel = [
    {
      field: "order",
      sort: "asc",
    },
  ];

  interface ValueGetterProps {
    row: { type: string };
  }

  const columns = [
    {
      field: "order",
      headerName: t("SingleRowSelectionGrid.moduleActivityOrder"),
      width:
        window.innerWidth >= 960
          ? window.innerWidth / 11
          : window.innerWidth / 10,
    },
    {
      field: "name",
      headerName: t("SingleRowSelectionGrid.moduleActivityName"),
      width:
        window.innerWidth >= 960
          ? window.innerWidth / 3
          : window.innerWidth / 2.5,
    },
    {
      field: "type",
      headerName: t("SingleRowSelectionGrid.moduleActivityType"),
      width:
        window.innerWidth >= 960
          ? window.innerWidth / 6
          : window.innerWidth / 5,
      valueGetter: (params: ValueGetterProps) =>
        `${
          // eslint-disable-next-line no-nested-ternary
          params.row.type === "Reflection"
            ? t("SingleRowSelectionGrid.moduleActivityReflection")
            : // eslint-disable-next-line no-nested-ternary
            params.row.type === "Lecture"
            ? t("SingleRowSelectionGrid.moduleActivityLecture")
            : params.row.type === "Exercise"
            ? t("SingleRowSelectionGrid.moduleActivityExercise")
            : "-"
        }`,
    },
    {
      field: "timeRequired",
      headerName: t("SingleRowSelectionGrid.moduleActivityTimeRequired"),
      width:
        window.innerWidth >= 960
          ? window.innerWidth / 6
          : window.innerWidth / 5,
    },
  ];

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const mainGui = () => {
    if (state.loading)
      return (
        <Loader
          text={t("Modules.activities.loading")}
          style={{ marginTop: 40 }}
        />
      );
    if (!state.loading && moduleActivities) {
      return (
        <>
          <Button
            className="activities-add-button-style"
            color="primary"
            variant="contained"
            onClick={() => navigate(`/modules/${moduleId}/activities/new`)}
          >
            <Add /> {t("Modules.activities.add")}
          </Button>
          <SingleSelectionGrid
            columns={columns}
            rows={moduleActivities}
            pageSize={10}
            loading={state.loading}
            extraWidth={30}
            sortModel={sortModel}
            path="activities"
          />
        </>
      );
    }

    return (
      <Loader text={t("module.activities.loading")} style={{ marginTop: 40 }} />
    );
  };

  return <div className="activities-wrapper">{mainGui()}</div>;
};

export default Activities;
