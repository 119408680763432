import "./CounselingResources.css";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Button } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Link } from "react-router-dom";
import Spacer from "../shared/Spacer";
import api from "../../utils/api/v1";
import Loader from "../shared/loader/Loader";
import { getLanguage } from "../../i18n";
import SingleSelectionGrid from "../../UI-Components/SingleRowSelectionGrid/SingleRowSelectionGrid";

interface State {
  loading: boolean;
  error: boolean;
}

interface RowProps {
  id: string;
  titleSv: string | null;
  titleEn: string | null;
  date: number;
  type: string | null;
  timeRequired: number;
}

const CounselingResources = () => {
  const { t } = useTranslation();
  const [state, setState] = useState<State>({
    loading: true,
    error: false,
  });
  const [counselingResources, setCounselingResources] = useState<
    Array<RowProps>
  >([]);
  const [, setWindowWidth] = useState(window.innerWidth);
  const formatDateTime = (dateTime: string | number | Date) => {
    const date = new Date(dateTime);
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    } as const;
    return date.toLocaleDateString(getLanguage(), options);
  };

  const getCounselingResources = () => {
    setState({
      loading: true,
      error: false,
    });
    api
      .getCounselingResources()
      .then(({ data }) => {
        const rows: Array<RowProps> = [];
        const timezoneOffset = new Date().getTimezoneOffset();
        data.forEach((counselingResource) => {
          const counselingResourceObject = {
            id: counselingResource.id,
            titleSv: counselingResource.titleSv,
            titleEn: counselingResource.titleEn,
            date:
              Date.parse(counselingResource.createdAt) -
              timezoneOffset * 60 * 1000,
            type: counselingResource.type,
            timeRequired: counselingResource.timeRequired,
          };
          rows.push(counselingResourceObject);
        });
        setCounselingResources(rows);
        setState({
          ...state,
          loading: false,
        });
      })
      .catch((err) => {
        console.error(err);
        setState({
          ...state,
          loading: false,
          error: true,
        });
      });
  };

  interface ValueGetterProps {
    row: {
      date: string;
      type: string;
    };
  }

  const columns = [
    { field: "id", hide: true },
    {
      field: getLanguage() === "sv" ? "titleSv" : "titleEn",
      headerName: t("SingleRowSelectionGrid.resourceName"),
      width:
        window.innerWidth >= 960
          ? window.innerWidth / 6.5
          : window.innerWidth / 5.5,
    },
    {
      field: "timeRequired",
      headerName: t("SingleRowSelectionGrid.timeRequired"),
      width:
        window.innerWidth >= 960
          ? window.innerWidth / 5.5
          : window.innerWidth / 4.5,
    },
    {
      field: "type",
      headerName: t("SingleRowSelectionGrid.type"),
      width:
        window.innerWidth >= 960
          ? window.innerWidth / 5.5
          : window.innerWidth / 4.5,
      valueGetter: (params: ValueGetterProps) =>
        `${
          // eslint-disable-next-line no-nested-ternary
          params.row.type === "Physical Activity"
            ? t("CounselingResources.type.physicalActivity")
            : // eslint-disable-next-line no-nested-ternary
            params.row.type === "Stress Management"
            ? t("CounselingResources.type.stressManagement")
            : params.row.type === "Personal Development"
            ? t("CounselingResources.type.personalDevelopment")
            : "-"
        }`,
    },
    {
      field: "date",
      headerName: t("SingleRowSelectionGrid.dateAdded"),
      width:
        window.innerWidth >= 960
          ? window.innerWidth / 5.5
          : window.innerWidth / 4.5,
      valueGetter: (params: ValueGetterProps) =>
        formatDateTime(params.row.date) || "-",
    },
  ];

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    getCounselingResources();
    window.addEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mainGui = () => {
    if (state.loading)
      return (
        <Loader
          text={t("CounselingResources.loading")}
          style={{ marginTop: 40 }}
        />
      );
    if (!state.loading && counselingResources) {
      return (
        <>
          <div className="section-toolbar">
            <Typography className="section-title" variant="h6">
              {t("CounselingResources.title")}
            </Typography>
            <Spacer />
            <Link
              to="/counseling-resources/counseling-resource/new"
              className="counseling-resources-link-style"
            >
              <Button startIcon={<Add />} color="primary" variant="contained">
                {t("CounselingResources.create")}
              </Button>
            </Link>
          </div>

          <div className="section-SingleSelectionGrid-layout">
            <SingleSelectionGrid
              columns={columns}
              rows={counselingResources}
              pageSize={10}
              loading={state.loading}
              path="counseling-resources"
            />
          </div>
        </>
      );
    }

    return (
      <Loader
        text={t("CounselingResources.loading")}
        style={{ marginTop: 40 }}
      />
    );
  };

  return <div className="counseling-wrapper">{mainGui()}</div>;
};

export default CounselingResources;
