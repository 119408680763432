import { useState, useEffect, VFC } from "react";
import { useTranslation } from "react-i18next";
import "./Surveys.css";
import { Typography, Button } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Link } from "react-router-dom";
import Spacer from "../shared/Spacer";
import api from "../../utils/api/v1";
import Loader from "../shared/loader/Loader";
import { getLanguage } from "../../i18n";
import SingleSelectionGrid from "../../UI-Components/SingleRowSelectionGrid/SingleRowSelectionGrid";
import type { ApiResponseSurveyWithoutQuestionModel } from "../../utils/api/apiInterfaces";
import type { Columns } from "../../UI-Components/CheckboxSelectionGrid/CheckboxSelectionGrid";

interface State {
  loading: boolean;
  error: boolean;
}

const Surveys: VFC = () => {
  const { t } = useTranslation();
  const [state, setState] = useState<State>({
    loading: true,
    error: false,
  });
  const [surveys, setSurveys] = useState<
    Array<ApiResponseSurveyWithoutQuestionModel>
  >([]);
  const [, setWindowWidth] = useState(window.innerWidth);

  const formatDateTime = (dateTime: number) => {
    const date = new Date(dateTime);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    return date.toLocaleDateString(getLanguage(), options);
  };

  const getSurveys = () => {
    setState({ ...state, error: false, loading: true });
    api
      .getSurveys()
      .then(({ data }) => {
        if (data && data.length) {
          const timezoneOffset = new Date().getTimezoneOffset();
          data.map((survey) => {
            /* eslint no-param-reassign: ["error", { "props": false }] */
            survey.createdAt = String(
              Date.parse(survey.createdAt) - timezoneOffset * 60 * 1000
            );
            return survey;
          });
          setSurveys(
            data.sort((a, b) => {
              const aName = a.name;
              const bName = b.name;

              if (!aName && !bName) return 0;
              if (!aName) return -1;
              if (!bName) return 1;
              return aName.localeCompare(bName);
            })
          );
        }
        setState({ ...state, error: false, loading: false });
      })
      .catch((err) => {
        console.error(err);
        setState({ ...state, error: true, loading: false });
      });
  };

  const columns: Columns<string> = [
    {
      field: "name",
      headerName: t("Surveys.table.name"),
      width:
        window.innerWidth >= 960
          ? window.innerWidth / 3
          : window.innerWidth / 2.5,
    },
    {
      field: "numberOfQuestions",
      headerName: t("Surveys.table.questions"),
      width:
        window.innerWidth >= 960
          ? window.innerWidth / 7
          : window.innerWidth / 6,
    },
    {
      field: "createdAt",
      headerName: t("Surveys.table.created"),
      width:
        window.innerWidth >= 960
          ? window.innerWidth / 4
          : window.innerWidth / 3,
      valueGetter: (params) =>
        formatDateTime(Number(params.row.createdAt)) || "-",
    },
  ];

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    getSurveys();
    window.addEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mainGui = () => {
    if (state.loading)
      return <Loader text={t("Surveys.loading")} style={{ marginTop: 40 }} />;
    if (!state.loading && surveys) {
      return (
        <>
          <div className="section-toolbar">
            <Typography className="section-title" variant="h6">
              {t("Surveys.title")}
            </Typography>
            <Spacer />
            <Link to="/surveys/new" className="surveys-link-style">
              <Button
                data-testid="create_survey_button"
                startIcon={<Add />}
                color="primary"
                variant="contained"
              >
                {t("Surveys.create")}
              </Button>
            </Link>
          </div>
          <div className="section-SingleSelectionGrid-layout">
            <SingleSelectionGrid
              columns={columns}
              rows={surveys}
              pageSize={10}
              loading={state.loading}
              path="surveys"
            />
          </div>
        </>
      );
    }

    return <Loader text={t("Surveys.loading")} style={{ marginTop: 40 }} />;
  };

  return <div className="surveys-wrapper">{mainGui()}</div>;
};

export default Surveys;
