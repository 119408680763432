/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CloudinaryContext } from "cloudinary-react";
import "./Activities.css";
import {
  Typography,
  Divider,
  Button,
  Paper,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogActions,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from "@material-ui/core";
import { Alert } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import PropTypes from "prop-types";
import { Save, Delete } from "@material-ui/icons";
import Spacer from "../../shared/Spacer";
import api from "../../../utils/api/v1";
import Loader from "../../shared/loader/Loader";
import { getLanguage, languages } from "../../../i18n";
import { createGuid } from "../../../utils/helpers/createGuid";
import { openUploadWidget } from "../../../utils/cloudinary/cloudinaryService";
import { FlexibleSizeLoader } from "../../shared/loader/FlexibleSizeLoader";
import { BackButton } from "../../../UI-Components/Buttons/Buttons";

const ActivityEdit = ({ create }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { moduleActivityId, moduleId } = useParams();
  const [isNotNumber, setIsNotNumber] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [publicIdForDelete, setPublicIdForDelete] = useState("");
  const [connectedResourceState, setConnectedResourceState] = useState({
    connectedResourceLoading: false,
    connectedResourceError: undefined,
    connectedMicroCourseLoading: false,
    connectedMicroCourseError: undefined,
  });

  const [state, setState] = useState({
    loading: false,
    error: false,
    activity: null,
  });
  const currLang = getLanguage();

  const [resourceData, setResourceData] = useState({
    microCourses: [],
    resources: [],
    linkedResourceId: "Not set",
    linkedMicroCourseId: "Not set",
  });
  const [form, setForm] = useState({
    Type: "",
    State: "",
    NameEn: "",
    NameSv: "",
    timeRequired: 1,
    enableInput: false,
    inputInstructions: "",
    inputInstructionsEn: "",
    inputInstructionsSv: "",
    shortDescriptionEn: "",
    shortDescriptionSv: "",
    descriptionEn: "",
    descriptionSv: "",
    order: 0,
  });

  const [requiredFields, setRequiredFields] = useState({
    Type: "",
    NameEn: "",
    NameSv: "",
    shortDescriptionEn: "",
    shortDescriptionSv: "",
    descriptionEn: "",
    descriptionSv: "",
    timeRequired: 1,
  });
  const [showErrorAll, setShowErrorAll] = useState(false);
  const [showError, setShowError] = useState({
    Type: false,
    NameEn: false,
    NameSv: false,
    shortDescriptionEn: false,
    shortDescriptionSv: false,
    descriptionEn: false,
    descriptionSv: false,
    timeRequired: false,
  });

  const [attributesForUpdate, setAttributesForUpdate] = useState({
    TextDataId: "00000000-0000-0000-0000-000000000000",
    ImagePublicId: "00000000-0000-0000-0000-000000000000",
    ResourcePublicId: "00000000-0000-0000-0000-000000000000",
    ResourcePublicIdEn: "00000000-0000-0000-0000-000000000000",
    ImageUrl: "",
    ResourceUrl: "",
    ResourceUrlEn: "",
  });

  const beginCloudinaryImageUpload = async () => {
    const guidForPublicId = createGuid();
    const uploadOptions = {
      cloudName: process.env.REACT_APP_CLOUD_NAME,
      uploadPreset: process.env.REACT_APP_CLOUD_UPLOAD_PRESET,
      publicId: `activities/${moduleActivityId}/${
        attributesForUpdate.ImagePublicId !==
        "00000000-0000-0000-0000-000000000000"
          ? attributesForUpdate.ImagePublicId
          : guidForPublicId
      }`,
      maxFiles: 1,
      preBatch: (cb, data) => {
        if (
          data.files[0].name.includes("video") ||
          data.files[0].name.includes("pdf")
        ) {
          console.error("Only images are allowed!");
          cb({ cancel: true });
        } else {
          cb();
        }
      },
    };

    openUploadWidget(uploadOptions, async (error, res) => {
      if (!error) {
        console.log(res);
      }

      if (res.event === "success") {
        setState({ ...state, loading: true, error: false });
        const data = {
          assetPublicId:
            attributesForUpdate.ImagePublicId !==
            "00000000-0000-0000-0000-000000000000"
              ? attributesForUpdate.ImagePublicId
              : guidForPublicId,
          resourceOrActivityUrl: res.info.secure_url,
        };

        await api
          .uploadActivityImageAsset(data, moduleActivityId)
          .then(() => {
            setAttributesForUpdate({
              ...attributesForUpdate,
              ImagePublicId: data.assetPublicId,
              ImageUrl: res.info.secure_url,
            });
            setState({ ...state, loading: false, error: false });
          })
          .catch((err) => {
            console.error(err);
            setState({ ...state, loading: false, error: true });
          });
      } else {
        console.error(error);
      }
    });
  };

  const beginCloudinaryImagePdfOrVideoUpload = async () => {
    const guidForPublicId = createGuid();
    const uploadOptions = {
      cloudName: process.env.REACT_APP_CLOUD_NAME,
      uploadPreset: process.env.REACT_APP_CLOUD_UPLOAD_PRESET,
      publicId: `activities/${moduleActivityId}/${
        attributesForUpdate.ResourcePublicId !==
        "00000000-0000-0000-0000-000000000000"
          ? attributesForUpdate.ResourcePublicId
          : guidForPublicId
      }`,
      maxVideoFileSize: 500000000,
      maxFiles: 1,
      preBatch: (cb, data) => {
        if (
          !data.files[0].name.includes("pdf") &&
          !data.files[0].name.includes("jpg") &&
          !data.files[0].name.includes("png") &&
          !data.files[0].name.includes("jpeg") &&
          !data.files[0].name.includes("mp4")
        ) {
          console.error(
            "Please upload one of the following filetypes: jpg, jpeg, png, mp4, pdf"
          );
          cb({ cancel: true });
        } else {
          cb();
        }
      },
    };

    openUploadWidget(uploadOptions, async (error, res) => {
      if (!error) {
        console.log(res);
      }

      if (res.event === "success") {
        setState({ ...state, loading: true, error: false });
        const data = {
          assetPublicId:
            attributesForUpdate.ResourcePublicId !==
            "00000000-0000-0000-0000-000000000000"
              ? attributesForUpdate.ResourcePublicId
              : guidForPublicId,
          resourceOrActivityUrl: res.info.secure_url,
        };

        await api
          .uploadActivityImagePdfOrVideoAsset(data, moduleActivityId)
          .then(() => {
            setAttributesForUpdate({
              ...attributesForUpdate,
              ResourcePublicId: data.assetPublicId,
              ResourceUrl: res.info.secure_url,
            });
            setState({ ...state, loading: false, error: false });
          })
          .catch((err) => {
            console.error(err);
            setState({ ...state, loading: false, error: true });
          });
      } else {
        console.error(error);
      }
    });
  };

  const beginEnglishCloudinaryImagePdfOrVideoUpload = async () => {
    const guidForPublicId = createGuid();
    const uploadOptions = {
      cloudName: process.env.REACT_APP_CLOUD_NAME,
      uploadPreset: process.env.REACT_APP_CLOUD_UPLOAD_PRESET,
      publicId: `activities/${moduleActivityId}/${
        attributesForUpdate.ResourcePublicIdEn !==
        "00000000-0000-0000-0000-000000000000"
          ? attributesForUpdate.ResourcePublicIdEn
          : guidForPublicId
      }`,
      maxVideoFileSize: 500000000,
      maxFiles: 1,
      preBatch: (cb, data) => {
        if (
          !data.files[0].name.includes("pdf") &&
          !data.files[0].name.includes("jpg") &&
          !data.files[0].name.includes("png") &&
          !data.files[0].name.includes("jpeg") &&
          !data.files[0].name.includes("mp4")
        ) {
          console.error(
            "Please upload one of the following filetypes: jpg, jpeg, png, mp4, pdf"
          );
          cb({ cancel: true });
        } else {
          cb();
        }
      },
    };

    openUploadWidget(uploadOptions, async (error, res) => {
      if (res.event === "success") {
        setState({ ...state, loading: true, error: false });
        const data = {
          assetPublicId:
            attributesForUpdate.ResourcePublicIdEn !==
            "00000000-0000-0000-0000-000000000000"
              ? attributesForUpdate.ResourcePublicIdEn
              : guidForPublicId,
          resourceOrActivityUrl: res.info.secure_url,
        };

        await api
          .uploadEnglishActivityImagePdfOrVideoAsset(data, moduleActivityId)
          .then(() => {
            setAttributesForUpdate({
              ...attributesForUpdate,
              ResourcePublicIdEn: data.assetPublicId,
              ResourceUrlEn: res.info.secure_url,
            });
            setState({ ...state, loading: false, error: false });
          })
          .catch((err) => {
            console.error(err);
            setState({ ...state, loading: false, error: true });
          });
      } else {
        console.error(error);
      }
    });
  };

  const handleOpenDeleteModalResource = () => {
    setPublicIdForDelete(attributesForUpdate.ResourcePublicId);

    setIsModalOpen(true);
  };

  const handleOpenDeleteModalImage = () => {
    setPublicIdForDelete(attributesForUpdate.ImagePublicId);

    setIsModalOpen(true);
  };

  const handleOpenDeleteModalEnglishResource = () => {
    setPublicIdForDelete(attributesForUpdate.ResourcePublicIdEn);

    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const getActivityAndResources = async () => {
    setState({ ...state, loading: true, error: false });
    try {
      const { data } = await api.getModuleActivity(moduleActivityId);
      const {
        data: { microCourses },
      } = await api.getMicroCourses();
      const { data: resources } = await api.getResources();
      setResourceData({
        ...resourceData,
        resources,
        microCourses,
        linkedResourceId: data.linkedResourceId ?? "Not set",
        linkedMicroCourseId: data.linkedMicroCourseId ?? "Not set",
      });
      setForm({
        Type: data.type,
        NameEn: data.nameEn,
        NameSv: data.nameSv,
        timeRequired: data.timeRequired,
        enableInput: data.enableInput,
        inputInstructions: data.inputInstructions,
        inputInstructionsEn: data.inputInstructionsEn,
        inputInstructionsSv: data.inputInstructionsSv,
        shortDescriptionEn: data.shortDescriptionEn,
        shortDescriptionSv: data.shortDescriptionSv,
        descriptionEn: data.descriptionEn,
        descriptionSv: data.descriptionSv,
        order: data.order,
      });
      setRequiredFields({
        Type: data.type,
        NameEn: data.nameEn,
        NameSv: data.nameSv,
        timeRequired: data.timeRequired,
        shortDescriptionEn: data.shortDescriptionEn,
        shortDescriptionSv: data.shortDescriptionSv,
        descriptionEn: data.descriptionEn,
        descriptionSv: data.descriptionSv,
      });
      setAttributesForUpdate({
        TextDataId: data.textDataId,
        ImagePublicId: data.imagePublicId,
        ResourcePublicId: data.resourcePublicId,
        ResourcePublicIdEn: data.resourcePublicIdEn,
        ImageUrl: data.imageUrl,
        ResourceUrl: data.resourceUrl,
        ResourceUrlEn: data.resourceUrlEn,
      });
      setState({
        ...state,
        activity: data.data,
        loading: false,
        error: false,
      });
    } catch (err) {
      console.error(err);
      setState({ ...state, error: true, loading: false });
    }
  };

  useEffect(() => {
    if (!create) {
      (async () => {
        await getActivityAndResources();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = () => {
    if (Object.values(requiredFields).some((value) => value === "")) {
      setShowErrorAll(true);
      return;
    }
    setState({ ...state, loading: true, error: false });

    // Check if activity should be created or edited
    if (create) {
      if (form.timeRequired === "" || null) {
        form.timeRequired = 0;
      }
      let lastOrder = 0;
      api
        .getModuleActivities(moduleId)
        .then(({ data }) => {
          data.forEach((moduleActivity) => {
            const currentOrder = moduleActivity.order;
            lastOrder = currentOrder > lastOrder ? currentOrder : lastOrder;
          });
          form.order = lastOrder + 1;

          const formData = new FormData();
          formData.append("form", JSON.stringify(form));

          api
            .createModuleActivity(moduleId, formData, {
              "content-type": "multipart/form-data",
            })
            .then((res) => {
              setState({ ...state, loading: false, error: false });
              navigate(`/activities/${res.data.id}`);
            })
            .catch((err) => {
              console.error(err);
              setState({ ...state, loading: false, error: true });
            });
        })
        .catch((err) => {
          console.error(err);
          setState({ ...state, loading: false, error: true });
        });
    } else if (!create) {
      const formData = new FormData();
      const formStateCopy = { ...form };

      if (formStateCopy.timeRequired === "" || null) {
        formStateCopy.timeRequired = 0;
      }

      const formDataForEdit = Object.assign(formStateCopy, attributesForUpdate);

      formData.append("form", JSON.stringify(formDataForEdit));

      api
        .updateModuleActivity(moduleActivityId, formData, {
          "content-type": "multipart/form-data",
        })
        .then(() => {
          setState({ ...state, loading: false, error: false });
          navigate(`/activities/${moduleActivityId}`);
        })
        .catch((err) => {
          console.error(err);
          setState({ ...state, loading: false, error: true });
        });
    }
  };

  const handleSelectChange = ({ target: { name, value } }) => {
    setShowError({ ...showError, [name]: true });
    let valueForForm = value;
    if (value === "false") {
      valueForForm = false;
    }
    if (value === "true") {
      valueForForm = true;
    }

    setForm({
      ...form,
      [name]: valueForForm,
    });
    setRequiredFields({ ...requiredFields, [name]: value });
  };

  const handleCheckboxChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChange = ({ target: { name, value } }) => {
    setShowError({ ...showError, [name]: true });
    if (
      name === "timeRequired" &&
      // eslint-disable-next-line no-restricted-globals
      (isNaN(parseInt(value, 10)) || !/^\d+$/.test(value))
    ) {
      setIsNotNumber(true);
      if (value.length > 0) {
        return;
      }
    } else {
      setIsNotNumber(false);
    }

    setForm({
      ...form,
      [name]: value,
    });
    setRequiredFields({ ...requiredFields, [name]: value });
  };

  const handleDeleteResourceAsset = () => {
    if (publicIdForDelete === attributesForUpdate.ResourcePublicId) {
      setState({ ...state, loading: true });
      api
        .deleteActivityResourceAsset(publicIdForDelete)
        .then(() => {
          setAttributesForUpdate({
            ...attributesForUpdate,
            ResourcePublicId: "00000000-0000-0000-0000-000000000000",
            ResourceUrl: "",
          });
          setState({ ...state, loading: false });
          handleCloseModal();
        })
        .catch((err) => {
          console.error(err);
          setState({ ...state, loading: false, error: true });
          handleCloseModal();
        });
    }

    if (publicIdForDelete === attributesForUpdate.ImagePublicId) {
      setState({ ...state, loading: true, error: false });
      api
        .deleteActivityImageAsset(publicIdForDelete)
        .then(() => {
          setAttributesForUpdate({
            ...attributesForUpdate,
            ImagePublicId: "00000000-0000-0000-0000-000000000000",
            ImageUrl: "",
          });
          setState({ ...state, loading: false });
          handleCloseModal();
        })
        .catch((err) => {
          console.error(err);
          setState({ ...state, loading: false, error: true });
        });
    }

    if (publicIdForDelete === attributesForUpdate.ResourcePublicIdEn) {
      setState({ ...state, loading: true, error: false });
      api
        .deleteEnglishActivityResourceAsset(publicIdForDelete)
        .then(() => {
          setAttributesForUpdate({
            ...attributesForUpdate,
            ResourcePublicIdEn: "00000000-0000-0000-0000-000000000000",
            ResourceUrlEn: "",
          });
          setState({ ...state, loading: false });
          handleCloseModal();
        })
        .catch((err) => {
          console.error(err);
          setState({ ...state, loading: false, error: true });
        });
    }
  };

  const checkRequiredInput = (input) =>
    (showError[input] || showErrorAll) &&
    (form[input] === "" || form[input] === undefined);

  const generateHelperText = (input, fieldName) =>
    checkRequiredInput(input)
      ? t("missingMandatoryInput", { field: fieldName })
      : "";

  const handleConnectedResourceChange = async ({ target: { name, value } }) => {
    if (name === "Resources") {
      setConnectedResourceState({
        ...connectedResourceState,
        connectedResourceLoading: true,
        connectedResourceError: undefined,
      });
      try {
        const data =
          value === "Not set" ? { ResourceId: null } : { ResourceId: value };
        await api.connectResourceToActivity(data, moduleActivityId);
        setResourceData({ ...resourceData, linkedResourceId: value });
        setConnectedResourceState({
          ...connectedResourceState,
          connectedResourceLoading: false,
        });
      } catch (err) {
        console.error(err);
        setConnectedResourceState({
          ...connectedResourceState,
          connectedResourceLoading: false,
          connectedResourceError: true,
        });
      }
      return;
    }

    if (name === "MicroCourses") {
      setConnectedResourceState({
        ...connectedResourceState,
        connectedMicroCourseLoading: true,
        connectedMicroCourseError: undefined,
      });
      try {
        const data =
          value === "Not set" ? { ResourceId: null } : { ResourceId: value };
        setResourceData({ ...resourceData, linkedMicroCourseId: value });
        await api.connectMicroCourseToActivity(data, moduleActivityId);
        setConnectedResourceState({
          ...connectedResourceState,
          connectedMicroCourseLoading: false,
        });
      } catch (err) {
        console.error(err);
        setConnectedResourceState({
          ...connectedResourceState,
          connectedMicroCourseLoading: false,
          connectedMicroCourseError: true,
        });
      }
    }
  };

  const { resources, microCourses } = useMemo(
    () => ({
      resources: resourceData.resources
        .filter((r) => r.nameSv && r.nameEn)
        .sort((a, b) =>
          (
            a[`name${currLang.charAt(0).toUpperCase() + currLang.slice(1)}`] ??
            ""
          ).localeCompare(
            b[`name${currLang.charAt(0).toUpperCase() + currLang.slice(1)}`]
          )
        ),
      microCourses: resourceData.microCourses
        .filter((mc) => mc.textData.nameSv && mc.textData.nameEn)
        .sort((a, b) =>
          (
            a.textData[
              `name${currLang.charAt(0).toUpperCase() + currLang.slice(1)}`
            ] ?? ""
          ).localeCompare(
            b.textData[
              `name${currLang.charAt(0).toUpperCase() + currLang.slice(1)}`
            ]
          )
        ),
    }),
    [currLang, resourceData]
  );

  const mainGui = () => {
    if (state.loading)
      return create ? (
        <Loader
          text={t("ActivityEdit.loadingCreate")}
          style={{ marginTop: 40 }}
        />
      ) : (
        <Loader
          text={t("ActivityEdit.loadingEdit")}
          style={{ marginTop: 40 }}
        />
      );
    if (!state.loading && !state.error) {
      return (
        <>
          <div className="section-toolbar">
            <BackButton />
            <Typography className="section-title" variant="h6">
              {create
                ? t("ActivityEdit.titleCreate")
                : t("ActivityEdit.titleEdit")}
            </Typography>
            <Spacer />
            <Button
              color="primary"
              variant="contained"
              startIcon={<Save />}
              onClick={handleSave}
            >
              {t("ButtonGeneral.save")}
            </Button>
          </div>
          <Divider />
          <div>
            <Paper className="activity-edit-details">
              <div>
                <div className="activity-select-wrapper">
                  <div className="activity-select-spacer">
                    <InputLabel id="label">{t("ActivityEdit.type")}</InputLabel>
                    <Select
                      labelId="label"
                      name="Type"
                      id="select"
                      value={form.Type}
                      onChange={(e) => handleSelectChange(e)}
                      error={checkRequiredInput("Type")}
                    >
                      <MenuItem value="Reflection">
                        {t("ActivityEdit.reflection")}
                      </MenuItem>
                      <MenuItem value="Lecture">
                        {t("ActivityEdit.lecture")}
                      </MenuItem>
                      <MenuItem value="Exercise">
                        {t("ActivityEdit.exercise")}
                      </MenuItem>
                    </Select>
                    <FormHelperText style={{ color: "red" }}>
                      {generateHelperText("Type", t("ActivityEdit.type"))}
                    </FormHelperText>
                    {!create && (
                      <div className="activity-edit-connected-material-content-wrapper">
                        <div className="activity-edit-connected-material-label-wrapper">
                          <div>
                            <InputLabel id="label">
                              {t("ActivityEdit.connectedResource")}
                            </InputLabel>
                            <Select
                              labelId="label"
                              fullWidth
                              name="Resources"
                              id="select"
                              value={resourceData.linkedResourceId}
                              onChange={handleConnectedResourceChange}
                            >
                              <MenuItem value="Not set">
                                {t("ActivityEdit.noSelectedConnectedResource")}
                              </MenuItem>
                              {resources.map((r) => (
                                <MenuItem value={r.id}>
                                  {
                                    r[
                                      `name${
                                        currLang.charAt(0).toUpperCase() +
                                        currLang.slice(1)
                                      }`
                                    ]
                                  }
                                </MenuItem>
                              ))}
                            </Select>
                          </div>
                          {connectedResourceState.connectedResourceLoading && (
                            <FlexibleSizeLoader
                              size={15}
                              style={{
                                marginTop: 20,
                                marginLeft: 10,
                                alignItems: "left",
                              }}
                            />
                          )}
                        </div>
                        {connectedResourceState.connectedResourceError && (
                          <Alert severity="error">
                            {t("ActivityEdit.connectedResourceError")}
                          </Alert>
                        )}
                        <div className="activity-edit-connected-material-label-wrapper">
                          <div>
                            <InputLabel id="label">
                              {t("ActivityEdit.connectedMicroCourse")}
                            </InputLabel>
                            <Select
                              labelId="label"
                              name="MicroCourses"
                              fullWidth
                              id="select"
                              value={resourceData.linkedMicroCourseId}
                              onChange={handleConnectedResourceChange}
                            >
                              <MenuItem value="Not set">
                                {t("ActivityEdit.noSelectedConnectedResource")}
                              </MenuItem>
                              {microCourses.map(({ id, textData }) => (
                                <MenuItem value={id}>
                                  {
                                    textData[
                                      `name${
                                        currLang.charAt(0).toUpperCase() +
                                        currLang.slice(1)
                                      }`
                                    ]
                                  }
                                </MenuItem>
                              ))}
                            </Select>
                          </div>
                          {connectedResourceState.connectedMicroCourseLoading && (
                            <FlexibleSizeLoader
                              size={15}
                              style={{
                                marginTop: 20,
                                marginLeft: 10,
                                alignItems: "left",
                              }}
                            />
                          )}
                        </div>
                      </div>
                    )}
                    {connectedResourceState.connectedMicroCourseError && (
                      <Alert severity="error">
                        {t("ActivityEdit.connectedMicroCourseError")}
                      </Alert>
                    )}
                  </div>
                  <FormControl className="activity-edit-form">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={form.enableInput}
                          onChange={handleCheckboxChange}
                          color="primary"
                          name="enableInput"
                        />
                      }
                      label={t("ActivityEdit.enableInput")}
                    />
                    {form.enableInput === true && (
                      <div>
                        <TextField
                          label={t("ActivityEdit.inputInstructionsSv")}
                          inputMode="text"
                          value={form.inputInstructionsSv}
                          name="inputInstructionsSv"
                          multiline
                          variant="outlined"
                          onChange={(e) => handleChange(e)}
                          className="activity-edit-instructions"
                        />
                        <TextField
                          label={t("ActivityEdit.inputInstructionsEn")}
                          inputMode="text"
                          value={form.inputInstructionsEn}
                          name="inputInstructionsEn"
                          multiline
                          variant="outlined"
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    )}
                  </FormControl>
                </div>
                {languages.map((lang) => [
                  <div key={lang}>
                    <div className="activity-form-text">
                      <TextField
                        name={`Name${
                          lang.charAt(0).toUpperCase() + lang.slice(1)
                        }`}
                        label={`${t(`ActivityEdit.name`)} ${t(
                          `Language.languages.${lang}`
                        )}`}
                        variant="outlined"
                        fullWidth
                        multiline
                        required
                        value={
                          form[
                            `Name${
                              lang.charAt(0).toUpperCase() + lang.slice(1)
                            }`
                          ]
                        }
                        error={checkRequiredInput(
                          `Name${lang.charAt(0).toUpperCase() + lang.slice(1)}`
                        )}
                        helperText={generateHelperText(
                          `Name${lang.charAt(0).toUpperCase() + lang.slice(1)}`,
                          t("ActivityEdit.name")
                        )}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="activity-form-text">
                      <TextField
                        name={`shortDescription${
                          lang.charAt(0).toUpperCase() + lang.slice(1)
                        }`}
                        label={`${t(`ActivityEdit.shortDesc`)} ${t(
                          `Language.languages.${lang}`
                        )}`}
                        variant="outlined"
                        fullWidth
                        multiline
                        required
                        rows={2}
                        rowsMax={15}
                        value={
                          form[
                            `shortDescription${
                              lang.charAt(0).toUpperCase() + lang.slice(1)
                            }`
                          ]
                        }
                        error={checkRequiredInput(
                          `shortDescription${
                            lang.charAt(0).toUpperCase() + lang.slice(1)
                          }`
                        )}
                        helperText={generateHelperText(
                          `shortDescription${
                            lang.charAt(0).toUpperCase() + lang.slice(1)
                          }`,
                          t("ActivityEdit.shortDesc")
                        )}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="activity-form-text">
                      <TextField
                        name={`description${
                          lang.charAt(0).toUpperCase() + lang.slice(1)
                        }`}
                        label={`${t(`ActivityEdit.desc`)} ${t(
                          `Language.languages.${lang}`
                        )}`}
                        variant="outlined"
                        fullWidth
                        multiline
                        required
                        rows={4}
                        rowsMax={25}
                        value={
                          form[
                            `description${
                              lang.charAt(0).toUpperCase() + lang.slice(1)
                            }`
                          ]
                        }
                        error={checkRequiredInput(
                          `description${
                            lang.charAt(0).toUpperCase() + lang.slice(1)
                          }`
                        )}
                        helperText={generateHelperText(
                          `description${
                            lang.charAt(0).toUpperCase() + lang.slice(1)
                          }`,
                          t("ActivityEdit.desc")
                        )}
                        onChange={handleChange}
                      />
                    </div>
                  </div>,
                ])}
                <div className="activity-form-text">
                  <TextField
                    error={isNotNumber}
                    name="timeRequired"
                    label={`${t(`ActivityEdit.timeRequired`)}`}
                    variant="outlined"
                    fullWidth
                    multiline
                    required
                    helperText={
                      isNotNumber ? t(`ResourceEdit.numberErrorText`) : ""
                    }
                    value={form.timeRequired}
                    onChange={handleChange}
                  />
                </div>
              </div>

              {!create ? (
                <div className="input-wrapper">
                  <div className="file-upload-wrapper">
                    <CloudinaryContext
                      cloudName={process.env.REACT_APP_CLOUD_NAME}
                    >
                      <p className="upload-paragraph-header">
                        {t(`ActivityEdit.presentationImage`)}
                      </p>
                      <Button
                        color="primary"
                        className="asset-upload-button-style"
                        variant="contained"
                        onClick={() => beginCloudinaryImageUpload()}
                        disabled={
                          attributesForUpdate.ImagePublicId !== "" &&
                          attributesForUpdate.ImagePublicId !==
                            "00000000-0000-0000-0000-000000000000"
                        }
                      >
                        {t(`ActivityEdit.upload`)}
                      </Button>
                    </CloudinaryContext>
                    {t(`ResourceEdit.uploaded`)}
                    {attributesForUpdate.ImageUrl ? (
                      <div className="activity-edit-upload-wrapper">
                        <img
                          src={attributesForUpdate.ImageUrl}
                          height="150px"
                          width="200px"
                          alt="imageUrl"
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="button-text-wrapper">
                      <Dialog
                        open={isModalOpen}
                        onClose={handleCloseModal}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">
                          <Typography className="reinforced-delete-alert-style">
                            {t("ActivityEdit.deleteAlertReinforced")}
                          </Typography>
                        </DialogTitle>
                        <DialogActions>
                          <Button
                            onClick={handleDeleteResourceAsset}
                            color="primary"
                          >
                            {t("ButtonGeneral.acceptDeleteAlert")}
                          </Button>
                          <Button onClick={handleCloseModal} color="primary">
                            {t("ButtonGeneral.denyDeleteAlert")}
                          </Button>
                        </DialogActions>
                      </Dialog>
                      <Button
                        color="secondary"
                        variant="contained"
                        name="deleteImage"
                        startIcon={<Delete />}
                        disabled={
                          !attributesForUpdate.ImagePublicId ||
                          attributesForUpdate.ImagePublicId ===
                            "00000000-0000-0000-0000-000000000000"
                        }
                        className="delete-button-style"
                        onClick={handleOpenDeleteModalImage}
                      >
                        {t("ActivityEdit.delete")}
                      </Button>
                    </div>
                  </div>

                  <div className="file-upload-wrapper">
                    <CloudinaryContext
                      cloudName={process.env.REACT_APP_CLOUD_NAME}
                    >
                      <p className="upload-paragraph-header">
                        {t(`ActivityEdit.resourceFile`)}
                      </p>
                      <Button
                        color="primary"
                        className="asset-upload-button-style"
                        variant="contained"
                        onClick={() => beginCloudinaryImagePdfOrVideoUpload()}
                        disabled={
                          attributesForUpdate.ResourcePublicId !== "" &&
                          attributesForUpdate.ResourcePublicId !==
                            "00000000-0000-0000-0000-000000000000"
                        }
                      >
                        {t(`ActivityEdit.upload`)}
                      </Button>
                    </CloudinaryContext>
                    {t(`ActivityEdit.uploaded`)}
                    <div className="button-text-wrapper">
                      {
                        // eslint-disable-next-line no-nested-ternary
                        attributesForUpdate.ResourceUrl &&
                        attributesForUpdate.ResourceUrl.includes("image") &&
                        !attributesForUpdate.ResourceUrl.includes("pdf") ? (
                          <div className="activity-edit-upload-wrapper">
                            <img
                              src={attributesForUpdate.ResourceUrl}
                              height="150px"
                              width="200px"
                              alt="resourceUrl"
                            />
                          </div>
                        ) : // eslint-disable-next-line no-nested-ternary
                        attributesForUpdate.ResourceUrl &&
                          (attributesForUpdate.ResourceUrl.includes("video") ||
                            attributesForUpdate.ResourceUrl.includes("mp4")) ? (
                          <div className="activity-edit-upload-wrapper">
                            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                            <video controls width="250">
                              <source
                                src={attributesForUpdate.ResourceUrl}
                                type="video/webm"
                              />
                              Sorry, your browser doesn&apos;t support embedded
                              videos.
                            </video>
                          </div>
                        ) : attributesForUpdate.ResourceUrl &&
                          attributesForUpdate.ResourceUrl.includes(
                            "youtube"
                          ) ? (
                          <iframe
                            width="250"
                            height="140"
                            title="youtube-video"
                            src={attributesForUpdate.ResourceUrl}
                          />
                        ) : (
                          attributesForUpdate.ResourceUrl &&
                          attributesForUpdate.ResourceUrl.includes("image") &&
                          attributesForUpdate.ResourceUrl.endsWith(".pdf") && (
                            <div className="activity-edit-upload-wrapper">
                              <iframe
                                scrolling="no"
                                title="pdf display"
                                src={attributesForUpdate.ResourceUrl}
                                className="activity-edit-pdf-display-style"
                              />
                            </div>
                          )
                        )
                      }
                      <Button
                        color="secondary"
                        variant="contained"
                        name="deleteResource"
                        startIcon={<Delete />}
                        disabled={
                          !attributesForUpdate.ResourcePublicId ||
                          attributesForUpdate.ResourcePublicId ===
                            "00000000-0000-0000-0000-000000000000"
                        }
                        onClick={() => handleOpenDeleteModalResource()}
                        className="delete-button-style"
                      >
                        {t("ActivityEdit.delete")}
                      </Button>
                    </div>
                  </div>
                  <div className="file-upload-wrapper">
                    <CloudinaryContext
                      cloudName={process.env.REACT_APP_CLOUD_NAME}
                    >
                      <p className="upload-paragraph-header">
                        {t(`ActivityEdit.resourceFileEn`)}
                      </p>
                      <Button
                        color="primary"
                        className="asset-upload-button-style"
                        variant="contained"
                        onClick={() =>
                          beginEnglishCloudinaryImagePdfOrVideoUpload()
                        }
                        disabled={
                          attributesForUpdate.ResourcePublicIdEn !== "" &&
                          attributesForUpdate.ResourcePublicIdEn !==
                            "00000000-0000-0000-0000-000000000000"
                        }
                      >
                        {t(`ActivityEdit.upload`)}
                      </Button>
                    </CloudinaryContext>
                    {t(`ResourceEdit.uploaded`)}
                    <div className="button-text-wrapper">
                      {
                        // eslint-disable-next-line no-nested-ternary
                        attributesForUpdate.ResourceUrlEn &&
                        attributesForUpdate.ResourceUrlEn.includes("image") &&
                        !attributesForUpdate.ResourceUrlEn.includes("pdf") ? (
                          <div className="activity-edit-upload-wrapper">
                            <img
                              src={attributesForUpdate.ResourceUrlEn}
                              height="150px"
                              width="200px"
                              alt="resourceUrl"
                            />
                          </div>
                        ) : // eslint-disable-next-line no-nested-ternary
                        attributesForUpdate.ResourceUrlEn &&
                          (attributesForUpdate.ResourceUrlEn.includes(
                            "video"
                          ) ||
                            attributesForUpdate.ResourceUrlEn.includes(
                              "mp4"
                            )) ? (
                          <div className="activity-edit-upload-wrapper">
                            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                            <video controls width="250">
                              <source
                                src={attributesForUpdate.ResourceUrlEn}
                                type="video/webm"
                              />
                              Sorry, your browser doesn&apos;t support embedded
                              videos.
                            </video>
                          </div>
                        ) : attributesForUpdate.ResourceUrlEn &&
                          attributesForUpdate.ResourceUrlEn.includes(
                            "youtube"
                          ) ? (
                          <iframe
                            width="250"
                            height="140"
                            title="youtube-video"
                            src={attributesForUpdate.ResourceUrlEn}
                          />
                        ) : (
                          attributesForUpdate.ResourceUrlEn &&
                          attributesForUpdate.ResourceUrlEn.includes("image") &&
                          attributesForUpdate.ResourceUrlEn.endsWith(
                            ".pdf"
                          ) && (
                            <div className="activity-edit-upload-wrapper">
                              <iframe
                                scrolling="no"
                                title="pdf display"
                                src={attributesForUpdate.ResourceUrlEn}
                                className="activity-edit-pdf-display-style"
                              />
                            </div>
                          )
                        )
                      }
                      <Button
                        color="secondary"
                        variant="contained"
                        name="deleteResourceEn"
                        startIcon={<Delete />}
                        disabled={
                          !attributesForUpdate.ResourcePublicIdEn ||
                          attributesForUpdate.ResourcePublicIdEn ===
                            "00000000-0000-0000-0000-000000000000"
                        }
                        onClick={() => handleOpenDeleteModalEnglishResource()}
                        className="delete-button-style"
                      >
                        {t("ResourceEdit.delete")}
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </Paper>
          </div>
        </>
      );
    }
    return (
      <Loader text={t("ResourceEdit.loading")} style={{ marginTop: 40 }} />
    );
  };

  return <div className="companies-wrapper">{mainGui()}</div>;
};

ActivityEdit.propTypes = {
  create: PropTypes.bool,
};

ActivityEdit.defaultProps = {
  create: false,
};

export default ActivityEdit;
