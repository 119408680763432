import React, { useEffect, useState } from "react";
import { Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { Save } from "@material-ui/icons";
import Select from "@material-ui/core/Select";
import { useTranslation } from "react-i18next";
import Loader from "../../shared/loader/Loader";
import Spacer from "../../shared/Spacer";
import api from "../../../utils/api/v1";

const ReminderSettings = () => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    loading: false,
    error: false,
  });
  const [form, setForm] = useState({
    surveyReminderTime: "",
    inactiveReminderTime: "",
    motivationReminderTime: "",
  });

  const [firstTime, setFirstTime] = useState(true);

  const getEmailReminderTime = () => {
    setState({ ...state, loading: true });
    api
      .getEmailReminderTime()
      .then(({ data }) => {
        if (data.length === 0) {
          setFirstTime(true);
        } else {
          setFirstTime(false);
        }
        setForm(data);
        setState({ ...state, loading: false });
      })
      .catch(() => {
        setState({ ...state, error: true, loading: false });
      });
  };

  const useStyles = makeStyles((theme) => ({
    formControl: {
      background: "#ffffff",
      margin: theme.spacing(2),
      width: 500,
      minWidth: 500,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    headlines: {
      margin: theme.spacing(2),
    },
  }));

  const classes = useStyles();

  const handleChange = ({ target: { name, value } }) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleCreate = async () => {
    setState({ ...state, loading: true, error: false });
    try {
      await api.createEmailReminderTime(form).then((data) => {
        console.log(data);
        setState({ ...state, loading: false });
        setFirstTime(false);
      });
    } catch (error) {
      console.log(error);
      setState({ ...state, error: true, loading: false });
    }
    window.location.reload();
  };

  const handleUpdate = async () => {
    setState({ ...state, loading: true, error: false });
    try {
      await api.editEmailReminderTime(form).then((data) => {
        console.log(data);
        setState({ ...state, loading: false });
      });
    } catch (error) {
      console.log(error);
      setState({ ...state, error: true, loading: false });
    }
    window.location.reload();
  };

  const reminderFields = [
    "surveyReminderTime",
    "inactiveReminderTime",
    "motivationReminderTime",
  ];

  useEffect(() => {
    getEmailReminderTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state.loading) {
    return (
      <Loader text={t("Teachers.loadingSingle")} style={{ marginTop: 40 }} />
    );
  }

  return (
    <div>
      {reminderFields.map((reminder) => (
        <div>
          <Typography className={classes.headlines} variant="h6">
            {t(`Reminder.${reminder}`)}
          </Typography>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">
              {t(`Reminder.${reminder}`)}
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              name={reminder}
              value={form[reminder]}
              placeholder={t(`Reminder.${reminder}`)}
              onChange={handleChange}
              label={t(`Reminder.${reminder}`)}
            >
              <MenuItem value="0">
                <em>{t("Reminder.Never")}</em>
              </MenuItem>
              <MenuItem value="2">{t("Reminder.2days")}</MenuItem>
              <MenuItem value="3">{t("Reminder.3days")}</MenuItem>
              <MenuItem value="5">{t("Reminder.5days")}</MenuItem>
              <MenuItem value="7">{t("Reminder.1week")}</MenuItem>
              <MenuItem value="14">{t("Reminder.2week")}</MenuItem>
              <MenuItem value="21">{t("Reminder.3week")}</MenuItem>
              <MenuItem value="30">{t("Reminder.1month")}</MenuItem>
            </Select>
          </FormControl>
          <Spacer />
        </div>
      ))}
      {firstTime ? (
        <Button
          className={classes.headlines}
          color="primary"
          variant="contained"
          startIcon={<Save />}
          onClick={handleCreate}
        >
          {t("Reminder.Create")}
        </Button>
      ) : (
        <Button
          className={classes.headlines}
          color="primary"
          variant="contained"
          startIcon={<Save />}
          onClick={handleUpdate}
        >
          {t("Reminder.Update")}
        </Button>
      )}
    </div>
  );
};

export default ReminderSettings;
