import { StyleSheet } from "@react-pdf/renderer";

export const strategyPlanKPIStyling = StyleSheet.create({
  page: {
    backgroundColor: "white",
    width: "100%",
    height: "100vh",
    orientation: "portrait",
    backgroundSize: "cover",
    position: "relative",
  },
  strategyPlanKPIMainContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    paddingLeft: "40px",
    paddingRight: "40px",
  },
  strategyPlanKPIHorizontalLine: {
    textAlign: "center",
    marginTop: "35px",
    width: "500px",
    borderBottom: "3px",
    borderBottomColor: "#068466",
    borderBottomStyle: "solid",
    borderBottomWidth: "3px",
  },
  strategyPlanKPIRecommendation: {
    fontSize: "13px",
    fontFamily: "Times-Roman",
    letterSpacing: "1px",
    marginTop: "20px",
    marginBottom: "20px",
  },
  columnsParentContainer: {
    display: "flex",
    flexDirection: "row",
  },
  leftColumnContainer: {
    width: "245px",
    marginRight: "30px",
  },
  strategicOne: {
    fontSize: "32px",
    fontFamily: "Helvetica",
    letterSpacing: "1px",
    marginTop: "15px",
    width: "230px",
  },
  strategicTwo: {
    fontSize: "32px",
    fontFamily: "Helvetica",
    letterSpacing: "1px",
    marginTop: "5px",
    marginBottom: "15px",
    width: "230px",
  },
  leftParagraphOne: {
    fontSize: "12px",
    fontFamily: "Times-Roman",
    letterSpacing: "0px",
    lineHeight: "1.2px",
    marginTop: "10px",
    marginBottom: "30px",
  },
  paragraphTitle: {
    fontFamily: "Helvetica",
    fontSize: "13px",
  },
  leftParagraphTwo: {
    fontSize: "12px",
    fontFamily: "Times-Roman",
    letterSpacing: "0px",
    lineHeight: "1.2px",
    marginTop: "2px",
    marginBottom: "10px",
    textAlign: "left",
  },
  leftParagraphThree: {
    fontSize: "12px",
    fontFamily: "Times-Roman",
    letterSpacing: "0px",
    lineHeight: "1.2px",
    marginTop: "2px",
    marginBottom: "10px",
    textAlign: "left",
  },
  leftParagraphFour: {
    fontSize: "12px",
    fontFamily: "Times-Roman",
    letterSpacing: "0px",
    lineHeight: "1.2px",
    marginTop: "2px",
    marginBottom: "10px",
    textAlign: "left",
  },
  leftParagraphFive: {
    fontSize: "12px",
    fontFamily: "Times-Roman",
    letterSpacing: "0px",
    lineHeight: "1.2px",
    marginTop: "2px",
    marginBottom: "10px",
    textAlign: "left",
  },
  rightColumnContainer: {
    width: "245px",
    zIndex: 0,
  },
  rightParagraphOne: {
    fontSize: "12px",
    fontFamily: "Times-Roman",
    letterSpacing: "0px",
    lineHeight: "1.2px",
    marginTop: "2px",
    marginBottom: "15px",
    width: "220px",
    textAlign: "left",
  },
  rightParagraphTwo: {
    fontSize: "12px",
    fontFamily: "Times-Roman",
    letterSpacing: "0px",
    lineHeight: "1.2px",
    marginTop: "2px",
    marginBottom: "15px",
    width: "220px",
    textAlign: "left",
  },
  strategyPlanKPICircleContainer: {
    position: "relative",
    zIndex: 1,
  },
  strategyPlanKPICircleDiv: {
    backgroundColor: "#068466",
    width: "200px",
    height: "200px",
    borderRadius: "50%",
    position: "absolute",
    bottom: -150,
    right: -110,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
  },
});
