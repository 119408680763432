import { StyleSheet } from "@react-pdf/renderer";

export const actionProgramStyle = StyleSheet.create({
  questionWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  subTitleWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  atctionProgram: {
    fontSize: "32px",
    fontFamily: "Helvetica",
    letterSpacing: "1px",
    marginTop: "15px",
    width: "500px",
  },
  circle: {
    backgroundColor: "#068466",
    width: "70px",
    height: "70px",
    bottom: 10,
    borderRadius: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "30px",
    marginRight: "10px",
  },
  circleText: {
    fontSize: "32px",
    color: "#fff",
  },
  paragraph: {
    fontSize: "12px",
    fontFamily: "Times-Roman",
    letterSpacing: "0px",
    lineHeight: "1.2px",
    marginTop: "10px",
    marginBottom: "30px",
  },
});
