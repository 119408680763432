import { useState, useEffect, VFC, SetStateAction, Dispatch } from "react";
import { useTranslation } from "react-i18next";
import "./Cycles.css";
import { Typography, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Add } from "@material-ui/icons";
import { useParams } from "react-router";
import SingleSelectionGrid from "../../../UI-Components/SingleRowSelectionGrid/SingleRowSelectionGrid";
import { getLanguage } from "../../../i18n";
import Spacer from "../../shared/Spacer";
import api from "../../../utils/api/v1";
import Loader from "../../shared/loader/Loader";
import { MissingRequiredParamError } from "../../errorHandling/MissingRequiredParamError";
import type { Columns } from "../../../UI-Components/CheckboxSelectionGrid/CheckboxSelectionGrid";
import type { ApiResponseCompanyCycle } from "../../../utils/api/apiInterfaces";

interface Props {
  setCompanyHasCycles: Dispatch<SetStateAction<boolean>>;
}

interface State {
  loading: boolean;
  error: boolean;
  cycles: Array<ApiResponseCompanyCycle>;
}

const Cycles: VFC<Props> = ({ setCompanyHasCycles }) => {
  const { t } = useTranslation();
  const { companyId } = useParams();
  const [, setWindowWidth] = useState(window.innerWidth);
  const [state, setState] = useState<State>({
    loading: false,
    error: false,
    cycles: [],
  });

  useEffect(() => {
    getCycles();
    window.addEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!companyId) {
    return <MissingRequiredParamError missingParam="companyId" />;
  }

  const getCycles = () => {
    setState({ ...state, loading: true, error: false });
    api
      .getCycles(companyId)
      .then(({ data }) => {
        if (data.length > 0) {
          setCompanyHasCycles(true);
        } else {
          setCompanyHasCycles(false);
        }

        const timezoneOffset = new Date().getTimezoneOffset();
        const dataCopy = data.map((cycle) => {
          cycle.startDate = String(
            Date.parse(cycle.startDate) - timezoneOffset * 60 * 1000
          );
          cycle.endDate = String(
            Date.parse(cycle.endDate) - timezoneOffset * 60 * 1000
          );
          cycle.emailLastSent = cycle.emailLastSent
            ? String(
                Date.parse(cycle.emailLastSent) - timezoneOffset * 60 * 1000
              )
            : null;
          return cycle;
        });

        setState({
          ...state,
          cycles: dataCopy.sort((c1, c2) => {
            const c1date = c1.endDate;
            const c2date = c2.endDate;
            if (!c1date && !c2date) return 0;
            if (!c2date) return -1;
            if (!c1date) return 1;
            return new Date(c2date).getTime() - new Date(c1date).getTime();
          }),
          loading: false,
        });
      })
      .catch((err) => {
        console.error(err);
        setState({ ...state, loading: false, error: true });
      });
  };

  const formatDateTime = (dateTime: number) => {
    const date = new Date(dateTime);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return date.toLocaleDateString(getLanguage(), options);
  };

  const columns: Columns<string> = [
    {
      field: "name",
      headerName: t("Cycles.table.surveyName"),
      width:
        window.innerWidth >= 960
          ? window.innerWidth / 4
          : window.innerWidth / 3,
      valueGetter: (params) => params.row.surveyName || "-",
    },
    {
      field: "startDate",
      headerName: t("Cycles.table.startDate"),
      width:
        window.innerWidth >= 960
          ? window.innerWidth / 6
          : window.innerWidth / 5,
      valueGetter: (params) =>
        formatDateTime(Number(params.row.startDate)) || "-",
    },
    {
      field: "endDate",
      headerName: t("Cycles.table.endDate"),
      width:
        window.innerWidth >= 960
          ? window.innerWidth / 6
          : window.innerWidth / 5,
      valueGetter: (params) =>
        formatDateTime(Number(params.row.endDate)) || "-",
    },
    {
      field: "emailLastSent",
      headerName: t("Cycles.emailSent"),
      width:
        window.innerWidth >= 960
          ? window.innerWidth / 6
          : window.innerWidth / 5,
      valueGetter: (params) =>
        params.row.emailLastSent
          ? formatDateTime(Number(params.row.emailLastSent))
          : "-",
    },
  ];

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const mainGui = () => {
    if (state.loading)
      return <Loader text={t("Cycles.loading")} style={{ marginTop: 40 }} />;
    if (!state.loading && state.cycles) {
      return (
        <>
          <div className="cycles-toolbar">
            <Typography className="cycles-title" variant="h5">
              {t("Cycles.title")}
            </Typography>
            <Spacer />
            <Link
              to={`/companies/${companyId}/cycles/new`}
              className="cycles-link-style"
            >
              <Button
                startIcon={<Add />}
                color="primary"
                variant="contained"
                data-testid="addCycleButton"
              >
                {t("Cycles.create")}
              </Button>
            </Link>
          </div>
          <div
            className="section-SingleSelectionGrid-layout"
            data-testid="cyclesTableGrid"
          >
            <SingleSelectionGrid
              columns={columns}
              rows={state.cycles}
              pageSize={10}
              loading={state.loading}
              path={`companies/${companyId}/cycles`}
            />
          </div>
        </>
      );
    }

    return <Loader text={t("Cycles.loading")} style={{ marginTop: 40 }} />;
  };

  return <div className="cycles-wrapper">{mainGui()}</div>;
};

export default Cycles;
