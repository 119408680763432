/* eslint-disable no-nested-ternary */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./Resources.css";
import {
  Paper,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { languages, getLanguage } from "../../i18n";
import Spacer from "../shared/Spacer";
import api from "../../utils/api/v1";
import Loader from "../shared/loader/Loader";
import { BackButton } from "../../UI-Components/Buttons/Buttons";
import type { ApiResponseResourceWithTextDataModel } from "../../utils/api/apiInterfaces";
import { MissingRequiredParamError } from "../errorHandling/MissingRequiredParamError";

interface State {
  loading: boolean;
  error: boolean;
  resource: ApiResponseResourceWithTextDataModel | null;
}

const resolveComplexityLevelOrContentType = (
  value: string | number | null,
  lang: string
) => {
  if (value === null) return lang === "sv" ? "Inte satt" : "Not set";
  return value;
};

const ResourceDetails = () => {
  const { t } = useTranslation();
  const { resourceId } = useParams();
  const [openAlert, setOpenAlert] = useState(false);
  const currentLanguage = getLanguage();
  const navigate = useNavigate();

  const [state, setState] = useState<State>({
    loading: false,
    error: false,
    resource: null,
  });

  useEffect(() => {
    getResource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!resourceId) {
    return <MissingRequiredParamError missingParam="resourceId" />;
  }

  const deleteResource = () => {
    setState({ ...state, loading: true });
    api
      .deleteResource(state.resource?.id ?? "")
      .then(() => {
        setState({ ...state, loading: false });
        navigate("/resources");
      })
      .catch((err) => {
        console.error(err);
        setState({ ...state, loading: false, error: true });
      });
  };

  const getResource = () => {
    setState({ ...state, loading: true });
    api
      .getResource(resourceId)
      .then(({ data }) => {
        setState({ ...state, resource: data, loading: false });
      })
      .catch((err) => {
        console.error(err);
        setState({ ...state, loading: false, error: true });
      });
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleDeleteResource = () => {
    deleteResource();
  };

  const mainGui = () => {
    if (state.loading)
      return (
        <Loader text={t("ResourceDetails.loading")} style={{ marginTop: 40 }} />
      );
    if (!state.loading && state.resource) {
      return (
        <>
          <div className="section-toolbar">
            <BackButton navigateTo="/resources" />
            <Typography className="section-title" variant="h6">
              {currentLanguage === "sv"
                ? state.resource.nameSv
                : currentLanguage === "en"
                ? state.resource.nameEn
                : ""}
            </Typography>
            <Spacer />
            <Link to="edit" className="surveys-link-style">
              <Button startIcon={<Edit />} color="primary" variant="contained">
                {t("CycleDetails.edit")}
              </Button>
            </Link>

            <Button
              startIcon={<Delete />}
              color="secondary"
              variant="contained"
              onClick={() => {
                handleOpenAlert();
              }}
              style={{ margin: 5 }}
            >
              {t("CycleDetails.delete")}
            </Button>

            <Dialog
              open={openAlert}
              onClose={handleCloseAlert}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {t("ResourceDetails.deleteAlert")}
              </DialogTitle>

              <DialogActions>
                <Button onClick={() => handleDeleteResource()} color="primary">
                  {t("CycleDetails.acceptDeleteAlert")}
                </Button>
                <Button onClick={handleCloseAlert} color="primary" autoFocus>
                  {t("CycleDetails.denyDeleteAlert")}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <div>
            <Paper className="section-detail-layout">
              <Typography variant="h5" className="section-detail-title">
                {t(`ResourceDetails.detailsHeader`)}
              </Typography>
              <div className="resource-complexity-level-content-type-container">
                <Typography
                  className="resource-complexity-level-content-type-title"
                  variant="h5"
                >
                  {t("ResourceDetails.complexityLevel")}
                  {": "}
                  <span>
                    {resolveComplexityLevelOrContentType(
                      state.resource.complexityLevel,
                      currentLanguage
                    )}
                  </span>
                </Typography>
              </div>
              <div className="resource-content-type-container">
                <Typography
                  className="resource-complexity-level-content-type-title"
                  variant="h5"
                >
                  {t("ResourceDetails.contentType")}
                  {": "}
                  <span>
                    {resolveComplexityLevelOrContentType(
                      state.resource.contentType,
                      currentLanguage
                    )}
                  </span>
                </Typography>
              </div>
              <div>
                {languages.map((lang) => (
                  <div
                    key={lang}
                    className="text-description-container-modules"
                  >
                    <div className="text-container-resource-details">
                      <h4>
                        {t(
                          `ResourceDetails.resource.Name${
                            lang.charAt(0).toUpperCase() + lang.slice(1)
                          }`
                        )}
                      </h4>
                      <p>
                        {state.resource &&
                          state.resource[
                            `name${
                              lang.charAt(0).toUpperCase() + lang.slice(1)
                            }`
                          ]}
                      </p>
                    </div>
                    <div className="text-container-resource-details">
                      <h4>
                        {t(
                          `ResourceDetails.resource.ShortDesc${
                            lang.charAt(0).toUpperCase() + lang.slice(1)
                          }`
                        )}
                      </h4>
                      <p>
                        {state.resource &&
                          state.resource[
                            `shortDescription${
                              lang.charAt(0).toUpperCase() + lang.slice(1)
                            }`
                          ]}
                      </p>
                    </div>
                    <div className="text-container-resource-details text-container-overflow-module">
                      <h4>
                        {t(
                          `ResourceDetails.resource.Desc${
                            lang.charAt(0).toUpperCase() + lang.slice(1)
                          }`
                        )}
                      </h4>
                      <p>
                        {state.resource &&
                          state.resource[
                            `description${
                              lang.charAt(0).toUpperCase() + lang.slice(1)
                            }`
                          ]}
                      </p>
                    </div>
                  </div>
                ))}
                <div className="text-description-container-modules">
                  <div className="text-container">
                    <h4 className="description-text-style">
                      {t(`ResourceDetails.resource.type`)}
                    </h4>
                    <p>{state.resource.type}</p>
                  </div>
                  <div className="text-container">
                    <h4 className="description-text-style">
                      {t(`ResourceDetails.resource.timeRequired`)}
                    </h4>
                    <p>
                      {state.resource.timeRequired}{" "}
                      {state.resource.timeRequired === 1
                        ? t(`ResourceDetails.resource.minute`)
                        : t(`ResourceDetails.resource.minutes`)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="resources-assets-container">
                <div className="resource-uploaded-asset-container">
                  <p className="presentation-image-text">
                    {t(`ResourceDetails.overviewImage`)}
                  </p>
                  {state.resource.imageUrl ? (
                    <img
                      src={state.resource.imageUrl}
                      alt="resourceImageUrl"
                      className="resource-image-url"
                    />
                  ) : (
                    t(`ResourceDetails.overviewImageNotUploaded`)
                  )}
                </div>
                <div className="resource-uploaded-asset-container">
                  <p className="presentation-image-text">
                    {t(`ResourceDetails.resourceAsset`)}
                  </p>
                  {state.resource.resourceUrl ? (
                    <div>
                      {state.resource.resourceUrl.includes("video") ? (
                        <video
                          width="auto"
                          height="240"
                          controls
                          className="video-style"
                        >
                          <source
                            src={state.resource.resourceUrl}
                            type="video/webm"
                          />
                          <track
                            src="captions_en.vtt"
                            kind="captions"
                            srcLang="en"
                            label="english_captions"
                          />
                        </video>
                      ) : state.resource.resourceUrl.includes("pdf") ? (
                        <a href={state.resource.resourceUrl}>
                          <div className="pdf-image-url" />
                          <p className="pdf-text">
                            {t(`ActivityDetails.download`)}
                          </p>
                        </a>
                      ) : (
                        state.resource.resourceUrl.includes("image") &&
                        !state.resource.resourceUrl.includes("pdf") && (
                          <img
                            src={state.resource.resourceUrl}
                            alt="resourceImageUrl"
                            className="resource-image-url"
                          />
                        )
                      )}
                    </div>
                  ) : (
                    t(`ResourceDetails.noPresentationMaterialUploaded`)
                  )}
                </div>
                <div className="resource-uploaded-asset-container">
                  <p className="presentation-image-text">
                    {t(`ResourceDetails.resourceAssetEnglish`)}
                  </p>
                  {state.resource.resourceUrlEn ? (
                    <div>
                      {state.resource.resourceUrlEn.includes("video") ? (
                        <video
                          width="auto"
                          height="240"
                          controls
                          className="video-style"
                        >
                          <source
                            src={state.resource.resourceUrlEn}
                            type="video/webm"
                          />
                          <track
                            src="captions_en.vtt"
                            kind="captions"
                            srcLang="en"
                            label="english_captions"
                          />
                        </video>
                      ) : state.resource.resourceUrlEn.includes("pdf") ? (
                        <a href={state.resource.resourceUrlEn}>
                          <div className="pdf-image-url" />
                          <p className="pdf-text">
                            {t(`ActivityDetails.download`)}
                          </p>
                        </a>
                      ) : state.resource.resourceUrlEn.includes("image") &&
                        !state.resource.resourceUrlEn.includes("pdf") ? (
                        <img
                          src={state.resource.resourceUrlEn}
                          alt="resourceImageUrl"
                          className="resource-image-url"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    t(`ResourceDetails.noEnglishPresentationMaterialUploaded`)
                  )}
                </div>
              </div>
            </Paper>
          </div>
        </>
      );
    }

    return (
      <Loader text={t("SurveyDetails.loading")} style={{ marginTop: 40 }} />
    );
  };

  return <div className="surveys-wrapper">{mainGui()}</div>;
};

export default ResourceDetails;
