import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Document, View, Page } from "@react-pdf/renderer";
import type {
  ApiResponseAutomaticAnalysisModel,
  ApiResponseCompanyCycle,
} from "../../../../utils/api/apiInterfaces";
import { CompanyResult } from "./company-result/CompanyResult";
import { CompanyStatistics } from "./company-result/CompanyStatistics";
import { IntroductionPage } from "./introduction-page/IntroductionPage";
import { AgeResult } from "./age-result/AgeResult";
import { BossResult } from "./boss-result/BossResult";
import { DepartmentResult } from "./department-result/DepartmentResult";
import { TitleResult } from "./title-result/TitleResult";
import { StrategyPlanKPI } from "./strategy-plan/StrategyPlanKPI";
import { GenderResult } from "./gender-result/GenderResult";
import { QuestionAverageResult } from "./company-questions/question-result-index/QuestionAverageResult";
import { GenderCircleResult } from "./gender-result/GenderCircleResult";
import { automaticAnalysisPageStyle } from "./styles/AutomaticAnalysisPageStyle";
import { AutomaticAnalysisHeader } from "./AutomaticAnalysisHeader";
import { AgeCircleResult } from "./age-result/AgeCircleResult";
import { CompanyPercentagesResult } from "./company-questions/company-level-result/CompanyPercentagesResult";
import { lowestQuestionIndexObjectReturn } from "./LowestQuestionIndex";
import { getLanguage } from "../../../../i18n";
import { ActionProgram } from "./strategy-plan/ActionProgram";
import { divideQuestionsToFitPage } from "./UseAutomaticAnalysisCalculationHelper";

interface Props {
  automaticAnalysisResult: ApiResponseAutomaticAnalysisModel | null;
  cycle: ApiResponseCompanyCycle;
  totalAverage: number | null;
}

export const AutomaticAnalysisPdf: FC<Props> = ({
  automaticAnalysisResult,
  cycle,
  totalAverage,
}) => {
  const { t } = useTranslation();
  if (
    !automaticAnalysisResult ||
    !automaticAnalysisResult?.companyResult ||
    !totalAverage
  ) {
    return null;
  }
  const validDepartmentResults = automaticAnalysisResult.departmentResults
    .filter((dr) => dr.totalAnswers >= 5)
    .map((dr, i) => ({ ...dr, key: i }));
  const validBossResults = automaticAnalysisResult.bossResults
    .filter((br) => br.totalAnswers >= 5)
    .map((br, i) => ({ ...br, key: i }));
  const validTitleResults = automaticAnalysisResult.titleResults
    .filter((tr) => tr.totalAnswers >= 5)
    .map((tr, i) => ({ ...tr, key: i }));
  const validAgeResults = automaticAnalysisResult.ageResults
    .filter((ar) => ar.totalAnswers >= 5 && ar.nameSv !== "1")
    .map((ar, i) => ({ ...ar, key: i }))
    .sort((a, b) => `${a.nameSv}`.localeCompare(b.nameSv))
    .slice();
  const validGenderResults = automaticAnalysisResult.genderResults
    .filter((gr) => gr.totalAnswers >= 5)
    .map((gr, i) => ({ ...gr, key: i }));

  const { physicalQuestions, stressQuestions, developmentQuestions } =
    automaticAnalysisResult.companyResult.automaticAnalyzeQuestions;

  const allQuestions = [
    ...physicalQuestions,
    ...stressQuestions,
    ...developmentQuestions,
  ].sort((a, b) => a.averageAnswerValue - b.averageAnswerValue);

  const lowestPhysicalQuestion = lowestQuestionIndexObjectReturn(
    physicalQuestions,
    getLanguage(),
    1
  );
  const lowestStressQuestion = lowestQuestionIndexObjectReturn(
    stressQuestions,
    getLanguage(),
    1
  );
  const lowestDevelopmentQuestion = lowestQuestionIndexObjectReturn(
    developmentQuestions,
    getLanguage(),
    1
  );

  const questionAverageDivided = divideQuestionsToFitPage(
    [...allQuestions],
    22
  );
  const companyLevelDivided = divideQuestionsToFitPage([...allQuestions], 20);

  return (
    <Document>
      <IntroductionPage
        nameEn={automaticAnalysisResult.companyResult.nameEn}
        nameSv={automaticAnalysisResult.companyResult.nameSv}
        cycleDate={cycle.startDate}
      />
      <CompanyResult
        automaticAnalysisResult={automaticAnalysisResult}
        cycleDate={cycle.startDate}
        totalAverage={totalAverage}
      />
      <CompanyStatistics
        automaticAnalysisResult={automaticAnalysisResult}
        cycleDate={cycle.startDate}
      />
      {validDepartmentResults.map((dr) => (
        <Page key={dr.key}>
          <View style={automaticAnalysisPageStyle.section}>
            <AutomaticAnalysisHeader
              title={t("DepartmentResult.title")}
              conditionalRendering={t("DepartmentResult.conditionalRendering")}
            />
            <DepartmentResult departmentResult={dr} />
          </View>
        </Page>
      ))}
      {validTitleResults.map((tr) => (
        <Page key={tr.key}>
          <View style={automaticAnalysisPageStyle.section}>
            <AutomaticAnalysisHeader
              title={t("TitleResult.title")}
              conditionalRendering={t("TitleResult.conditionalRendering")}
            />
            <TitleResult titleResult={tr} />
          </View>
        </Page>
      ))}
      {validBossResults.map((br) => (
        <Page key={br.key}>
          <View style={automaticAnalysisPageStyle.section}>
            <AutomaticAnalysisHeader
              title={t("BossResult.manager")}
              conditionalRendering={t("BossResult.conditionalRendering")}
            />
            <BossResult bossResult={br} />
          </View>
        </Page>
      ))}
      {validAgeResults.length > 0 && (
        <Page>
          <View style={automaticAnalysisPageStyle.section}>
            <AutomaticAnalysisHeader
              title={t("AgeResult.age")}
              conditionalRendering={t("AgeResult.conditionalRendering")}
            />
            {validAgeResults.map((ar) => (
              <AgeResult
                ageResult={ar}
                key={ar.key}
                allAgeResults={validAgeResults}
              />
            ))}
            <AgeCircleResult ageResults={automaticAnalysisResult.ageResults} />
          </View>
        </Page>
      )}
      {validGenderResults.length > 0 && (
        <Page>
          <View style={automaticAnalysisPageStyle.section}>
            <AutomaticAnalysisHeader
              title={t("GenderResult.gender")}
              conditionalRendering={t("GenderResult.conditionalRendering")}
            />
            {validGenderResults.map((gr) => (
              <GenderResult
                genderResult={gr}
                key={gr.key}
                allValidResults={validGenderResults}
              />
            ))}
            <GenderCircleResult
              genderResults={automaticAnalysisResult.genderResults}
            />
          </View>
        </Page>
      )}
      {allQuestions.length <= 23 ? (
        <QuestionAverageResult questions={allQuestions} />
      ) : (
        questionAverageDivided &&
        questionAverageDivided.map((array) => (
          <QuestionAverageResult key={Math.random()} questions={array} />
        ))
      )}
      {allQuestions.length <= 21 ? (
        <Page>
          <View style={automaticAnalysisPageStyle.section}>
            <AutomaticAnalysisHeader
              title={t("CompanyPercentagesResult.title")}
              conditionalRendering={t("CompanyPercentagesResult.description")}
            />
            <CompanyPercentagesResult questions={allQuestions} />
          </View>
        </Page>
      ) : (
        companyLevelDivided &&
        companyLevelDivided.map((array) => (
          <Page key={Math.random()}>
            <View style={automaticAnalysisPageStyle.section}>
              <AutomaticAnalysisHeader
                title={t("CompanyPercentagesResult.title")}
                conditionalRendering={t("CompanyPercentagesResult.description")}
              />
              <CompanyPercentagesResult questions={array} />
            </View>
          </Page>
        ))
      )}
      {lowestPhysicalQuestion[0]?.action &&
        lowestPhysicalQuestion[0].recommendation && (
          <ActionProgram
            sortedQuestion={lowestPhysicalQuestion[0]}
            questionNumber={1}
          />
        )}
      {lowestStressQuestion[0]?.action &&
        lowestStressQuestion[0].recommendation && (
          <ActionProgram
            sortedQuestion={lowestStressQuestion[0]}
            questionNumber={2}
          />
        )}
      {lowestDevelopmentQuestion[0]?.action &&
        lowestDevelopmentQuestion[0].recommendation && (
          <ActionProgram
            sortedQuestion={lowestDevelopmentQuestion[0]}
            questionNumber={3}
          />
        )}
      <StrategyPlanKPI automaticAnalysisResult={automaticAnalysisResult} />
    </Document>
  );
};
