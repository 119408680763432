import { Rect, Svg, View, Text, StyleSheet } from "@react-pdf/renderer";
import type { VFC } from "react";

const styles = StyleSheet.create({
  text: {
    fontSize: "7px",
  },
});

interface Props {
  developmentAverage: number;
  physicalAverage: number;
  stressAverage: number;
}

export const AutomaticAnalysisBarChart: VFC<Props> = ({
  developmentAverage,
  stressAverage,
  physicalAverage,
}) => (
  <View>
    <Svg viewBox="0 0 0 0">
      <Rect
        x="0"
        y="0"
        width="390"
        height="12"
        fill="#38c976"
        fillOpacity="0.2"
      />
      <Rect
        x="0"
        y="0"
        width={`${developmentAverage * 39}`}
        height="12"
        fill="#41aa88"
      />
      <Text
        x={`${developmentAverage * 39 - 15}`}
        y="8"
        style={styles.text}
        fill="white"
      >
        {developmentAverage.toFixed(1)}
      </Text>
      <Rect
        x="0"
        y="20"
        width="390"
        height="12"
        fill="#38c976"
        fillOpacity="0.2"
      />
      <Rect
        x="0"
        y="20"
        width={`${physicalAverage * 39}`}
        height="12"
        fill="#41aa88"
      />
      <Text
        x={`${physicalAverage * 39 - 15}`}
        y="28"
        style={styles.text}
        fill="white"
      >
        {physicalAverage.toFixed(1)}
      </Text>
      <Rect
        x="0"
        y="40"
        width="390"
        height="12"
        fill="#38c976"
        fillOpacity="0.2"
      />
      <Rect
        x="0"
        y="40"
        width={`${stressAverage * 39}`}
        height="12"
        fill="#41aa88"
      />
      <Text
        x={`${stressAverage * 39 - 15}`}
        y="48"
        style={styles.text}
        fill="white"
      >
        {stressAverage.toFixed(1)}
      </Text>
    </Svg>
  </View>
);
