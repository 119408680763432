import React, { VFC } from "react";
import { useTranslation } from "react-i18next";
import "./Employees.css";
import {
  Button,
  CircularProgress,
  FormControl,
  Modal,
  Paper,
  TextField,
} from "@material-ui/core";
import { useParams } from "react-router";
import { CloudUpload } from "@material-ui/icons";
import api from "../../../utils/api/v1";
import Spacer from "../../shared/Spacer";
import Alert from "../../shared/Alert";
import InviteManyEmployeeModal from "./InviteManyEmployeeModal";
import { emailValidation } from "../../../utils/validation/emailValidation";
import { MissingRequiredParamError } from "../../errorHandling/MissingRequiredParamError";

interface State {
  error: string | null;
  loading: boolean;
}

interface Props {
  open: boolean;
  onClose: (reloadEmployees: boolean) => Promise<void>;
}

const InviteEmployeeModal: VFC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { companyId } = useParams();
  const [form, setForm] = React.useState({
    email: "",
    error: null,
  });

  const [state, setState] = React.useState<State>({
    error: "",
    loading: false,
  });
  const [inviteManyModal, setInviteManyModal] = React.useState(false);

  if (!companyId) {
    return <MissingRequiredParamError missingParam="companyId" />;
  }

  const handleClose = (reloadEmployees: boolean) =>
    onClose && onClose(reloadEmployees);

  const showInviteManyModal = () => {
    setInviteManyModal(true);
  };

  const closeInviteManyModal = () => {
    setInviteManyModal(false);
  };

  const handleChange = ({
    target: { name, value },
  }: {
    target: { name: string; value: string };
  }) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSave = () => {
    if (!form.email) {
      setState({
        loading: false,
        error: t("InviteEmployeeModal.emailRequired"),
      });
      return;
    }
    if (!emailValidation(form.email)) {
      setState({
        loading: false,
        error: `${t("InviteEmployeeModal.invalidEmail")} ${form.email}`,
      });
      return;
    }
    setState({ ...state, loading: true });
    api
      .inviteEmployees({ users: [form] }, companyId)
      .then((result) => {
        if (result.data[0]?.error != null) {
          setState({ loading: false, error: result.data[0].error });
        } else {
          handleClose(true);
        }
      })
      .catch((err) => {
        console.error(err);
        const message = t("InviteEmployeeModal.unknownError");
        setState({ loading: false, error: message });
      });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="employee-modal-title"
      aria-describedby="employee-modal-description"
    >
      <Paper className="employee-modal-wrapper">
        <h2 id="employee-modal-title">{t("InviteEmployeeModal.title")}</h2>

        <Button onClick={showInviteManyModal} className="upload-employees">
          <CloudUpload />
        </Button>
        <InviteManyEmployeeModal
          open={inviteManyModal}
          onClose={closeInviteManyModal}
        />

        <FormControl className="employee-modal-form">
          <TextField
            label={t("InviteEmployeeModal.email")}
            inputMode="email"
            value={form.email}
            name="email"
            autoComplete="off"
            onChange={handleChange}
            data-testid="newEmployeeEmailInput"
          />
        </FormControl>
        {state.loading ? <CircularProgress style={{ marginTop: 20 }} /> : null}
        {state.error ? (
          <Alert style={{ marginTop: 20 }}>{state.error}</Alert>
        ) : null}
        <div className="buttons">
          <Spacer />
          <Button
            disabled={state.loading}
            variant="outlined"
            className="cancel"
            onClick={() => {
              handleClose(false);
            }}
          >
            {t("InviteEmployeeModal.cancel")}
          </Button>
          <Button
            disabled={state.loading}
            color="primary"
            variant="contained"
            onClick={handleSave}
            data-testid="newEmployeeSaveButton"
          >
            {t("InviteEmployeeModal.save")}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

export default InviteEmployeeModal;
