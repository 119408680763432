import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { Delete, Save } from "@material-ui/icons";
import { CloudinaryContext } from "cloudinary-react";
import { openUploadWidget } from "../../utils/cloudinary/cloudinaryService";
import { createGuid } from "../../utils/helpers/createGuid";
import api from "../../utils/api/v1";
import Loader from "../shared/loader/Loader";
import Spacer from "../shared/Spacer";
import { languages } from "../../i18n";
import { BackButton } from "../../UI-Components/Buttons/Buttons";

export const MicroCourseCreateEdit = ({ create }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { microCourseId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [state, setState] = useState({
    loading: false,
    error: false,
    microCourse: null,
  });
  const [form, setForm] = useState({
    type: "",
    complexityLevel: null,
    contentType: null,
    nameEn: "",
    nameSv: "",
    shortDescriptionEn: "",
    shortDescriptionSv: "",
    descriptionEn: "",
    descriptionSv: "",
  });
  const [attributesForUpdate, setAttributesForUpdate] = useState({
    textDataId: null,
    imagePublicId: null,
    imageUrl: null,
  });

  const [requiredFields, setRequiredFields] = useState({
    type: "",
    nameEn: "",
    nameSv: "",
    shortDescriptionEn: "",
    shortDescriptionSv: "",
    descriptionEn: "",
    descriptionSv: "",
  });

  const [showErrorAll, setShowErrorAll] = useState(false);
  const [showError, setShowError] = useState({
    type: false,
    nameEn: false,
    nameSv: false,
    shortDescriptionEn: false,
    shortDescriptionSv: false,
    descriptionEn: false,
    descriptionSv: false,
  });

  const handleOpenDeleteModalImage = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const beginCloudinaryImageUpload = async () => {
    const guidForPublicId = createGuid();
    const uploadOptions = {
      cloudName: process.env.REACT_APP_CLOUD_NAME,
      uploadPreset: process.env.REACT_APP_CLOUD_UPLOAD_PRESET,
      publicId: `microCourses/${microCourseId}/${
        attributesForUpdate.imagePublicId !== null
          ? attributesForUpdate.imagePublicId
          : guidForPublicId
      }`,
      maxFiles: 1,
      preBatch: (cb, data) => {
        if (
          !data.files[0].name.includes("jpg") &&
          !data.files[0].name.includes("jpeg") &&
          !data.files[0].name.includes("png")
        ) {
          console.error("Only images are allowed!");
          cb({ cancel: true });
        } else {
          cb();
        }
      },
    };

    openUploadWidget(uploadOptions, async (error, res) => {
      if (!error) {
        console.log(res);
      }

      if (res.event === "success") {
        setState({ ...state, loading: true, error: false });
        const data = {
          assetPublicId:
            attributesForUpdate.imagePublicId !== null
              ? attributesForUpdate.imagePublicId
              : guidForPublicId,
          resourceOrActivityUrl: res.info.secure_url,
        };

        try {
          await api.uploadMicroCourseImageAsset(data, microCourseId);
          setAttributesForUpdate({
            ...attributesForUpdate,
            imagePublicId: data.assetPublicId,
            imageUrl: res.info.secure_url,
          });
          setState({ ...state, loading: false, error: false });
        } catch (err) {
          console.error(error);
          setState({ ...state, loading: false, error: true });
        }
      } else {
        console.error(error);
      }
    });
  };

  const getMicroCourse = async () => {
    setState({ ...state, loading: true, error: false });
    try {
      const {
        data: { microCourse },
      } = await api.getMicroCourse(microCourseId);
      setForm({
        type: microCourse.type,
        complexityLevel: microCourse.complexityLevel,
        contentType: microCourse.contentType,
        nameEn: microCourse.textData.nameEn,
        nameSv: microCourse.textData.nameSv,
        shortDescriptionEn: microCourse.textData.shortDescriptionEn,
        shortDescriptionSv: microCourse.textData.shortDescriptionSv,
        descriptionEn: microCourse.textData.descriptionEn,
        descriptionSv: microCourse.textData.descriptionSv,
      });
      setRequiredFields({
        type: microCourse.type,
        nameEn: microCourse.textData.nameEn,
        nameSv: microCourse.textData.nameSv,
        shortDescriptionEn: microCourse.textData.shortDescriptionEn,
        shortDescriptionSv: microCourse.textData.shortDescriptionSv,
        descriptionEn: microCourse.textData.descriptionEn,
        descriptionSv: microCourse.textData.descriptionSv,
      });
      setAttributesForUpdate({
        textDataId: microCourse.textDataId,
        imagePublicId: microCourse.imagePublicId,
        imageUrl: microCourse.imageUrl,
      });
      setState({
        ...state,
        microCourse,
        loading: false,
        error: false,
      });
    } catch (err) {
      console.error(err);
      setState({ ...state, error: true, loading: false });
    }
  };

  useEffect(() => {
    if (!create) {
      (async () => {
        await getMicroCourse();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = async () => {
    if (Object.values(requiredFields).some((value) => value === "")) {
      setShowErrorAll(true);
      return;
    }
    setState({ ...state, loading: true, error: false });
    if (create) {
      const formData = new FormData();
      const dateForUpdate = {
        type: form.type,
        textData: {
          nameEn: form.nameEn,
          nameSv: form.nameSv,
          shortDescriptionEn: form.shortDescriptionEn,
          shortDescriptionSv: form.shortDescriptionSv,
          descriptionEn: form.descriptionEn,
          descriptionSv: form.descriptionSv,
        },
      };
      formData.append("form", JSON.stringify(dateForUpdate));

      try {
        const { data: newMicroCourseId } = await api.createMicroCourse(
          formData,
          {
            "content-type": "multipart/form-data",
          }
        );
        setState({ ...state, loading: false, error: false });
        navigate(`/micro-courses/${newMicroCourseId}`);
      } catch (err) {
        console.error(err);
        setState({ ...state, loading: false, error: true });
      }
    } else {
      const formData = new FormData();
      const dateForUpdate = {
        type: form.type,
        complexityLevel: form.complexityLevel,
        contentType: form.contentType,
        textData: {
          nameEn: form.nameEn,
          nameSv: form.nameSv,
          shortDescriptionEn: form.shortDescriptionEn,
          shortDescriptionSv: form.shortDescriptionSv,
          descriptionEn: form.descriptionEn,
          descriptionSv: form.descriptionSv,
        },
      };
      const formDataForEdit = Object.assign(dateForUpdate, attributesForUpdate);
      formData.append("form", JSON.stringify(formDataForEdit));

      try {
        await api.updateMicroCourse(microCourseId, formData, {
          "content-type": "multipart/form-data",
        });
        setState({ ...state, loading: false, error: false });
        navigate(`/micro-courses/${microCourseId}`);
      } catch (err) {
        console.error(err);
        setState({ ...state, loading: false, error: true });
      }
    }
  };

  const handleSelectChange = ({ target: { name, value } }) => {
    setShowError({ ...showError, [name]: true });
    setForm({
      ...form,
      [name]: value,
    });
    setRequiredFields({ ...requiredFields, [name]: value });
  };

  const handleChange = ({ target: { name, value } }) => {
    setShowError({ ...showError, [name]: true });

    setForm({
      ...form,
      [name]: value,
    });
    setRequiredFields({ ...requiredFields, [name]: value });
  };

  const handleDeleteImageAsset = async () => {
    setState({ ...state, loading: true, error: false });
    try {
      await api.deleteMicroCourseImageAsset(
        microCourseId,
        attributesForUpdate.imagePublicId
      );
      setAttributesForUpdate({
        ...attributesForUpdate,
        imagePublicId: null,
        imageUrl: null,
      });
      setState({ ...state, loading: false });
      handleCloseModal();
    } catch (err) {
      console.error(err);
      setState({ ...state, loading: false, error: true });
    }
  };

  const checkRequiredInput = (input) =>
    (showError[input] || showErrorAll) &&
    (form[input] === "" || form[input] === undefined);

  const generateHelperText = (input, fieldName) =>
    checkRequiredInput(input)
      ? t("missingMandatoryInput", { field: fieldName })
      : "";

  const mainGui = () => {
    if (state.loading)
      return (
        <Loader
          text={t("MicroCourses.editCreate.loading")}
          style={{ marginTop: 40 }}
        />
      );
    if (!state.loading && !state.error) {
      return (
        <>
          <div className="section-toolbar">
            <BackButton />
            <Typography className="section-title" variant="h6">
              {create
                ? t("MicroCourses.editCreate.titleCreate")
                : t("MicroCourses.editCreate.titleEdit")}
            </Typography>
            <Spacer />
            <Button
              color="primary"
              variant="contained"
              startIcon={<Save />}
              onClick={handleSave}
            >
              {t("ButtonGeneral.save")}
            </Button>
          </div>
          <Divider />

          <div>
            <Paper className="update-create-micro-course-input-wrapper">
              <div>
                <div className="micro-courses-select-wrapper">
                  <div className="micro-courses-select-type-container">
                    <InputLabel id="label">
                      {t("MicroCourses.editCreate.type")}
                    </InputLabel>
                    <Select
                      labelId="label"
                      name="type"
                      id="select"
                      value={form.type}
                      onChange={handleSelectChange}
                      error={checkRequiredInput("type")}
                    >
                      <MenuItem value="Relaxation">
                        {t("ResourceEdit.relaxation")}
                      </MenuItem>
                      <MenuItem value="Physical Activity">
                        {t("ResourceEdit.physicalActivity")}
                      </MenuItem>
                      <MenuItem value="Nutrition">
                        {t("ResourceEdit.nutrition")}
                      </MenuItem>
                      <MenuItem value="Personal Development">
                        {t("ResourceEdit.personalDevelopment")}
                      </MenuItem>
                      <MenuItem value="Recovery">
                        {t("ResourceEdit.recovery")}
                      </MenuItem>
                    </Select>
                    <FormHelperText style={{ color: "red" }}>
                      {generateHelperText(
                        "Type",
                        t("MicroCourses.editCreate.type")
                      )}
                    </FormHelperText>
                  </div>
                  <div>
                    <InputLabel id="labelComplexityLevel">
                      {t("MicroCourses.editCreate.complexityLevel")}
                    </InputLabel>
                    <Select
                      labelId="labelComplexityLevel"
                      name="complexityLevel"
                      id="select"
                      value={form.complexityLevel}
                      onChange={handleSelectChange}
                    >
                      <MenuItem value={null}>
                        {t("MicroCourses.editCreate.noComplexity")}
                      </MenuItem>
                      <MenuItem value={1}>{1}</MenuItem>
                      <MenuItem value={2}>{2}</MenuItem>
                      <MenuItem value={3}>{3}</MenuItem>
                    </Select>
                  </div>
                  <div style={{ marginLeft: "1rem" }}>
                    <InputLabel id="labelContentType">
                      {t("MicroCourses.editCreate.contentType")}
                    </InputLabel>
                    <Select
                      labelId="labelContentType"
                      name="contentType"
                      id="select-content-type"
                      value={form.contentType}
                      onChange={handleSelectChange}
                    >
                      <MenuItem value={null}>
                        {t("MicroCourses.editCreate.noContentType")}
                      </MenuItem>
                      <MenuItem value="Watch">Watch</MenuItem>
                      <MenuItem value="Read">Read</MenuItem>
                      <MenuItem value="Listen">Listen</MenuItem>
                    </Select>
                  </div>
                </div>
                {languages.map((lang) => (
                  <div key={lang}>
                    <div className="micro-course-form-text">
                      <TextField
                        name={`name${
                          lang.charAt(0).toUpperCase() + lang.slice(1)
                        }`}
                        label={`${t(`ResourceEdit.name`)} ${t(
                          `Language.languages.${lang}`
                        )}`}
                        variant="outlined"
                        fullWidth
                        multiline
                        required
                        value={
                          form[
                            `name${
                              lang.charAt(0).toUpperCase() + lang.slice(1)
                            }`
                          ]
                        }
                        onChange={handleChange}
                        error={checkRequiredInput(
                          `name${lang.charAt(0).toUpperCase() + lang.slice(1)}`
                        )}
                        helperText={generateHelperText(
                          `name${lang.charAt(0).toUpperCase() + lang.slice(1)}`,
                          t("ActivityEdit.name")
                        )}
                      />
                    </div>
                    <div className="micro-course-form-text">
                      <TextField
                        name={`shortDescription${
                          lang.charAt(0).toUpperCase() + lang.slice(1)
                        }`}
                        label={`${t(`ResourceEdit.shortDesc`)} ${t(
                          `Language.languages.${lang}`
                        )}`}
                        variant="outlined"
                        fullWidth
                        multiline
                        required
                        minRows={2}
                        maxRows={15}
                        value={
                          form[
                            `shortDescription${
                              lang.charAt(0).toUpperCase() + lang.slice(1)
                            }`
                          ]
                        }
                        onChange={handleChange}
                        error={checkRequiredInput(
                          `shortDescription${
                            lang.charAt(0).toUpperCase() + lang.slice(1)
                          }`
                        )}
                        helperText={generateHelperText(
                          `shortDescription${
                            lang.charAt(0).toUpperCase() + lang.slice(1)
                          }`,
                          t("ActivityEdit.shortDesc")
                        )}
                      />
                    </div>
                    <div className="micro-course-form-text">
                      <TextField
                        name={`description${
                          lang.charAt(0).toUpperCase() + lang.slice(1)
                        }`}
                        label={`${t(`ResourceEdit.desc`)} ${t(
                          `Language.languages.${lang}`
                        )}`}
                        variant="outlined"
                        fullWidth
                        multiline
                        required
                        minRows={4}
                        maxRows={25}
                        value={
                          form[
                            `description${
                              lang.charAt(0).toUpperCase() + lang.slice(1)
                            }`
                          ]
                        }
                        onChange={handleChange}
                        error={checkRequiredInput(
                          `description${
                            lang.charAt(0).toUpperCase() + lang.slice(1)
                          }`
                        )}
                        helperText={generateHelperText(
                          `description${
                            lang.charAt(0).toUpperCase() + lang.slice(1)
                          }`,
                          t("ActivityEdit.desc")
                        )}
                      />
                    </div>
                  </div>
                ))}
              </div>
              {!create && (
                <div className="input-wrapper">
                  <div className="file-upload-wrapper">
                    <CloudinaryContext
                      cloudName={process.env.REACT_APP_CLOUD_NAME}
                    >
                      <p className="upload-paragraph-header">
                        {t(`ResourceEdit.presentationImage`)}
                      </p>
                      <Button
                        color="primary"
                        className="asset-upload-button-style"
                        variant="contained"
                        onClick={beginCloudinaryImageUpload}
                        disabled={attributesForUpdate.imagePublicId !== null}
                      >
                        {t(`MicroCourses.upload`)}
                      </Button>
                    </CloudinaryContext>
                    {t(`MicroCourses.uploaded`)}
                    {attributesForUpdate.imageUrl && (
                      <div className="micro-course-create-edit-upload-wrapper ">
                        <img
                          src={attributesForUpdate.imageUrl}
                          height="150px"
                          width="200px"
                          alt="imageUrl"
                          className="micro-course-edit-create-uploaded-image"
                        />
                      </div>
                    )}
                    <div className="button-text-wrapper">
                      <Dialog
                        open={isModalOpen}
                        onClose={handleCloseModal}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">
                          <Typography className="reinforced-delete-alert-style">
                            {t("ResourceEdit.deleteAlertReinforced")}
                          </Typography>
                        </DialogTitle>

                        <DialogActions>
                          <Button
                            onClick={handleDeleteImageAsset}
                            color="primary"
                          >
                            {t("ButtonGeneral.acceptDeleteAlert")}
                          </Button>
                          <Button onClick={handleCloseModal} color="primary">
                            {t("ButtonGeneral.denyDeleteAlert")}
                          </Button>
                        </DialogActions>
                      </Dialog>
                      <Button
                        color="secondary"
                        variant="contained"
                        name="deleteImage"
                        startIcon={<Delete />}
                        disabled={!attributesForUpdate.imagePublicId}
                        className="micro-courses-create-edit-delete-button-style"
                        onClick={handleOpenDeleteModalImage}
                      >
                        {t("ButtonGeneral.delete")}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </Paper>
          </div>
        </>
      );
    }
    return (
      <Loader
        text={t("MicroCourses.editCreate.loading")}
        style={{ marginTop: 40 }}
      />
    );
  };

  return <div className="companies-wrapper">{mainGui()}</div>;
};

MicroCourseCreateEdit.propTypes = {
  create: PropTypes.bool,
};

MicroCourseCreateEdit.defaultProps = {
  create: false,
};
