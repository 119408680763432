import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Tabs, Divider, Tab, Typography, Box } from "@material-ui/core";
import { useParams } from "react-router";
import PropTypes from "prop-types";
import Spacer from "../../../shared/Spacer";
import api from "../../../../utils/api/v1";
import DefaultCompanyMail from "./DefaultCompanyMailSettings/DefaultCompanyMail";
import MotivationCompanyMail from "./MotivationCompanyMailSettings/MotivationCompanyMail";
import { BackButton } from "../../../../UI-Components/Buttons/Buttons";

const CompanyMailModal = () => {
  const { t } = useTranslation();
  const { companyId } = useParams();
  const [tab, setTab] = useState(0);
  const [company, setCompany] = useState(null);
  const [state, setState] = useState({
    loading: false,
    error: false,
  });

  const getCompany = () => {
    setState({ ...state, loading: true });
    api
      .getCompany(companyId)
      .then(({ data }) => {
        setCompany(data);
        setState({ ...state, loading: false });
      })
      .catch(() => {
        setState({ ...state, error: true, loading: false });
      });
  };

  useEffect(() => {
    getCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      <div className="section-toolbar">
        <BackButton />
        <Typography className="section-title" variant="h6">
          {company ? company.name : "Company Email Settings"}
        </Typography>
        <Spacer />
      </div>
      <Tabs className="company-tabs" value={tab} onChange={handleChange}>
        <LinkTab label={t("Reminder.Mail")} />
        <LinkTab
          label={t("Reminder.Inspiration")}
          data-testid="reminderCompaniesTab"
        />
      </Tabs>
      <Divider />
      <TabPanel value={tab} index={0}>
        <DefaultCompanyMail />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <MotivationCompanyMail />
      </TabPanel>
    </>
  );
};

export default CompanyMailModal;

const LinkTab = (props) => (
  <Tab
    component="a"
    onClick={(event) => event.preventDefault()}
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  />
);

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`nav-tabpanel-${index}`}
    aria-labelledby={`nav-tab-${index}`}
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...other}
  >
    {value === index && <Box p={0}>{children}</Box>}
  </div>
);

TabPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};
