import React, { useEffect } from "react";
import { Menu, MenuItem, Button, useMediaQuery } from "@material-ui/core";
import { Language } from "@material-ui/icons";
import { setLanguage, getLanguage, i18nLoading } from "../../i18n";

const LanguagePicker = () => {
  const languages = [
    { value: "en", text: "English" },
    { value: "sv", text: "Svenska" },
  ];
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentLanguage, setCurrentLanguage] = React.useState("");
  const mobile = useMediaQuery("(max-width: 600px)");

  useEffect(() => {
    if (currentLanguage === "") {
      i18nLoading.then(() => {
        setCurrentLanguage(getLanguage() || "sv");
      });
    }
  }, [currentLanguage]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectLanguage = (language) => {
    setCurrentLanguage(language);
    setLanguage(language);
    handleClose();
  };

  return (
    <div>
      <Button
        className="language-picker-button"
        aria-controls="simple-menu"
        aria-haspopup="true"
        variant="text"
        startIcon={<Language />}
        onClick={handleClick}
      >
        {!mobile ? currentLanguage : ""}
      </Button>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {languages.map(({ value, text }) => (
          <MenuItem key={value} onClick={() => selectLanguage(value)}>
            {text}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default LanguagePicker;
