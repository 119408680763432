import { ChangeEvent, useEffect, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import "./TermTeacherDetails.css";
import {
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Input,
  Select,
  Button,
  Grid,
  Avatar,
  Tooltip,
} from "@material-ui/core";
import { useNavigate, useParams } from "react-router";
import { Add } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../../utils/api/v1";
import Loader from "../../shared/loader/Loader";
import Spacer from "../../shared/Spacer";
import { BackButton } from "../../../UI-Components/Buttons/Buttons";
import type { ApiResponseTeacher } from "../../../utils/api/apiInterfaces";
import { MissingRequiredParamError } from "../../errorHandling/MissingRequiredParamError";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(2),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
    display: "flex",
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
}));

interface State {
  loading: boolean;
  error: boolean;
  errorMessage: string;
}

const TermTeacherCreate: VFC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { termId } = useParams();
  const [teachers, setTeachers] = useState<Array<ApiResponseTeacher>>([]);
  const [teachersId, setTeacherId] = useState("");
  const [state, setState] = useState<State>({
    loading: false,
    error: false,
    errorMessage: "",
  });
  const classes = useStyles();

  useEffect(() => {
    (async () => {
      await getAllTeachers();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!termId) {
    return <MissingRequiredParamError missingParam="termId" />;
  }

  const getAllTeachers = async () => {
    setState({ ...state, error: false, loading: true });
    try {
      await api.getTeachers().then(({ data }) => {
        setTeachers(data);
        setState({ ...state, loading: false });
      });
    } catch (err) {
      console.error(err);
      setState({ ...state, error: true, loading: false });
    }
  };

  const createTermTeacher = async () => {
    const idTerm = parseInt(termId, 10);
    setState({ ...state, error: false, loading: true });
    const termTeacher = {
      termId: idTerm,
      teacherId: teachersId,
    };
    try {
      await api.createTermTeacher(termTeacher);
      setState({ ...state, loading: false });
      navigate(`/terms/${termId}`);
    } catch (err) {
      console.error(err);
      setState({ ...state, error: true, loading: false });
    }
  };

  const handleChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    setTeacherId(event.target.value as string);
  };

  const mainGui = () => {
    if (state.loading)
      return (
        <Loader text={t("TermDetails.loading")} style={{ marginTop: 40 }} />
      );
    if (!state.loading) {
      return (
        <>
          <div className="section-toolbar">
            <BackButton />
            <Typography className="section-title" variant="h6">
              {t("TermTeacherDetails.AddTeacher")} {t("TermDetails.term")}{" "}
              {termId}
            </Typography>
            <Spacer />
          </div>
          <Spacer />
          <div className="teachersheading">
            <Typography className="section-title" variant="h6">
              {t("TermTeacherDetails.AllTeachers")}
            </Typography>
          </div>
          <div className={classes.root}>
            <Spacer />
            {teachers.map((teacher) => (
              <Grid
                key={teacher.id}
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                item
                xs={2}
                sm={4}
              >
                <Tooltip title={teacher.name ?? ""}>
                  <Avatar
                    className={classes.large}
                    alt={teacher.name ?? ""}
                    src={teacher.imageUrl ?? ""}
                  />
                </Tooltip>
              </Grid>
            ))}
          </div>
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            item
            xs={12}
            sm={12}
          >
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-mutiple-name-label">
                {t("TermTeacherDetails.TeacherNameInput")}
              </InputLabel>
              <Select
                style={{
                  minWidth: 360,
                  padding: 3,
                  background: "white",
                  border: "1px solid green",
                }}
                labelId="demo-mutiple-name-label"
                id="teacher.id"
                value={teachersId}
                onChange={handleChange}
                input={<Input />}
              >
                {teachers.map((teacher) => (
                  <MenuItem key={teacher.id} value={teacher.id}>
                    {teacher.name}
                  </MenuItem>
                ))}
              </Select>
              <Button
                startIcon={<Add />}
                onClick={createTermTeacher}
                color="primary"
                variant="contained"
              >
                {t("TermTeacherDetails.RegisterTeacherButton")}
              </Button>
            </FormControl>
          </Grid>
        </>
      );
    }
    return <Loader text={t("TermDetails.loading")} style={{ marginTop: 40 }} />;
  };

  return <div className="companies-wrapper">{mainGui()}</div>;
};

export default TermTeacherCreate;
