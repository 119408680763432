import type { VFC } from "react";
import { useTranslation } from "react-i18next";
import "./StatisticsChart.css";
import { Grid, Paper } from "@material-ui/core";
import BarChart from "./BarChart";

interface Props {
  totalAverage?: number;
  stressAverage?: number;
  physicalAverage?: number;
  developmentAverage?: number;
  finishedSurveyCount?: number;
}

const StatisticsChart: VFC<Props> = ({
  totalAverage = 0,
  stressAverage = 0,
  physicalAverage = 0,
  developmentAverage = 0,
  finishedSurveyCount = 0,
}) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      className="cards-grid-container"
    >
      <Paper className="analysis-details" square>
        {finishedSurveyCount >= 5 ? (
          <div className="chart-container">
            <p className="analysis-details-title">
              {t("CycleResults.wellbeingIndex")}: {totalAverage}
            </p>
            <BarChart
              wellbeingText={t("CycleResults.wellbeingIndex")}
              stressCategory={t("CycleResults.stressManagement")}
              physicsCategory={t("CycleResults.physicalActivity")}
              developmentCategory={t("CycleResults.personalDevelopment")}
              stress={stressAverage}
              physics={physicalAverage}
              development={developmentAverage}
            />
          </div>
        ) : (
          <div className="chart-container">
            <div className="to-few-results">
              <p>{t("CycleResults.filteredStatisticsChart.toFewResults")}</p>
            </div>
          </div>
        )}
        <Paper className="analysis-text-container" square>
          <h3>{t("CycleResults.analysisRecommendations")}</h3>
          <p>{t("CycleResults.analysisText")}</p>
        </Paper>
      </Paper>
    </Grid>
  );
};

export default StatisticsChart;
