import React, { useState } from "react";
import {
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import {
  Lock,
  Business,
  QuestionAnswer,
  ExitToApp,
  ViewModule,
  CalendarToday,
  PresentToAll,
  ContactSupport,
} from "@material-ui/icons";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import SchoolIcon from "@material-ui/icons/School";
import { useTranslation } from "react-i18next";
import "./Navigation.css";
import { useNavigate } from "react-router";
import useGlobal from "../../store/store";
import TopBar from "../topbar/TopBar";
import api from "../../utils/api/v1";

const Navigation = (props) => {
  // eslint-disable-next-line react/prop-types
  const { window, children } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [, globalActions] = useGlobal();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const logOut = async () => {
    try {
      await api.logout();
      globalActions.auth.signOut();
      navigate("/login");
    } catch (err) {
      console.error(err);
      navigate("/login");
    }
  };

  const content = (
    <>
      <Typography className="navigation-welcometext">
        {t("Navigation.welcometext")}
      </Typography>
      <List className="navigation-list">
        <div>
          <ListItem
            button
            key="companies"
            onClick={() => navigate("/companies")}
            className="navigation-link-hover"
          >
            <ListItemIcon>
              <Business className="navigation-icon-style" />
            </ListItemIcon>
            <ListItemText
              primary={t("Navigation.company")}
              className="navigation-text"
            />
          </ListItem>

          <ListItem
            button
            key="surveys"
            onClick={() => navigate("/surveys")}
            className="navigation-link-hover"
          >
            <ListItemIcon>
              <QuestionAnswer className="navigation-icon-style" />
            </ListItemIcon>
            <ListItemText
              primary={t("Navigation.surveys")}
              className="navigation-text"
            />
          </ListItem>

          <ListItem
            button
            key="modules"
            onClick={() => navigate("/modules")}
            className="navigation-link-hover"
          >
            <ListItemIcon>
              <ViewModule className="navigation-icon-style" />
            </ListItemIcon>
            <ListItemText
              primary={t("Navigation.modules")}
              className="navigation-text"
            />
          </ListItem>
          <ListItem
            button
            key="terms"
            onClick={() => navigate("/terms")}
            className="navigation-link-hover"
          >
            <ListItemIcon>
              <CalendarToday className="navigation-icon-style" />
            </ListItemIcon>
            <ListItemText
              primary={t("Navigation.terms")}
              className="navigation-text"
            />
          </ListItem>
          <ListItem
            button
            key="resources"
            onClick={() => navigate("/resources")}
            className="navigation-link-hover"
          >
            <ListItemIcon>
              <PresentToAll className="navigation-icon-style" />
            </ListItemIcon>
            <ListItemText
              primary={t("Navigation.library")}
              className="navigation-text"
            />
          </ListItem>
          <ListItem
            button
            key="microCourses"
            onClick={() => navigate("/micro-courses")}
            className="navigation-link-hover"
          >
            <ListItemIcon>
              <AutoAwesomeMotionIcon className="navigation-icon-style" />
            </ListItemIcon>
            <ListItemText
              primary={t("Navigation.microCourses")}
              className="navigation-text"
            />
          </ListItem>

          <ListItem
            button
            key="counseling-resources"
            onClick={() => navigate("/counseling-resources")}
            className="navigation-link-hover"
          >
            <ListItemIcon>
              <ContactSupport className="navigation-icon-style" />
            </ListItemIcon>
            <ListItemText
              primary={t("Navigation.counseling")}
              className="navigation-text"
            />
          </ListItem>

          <ListItem
            button
            key="teachers"
            onClick={() => navigate("/teachers")}
            className="navigation-link-hover"
          >
            <ListItemIcon>
              <SchoolIcon className="navigation-icon-style" />
            </ListItemIcon>
            <ListItemText
              primary={t("Navigation.teachers")}
              className="navigation-text"
            />
          </ListItem>
          <ListItem
            button
            key="reminders"
            onClick={() => navigate("/reminders")}
            className="navigation-link-hover"
          >
            <ListItemIcon>
              <AddAlertIcon className="navigation-icon-style" />
            </ListItemIcon>
            <ListItemText
              primary={t("Navigation.emailReminders")}
              className="navigation-text"
            />
          </ListItem>
          <ListItem
            disabled
            button
            key="admins"
            onClick={() => navigate("/admins")}
            className="navigation-link-hover"
          >
            <ListItemIcon>
              <Lock className="navigation-icon-style" />
            </ListItemIcon>
            <ListItemText primary="Admins" className="navigation-text" />
          </ListItem>
        </div>

        <div>
          <List>
            <ListItem
              button
              key="logout"
              onClick={logOut}
              className="navigation-logout-hover"
            >
              <ListItemIcon>
                <ExitToApp className="navigation-icon-style" />
              </ListItemIcon>
              <ListItemText
                primary="Log out"
                className="navigation-logout-text"
              />
            </ListItem>
          </List>
        </div>
      </List>
    </>
  );

  const toggleOpen = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className="navigation-wrapper">
      <nav>
        <Hidden smUp implementation="css">
          <Drawer
            className="navigation-drawer"
            container={container}
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {content}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer className="navigation-drawer" variant="permanent" open>
            {content}
          </Drawer>
        </Hidden>
      </nav>
      <main className="main-content">
        <TopBar menuCallback={() => toggleOpen()} />
        {children}
      </main>
    </div>
  );
};

export default Navigation;
