import { useTranslation } from "react-i18next";
import { View, Text } from "@react-pdf/renderer";
import type { VFC } from "react";
import { automaticAnalysisNarrowPageStyle } from "./styles/AutomaticAnalysisNarrowPageStyle";

interface Props {
  title: string;
  conditionalRendering: string;
}

export const AutomaticAnalysisHeader: VFC<Props> = ({
  title,
  conditionalRendering,
}) => {
  const { t } = useTranslation();
  return (
    <View style={automaticAnalysisNarrowPageStyle.header}>
      <View style={automaticAnalysisNarrowPageStyle.horizontalLine} />
      <Text style={automaticAnalysisNarrowPageStyle.statistics}>
        {t("CompanyStatistics.statistics")}
      </Text>
      <Text style={automaticAnalysisNarrowPageStyle.title}>{title}</Text>
      <Text style={automaticAnalysisNarrowPageStyle.text}>
        {conditionalRendering}
      </Text>
    </View>
  );
};
