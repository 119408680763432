/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CloudinaryContext } from "cloudinary-react";
import "./Resources.css";
import {
  Typography,
  Divider,
  Button,
  Paper,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogActions,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from "@material-ui/core";
import { useNavigate, useParams } from "react-router";
import PropTypes from "prop-types";
import { Save, Delete } from "@material-ui/icons";
import Spacer from "../shared/Spacer";
import api from "../../utils/api/v1";
import Loader from "../shared/loader/Loader";
import { languages } from "../../i18n";
import { createGuid } from "../../utils/helpers/createGuid";
import { openUploadWidget } from "../../utils/cloudinary/cloudinaryService";
import { BackButton } from "../../UI-Components/Buttons/Buttons";

const ResourceEdit = ({ create }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { resourceId } = useParams();
  const [isNotNumber, setIsNotNumber] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [publicIdForDelete, setPublicIdForDelete] = useState("");
  const [state, setState] = useState({
    loading: false,
    error: "",
    resource: null,
  });
  const [form, setForm] = useState({
    Type: "",
    AvailableForAll: false,
    State: "",
    NameEn: "",
    NameSv: "",
    complexityLevel: null,
    contentType: null,
    timeRequired: 1,
    shortDescriptionEn: "",
    shortDescriptionSv: "",
    descriptionEn: "",
    descriptionSv: "",
  });
  const [attributesForUpdate, setAttributesForUpdate] = useState({
    TextDataId: "00000000-0000-0000-0000-000000000000",
    ImagePublicId: "00000000-0000-0000-0000-000000000000",
    ResourcePublicId: "00000000-0000-0000-0000-000000000000",
    ResourcePublicIdEn: "00000000-0000-0000-0000-000000000000",
    ImageUrl: "",
    ResourceUrl: "",
    ResourceUrlEn: "",
  });

  const [requiredFields, setRequiredFields] = useState({
    Type: "",
    NameEn: "",
    NameSv: "",
    shortDescriptionEn: "",
    shortDescriptionSv: "",
    descriptionEn: "",
    descriptionSv: "",
    timeRequired: 1,
  });

  const [showErrorAll, setShowErrorAll] = useState(false);
  const [showError, setShowError] = useState({
    Type: false,
    NameEn: false,
    NameSv: false,
    shortDescriptionEn: false,
    shortDescriptionSv: false,
    descriptionEn: false,
    descriptionSv: false,
    timeRequired: false,
  });

  const handleOpenDeleteModalResource = () => {
    setPublicIdForDelete(attributesForUpdate.ResourcePublicId);

    setIsModalOpen(true);
  };

  const handleOpenDeleteModalEnglishResource = () => {
    setPublicIdForDelete(attributesForUpdate.ResourcePublicIdEn);

    setIsModalOpen(true);
  };

  const handleOpenDeleteModalImage = () => {
    setPublicIdForDelete(attributesForUpdate.ImagePublicId);

    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const beginCloudinaryImageUpload = async () => {
    const guidForPublicId = createGuid();
    const uploadOptions = {
      cloudName: process.env.REACT_APP_CLOUD_NAME,
      uploadPreset: process.env.REACT_APP_CLOUD_UPLOAD_PRESET,
      publicId: `resources/${resourceId}/${
        attributesForUpdate.ImagePublicId !==
        "00000000-0000-0000-0000-000000000000"
          ? attributesForUpdate.ImagePublicId
          : guidForPublicId
      }`,
      maxFiles: 1,
      preBatch: (cb, data) => {
        if (
          data.files[0].name.includes("video") ||
          data.files[0].name.includes("pdf")
        ) {
          console.error("Only images are allowed!");
          cb({ cancel: true });
        } else {
          cb();
        }
      },
    };

    openUploadWidget(uploadOptions, async (error, res) => {
      if (!error) {
        console.log(res);
      }

      if (res.event === "success") {
        setState({ ...state, loading: true, error: false });
        const data = {
          assetPublicId:
            attributesForUpdate.ImagePublicId !==
            "00000000-0000-0000-0000-000000000000"
              ? attributesForUpdate.ImagePublicId
              : guidForPublicId,
          resourceOrActivityUrl: res.info.secure_url,
        };

        await api
          .uploadResourceImageAsset(data, resourceId)
          .then(() => {
            setAttributesForUpdate({
              ...attributesForUpdate,
              ImagePublicId: data.assetPublicId,
              ImageUrl: res.info.secure_url,
            });
            setState({ ...state, loading: false, error: false });
          })
          .catch((err) => {
            console.error(err);
            setState({ ...state, loading: false, error: true });
          });
      } else {
        console.error(error);
      }
    });
  };

  const beginCloudinaryImagePdfOrVideoUpload = async () => {
    const guidForPublicId = createGuid();
    const uploadOptions = {
      cloudName: process.env.REACT_APP_CLOUD_NAME,
      uploadPreset: process.env.REACT_APP_CLOUD_UPLOAD_PRESET,
      publicId: `resources/${resourceId}/${
        attributesForUpdate.ResourcePublicId !==
        "00000000-0000-0000-0000-000000000000"
          ? attributesForUpdate.ResourcePublicId
          : guidForPublicId
      }`,
      maxVideoFileSize: 500000000,
      maxFiles: 1,
      preBatch: (cb, data) => {
        if (
          !data.files[0].name.includes("pdf") &&
          !data.files[0].name.includes("jpg") &&
          !data.files[0].name.includes("png") &&
          !data.files[0].name.includes("jpeg") &&
          !data.files[0].name.includes("mp4")
        ) {
          console.error(
            "Please upload one of the following filetypes: jpg, jpeg, png, mp4, pdf"
          );
          cb({ cancel: true });
        } else {
          cb();
        }
      },
    };

    openUploadWidget(uploadOptions, async (error, res) => {
      if (!error) {
        console.log(res);
      }

      if (res.event === "success") {
        setState({ ...state, loading: true, error: false });
        const data = {
          assetPublicId:
            attributesForUpdate.ResourcePublicId !==
            "00000000-0000-0000-0000-000000000000"
              ? attributesForUpdate.ResourcePublicId
              : guidForPublicId,
          resourceOrActivityUrl: res.info.secure_url,
        };

        await api
          .uploadResourceImagePdfOrVideoAsset(data, resourceId)
          .then(() => {
            setAttributesForUpdate({
              ...attributesForUpdate,
              ResourcePublicId: data.assetPublicId,
              ResourceUrl: res.info.secure_url,
            });
            setState({ ...state, loading: false, error: false });
          })
          .catch((err) => {
            console.error(err);
            setState({ ...state, loading: false, error: true });
          });
      } else {
        console.error(error);
      }
    });
  };

  const beginEnglishCloudinaryImagePdfOrVideoUpload = async () => {
    const guidForPublicId = createGuid();
    const uploadOptions = {
      cloudName: process.env.REACT_APP_CLOUD_NAME,
      uploadPreset: process.env.REACT_APP_CLOUD_UPLOAD_PRESET,
      publicId: `resources/${resourceId}/${
        attributesForUpdate.ResourcePublicIdEn !==
        "00000000-0000-0000-0000-000000000000"
          ? attributesForUpdate.ResourcePublicIdEn
          : guidForPublicId
      }`,
      maxVideoFileSize: 500000000,
      maxFiles: 1,
      preBatch: (cb, data) => {
        if (
          !data.files[0].name.includes("pdf") &&
          !data.files[0].name.includes("jpg") &&
          !data.files[0].name.includes("png") &&
          !data.files[0].name.includes("jpeg") &&
          !data.files[0].name.includes("mp4")
        ) {
          console.error(
            "Please upload one of the following filetypes: jpg, jpeg, png, mp4, pdf"
          );
          cb({ cancel: true });
        } else {
          cb();
        }
      },
    };

    openUploadWidget(uploadOptions, async (error, res) => {
      if (res.event === "success") {
        setState({ ...state, loading: true, error: false });
        const data = {
          assetPublicId:
            attributesForUpdate.ResourcePublicIdEn !==
            "00000000-0000-0000-0000-000000000000"
              ? attributesForUpdate.ResourcePublicIdEn
              : guidForPublicId,
          resourceOrActivityUrl: res.info.secure_url,
        };

        await api
          .uploadEnglishResourceImagePdfOrVideoAsset(data, resourceId)
          .then(() => {
            setAttributesForUpdate({
              ...attributesForUpdate,
              ResourcePublicIdEn: data.assetPublicId,
              ResourceUrlEn: res.info.secure_url,
            });
            setState({ ...state, loading: false, error: false });
          })
          .catch((err) => {
            console.error(err);
            setState({ ...state, loading: false, error: true });
          });
      } else {
        console.error(error);
      }
    });
  };

  const getResource = () => {
    setState({ ...state, loading: true, error: false });
    api
      .getResource(resourceId)
      .then(({ data }) => {
        setForm({
          Type: data.type,
          AvailableForAll: data.availableForAll,
          State: data.state,
          NameEn: data.nameEn,
          NameSv: data.nameSv,
          complexityLevel: data.complexityLevel,
          contentType: data.contentType,
          timeRequired: data.timeRequired,
          shortDescriptionEn: data.shortDescriptionEn,
          shortDescriptionSv: data.shortDescriptionSv,
          descriptionEn: data.descriptionEn,
          descriptionSv: data.descriptionSv,
        });
        setRequiredFields({
          Type: data.type,
          NameEn: data.nameEn,
          NameSv: data.nameSv,
          timeRequired: data.timeRequired,
          shortDescriptionEn: data.shortDescriptionEn,
          shortDescriptionSv: data.shortDescriptionSv,
          descriptionEn: data.descriptionEn,
          descriptionSv: data.descriptionSv,
        });
        setAttributesForUpdate({
          TextDataId: data.textDataId,
          ImagePublicId: data.imagePublicId,
          ResourcePublicId: data.resourcePublicId,
          ResourcePublicIdEn: data.resourcePublicIdEn,
          ImageUrl: data.imageUrl,
          ResourceUrl: data.resourceUrl,
          ResourceUrlEn: data.resourceUrlEn,
        });
        setState({
          ...state,
          resource: data.data,
          loading: false,
          error: false,
        });
      })
      .catch((err) => {
        console.error(err);
        setState({ ...state, error: true, loading: false });
      });
  };

  useEffect(() => {
    if (!create) {
      getResource();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = () => {
    if (Object.values(requiredFields).some((value) => value === "")) {
      setShowErrorAll(true);
      return;
    }
    setState({ ...state, loading: true, error: false });

    if (create) {
      const formData = new FormData();
      formData.append("form", JSON.stringify(form));

      if (formData.timeRequired === "" || null) {
        formData.timeRequired = 0;
      }

      api
        .createResource(formData, { "content-type": "multipart/form-data" })
        .then(({ data }) => {
          setState({ ...state, loading: false, error: false });
          navigate(`/resources/${data}`);
        })
        .catch((err) => {
          console.error(err);
          setState({ ...state, loading: false, error: true });
        });
    } else if (!create) {
      const formData = new FormData();
      const formStateCopy = { ...form };

      if (formStateCopy.timeRequired === "" || null) {
        formStateCopy.timeRequired = 0;
      }

      const formDataForEdit = Object.assign(formStateCopy, attributesForUpdate);

      formData.append("form", JSON.stringify(formDataForEdit));

      api
        .updateResource(resourceId, formData, {
          "content-type": "multipart/form-data",
        })
        .then(() => {
          setState({ ...state, loading: false, error: false });
          navigate(`/resources/${resourceId}`);
        })
        .catch((err) => {
          console.error(err);
          setState({ ...state, loading: false, error: true });
        });
    }
  };

  const handleSelectChange = ({ target: { name, value } }) => {
    setShowError({ ...showError, [name]: true });
    let valueForForm = value;
    if (value === "false") {
      valueForForm = false;
    }
    if (value === "true") {
      valueForForm = true;
    }

    setForm({
      ...form,
      [name]: valueForForm,
    });
    setRequiredFields({ ...requiredFields, [name]: value });
  };

  const handleChange = ({ target: { name, value } }) => {
    setShowError({ ...showError, [name]: true });
    if (
      name === "timeRequired" &&
      // eslint-disable-next-line no-restricted-globals
      (isNaN(parseInt(value, 10)) || !/^\d+$/.test(value))
    ) {
      setIsNotNumber(true);
      if (value.length > 0) {
        return;
      }
    } else {
      setIsNotNumber(false);
    }

    setForm({
      ...form,
      [name]: value,
    });
    setRequiredFields({ ...requiredFields, [name]: value });
  };

  const handleDeleteResourceAsset = async () => {
    if (publicIdForDelete === attributesForUpdate.ResourcePublicId) {
      setState({ ...state, loading: true });
      await api
        .deleteResourceAsset(publicIdForDelete)
        .then(() => {
          setAttributesForUpdate({
            ...attributesForUpdate,
            ResourcePublicId: "00000000-0000-0000-0000-000000000000",
            ResourceUrl: "",
          });
          setState({ ...state, loading: false });
          handleCloseModal();
        })
        .catch((err) => {
          console.error(err);
          setState({ ...state, loading: false, error: true });
          handleCloseModal();
        });
    }

    if (publicIdForDelete === attributesForUpdate.ResourcePublicIdEn) {
      setState({ ...state, loading: true });
      await api
        .deleteEnglishResourceAsset(publicIdForDelete)
        .then(() => {
          setAttributesForUpdate({
            ...attributesForUpdate,
            ResourcePublicIdEn: "00000000-0000-0000-0000-000000000000",
            ResourceUrlEn: "",
          });
          setState({ ...state, loading: false });
          handleCloseModal();
        })
        .catch((err) => {
          console.error(err);
          setState({ ...state, loading: false, error: true });
          handleCloseModal();
        });
    }

    if (publicIdForDelete === attributesForUpdate.ImagePublicId) {
      setState({ ...state, loading: true, error: false });
      await api
        .deleteImageAsset(publicIdForDelete)
        .then(() => {
          setAttributesForUpdate({
            ...attributesForUpdate,
            ImagePublicId: "00000000-0000-0000-0000-000000000000",
            ImageUrl: "",
          });
          setState({ ...state, loading: false });
          handleCloseModal();
        })
        .catch((err) => {
          console.error(err);
          setState({ ...state, loading: false, error: true });
        });
    }
  };

  const checkRequiredInput = (input) =>
    (showError[input] || showErrorAll) &&
    (form[input] === "" || form[input] === undefined);

  const generateHelperText = (input, fieldName) =>
    checkRequiredInput(input)
      ? t("missingMandatoryInput", { field: fieldName })
      : "";

  const mainGui = () => {
    if (state.loading)
      return (
        <Loader text={t("ResourceEdit.loading")} style={{ marginTop: 40 }} />
      );
    if (!state.loading && !state.error) {
      return (
        <>
          <div className="section-toolbar">
            <BackButton />
            <Typography className="section-title" variant="h6">
              {create
                ? t("ResourceEdit.titleCreate")
                : t("ResourceEdit.titleEdit")}
            </Typography>
            <Spacer />
            <Button
              color="primary"
              variant="contained"
              startIcon={<Save />}
              onClick={handleSave}
            >
              {t("CompanyDetails.save")}
            </Button>
          </div>
          <Divider />

          <div className="companies-content">
            <Paper className="company-details">
              <div>
                <div className="select-wrapper">
                  <div className="select-spacer">
                    <InputLabel id="label">{t("ResourceEdit.type")}</InputLabel>
                    <Select
                      labelId="label"
                      name="Type"
                      id="select"
                      value={form.Type}
                      onChange={(e) => handleSelectChange(e)}
                      error={checkRequiredInput("Type")}
                    >
                      <MenuItem value="Relaxation">
                        {t("ResourceEdit.relaxation")}
                      </MenuItem>
                      <MenuItem value="Physical Activity">
                        {t("ResourceEdit.physicalActivity")}
                      </MenuItem>
                      <MenuItem value="Nutrition">
                        {t("ResourceEdit.nutrition")}
                      </MenuItem>
                      <MenuItem value="Personal Development">
                        {t("ResourceEdit.personalDevelopment")}
                      </MenuItem>
                      <MenuItem value="Recovery">
                        {t("ResourceEdit.recovery")}
                      </MenuItem>
                      <MenuItem value="Exercise">
                        {t("ResourceEdit.exercise")}
                      </MenuItem>
                    </Select>
                    <FormHelperText style={{ color: "red" }}>
                      {generateHelperText("Type", t("ActivityEdit.type"))}
                    </FormHelperText>
                  </div>
                  <div>
                    <InputLabel id="label">
                      {t("ResourceEdit.availability")}
                    </InputLabel>

                    <RadioGroup
                      aria-label="available"
                      name="AvailableForAll"
                      value={form.AvailableForAll.toString()}
                      onChange={(e) => handleSelectChange(e)}
                      className="radiogroup-style"
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label={t("ResourceEdit.availableForAllTrue")}
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label={t("ResourceEdit.availableForAllFalse")}
                      />
                    </RadioGroup>
                  </div>
                  <div className="resource-edit-complexity-level-container">
                    <InputLabel id="labelComplexityLevel">
                      {t("ResourceEdit.complexityLevel")}
                    </InputLabel>
                    <Select
                      labelId="labelComplexityLevel"
                      name="complexityLevel"
                      id="select-resource-complexity"
                      value={form.complexityLevel}
                      onChange={handleSelectChange}
                    >
                      <MenuItem value={null}>
                        {t("ResourceEdit.noComplexity")}
                      </MenuItem>
                      <MenuItem value={1}>{1}</MenuItem>
                      <MenuItem value={2}>{2}</MenuItem>
                      <MenuItem value={3}>{3}</MenuItem>
                    </Select>
                  </div>
                  <div className="resource-edit-content-type-container">
                    <InputLabel id="labelContentType">
                      {t("ResourceEdit.contentType")}
                    </InputLabel>
                    <Select
                      labelId="labelContentType"
                      name="contentType"
                      id="select-resource-content-type"
                      value={form.contentType}
                      onChange={handleSelectChange}
                    >
                      <MenuItem value={null}>
                        {t("ResourceEdit.noContentType")}
                      </MenuItem>
                      <MenuItem value="Watch">Watch</MenuItem>
                      <MenuItem value="Read">Read</MenuItem>
                      <MenuItem value="Listen">Listen</MenuItem>
                    </Select>
                  </div>
                </div>
                {languages.map((lang) => [
                  <div key={lang}>
                    <div className="resource-form-text">
                      <TextField
                        name={`Name${
                          lang.charAt(0).toUpperCase() + lang.slice(1)
                        }`}
                        label={`${t(`ResourceEdit.name`)} ${t(
                          `Language.languages.${lang}`
                        )}`}
                        variant="outlined"
                        fullWidth
                        multiline
                        required
                        value={
                          form[
                            `Name${
                              lang.charAt(0).toUpperCase() + lang.slice(1)
                            }`
                          ]
                        }
                        onChange={handleChange}
                        error={checkRequiredInput(
                          `Name${lang.charAt(0).toUpperCase() + lang.slice(1)}`
                        )}
                        helperText={generateHelperText(
                          `Name${lang.charAt(0).toUpperCase() + lang.slice(1)}`,
                          t("ActivityEdit.name")
                        )}
                      />
                    </div>
                    <div className="resource-form-text">
                      <TextField
                        name={`shortDescription${
                          lang.charAt(0).toUpperCase() + lang.slice(1)
                        }`}
                        label={`${t(`ResourceEdit.shortDesc`)} ${t(
                          `Language.languages.${lang}`
                        )}`}
                        variant="outlined"
                        fullWidth
                        multiline
                        required
                        minRows={2}
                        maxRows={15}
                        value={
                          form[
                            `shortDescription${
                              lang.charAt(0).toUpperCase() + lang.slice(1)
                            }`
                          ]
                        }
                        onChange={handleChange}
                        error={checkRequiredInput(
                          `shortDescription${
                            lang.charAt(0).toUpperCase() + lang.slice(1)
                          }`
                        )}
                        helperText={generateHelperText(
                          `shortDescription${
                            lang.charAt(0).toUpperCase() + lang.slice(1)
                          }`,
                          t("ActivityEdit.shortDesc")
                        )}
                      />
                    </div>
                    <div className="resource-form-text">
                      <TextField
                        name={`description${
                          lang.charAt(0).toUpperCase() + lang.slice(1)
                        }`}
                        label={`${t(`ResourceEdit.desc`)} ${t(
                          `Language.languages.${lang}`
                        )}`}
                        variant="outlined"
                        fullWidth
                        multiline
                        required
                        minRows={4}
                        maxRows={25}
                        value={
                          form[
                            `description${
                              lang.charAt(0).toUpperCase() + lang.slice(1)
                            }`
                          ]
                        }
                        onChange={handleChange}
                        error={checkRequiredInput(
                          `description${
                            lang.charAt(0).toUpperCase() + lang.slice(1)
                          }`
                        )}
                        helperText={generateHelperText(
                          `description${
                            lang.charAt(0).toUpperCase() + lang.slice(1)
                          }`,
                          t("ActivityEdit.desc")
                        )}
                      />
                    </div>
                  </div>,
                ])}
                <div className="resource-form-text">
                  <TextField
                    error={isNotNumber}
                    name="timeRequired"
                    label={`${t(`ResourceEdit.timeRequired`)}`}
                    variant="outlined"
                    fullWidth
                    multiline
                    required
                    helperText={
                      isNotNumber ? t(`ResourceEdit.numberErrorText`) : ""
                    }
                    value={form.timeRequired}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {!create ? (
                <div className="input-wrapper">
                  <div className="file-upload-wrapper">
                    <CloudinaryContext
                      cloudName={process.env.REACT_APP_CLOUD_NAME}
                    >
                      <p className="upload-paragraph-header">
                        {t(`ResourceEdit.presentationImage`)}
                      </p>
                      <Button
                        color="primary"
                        className="asset-upload-button-style"
                        variant="contained"
                        onClick={() => beginCloudinaryImageUpload()}
                        disabled={
                          attributesForUpdate.ImagePublicId !== "" &&
                          attributesForUpdate.ImagePublicId !==
                            "00000000-0000-0000-0000-000000000000"
                        }
                      >
                        {t(`ResourceEdit.upload`)}
                      </Button>
                    </CloudinaryContext>
                    {t(`ResourceEdit.uploaded`)}
                    {attributesForUpdate.ImageUrl && (
                      <div className="resource-edit-upload-wrapper">
                        <img
                          src={attributesForUpdate.ImageUrl}
                          width="auto"
                          height="240"
                          alt="imageUrl"
                          style={{ objectFit: "cover" }}
                        />
                      </div>
                    )}
                    <div className="button-text-wrapper">
                      <Dialog
                        open={isModalOpen}
                        onClose={handleCloseModal}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">
                          <Typography className="reinforced-delete-alert-style">
                            {t("ResourceEdit.deleteAlertReinforced")}
                          </Typography>
                        </DialogTitle>

                        <DialogActions>
                          <Button
                            onClick={() => handleDeleteResourceAsset()}
                            color="primary"
                          >
                            {t("ButtonGeneral.acceptDeleteAlert")}
                          </Button>
                          <Button onClick={handleCloseModal} color="primary">
                            {t("ButtonGeneral.denyDeleteAlert")}
                          </Button>
                        </DialogActions>
                      </Dialog>
                      <Button
                        color="secondary"
                        variant="contained"
                        name="deleteImage"
                        startIcon={<Delete />}
                        disabled={
                          !attributesForUpdate.ImagePublicId ||
                          attributesForUpdate.ImagePublicId ===
                            "00000000-0000-0000-0000-000000000000"
                        }
                        className="delete-button-style"
                        onClick={() => handleOpenDeleteModalImage()}
                      >
                        {t("ResourceEdit.delete")}
                      </Button>
                    </div>
                  </div>
                  <div className="file-upload-wrapper">
                    <CloudinaryContext
                      cloudName={process.env.REACT_APP_CLOUD_NAME}
                    >
                      <p className="upload-paragraph-header">
                        {t(`ResourceEdit.resourceFile`)}
                      </p>
                      <Button
                        color="primary"
                        className="asset-upload-button-style"
                        variant="contained"
                        onClick={() => beginCloudinaryImagePdfOrVideoUpload()}
                        disabled={
                          attributesForUpdate.ResourcePublicId !== "" &&
                          attributesForUpdate.ResourcePublicId !==
                            "00000000-0000-0000-0000-000000000000"
                        }
                      >
                        {t(`ResourceEdit.upload`)}
                      </Button>
                    </CloudinaryContext>
                    {t(`ResourceEdit.uploaded`)}
                    <div className="button-text-wrapper">
                      {
                        // eslint-disable-next-line no-nested-ternary
                        attributesForUpdate.ResourceUrl &&
                        attributesForUpdate.ResourceUrl.includes("image") &&
                        !attributesForUpdate.ResourceUrl.includes("pdf") ? (
                          <div className="resource-edit-upload-wrapper">
                            <img
                              src={attributesForUpdate.ResourceUrl}
                              width="auto"
                              height="240"
                              alt="resourceUrl"
                            />
                          </div>
                        ) : // eslint-disable-next-line no-nested-ternary
                        attributesForUpdate.ResourceUrl &&
                          attributesForUpdate.ResourceUrl.includes("video") ? (
                          <div className="resource-edit-upload-wrapper">
                            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                            <video controls width="auto" height="240">
                              <source
                                src={attributesForUpdate.ResourceUrl}
                                type="video/webm"
                              />
                              Sorry, your browser doesn&apos;t support embedded
                              videos.
                            </video>
                          </div>
                        ) : (
                          attributesForUpdate.ResourceUrl &&
                          attributesForUpdate.ResourceUrl.includes("image") &&
                          attributesForUpdate.ResourceUrl.endsWith(".pdf") && (
                            <div className="resource-edit-upload-wrapper">
                              <iframe
                                scrolling="no"
                                title="pdf display"
                                src={attributesForUpdate.ResourceUrl}
                                className="resource-edit-pdf-display-style"
                              />
                            </div>
                          )
                        )
                      }
                      <Button
                        color="secondary"
                        variant="contained"
                        name="deleteResource"
                        startIcon={<Delete />}
                        disabled={
                          !attributesForUpdate.ResourcePublicId ||
                          attributesForUpdate.ResourcePublicId ===
                            "00000000-0000-0000-0000-000000000000"
                        }
                        onClick={() => handleOpenDeleteModalResource()}
                        className="delete-button-style"
                      >
                        {t("ResourceEdit.delete")}
                      </Button>
                    </div>
                  </div>
                  <div className="file-upload-wrapper">
                    <CloudinaryContext
                      cloudName={process.env.REACT_APP_CLOUD_NAME}
                    >
                      <p className="upload-paragraph-header">
                        {t(`ResourceEdit.resourceFileEn`)}
                      </p>
                      <Button
                        color="primary"
                        className="asset-upload-button-style"
                        variant="contained"
                        onClick={() =>
                          beginEnglishCloudinaryImagePdfOrVideoUpload()
                        }
                        disabled={
                          attributesForUpdate.ResourcePublicIdEn !== "" &&
                          attributesForUpdate.ResourcePublicIdEn !==
                            "00000000-0000-0000-0000-000000000000"
                        }
                      >
                        {t(`ResourceEdit.upload`)}
                      </Button>
                    </CloudinaryContext>
                    {t(`ResourceEdit.uploaded`)}
                    <div className="button-text-wrapper">
                      {
                        // eslint-disable-next-line no-nested-ternary
                        attributesForUpdate.ResourceUrlEn &&
                        attributesForUpdate.ResourceUrlEn.includes("image") &&
                        !attributesForUpdate.ResourceUrlEn.includes("pdf") ? (
                          <div className="resource-edit-upload-wrapper">
                            <img
                              src={attributesForUpdate.ResourceUrlEn}
                              width="auto"
                              height="240"
                              alt="resourceUrl"
                            />
                          </div>
                        ) : // eslint-disable-next-line no-nested-ternary
                        attributesForUpdate.ResourceUrlEn &&
                          attributesForUpdate.ResourceUrlEn.includes(
                            "video"
                          ) ? (
                          <div className="resource-edit-upload-wrapper">
                            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                            <video controls width="auto" height="240">
                              <source
                                src={attributesForUpdate.ResourceUrlEn}
                                type="video/webm"
                              />
                              Sorry, your browser doesn&apos;t support embedded
                              videos.
                            </video>
                          </div>
                        ) : (
                          attributesForUpdate.ResourceUrlEn &&
                          attributesForUpdate.ResourceUrlEn.includes("image") &&
                          attributesForUpdate.ResourceUrlEn.endsWith(
                            ".pdf"
                          ) && (
                            <div className="resource-edit-upload-wrapper">
                              <iframe
                                scrolling="no"
                                title="pdf display"
                                src={attributesForUpdate.ResourceUrlEn}
                                className="resource-edit-pdf-display-style"
                              />
                            </div>
                          )
                        )
                      }
                      <Button
                        color="secondary"
                        variant="contained"
                        name="deleteResourceEn"
                        startIcon={<Delete />}
                        disabled={
                          !attributesForUpdate.ResourcePublicIdEn ||
                          attributesForUpdate.ResourcePublicIdEn ===
                            "00000000-0000-0000-0000-000000000000"
                        }
                        onClick={() => handleOpenDeleteModalEnglishResource()}
                        className="delete-button-style"
                      >
                        {t("ResourceEdit.delete")}
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </Paper>
          </div>
        </>
      );
    }
    return (
      <Loader text={t("ResourceEdit.loading")} style={{ marginTop: 40 }} />
    );
  };

  return <div className="companies-wrapper">{mainGui()}</div>;
};

ResourceEdit.propTypes = {
  create: PropTypes.bool,
};

ResourceEdit.defaultProps = {
  create: false,
};

export default ResourceEdit;
