import { useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import "./Employees.css";
import { CircularProgress, FormControl, Modal, Paper } from "@material-ui/core";
import { CloseTwoTone, Check } from "@material-ui/icons";
import { useParams } from "react-router";
import { CSVReader } from "react-papaparse";
import api from "../../../utils/api/v1";
import Alert from "../../shared/Alert";
import { emailValidation } from "../../../utils/validation/emailValidation";
import CsvImportButton from "../../csvImportButton/CsvImportButton";
import type { InviteUserListModel } from "../../../utils/api/apiInterfaces";
import { MissingRequiredParamError } from "../../errorHandling/MissingRequiredParamError";

interface Props {
  open: boolean;
  onClose: () => void;
}

interface State {
  fileData: null | Array<any>;
  error: string | boolean;
  loading: boolean;
  invalidEmails: Array<boolean>;
  invite: InviteUserListModel;
}

const InviteManyEmployeeModal: VFC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { companyId } = useParams();
  const [state, setState] = useState<State>({
    fileData: null,
    invite: { users: [] },
    error: "",
    loading: false,
    invalidEmails: [],
  });

  const handleClose = () => {
    setState({
      fileData: null,
      invite: { users: [] },
      error: "",
      loading: false,
      invalidEmails: [],
    });

    return onClose && onClose();
  };

  const handleDrop = (data: Array<any>) => {
    setState({ ...state, fileData: data });
  };

  const handleError = () => {
    setState({ ...state, fileData: null, error: true, loading: false });
  };

  const handleRemoveFile = () => {
    setState({
      ...state,
      invalidEmails: [],
      invite: { users: [] },
      fileData: null,
    });
  };

  if (!companyId) {
    return <MissingRequiredParamError missingParam="companyId" />;
  }

  const inviteUsers = () => {
    state.invalidEmails = [];
    const usersInvited = state.fileData?.map((obj) => obj.data[0]);

    // Empty the array in the edge case where the user uploads a n:th CSV file.
    if (state.invite.users.length > 0) {
      state.invite.users = [];
    }

    usersInvited?.forEach((invitedUser) => {
      if (!emailValidation(invitedUser)) {
        if (invitedUser !== "" && invitedUser !== null) {
          state.invalidEmails.push(invitedUser);
        }
      }
    });

    if (state.invalidEmails.length > 0) {
      return;
    }

    // eslint-disable-next-line no-restricted-syntax
    usersInvited?.forEach((user) => {
      if (user !== null && user !== "") {
        state.invite.users.push({ email: user, error: "false" });
      }
    });

    api
      .inviteEmployees(state.invite, companyId)
      .then((result) => {
        setState({
          ...state,
          fileData: null,
          loading: false,
          invalidEmails: [],
          invite: { users: result.data },
        });
      })
      .catch((err) => {
        console.error(err);
        setState({ ...state, error: true });
      });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="employee-modal-title"
      aria-describedby="employee-modal-description"
    >
      <Paper className="employee-modal-wrapper">
        {!state.invite.users.length && (
          <button
            className="close-invite-many-modal-button"
            onClick={handleClose}
            type="button"
          >
            <CloseTwoTone />
          </button>
        )}
        <h2 id="employee-modal-title">
          {t("InviteEmployeeModal.createManyTitle")}
        </h2>
        <FormControl className="employee-modal-form">
          <CSVReader
            onDrop={handleDrop}
            onError={handleError}
            addRemoveButton
            removeButtonColor="#659cef"
            onRemoveFile={handleRemoveFile}
          >
            <span>{t("InviteEmployeeModal.fileUpload")}</span>
          </CSVReader>
          {state.fileData && state.invalidEmails.length < 1 && (
            <div
              className="export-button-alignment"
              onClick={inviteUsers}
              onKeyDown={inviteUsers}
              tabIndex={-1}
              role="button"
            >
              <CsvImportButton />
            </div>
          )}
        </FormControl>
        {state.loading && <CircularProgress style={{ marginTop: 20 }} />}
        {state.invalidEmails.length > 0 && (
          <div className="invalid-email-display-container">
            <h4 style={{ color: "#f44336" }}>
              {t("InviteEmployeeModal.invalidEmailFormat")}
            </h4>
            {state.invalidEmails.map((invalidEmail, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <p key={index}>{invalidEmail}</p>
            ))}
          </div>
        )}
        {state.invite.users &&
          state.invite.users.length &&
          state.invalidEmails.length === 0 && (
            <h4 className="invite-success-style">
              {t("InviteEmployeeModal.successfullyInvited")}{" "}
              {state.invite.users.filter((user) => user.error === null).length}{" "}
              {t("InviteEmployeeModal.persons")}{" "}
              {state.invite.users.filter((user) => user.error !== null).length >
              0
                ? t("InviteEmployeeModal.alreadyExistingEmails")
                : ""}
            </h4>
          )}
        {state.invite.users &&
          state.invite.users.length &&
          state.invalidEmails.length === 0 && (
            <ul className="list-style">
              {state.invite.users
                .filter((invitedUser) => invitedUser.error !== null)
                .map((errorUser) => (
                  <li
                    key={errorUser.email}
                    className="company-details-department"
                    style={{ color: "#f44336" }}
                  >
                    {errorUser.email}
                  </li>
                ))}
            </ul>
          )}

        {state.error && <Alert style={{ marginTop: 20 }}>{state.error}</Alert>}
        {state.invite.users.length && (
          <button
            className="checkbox-invite-many-modal-button"
            onClick={handleClose}
            type="button"
          >
            <Check className="invite-many-checkbox-style" />
          </button>
        )}
      </Paper>
    </Modal>
  );
};

export default InviteManyEmployeeModal;
