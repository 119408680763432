import { Page, View, Text } from "@react-pdf/renderer";
import type { VFC } from "react";
import { useTranslation } from "react-i18next";
import type { ApiResponseAutomaticAnalysisModel } from "../../../../../utils/api/apiInterfaces";
import {
  getLowestHighScore,
  getPercentageResults,
} from "../UseAutomaticAnalysisCalculationHelper";
import { strategyPlanKPIStyling } from "./StrategyPlanKPIStyling";

interface Props {
  automaticAnalysisResult: ApiResponseAutomaticAnalysisModel;
}

export const StrategyPlanKPI: VFC<Props> = ({ automaticAnalysisResult }) => {
  const { t } = useTranslation();
  const { companyResult } = automaticAnalysisResult;
  const companyPercentageResults = getPercentageResults(companyResult);
  const highScores = [
    companyPercentageResults.developmentPercentages.high,
    companyPercentageResults.physicalPercentages.high,
    companyPercentageResults.stressPercentages.high,
  ];

  return (
    <Page size="A4" style={strategyPlanKPIStyling.page} object-fit="fill">
      <View style={strategyPlanKPIStyling.strategyPlanKPIMainContainer}>
        <View style={strategyPlanKPIStyling.strategyPlanKPIHorizontalLine} />
        <Text style={strategyPlanKPIStyling.strategyPlanKPIRecommendation}>
          {t("KPI.recommendations")}
        </Text>
        <View style={strategyPlanKPIStyling.columnsParentContainer}>
          <View style={strategyPlanKPIStyling.leftColumnContainer}>
            <Text style={strategyPlanKPIStyling.strategicOne}>
              {t("KPI.strategicOne")}
            </Text>
            <Text style={strategyPlanKPIStyling.strategicTwo}>
              {t("KPI.strategicTwo")}
            </Text>
            <Text style={strategyPlanKPIStyling.leftParagraphOne}>
              {t("KPI.kpiIntro")}
            </Text>
            <Text style={strategyPlanKPIStyling.paragraphTitle}>
              {t("KPI.goalHeading")}
            </Text>
            <Text style={strategyPlanKPIStyling.leftParagraphTwo}>
              {t("KPI.goalText-1")} {companyResult.totalAverage + 1}.{" "}
              {t("KPI.goalText-2")}
            </Text>
            <Text style={strategyPlanKPIStyling.paragraphTitle}>
              {t("KPI.subGoalHeading")}
            </Text>
            <Text style={strategyPlanKPIStyling.leftParagraphThree}>
              {t("KPI.subGoalText")}
            </Text>
            <Text style={strategyPlanKPIStyling.paragraphTitle}>
              {t("KPI.visionOfHealthHead")}
            </Text>
            <Text style={strategyPlanKPIStyling.leftParagraphFour}>
              {t("KPI.visionOfHealth-1")}
            </Text>
            <Text style={strategyPlanKPIStyling.paragraphTitle}>
              {t("KPI.kpiHead")}
            </Text>
            <Text style={strategyPlanKPIStyling.leftParagraphFive}>
              {t("KPI.kpiText-1")}
            </Text>
          </View>
          <View style={strategyPlanKPIStyling.rightColumnContainer}>
            <Text style={strategyPlanKPIStyling.rightParagraphOne}>
              {t("KPI.kpiText-2")}
            </Text>
            <Text style={strategyPlanKPIStyling.paragraphTitle}>
              {t("KPI.directionHead")}
            </Text>
            <Text style={strategyPlanKPIStyling.rightParagraphTwo}>
              {t("KPI.directionText-1")}
              {getLowestHighScore(highScores) === 0
                ? `${t(`KPI.directionText-2v2`)}`
                : `${getLowestHighScore(highScores)} ${t(
                    `KPI.directionText-2`
                  )}`}{" "}
            </Text>
          </View>
          <View style={strategyPlanKPIStyling.strategyPlanKPICircleContainer}>
            <View style={strategyPlanKPIStyling.strategyPlanKPICircleDiv} />
          </View>
        </View>
      </View>
    </Page>
  );
};
