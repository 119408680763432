import type { VFC } from "react";
import { View, Text } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import type { ApiResponseAutomaticAnalysisResult } from "../../../../../utils/api/apiInterfaces";
import { AutomaticAnalysisBarChart } from "../AutomaticAnalysisBarChart";
import { automaticAnalysisPageStyle } from "../styles/AutomaticAnalysisPageStyle";
import {
  getAmountOfTenResults,
  getPercentageResults,
} from "../UseAutomaticAnalysisCalculationHelper";
import { lowestQuestionIndex } from "../LowestQuestionIndex";
import { getLanguage } from "../../../../../i18n";

interface Props {
  bossResult: ApiResponseAutomaticAnalysisResult;
}

export const BossResult: VFC<Props> = ({ bossResult }) => {
  const { t } = useTranslation();
  const companyOutOfTenResults = getAmountOfTenResults(bossResult);
  const lowestDevelopmentQuestion = lowestQuestionIndex(
    bossResult.automaticAnalyzeQuestions.developmentQuestions,
    getLanguage(),
    1
  );
  const lowestPhysicalQuestions = lowestQuestionIndex(
    bossResult.automaticAnalyzeQuestions.physicalQuestions,
    getLanguage(),
    3
  );
  const lowestStressQuestions = lowestQuestionIndex(
    bossResult.automaticAnalyzeQuestions.stressQuestions,
    getLanguage(),
    2
  );
  const bossPercantageResults = getPercentageResults(bossResult);
  return (
    <View style={automaticAnalysisPageStyle.mainContainer}>
      <View style={automaticAnalysisPageStyle.textParentContainer}>
        <Text style={automaticAnalysisPageStyle.heading}>
          {bossResult.nameEn}
        </Text>
        <Text style={automaticAnalysisPageStyle.resultText}>
          {t("BossResult.averageResult")} {bossResult.totalAverage}.
        </Text>
        <Text style={automaticAnalysisPageStyle.resultText}>
          {bossPercantageResults.developmentPercentages.high === 0
            ? `${t(`BossResult.developmentAveragev2`)}`
            : `${bossPercantageResults.developmentPercentages.high} ${t(
                `BossResult.developmentAverage`
              )}`}
          &quot;{lowestDevelopmentQuestion[0]}&quot;.
        </Text>

        <Text style={automaticAnalysisPageStyle.resultText}>
          {bossPercantageResults.physicalPercentages.high === 0
            ? `${t(`BossResult.physicalAverageP1v2`)}`
            : `${bossPercantageResults.physicalPercentages.high} ${t(
                `BossResult.physicalAverageP1`
              )}`}{" "}
          {companyOutOfTenResults.physicalAmount.high}{" "}
          {t("BossResult.physicalAverageP2")}{" "}
          {t("BossResult.physicalAverageP3")}{" "}
          {lowestPhysicalQuestions.map((lpq, i) =>
            i === 0 ? (
              <Text key="1">&quot;{lpq.slice(0, -1)}&quot;, </Text>
            ) : i === 1 ? (
              <Text key="2">
                &quot;{lpq.slice(0, -1)}&quot; {t("CompanyResult.and")}
              </Text>
            ) : (
              <Text key="3">&quot;{lpq}&quot;.</Text>
            )
          )}
        </Text>

        <Text style={automaticAnalysisPageStyle.resultText}>
          {bossPercantageResults.stressPercentages.medium +
            bossPercantageResults.stressPercentages.low ===
          0
            ? `${t(`BossResult.stressAveragev2`)}`
            : `${
                bossPercantageResults.stressPercentages.medium +
                bossPercantageResults.stressPercentages.low
              } ${t(`BossResult.stressAverage`)}`}{" "}
          {lowestStressQuestions.map((lsq, i) =>
            i === 0 ? (
              <Text key="1">
                &quot;{lsq.slice(0, -1)}&quot; {t("CompanyResult.and")}
              </Text>
            ) : (
              <Text key="2">&quot;{lsq}&quot;.</Text>
            )
          )}
          {bossResult.stressResults.low > 0 && (
            <Text style={automaticAnalysisPageStyle.resultText}>
              {t("DepartmentResult.highStressP1")}{" "}
              {bossResult.stressResults.low}{" "}
              {t("DepartmentResult.highStressP2")}
            </Text>
          )}
        </Text>
      </View>
      <View style={automaticAnalysisPageStyle.barChartContainer}>
        <View style={automaticAnalysisPageStyle.barchartTextContainer}>
          <Text style={automaticAnalysisPageStyle.barchartText}>
            {t("CompanyStatistics.wellBeingDevelopmentDistribution")}
          </Text>
          <Text style={automaticAnalysisPageStyle.barchartText}>
            {t("CompanyStatistics.wellBeingPhysicalDistribution")}
          </Text>
          <Text style={automaticAnalysisPageStyle.barchartText}>
            {t("CompanyStatistics.wellBeingStressDistribution")}
          </Text>
        </View>
        <View style={automaticAnalysisPageStyle.barchartChildDiv}>
          <AutomaticAnalysisBarChart
            developmentAverage={bossResult.developmentAverage}
            stressAverage={bossResult.stressAverage}
            physicalAverage={bossResult.physicalAverage}
          />
        </View>
      </View>
    </View>
  );
};
