/* eslint-disable no-nested-ternary */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./Activities.css";
import {
  Paper,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { languages, getLanguage } from "../../../i18n";
import Spacer from "../../shared/Spacer";
import api from "../../../utils/api/v1";
import Loader from "../../shared/loader/Loader";
import { BackButton } from "../../../UI-Components/Buttons/Buttons";
import type { ApiResponseModuleActivityWithTextDataModel } from "../../../utils/api/apiInterfaces";
import { MissingRequiredParamError } from "../../errorHandling/MissingRequiredParamError";

interface State {
  loading: boolean;
  error: boolean;
  activity: ApiResponseModuleActivityWithTextDataModel | null;
}

const ActivityDetails = () => {
  const { t } = useTranslation();
  const { moduleActivityId } = useParams();
  const [openAlert, setOpenAlert] = useState(false);
  const currentLanguage = getLanguage();
  const navigate = useNavigate();

  const [state, setState] = useState<State>({
    loading: false,
    error: false,
    activity: null,
  });

  const deleteModuleActivity = () => {
    setState({ ...state, loading: true });
    api
      .deleteModuleActivity(state.activity?.id ?? "")
      .then(() => {
        setState({ ...state, loading: false });
        navigate(`/modules/${state.activity?.moduleId}`);
      })
      .catch((err) => {
        console.error(err);
        setState({ ...state, loading: false, error: true });
      });
  };

  useEffect(() => {
    getModuleActivity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!moduleActivityId) {
    return <MissingRequiredParamError missingParam="moduleActivityId" />;
  }

  const getModuleActivity = () => {
    setState({ ...state, loading: true });
    api
      .getModuleActivity(moduleActivityId)
      .then((res) => {
        setState({ ...state, activity: res.data, loading: false });
      })
      .catch((err) => {
        console.error(err);
        setState({ ...state, loading: false, error: true });
      });
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleDeleteResource = () => {
    deleteModuleActivity();
  };

  const mainGui = () => {
    if (state.loading)
      return (
        <Loader text={t("ActivityDetails.loading")} style={{ marginTop: 40 }} />
      );
    if (!state.loading && state.activity) {
      return (
        <>
          <div className="activities-toolbar">
            <BackButton />
            <Typography className="activity-title" variant="h6">
              {currentLanguage === "sv"
                ? state.activity.nameSv
                : currentLanguage === "en"
                ? state.activity.nameEn
                : ""}
            </Typography>
            <Spacer />
            <Link to="edit" className="activities-link-style">
              <Button startIcon={<Edit />} color="primary" variant="contained">
                {t("ButtonGeneral.edit")}
              </Button>
            </Link>

            <Button
              startIcon={<Delete />}
              color="secondary"
              variant="contained"
              onClick={() => {
                handleOpenAlert();
              }}
              style={{ margin: 5 }}
            >
              {t("ButtonGeneral.delete")}
            </Button>

            <Dialog
              open={openAlert}
              onClose={handleCloseAlert}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {t("ActivityDetails.deleteAlert")}
              </DialogTitle>

              <DialogActions>
                <Button onClick={() => handleDeleteResource()} color="primary">
                  {t("ActivityDetails.acceptDeleteAlert")}
                </Button>
                <Button onClick={handleCloseAlert} color="primary" autoFocus>
                  {t("ActivityDetails.denyDeleteAlert")}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <div className="">
            <Paper className="section-detail-layout">
              <Typography variant="h5" className="section-detail-title">
                {t(`ActivityDetails.detailsHeader`)}
              </Typography>
              <div />
              <div>
                {languages.map((lang) => (
                  <div
                    key={lang}
                    className="text-description-container-activities"
                  >
                    <div className="text-container-activity-details">
                      <h4>
                        {t(
                          `ActivityDetails.activity.Name${
                            lang.charAt(0).toUpperCase() + lang.slice(1)
                          }`
                        )}
                      </h4>
                      <p>
                        {state.activity &&
                          state.activity[
                            `name${
                              lang.charAt(0).toUpperCase() + lang.slice(1)
                            }`
                          ]}
                      </p>
                    </div>
                    <div className="text-container-activity-details">
                      <h4>
                        {t(
                          `ActivityDetails.activity.ShortDesc${
                            lang.charAt(0).toUpperCase() + lang.slice(1)
                          }`
                        )}
                      </h4>
                      <p>
                        {state.activity &&
                          state.activity[
                            `shortDescription${
                              lang.charAt(0).toUpperCase() + lang.slice(1)
                            }`
                          ]}
                      </p>
                    </div>
                    <div className="text-container-activity-details">
                      <h4>
                        {t(
                          `ActivityDetails.activity.Desc${
                            lang.charAt(0).toUpperCase() + lang.slice(1)
                          }`
                        )}
                      </h4>
                      <p>
                        {state.activity &&
                          state.activity[
                            `description${
                              lang.charAt(0).toUpperCase() + lang.slice(1)
                            }`
                          ]}
                      </p>
                    </div>
                  </div>
                ))}
                <div className="text-description-container-activities">
                  <div className="text-container">
                    <h4 className="description-text-style">
                      {t(`ActivityDetails.activity.type`)}
                    </h4>
                    <p>{state.activity.type}</p>
                  </div>
                  <div className="text-container">
                    <h4 className="description-text-style">
                      {t(`ActivityDetails.activity.timeRequired`)}
                    </h4>
                    <p>
                      {state.activity.timeRequired}{" "}
                      {state.activity.timeRequired === 1
                        ? t(`ActivityDetails.activity.minute`)
                        : t(`ActivityDetails.activity.minutes`)}
                    </p>
                  </div>
                  <div className="text-container">
                    <h4 className="description-text-style">
                      {t(`ActivityDetails.activity.enableInput`)}
                    </h4>
                    <div>
                      {state.activity.enableInput ? (
                        <p>{t(`ActivityDetails.activity.enableInputTrue`)}</p>
                      ) : (
                        <p>{t(`ActivityDetails.activity.enableInputFalse`)}</p>
                      )}
                    </div>
                  </div>
                  <div className="text-container">
                    <h4 className="description-text-style">
                      {t(`ActivityDetails.activity.inputInstructionsSv`)}
                    </h4>
                    <p>{state.activity.inputInstructionsSv}</p>
                  </div>
                  <div className="text-container">
                    <h4 className="description-text-style">
                      {t(`ActivityDetails.activity.inputInstructionsEn`)}
                    </h4>
                    <p>{state.activity.inputInstructionsEn}</p>
                  </div>
                </div>
              </div>
              <div className="activities-assets-container">
                <div className="activity-uploaded-asset-container">
                  <p className="presentation-image-text">
                    {t(`ActivityDetails.overviewImage`)}
                  </p>
                  {state.activity.imageUrl ? (
                    <img
                      src={state.activity.imageUrl}
                      alt="resourceImageUrl"
                      className="activity-image-url"
                    />
                  ) : (
                    t(`ActivityDetails.overviewImageNotUploaded`)
                  )}
                </div>
                <div className="activity-uploaded-asset-container">
                  <p className="presentation-image-text">
                    {t(`ActivityDetails.resourceAsset`)}
                  </p>
                  {state.activity.resourceUrl ? (
                    <div>
                      {state.activity.resourceUrl.includes("video") ? (
                        <video
                          width="320"
                          height="240"
                          controls
                          className="video-style"
                        >
                          <source
                            src={state.activity.resourceUrl}
                            type="video/mp4"
                          />
                          <track
                            src="captions_en.vtt"
                            kind="captions"
                            srcLang="en"
                            label="english_captions"
                          />
                        </video>
                      ) : state.activity.resourceUrl.includes("youtube") ? (
                        <iframe
                          width="400"
                          height="250"
                          title="youtube-video"
                          src={state.activity.resourceUrl}
                        />
                      ) : state.activity.resourceUrl.includes("pdf") ? (
                        <a href={state.activity.resourceUrl}>
                          <div className="pdf-image-url" />
                          <p className="pdf-text">
                            {t(`ActivityDetails.download`)}
                          </p>
                        </a>
                      ) : state.activity.resourceUrl.includes("image") &&
                        !state.activity.resourceUrl.includes("pdf") ? (
                        <img
                          src={state.activity.resourceUrl}
                          alt="resourceImageUrl"
                          className="activity-image-url"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    t(`ActivityDetails.noPresentationMaterialUploaded`)
                  )}
                </div>
                <div className="activity-uploaded-asset-container">
                  <p className="presentation-image-text">
                    {t(`ActivityDetails.resourceAssetEnglish`)}
                  </p>
                  {state.activity.resourceUrlEn ? (
                    <div>
                      {state.activity.resourceUrlEn.includes("video") ? (
                        <video
                          width="320"
                          height="240"
                          controls
                          className="video-style"
                        >
                          <source
                            src={state.activity.resourceUrlEn}
                            type="video/mp4"
                          />
                          <track
                            src="captions_en.vtt"
                            kind="captions"
                            srcLang="en"
                            label="english_captions"
                          />
                        </video>
                      ) : state.activity.resourceUrlEn.includes("youtube") ? (
                        <iframe
                          width="400"
                          height="250"
                          title="youtube-video"
                          src={state.activity.resourceUrlEn}
                        />
                      ) : state.activity.resourceUrlEn.includes("pdf") ? (
                        <a href={state.activity.resourceUrlEn}>
                          <div className="pdf-image-url" />
                          <p className="pdf-text">
                            {t(`ActivityDetails.download`)}
                          </p>
                        </a>
                      ) : state.activity.resourceUrlEn.includes("image") &&
                        !state.activity.resourceUrlEn.includes("pdf") ? (
                        <img
                          src={state.activity.resourceUrlEn}
                          alt="resourceImageUrl"
                          className="activity-image-url"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    t(`ActivityDetails.noEnglishPresentationMaterialUploaded`)
                  )}
                </div>
              </div>
            </Paper>
          </div>
        </>
      );
    }

    return (
      <Loader text={t("ActivityDetails.loading")} style={{ marginTop: 40 }} />
    );
  };

  return <div className="surveys-wrapper">{mainGui()}</div>;
};

export default ActivityDetails;
