/* eslint-disable no-nested-ternary */
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./Modules.css";
import { Typography, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Add } from "@material-ui/icons";
import Spacer from "../shared/Spacer";
import api from "../../utils/api/v1";
import Loader from "../shared/loader/Loader";
import { getLanguage } from "../../i18n";
import SingleSelectionGrid from "../../UI-Components/SingleRowSelectionGrid/SingleRowSelectionGrid";
import type { ApiResponseModuleWithTextDataModel } from "../../utils/api/apiInterfaces";

interface StateModules extends ApiResponseModuleWithTextDataModel {
  category: string | undefined;
  type: string | undefined;
}

const formatDateTime = (dateTime: number) => {
  const date = new Date(dateTime);
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  } as const;
  return date.toLocaleDateString(getLanguage(), options);
};

const Modules = () => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    loading: true,
    error: false,
  });
  const [modules, setModules] = useState<Array<StateModules>>([]);
  const [, setWindowWidth] = useState(window.innerWidth);

  const getModules = () => {
    setState({ ...state, error: false, loading: true });
    api
      .getModules()
      .then(({ data }) => {
        if (data && data.length) {
          const timezoneOffset = new Date().getTimezoneOffset();
          const dataCopy = data.map((module): StateModules => {
            /* eslint no-param-reassign: ["error", { "props": false }] */
            const moduleAdditionalState: StateModules = {
              ...module,
              type: undefined,
              category: undefined,
            };
            module.createdAt = String(
              Date.parse(module.createdAt) - timezoneOffset * 60 * 1000
            );
            if (module.isPhysical) {
              moduleAdditionalState.category = t("Modules.physical");
              moduleAdditionalState.type = "physical";
            } else if (module.isDevelopment) {
              moduleAdditionalState.category = t("Modules.development");
              moduleAdditionalState.type = "development";
            } else {
              moduleAdditionalState.category = t("Modules.stress");
              moduleAdditionalState.type = "stress";
            }
            return moduleAdditionalState;
          });

          setModules(
            dataCopy.sort((a, b) => {
              const aName = a.nameSv;
              const bName = b.nameSv;
              if (!aName && !bName) return 0;
              if (!aName) return -1;
              if (!bName) return 1;
              return aName.localeCompare(bName);
            })
          );
        }
        setState({ ...state, error: false, loading: false });
      })
      .catch((e) => {
        console.error(e);
        setState({ ...state, error: true, loading: false });
      });
  };

  interface ValueGetterProps {
    row: {
      createdAt: number;
      type: string;
      date: number;
    };
  }

  const columns = [
    {
      field: getLanguage() === "sv" ? "nameSv" : "nameEn",
      headerName: t("Surveys.table.name"),
      width:
        window.innerWidth >= 960
          ? window.innerWidth / 3
          : window.innerWidth / 2.5,
    },
    {
      field: "category",
      headerName: t("Modules.category"),
      width:
        window.innerWidth >= 960
          ? window.innerWidth / 4.5
          : window.innerWidth / 3.5,
      valueGetter: (params: ValueGetterProps) => {
        const { type } = params.row;
        return `${
          type === "physical"
            ? t("Modules.physical")
            : type === "stress"
            ? t("Modules.stress")
            : type === "development"
            ? t("Modules.development")
            : "-"
        }`;
      },
    },
    {
      field: "createdAt",
      headerName: t("Surveys.table.created"),
      width:
        window.innerWidth >= 960
          ? window.innerWidth / 4
          : window.innerWidth / 3,
      valueGetter: (params: ValueGetterProps) =>
        formatDateTime(params.row.createdAt) || "-",
    },
  ];

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    getModules();
    window.addEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mainGui = () => {
    if (state.loading)
      return <Loader text={t("Modules.loading")} style={{ marginTop: 40 }} />;
    if (!state.loading && modules) {
      return (
        <>
          <div className="section-toolbar">
            <Typography className="section-title" variant="h6">
              {t("Modules.modules")}
            </Typography>

            <Spacer />

            <Link to="/modules/module/new" className="resources-link-style">
              <Button startIcon={<Add />} color="primary" variant="contained">
                {t("Surveys.create")}
              </Button>
            </Link>
          </div>

          <div className="section-SingleSelectionGrid-layout">
            <SingleSelectionGrid
              columns={columns}
              rows={modules}
              pageSize={10}
              loading={state.loading}
              path="modules"
            />
          </div>
        </>
      );
    }

    return <Loader text={t("Modules.loading")} style={{ marginTop: 40 }} />;
  };

  return <div className="surveys-wrapper">{mainGui()}</div>;
};

export default Modules;
