import React, { VFC } from "react";
import { useTranslation } from "react-i18next";
import "./titles.css";
import {
  Button,
  CircularProgress,
  FormControl,
  Modal,
  Paper,
  TextField,
} from "@material-ui/core";
import api from "../../../utils/api/v1";
import Spacer from "../../shared/Spacer";
import Alert from "../../shared/Alert";

interface Props {
  open: boolean;
  onClose: (id: string | null) => void;
  companyId: string;
}

// By supplying an optional title object this will function as an Edit modal
const TitleModal: VFC<Props> = ({ open, onClose, companyId }) => {
  const { t } = useTranslation();
  const [form, setForm] = React.useState({
    name: "",
  });
  const [state, setState] = React.useState({
    error: "",
    loading: false,
  });

  const handleClose = (id: string | null) =>
    onClose && onClose(typeof id === "string" ? id : null);

  const handleChange = ({
    target: { name, value },
  }: {
    target: { name: string; value: string };
  }) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSave = () => {
    if (!form.name || !form.name.length) {
      setState({ loading: false, error: t("TitleModal.error.name") });
      return;
    }
    setState({ ...state, loading: true, error: "" });
    api
      .createTitle(companyId, {
        ...form,
        companyId,
      })
      .then(({ data }) => {
        setState({ ...state, loading: false });
        handleClose(data.id);
      })
      .catch((e) => {
        const message = t("TitleModal.error.default");
        setState({ loading: false, error: message });
        console.error(e);
      });
  };

  return (
    <Modal
      open={open}
      onClose={() => handleClose(null)}
      aria-labelledby="title-modal-title"
      aria-describedby="title-modal-description"
    >
      <Paper className="title-wrapper" data-testid="titleModal">
        <h2 id="title-modal-title">{t("TitleModal.title")}</h2>
        <FormControl className="title-form">
          <TextField
            label={t("TitleModal.name")}
            inputMode="text"
            value={form.name}
            name="name"
            onChange={handleChange}
            data-testid="titleNameInput"
          />
        </FormControl>
        {state.loading ? <CircularProgress style={{ marginTop: 20 }} /> : null}
        {state.error ? (
          <Alert style={{ marginTop: 20 }}>{state.error}</Alert>
        ) : null}
        <div className="buttons">
          <Spacer />
          <Button
            disabled={state.loading}
            variant="outlined"
            className="cancel"
            onClick={() => handleClose(null)}
          >
            {t("TitleModal.cancel")}
          </Button>
          <Button
            disabled={state.loading}
            color="primary"
            variant="contained"
            onClick={handleSave}
            data-testid="saveTitleButton"
          >
            {t("TitleModal.save")}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

export default TitleModal;
