/* eslint-disable no-nested-ternary */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./CounselingResources.css";
import {
  Paper,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router";
import { languages, getLanguage } from "../../i18n";
import Spacer from "../shared/Spacer";
import api from "../../utils/api/v1";
import Loader from "../shared/loader/Loader";
import { BackButton } from "../../UI-Components/Buttons/Buttons";
import type { ApiResponseCounselingResourceModel } from "../../utils/api/apiInterfaces";
import { MissingRequiredParamError } from "../errorHandling/MissingRequiredParamError";

interface State {
  loading: boolean;
  error: boolean;
  counselingResource: ApiResponseCounselingResourceModel | null;
}

const CounselingResourceDetails = () => {
  const { t } = useTranslation();
  const { counselingResourceId } = useParams();
  const [openAlert, setOpenAlert] = useState(false);
  const currentLanguage = getLanguage();
  const navigate = useNavigate();

  const [state, setState] = useState<State>({
    loading: false,
    error: false,
    counselingResource: null,
  });

  const deleteCounselingResource = () => {
    setState({ ...state, loading: true });
    api
      .deleteCounselingResource(state.counselingResource?.id ?? "")
      .then(() => {
        setState({ ...state, loading: false });
        navigate("/counseling-resources");
      })
      .catch((err) => {
        console.error(err);
        setState({ ...state, loading: false, error: true });
      });
  };

  useEffect(() => {
    getCounselingResource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!counselingResourceId) {
    return <MissingRequiredParamError missingParam="counselingResourceId" />;
  }

  const getCounselingResource = () => {
    setState({ ...state, loading: true });
    api
      .getCounselingResource(counselingResourceId)
      .then(({ data }) => {
        setState({ ...state, counselingResource: data, loading: false });
      })
      .catch((err) => {
        console.error(err);
        setState({ ...state, loading: false, error: true });
      });
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleDeleteCounselingResource = () => {
    deleteCounselingResource();
  };

  const mainGui = () => {
    if (state.loading)
      return (
        <Loader
          text={t("CounselingResourcesDetails.loading")}
          style={{ marginTop: 40 }}
        />
      );
    if (state.counselingResource) {
      return (
        <>
          <div className="section-toolbar">
            <BackButton navigateTo="/counseling-resources" />
            <Typography className="section-title" variant="h6">
              {currentLanguage === "sv"
                ? state.counselingResource.titleSv
                : currentLanguage === "en"
                ? state.counselingResource.titleEn
                : ""}
            </Typography>
            <Spacer />
            <Link to="edit" className="counseling-resources-link-style">
              <Button startIcon={<Edit />} color="primary" variant="contained">
                {t("ButtonGeneral.edit")}
              </Button>
            </Link>

            <Button
              startIcon={<Delete />}
              color="secondary"
              variant="contained"
              onClick={() => {
                handleOpenAlert();
              }}
              style={{ margin: 5 }}
            >
              {t("ButtonGeneral.delete")}
            </Button>
            <Dialog
              open={openAlert}
              onClose={handleCloseAlert}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {t("CounselingResourcesDetails.deleteAlert")}
              </DialogTitle>

              <DialogActions>
                <Button
                  onClick={handleDeleteCounselingResource}
                  color="primary"
                >
                  {t("ButtonGeneral.acceptDeleteAlert")}
                </Button>
                <Button onClick={handleCloseAlert} color="primary" autoFocus>
                  {t("ButtonGeneral.denyDeleteAlert")}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <div className="">
            <Paper className="section-detail-layout">
              <Typography variant="h5" className="section-detail-title">
                {t(`CounselingResourcesDetails.detailsHeader`)}
              </Typography>
              <div />
              <div>
                {languages.map((lang) => (
                  <div
                    key={lang}
                    className="text-description-container-counseling-resource"
                  >
                    <div className="text-container-counseling-resource-details">
                      <h4>
                        {t(
                          `CounselingResourcesDetails.counselingResource.title${
                            lang.charAt(0).toUpperCase() + lang.slice(1)
                          }`
                        )}
                      </h4>
                      <p>
                        {state.counselingResource &&
                          state.counselingResource[
                            `title${
                              lang.charAt(0).toUpperCase() + lang.slice(1)
                            }`
                          ]}
                      </p>
                    </div>
                    <div className="text-container-counseling-resource-details">
                      <h4>
                        {t(
                          `CounselingResourcesDetails.counselingResource.answer${
                            lang.charAt(0).toUpperCase() + lang.slice(1)
                          }`
                        )}
                      </h4>
                      <p>
                        {state.counselingResource &&
                          state.counselingResource[
                            `answer${
                              lang.charAt(0).toUpperCase() + lang.slice(1)
                            }`
                          ]}
                      </p>
                    </div>
                  </div>
                ))}
                <div className="text-description-container-modules">
                  <div className="text-container">
                    <h4 className="description-text-style">
                      {t(`CounselingResourcesDetails.counselingResource.type`)}
                    </h4>
                    <p>{state.counselingResource.type}</p>
                  </div>
                  <div className="text-container">
                    <h4 className="description-text-style">
                      {t(
                        `CounselingResourcesDetails.counselingResource.timeRequired`
                      )}
                    </h4>
                    <p>
                      {state.counselingResource.timeRequired}{" "}
                      {state.counselingResource.timeRequired === 1
                        ? t(
                            `CounselingResourcesDetails.counselingResource.minute`
                          )
                        : t(
                            `CounselingResourcesDetails.counselingResource.minutes`
                          )}
                    </p>
                  </div>
                  <div className="text-container">
                    <h4 className="description-text-style">
                      {t(`CounselingResourcesDetails.counselingResource.state`)}
                    </h4>
                    <p>{state.counselingResource.state}</p>
                  </div>
                </div>
              </div>
              <div className="resources-assets-container">
                <div className="resource-uploaded-asset-container">
                  <p className="presentation-image-text">
                    {t(`CounselingResourcesDetails.overviewImage`)}
                  </p>
                  {state.counselingResource.imageUrl ? (
                    <img
                      src={state.counselingResource.imageUrl}
                      alt="resourceImageUrl"
                      className="counseling-resource-image-url"
                    />
                  ) : (
                    t(`CounselingResourcesDetails.overviewImageNotUploaded`)
                  )}
                </div>
                <div className="resource-uploaded-asset-container">
                  <p className="presentation-image-text">
                    {t(`CounselingResourcesDetails.resourceAsset`)}
                  </p>
                  {state.counselingResource.resourceUrl ? (
                    <div>
                      {state.counselingResource.resourceUrl.includes(
                        "video"
                      ) ? (
                        <video
                          width="auto"
                          height="240"
                          controls
                          className="video-style"
                        >
                          <source
                            src={state.counselingResource.resourceUrl}
                            type="video/mp4"
                          />
                          <track
                            src="captions_en.vtt"
                            kind="captions"
                            srcLang="en"
                            label="english_captions"
                          />
                        </video>
                      ) : state.counselingResource.resourceUrl.includes(
                          "pdf"
                        ) ? (
                        <a href={state.counselingResource.resourceUrl}>
                          <div className="pdf-image-url" />
                          <p className="pdf-text">
                            {t(`CounselingResourcesDetails.download`)}
                          </p>
                        </a>
                      ) : (
                        state.counselingResource.resourceUrl.includes(
                          "image"
                        ) &&
                        !state.counselingResource.resourceUrl.includes(
                          "pdf"
                        ) && (
                          <img
                            src={state.counselingResource.resourceUrl}
                            alt="resourceImageUrl"
                            className="counseling-resource-image-url"
                          />
                        )
                      )}
                    </div>
                  ) : (
                    t(
                      `CounselingResourcesDetails.noPresentationMaterialUploaded`
                    )
                  )}
                </div>
                <div className="resource-uploaded-asset-container">
                  <p className="presentation-image-text">
                    {t(`CounselingResourcesDetails.resourceAssetEnglish`)}
                  </p>
                  {state.counselingResource.resourceUrlEn ? (
                    <div>
                      {state.counselingResource.resourceUrlEn.includes(
                        "video"
                      ) ? (
                        <video
                          width="auto"
                          height="240"
                          controls
                          className="video-style"
                        >
                          <source
                            src={state.counselingResource.resourceUrlEn}
                            type="video/mp4"
                          />
                          <track
                            src="captions_en.vtt"
                            kind="captions"
                            srcLang="en"
                            label="english_captions"
                          />
                        </video>
                      ) : state.counselingResource.resourceUrlEn.includes(
                          "pdf"
                        ) ? (
                        <a href={state.counselingResource.resourceUrlEn}>
                          <div className="pdf-image-url" />
                          <p className="pdf-text">
                            {t(`CounselingResourcesDetails.download`)}
                          </p>
                        </a>
                      ) : (
                        state.counselingResource.resourceUrlEn.includes(
                          "image"
                        ) &&
                        !state.counselingResource.resourceUrlEn.includes(
                          "pdf"
                        ) && (
                          <img
                            src={state.counselingResource.resourceUrlEn}
                            alt="resourceImageUrl"
                            className="counseling-resource-image-url"
                          />
                        )
                      )}
                    </div>
                  ) : (
                    t(
                      `CounselingResourcesDetails.noPresentationMaterialUploaded`
                    )
                  )}
                </div>
              </div>
            </Paper>
          </div>
        </>
      );
    }

    return (
      <Loader
        text={t("CounselingResourcesDetails.loading")}
        style={{ marginTop: 40 }}
      />
    );
  };

  return <div className="counseling-wrapper">{mainGui()}</div>;
};

export default CounselingResourceDetails;
