import { useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import "./bosses.css";
import {
  Button,
  CircularProgress,
  FormControl,
  Modal,
  Paper,
  TextField,
} from "@material-ui/core";
import api from "../../../utils/api/v1";
import Spacer from "../../shared/Spacer";

interface Props {
  open: boolean;
  onClose: (id: string | null) => void;
  companyId: string;
}

interface State {
  error: boolean | string;
  loading: boolean;
}

// By supplying an optional boss object this will function as an Edit modal
const BossModal: VFC<Props> = ({ open, onClose, companyId }) => {
  const { t } = useTranslation();
  const [form, setForm] = useState({ name: "" });
  const [state, setState] = useState<State>({
    error: "",
    loading: false,
  });

  const handleClose = (id: string | null) =>
    onClose && onClose(typeof id === "string" ? id : null);

  const handleChange = ({
    target: { name, value },
  }: {
    target: { name: string; value: string };
  }) => {
    setForm({ ...form, [name]: value });

    const errorMessage =
      name === "name" && value === "" ? t("DepartmentModal.error.name") : "";
    setState({ ...state, error: errorMessage });
  };

  const handleSave = () => {
    if (!form.name || !form.name.length) {
      setState({ loading: false, error: t("DepartmentModal.error.name") });
      return;
    }
    setState({ ...state, loading: true, error: "" });
    api
      .createBoss(companyId, { ...form, companyId })
      .then(({ data }) => {
        setState({ ...state, loading: false, error: "" });
        handleClose(data.id);
      })
      .catch((e) => {
        const message = t("BossModal.error.default");
        setState({ loading: false, error: message });
        console.error(e);
      });
  };

  return (
    <Modal
      open={open}
      onClose={() => handleClose(null)}
      aria-labelledby="boss-modal-title"
      aria-describedby="boss-modal-description"
    >
      <Paper className="boss-wrapper">
        <h2 id="boss-modal-title">{t("BossModal.title")}</h2>
        <FormControl className="boss-form">
          <TextField
            label={t("BossModal.name")}
            inputMode="text"
            value={form.name}
            name="name"
            onChange={handleChange}
            error={Boolean(state.error)}
            helperText={state.error}
          />
        </FormControl>
        {state.loading && <CircularProgress style={{ marginTop: 20 }} />}
        <div className="buttons">
          <Spacer />
          <Button
            disabled={state.loading}
            variant="outlined"
            className="cancel"
            onClick={() => handleClose(null)}
          >
            {t("BossModal.cancel")}
          </Button>
          <Button
            disabled={state.loading}
            color="primary"
            variant="contained"
            onClick={handleSave}
          >
            {t("BossModal.save")}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

export default BossModal;
