import type { MouseEventHandler, VFC } from "react";
import "./MicroCourseResourcesTable.css";
import { useNavigate } from "react-router";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { FlexibleSizeLoader } from "../../shared/loader/FlexibleSizeLoader";
import type { ObjectKeys } from "../../../utils/api/apiInterfaces";

interface Row extends ObjectKeys {
  id: string;
  order: number;
  name: string;
  timeRequired: number;
}

interface Columns {
  field: string;
  headerName: string;
  width: number;
}

interface Props {
  columns: Array<Columns>;
  rows: Array<Row>;
  handleSortOrder: (id: string, order: number, type: string) => void;
  path: string;
  updateSortOrder: MouseEventHandler<HTMLButtonElement>;
  loading: boolean;
}

export const microCourseResourceTableSorting = {
  up: "Up",
  down: "Down",
};

export const MicroCourseResourcesTable: VFC<Props> = ({
  columns,
  rows,
  handleSortOrder,
  path,
  updateSortOrder,
  loading,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="micro-course-resources-table-wrapper">
      {loading ? (
        <div
          style={{ height: (rows.length + 1) * 53.02 + 68.5 }}
          className="micro-course-resources-table-loader-container"
        >
          <FlexibleSizeLoader
            text={`${t("MicroCourses.details.resourceList.updateSortOrder")}`}
            size={25}
          />
        </div>
      ) : (
        <>
          <table>
            <tr>
              {columns.map((c) => (
                <th
                  key={c.headerName}
                  style={{ width: c.width }}
                  className="micro-course-th"
                >
                  {c.headerName}
                </th>
              ))}
            </tr>
            {rows.map((r) => (
              <tr className="micro-course-resource-table-row">
                {columns.map((c) => (
                  <td className="micro-course-td">
                    <div
                      role="button"
                      tabIndex={-1}
                      onKeyDown={() => navigate(`/${path}/${r.id}`)}
                      onClick={() => navigate(`/${path}/${r.id}`)}
                    >
                      {r[c.field]}
                    </div>
                  </td>
                ))}
                <td className="sort-arrow-container micro-course-td">
                  <button
                    type="button"
                    className="arrow up"
                    onClick={() =>
                      handleSortOrder(
                        r.id,
                        r.order,
                        microCourseResourceTableSorting.up
                      )
                    }
                  />
                  <button
                    type="button"
                    className="arrow down"
                    onClick={() =>
                      handleSortOrder(
                        r.id,
                        r.order,
                        microCourseResourceTableSorting.down
                      )
                    }
                  />
                </td>
              </tr>
            ))}
          </table>
          <div className="change-order-button-container">
            <Button
              color="primary"
              variant="contained"
              onClick={updateSortOrder}
            >
              {t("MicroCourses.details.resourceList.changeSortOrder")}`
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
