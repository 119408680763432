import { Page, Text, View } from "@react-pdf/renderer";
import type { FC } from "react";
import { useTranslation } from "react-i18next";
import type { ApiResponseAutomaticAnalysisModel } from "../../../../../utils/api/apiInterfaces";
import { CompanyStatisticsCircleDiagram } from "./CompanyStatisticsCircleDiagram";
import { AutomaticAnalysisBarChart } from "../AutomaticAnalysisBarChart";
import { companyStatisticsStyling } from "./CompanyStatisticsStyling";
import {
  getLowestHighScore,
  getExactPercentageResults,
} from "../UseAutomaticAnalysisCalculationHelper";
import { lowestCategoryName } from "../LowestCategoryName";
import {
  getCycleSeason,
  getCycleYear,
} from "../../../../../utils/helpers/dateConverters";
import { getLanguage } from "../../../../../i18n";

interface Props {
  automaticAnalysisResult: ApiResponseAutomaticAnalysisModel;
  cycleDate: string;
}

export const CompanyStatistics: FC<Props> = ({
  automaticAnalysisResult,
  cycleDate,
}) => {
  const { t } = useTranslation();
  const { companyResult } = automaticAnalysisResult;
  const currentLanguage = getLanguage();

  const companyPercentageResults = getExactPercentageResults(companyResult);
  const season = getCycleSeason(cycleDate);
  const lowestCategoryNames = lowestCategoryName(
    companyResult.physicalAverage,
    companyResult.stressAverage,
    companyResult.developmentAverage
  );

  const highScores = [
    companyPercentageResults.developmentPercentages.high,
    companyPercentageResults.physicalPercentages.high,
    companyPercentageResults.stressPercentages.high,
  ];

  return (
    <Page size="A4" style={companyStatisticsStyling.page} object-fit="fill">
      <View style={companyStatisticsStyling.parentDiv}>
        <View style={companyStatisticsStyling.horizontalLineTop} />
        <View style={companyStatisticsStyling.parentDivStatistic}>
          <View style={companyStatisticsStyling.header}>
            <Text style={companyStatisticsStyling.headerText}>
              {t("CompanyStatistics.statistics")}
            </Text>
          </View>
          <View style={companyStatisticsStyling.indexDiv}>
            <View>
              <View>
                <Text style={companyStatisticsStyling.indexHeader}>
                  {t("CompanyStatistics.companyStatisticsP1")}
                </Text>
                <Text style={companyStatisticsStyling.indexHeader}>
                  {t("CompanyStatistics.companyStatisticsP2")}
                </Text>
              </View>
              <View style={companyStatisticsStyling.descriptionsDiv}>
                <Text style={companyStatisticsStyling.indexDescription}>
                  {t("CompanyStatistics.analysisFrom")}{" "}
                  {currentLanguage === "sv"
                    ? automaticAnalysisResult.companyResult.nameSv
                    : automaticAnalysisResult.companyResult.nameEn}
                </Text>
                <Text style={companyStatisticsStyling.indexDescription}>
                  {t("CompanyStatistics.analysisType")}{" "}
                  {t(`CompanyResult.${season}`)} {getCycleYear(cycleDate)}
                </Text>
              </View>
            </View>
            <View style={companyStatisticsStyling.averageIndexDiv}>
              <View>
                <Text style={companyStatisticsStyling.index}>
                  {automaticAnalysisResult.companyResult.totalAverage.toFixed(
                    1
                  )}
                </Text>
              </View>
              <View>
                <Text style={companyStatisticsStyling.indexFooter}>
                  {t("CompanyStatistics.wellBeingIndex")}
                </Text>
              </View>
              <View style={companyStatisticsStyling.indexHorizontalLine} />
            </View>
            <View>
              <Text style={companyStatisticsStyling.averageIndexDescription}>
                {getLowestHighScore(highScores) === 0
                  ? `${t(`CompanyStatistics.statisticsP1v2`)}`
                  : `${Math.round(getLowestHighScore(highScores))} ${t(
                      `CompanyStatistics.statisticsP1`
                    )}`}{" "}
                {t("CompanyStatistics.statisticsP2")}{" "}
                {lowestCategoryNames.map((lcn, i, { length }) => {
                  if (length - 1 === i) {
                    return `${t(`CompanyStatistics.${lcn}`)} `;
                  }
                  if ((length === 2 && i === 0) || (length === 3 && i === 1)) {
                    return `${t(`CompanyStatistics.${lcn}`)} ${t(
                      `CompanyStatistics.statisticsP3`
                    )}`;
                  }
                  return `${t(`CompanyStatistics.${lcn}`)}, `;
                })}
                {t("CompanyStatistics.statisticsP4")}
              </Text>
            </View>
          </View>
          <View style={companyStatisticsStyling.circleDiv}>
            <View>
              <Text style={companyStatisticsStyling.circleDivHeader}>
                {t("CompanyStatistics.wellBeingIndexDistribution")}
              </Text>
            </View>
            <View>
              <Text style={companyStatisticsStyling.circleHeader}>
                {t("CompanyStatistics.wellBeingDevelopmentDistribution")}
              </Text>
              <View style={companyStatisticsStyling.developmentCircle}>
                <CompanyStatisticsCircleDiagram
                  low={companyPercentageResults.developmentPercentages.low}
                  medium={
                    companyPercentageResults.developmentPercentages.medium
                  }
                  high={companyPercentageResults.developmentPercentages.high}
                />
              </View>
            </View>
            <View>
              <Text style={companyStatisticsStyling.circleHeader}>
                {t("CompanyStatistics.wellBeingStressDistribution")}
              </Text>
              <CompanyStatisticsCircleDiagram
                low={companyPercentageResults.stressPercentages.low}
                medium={companyPercentageResults.stressPercentages.medium}
                high={companyPercentageResults.stressPercentages.high}
              />
            </View>
            <View>
              <Text style={companyStatisticsStyling.circleHeader}>
                {t("CompanyStatistics.wellBeingPhysicalDistribution")}
              </Text>
              <CompanyStatisticsCircleDiagram
                low={companyPercentageResults.physicalPercentages.low}
                medium={companyPercentageResults.physicalPercentages.medium}
                high={companyPercentageResults.physicalPercentages.high}
              />
            </View>
          </View>
        </View>
        <View style={companyStatisticsStyling.horizontalLineBottom} />
        <View style={companyStatisticsStyling.barchartHeaderDiv}>
          <Text style={companyStatisticsStyling.barchartHeader}>
            {t("CompanyStatistics.wellBeingAreas")}
          </Text>
        </View>
        <View style={companyStatisticsStyling.barchartDiv}>
          <View>
            <Text style={companyStatisticsStyling.barchartText}>
              {t("CompanyStatistics.wellBeingDevelopmentDistribution")}
            </Text>
            <Text style={companyStatisticsStyling.barchartText}>
              {t("CompanyStatistics.wellBeingPhysicalDistribution")}
            </Text>
            <Text style={companyStatisticsStyling.barchartText}>
              {t("CompanyStatistics.wellBeingStressDistribution")}
            </Text>
          </View>
          <View>
            <AutomaticAnalysisBarChart
              developmentAverage={
                automaticAnalysisResult.companyResult.developmentAverage
              }
              stressAverage={
                automaticAnalysisResult.companyResult.stressAverage
              }
              physicalAverage={
                automaticAnalysisResult.companyResult.physicalAverage
              }
            />
          </View>
        </View>
      </View>
    </Page>
  );
};
