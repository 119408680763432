import type { VFC } from "react";
import { useTranslation } from "react-i18next";
import { Page, View, Text } from "@react-pdf/renderer";
import { questionAverageResultStyling } from "./QuestionAverageResultStyling";
import type { ApiResponseQuestionResult } from "../../../../../../utils/api/apiInterfaces";
import { getLanguage } from "../../../../../../i18n";

interface Props {
  questions: ApiResponseQuestionResult[];
}

const resolveBackgroundColor = (answerValue: number) => {
  if (answerValue >= 7) return "#068466";
  if (answerValue > 4) return "#FDCF5B";
  return "#EE7362";
};

export const QuestionAverageResult: VFC<Props> = ({ questions }) => {
  const { t } = useTranslation();

  const lng = getLanguage();
  return (
    <Page size="A4" style={questionAverageResultStyling.page} object-fit="fill">
      <View style={questionAverageResultStyling.questionAverageMainContainer}>
        <View
          style={questionAverageResultStyling.questionAverageHorizontalLine}
        />
        <Text style={questionAverageResultStyling.questionAverageStatistics}>
          {t("CompanyStatistics.statistics")}
        </Text>
        <View>
          <Text style={questionAverageResultStyling.heading}>
            {t("QuestionResult.title")}
          </Text>
          <Text style={questionAverageResultStyling.aboutText}>
            {t("QuestionResult.description")}
          </Text>
        </View>
        <View style={questionAverageResultStyling.indexHeader}>
          <Text style={questionAverageResultStyling.indexHeaderText}>
            {t("QuestionResult.companyAverage")}
          </Text>
        </View>
        <View style={questionAverageResultStyling.parentContainer}>
          <View style={questionAverageResultStyling.questionContainer}>
            {questions.map(({ nameSv, nameEn, questionId }) => (
              <View
                style={questionAverageResultStyling.mappedList}
                key={questionId}
              >
                <Text style={questionAverageResultStyling.mappedText}>
                  {lng === "sv" ? nameSv : nameEn}
                </Text>
              </View>
            ))}
          </View>
          <View style={questionAverageResultStyling.indexContainer}>
            {questions.map(({ averageAnswerValue, questionId }) => (
              <View
                key={questionId}
                style={{
                  backgroundColor: `${resolveBackgroundColor(
                    averageAnswerValue
                  )}`,
                  margin: "0.5",
                  width: "100%",
                  height: "25px",
                }}
              >
                <Text style={questionAverageResultStyling.indexText}>
                  {averageAnswerValue}
                </Text>
              </View>
            ))}
          </View>
        </View>
      </View>
    </Page>
  );
};
