import { ChangeEvent, useEffect, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import "./Companies.css";
import {
  Typography,
  Divider,
  Button,
  Paper,
  TextField,
  IconButton,
  Checkbox,
  DialogTitle,
  Dialog,
} from "@material-ui/core";
import { useNavigate, useParams } from "react-router";
import { Cancel, Save } from "@material-ui/icons";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Spacer from "../shared/Spacer";
import api from "../../utils/api/v1";
import Loader from "../shared/loader/Loader";
import { BackButton } from "../../UI-Components/Buttons/Buttons";
import type {
  ApiCompanyResponse,
  CompanyBossModel,
  CompanyDepartmentModel,
  CompanyFormDataModel,
  CompanyTitleModel,
} from "../../utils/api/apiInterfaces";
import { MissingRequiredParamError } from "../errorHandling/MissingRequiredParamError";

interface State {
  loading: boolean;
  error: boolean;
  errorMessage: string;
  company: ApiCompanyResponse | null;
}

interface ExtendedCompanyDepartmentModel extends CompanyDepartmentModel {
  changed?: boolean;
}

interface ExtendedCompanyTitleModel extends CompanyTitleModel {
  changed?: boolean;
}

interface ExtendedCompanyBossModel extends CompanyBossModel {
  changed?: boolean;
}

type Departments = Array<ExtendedCompanyDepartmentModel>;
type Titles = Array<ExtendedCompanyTitleModel>;
type Bosses = Array<ExtendedCompanyBossModel>;
type CompanyForm = Pick<
  ApiCompanyResponse,
  | "name"
  | "description"
  | "address"
  | "hasDepartments"
  | "hasTitles"
  | "hasBosses"
>;

const CompanyEdit: VFC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { companyId } = useParams();
  const [state, setState] = useState<State>({
    loading: false,
    error: false,
    errorMessage: "",
    company: null,
  });
  const [form, setForm] = useState<CompanyForm>({
    name: "",
    description: "",
    address: "",
    hasDepartments: false,
    hasTitles: false,
    hasBosses: false,
  });

  const [departments, setDepartments] = useState<Departments>([]);
  const [departmentsToDelete, setDepartmentsToDelete] = useState<Departments>(
    []
  );
  const [titles, setTitles] = useState<Titles>([]);
  const [titlesToDelete, setTitlesToDelete] = useState<Titles>([]);
  const [bosses, setBosses] = useState<Bosses>([]);
  const [bossesToDelete, setBossesToDelete] = useState<Bosses>([]);
  const [alertDialog, setAlertDialog] = useState(false);
  const [readyToSave, setReadyToSave] = useState({ value: 0 });

  useEffect(() => {
    getCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!companyId) {
    return <MissingRequiredParamError missingParam="companyId" />;
  }

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [event.target.name]: event.target.checked,
    });
  };

  const doneUpdated = () => {
    setReadyToSave({ value: readyToSave.value + 1 });
    if (readyToSave.value === 4) {
      navigate(`/companies/${companyId}`);
    }
  };

  const handleOpenAlertDialog = () => {
    setReadyToSave({ value: 0 });
    setAlertDialog(true);
  };

  const getDepartments = (hasDepartments: boolean) => {
    if (hasDepartments) {
      api
        .getDepartments(companyId)
        .then((result) => {
          setDepartments(result.data);
        })
        .catch((err) => {
          console.error(err);
          setState({ ...state, error: true, loading: false });
        });
    }
  };

  const getTitles = (hasTitles: boolean) => {
    if (hasTitles) {
      api
        .getTitles(companyId)
        .then((result) => {
          setTitles(result.data);
        })
        .catch((err) => {
          console.error(err);
          setState({ ...state, error: true, loading: false });
        });
    }
  };

  const getBosses = (hasBosses: boolean) => {
    if (hasBosses) {
      api
        .getBosses(companyId)
        .then((result) => {
          setBosses(result.data);
        })
        .catch((error) => {
          console.error(error);
          setState({ ...state, error: true, loading: false });
        });
    }
  };

  const getCompany = () => {
    setState({ ...state, loading: true, error: false });
    api
      .getCompany(companyId)
      .then((result) => {
        setForm(result.data);
        getDepartments(result.data.hasDepartments);
        getTitles(result.data.hasTitles);
        getBosses(result.data.hasBosses);
        setState({
          ...state,
          company: result.data,
          loading: false,
          error: false,
        });
      })
      .catch((err) => {
        console.log(err);
        setState({ ...state, error: true, loading: false });
      });
  };

  const updateDepartments = () => {
    const departmentsToUpdate: {
      departments: Array<ExtendedCompanyDepartmentModel>;
    } = { departments: [] };
    departments.forEach((department) => {
      if (Object.keys(department).includes("changed") && department.changed) {
        departmentsToUpdate.departments.push(department);
      }
    });

    if (departmentsToUpdate.departments.length > 0) {
      api
        .updateDepartments(companyId, departmentsToUpdate)
        .then((result) => {
          setDepartments(result.data);
          doneUpdated();
        })
        .catch((err) => {
          console.error(err);
          setState({ ...state, error: true, loading: false });
        });
    } else {
      doneUpdated();
    }
  };

  const updateTitles = () => {
    const titlesToUpdate: { titles: Array<ExtendedCompanyTitleModel> } = {
      titles: [],
    };

    titles.forEach((title) => {
      if (Object.keys(title).includes("changed") && title.changed) {
        titlesToUpdate.titles.push(title);
      }
    });

    if (titlesToUpdate.titles.length > 0) {
      api
        .updateTitles(companyId, titlesToUpdate)
        .then((result) => {
          setTitles(result.data);
          doneUpdated();
        })
        .catch((err) => {
          console.error(err);
          setState({ ...state, error: true, loading: false });
        });
    } else {
      doneUpdated();
    }
  };

  const updateBosses = () => {
    const bossesToUpdate: { bosses: Array<ExtendedCompanyBossModel> } = {
      bosses: [],
    };
    bosses.forEach((boss) => {
      if (Object.keys(boss).includes("changed") && boss.changed) {
        bossesToUpdate.bosses.push(boss);
      }
    });

    if (bossesToUpdate.bosses.length > 0) {
      api
        .updateBosses(companyId, bossesToUpdate)
        .then((result) => {
          setBosses(result.data);
          doneUpdated();
        })
        .catch((error) => {
          console.error(error);
          setState({ ...state, error: true, loading: false });
        });
    } else {
      doneUpdated();
    }
  };

  const deleteDepartments = () => {
    if (departmentsToDelete.length > 0) {
      api
        .deleteDepartments(companyId, {
          departments: departmentsToDelete,
        })
        .then(() => {
          doneUpdated();
        })
        .catch((err) => {
          console.error(err);
          setState({
            ...state,
            errorMessage: err.response.data.message,
            loading: false,
          });
          handleOpenAlertDialog();
        });
    } else {
      doneUpdated();
    }
  };

  const deleteTitles = () => {
    if (titlesToDelete.length > 0) {
      api
        .deleteTitles(companyId, { titles: titlesToDelete })
        .then(() => {
          doneUpdated();
        })
        .catch((err) => {
          console.error(err);
          setState({
            ...state,
            errorMessage: err.response.data.message,
            loading: false,
          });
          handleOpenAlertDialog();
        });
    } else {
      doneUpdated();
    }
  };

  const deleteBosses = () => {
    if (bossesToDelete.length > 0) {
      api
        .deleteBosses(companyId, { bosses: bossesToDelete })
        .then(() => {
          doneUpdated();
        })
        .catch((error) => {
          console.error(error);
          setState({ ...state, loading: false });
          handleOpenAlertDialog();
        });
    } else {
      doneUpdated();
    }
  };

  const handleSave = () => {
    // TODO: Move updateCompany logic into own function for consistency and modularity sake.
    if (
      form.name?.trim() === "" ||
      departments.some((department) => department.name.trim() === "") ||
      titles.some((title) => title.name.trim() === "") ||
      bosses.some((boss) => boss.name.trim() === "")
    ) {
      return;
    }

    setState({ ...state, loading: true, error: false });
    const dataForUpdate: CompanyFormDataModel = {
      name: form.name ?? "",
      description: form.description ?? "",
      address: form.address ?? "",
      hasBosses: form.hasBosses ?? "",
      hasDepartments: form.hasDepartments ?? "",
      hasTitles: form.hasTitles ?? "",
    };
    api
      .updateCompany(companyId, dataForUpdate)
      .then(() => {
        deleteDepartments();
        deleteTitles();
        deleteBosses();
        updateDepartments();
        updateTitles();
        updateBosses();
        setState({ ...state, error: false, loading: false });
      })
      .catch((err) => {
        console.error(err);
        setState({ ...state, error: true, loading: false });
      });
  };

  const handleCloseAlertDialog = () => {
    setTitlesToDelete([]);
    setDepartmentsToDelete([]);
    getCompany();
    setAlertDialog(false);
  };

  const handleChange = ({
    target: { name, value },
  }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleDepartmentChange = (index: number, name: string) => {
    const newDeps = [...departments];
    const existingDepartment = departments[index];

    if (existingDepartment) {
      newDeps[index] = {
        id: existingDepartment.id,
        name,
        changed: true,
        companyId: existingDepartment.companyId,
      };
      setDepartments(newDeps);
    }
  };

  const removeDepartment = (index: number) => {
    const newDepartmentsToDelete = [...departmentsToDelete];
    const newDeps = departments
      .slice(0, index)
      .concat(departments.slice(index + 1, departments.length));

    const existingDepartment = departments[index];

    if (existingDepartment) {
      newDepartmentsToDelete.push(existingDepartment);
      setDepartments(newDeps);
      setDepartmentsToDelete(newDepartmentsToDelete);
    }
  };

  const handleBossChange = (index: number, name: string) => {
    const newBosses = [...bosses];
    const existingBoss = newBosses[index];
    if (existingBoss) {
      newBosses[index] = {
        id: existingBoss.id,
        name,
        changed: true,
        companyId: existingBoss.companyId,
      };
      setBosses(newBosses);
    }
  };

  const removeBoss = (index: number) => {
    const newBossesToDelete = [...bossesToDelete];
    const newBosses = bosses
      .slice(0, index)
      .concat(bosses.slice(index + 1, bosses.length));

    const existingBoss = bosses[index];

    if (existingBoss) {
      newBossesToDelete.push(existingBoss);
      setBosses(newBosses);
      setBossesToDelete(newBossesToDelete);
    }
  };

  const handlePositionChange = (index: number, name: string) => {
    const newPos = [...titles];
    const existingTitle = titles[index];

    if (existingTitle) {
      newPos[index] = {
        id: existingTitle.id,
        name,
        changed: true,
        companyId: existingTitle.companyId,
      };
      setTitles(newPos);
    }
  };

  const removePosition = (index: number) => {
    const newTitlesToDelete = [...titlesToDelete];
    const newDeps = titles
      .slice(0, index)
      .concat(titles.slice(index + 1, titles.length));

    const existingTitle = titles[index];
    if (existingTitle) {
      newTitlesToDelete.push(existingTitle);
      setTitles(newDeps);
      setTitlesToDelete(newTitlesToDelete);
    }
  };

  const mainGui = () => {
    if (state.loading)
      return (
        <Loader text={t("CompanyDetails.loading")} style={{ marginTop: 40 }} />
      );
    if (!state.loading && form) {
      return (
        <>
          <div className="section-toolbar">
            <BackButton />
            <TextField
              className="company-name"
              label={t("CompanyDetails.name")}
              data-testid="companyNameInput"
              name="name"
              value={form.name}
              required
              error={form.name === ""}
              helperText={
                form.name === ""
                  ? t("missingMandatoryInput", {
                      field: t("CompanyDetails.name"),
                    })
                  : ""
              }
              onChange={handleChange}
            />

            <Spacer />
            <Button
              color="primary"
              variant="contained"
              startIcon={<Save />}
              onClick={handleSave}
              data-testid="saveButton"
            >
              {t("CompanyDetails.save")}
            </Button>
          </div>
          <Divider />
          <div className="companies-content">
            <Paper className="company-details">
              <Typography variant="h6">{t("CompanyDetails.about")}</Typography>
              <TextField
                className="company-edit-category"
                label={t("CompanyDetails.about")}
                data-testid="descriptionInput"
                name="description"
                value={form.description}
                multiline
                fullWidth
                variant="outlined"
                onChange={handleChange}
              />
              <Typography variant="h6">
                {t("CompanyDetails.address")}
              </Typography>
              <TextField
                className="company-edit-category"
                label={t("CompanyDetails.address")}
                name="address"
                value={form.address}
                multiline
                fullWidth
                variant="outlined"
                onChange={handleChange}
              />

              <Dialog
                onClose={handleCloseAlertDialog}
                aria-labelledby="simple-dialog-title"
                open={alertDialog}
              >
                <DialogTitle
                  id="simple-dialog-title"
                  className="companies-dialogTitle-warning-style"
                >
                  {t("CompanyEdit.dialogAlertTitle")}
                </DialogTitle>
                <Typography className="companies-dialogText-warning-style">
                  {state.errorMessage}
                </Typography>
                <Button
                  onClick={handleCloseAlertDialog}
                  color="primary"
                  variant="outlined"
                  fullWidth
                >
                  {t("CompanyEdit.dialogAlertClose")}
                </Button>
              </Dialog>

              {state.company && state.company.hasDepartments
                ? [
                    <Typography variant="h6" key="departments">
                      {t("CompanyDetails.departments")}
                    </Typography>,
                    <div
                      className="company-edit-category list"
                      key="department_list"
                      data-testid="department_list"
                    >
                      {departments.map((department, index) => (
                        <div
                          className="list-item"
                          key={department.id}
                          data-testid="departmentListItem"
                        >
                          <TextField
                            key={department.id}
                            name="department"
                            value={department.name}
                            variant="outlined"
                            fullWidth
                            required
                            error={department.name === ""}
                            helperText={
                              department.name === ""
                                ? t("missingMandatoryInput", {
                                    field: t("CompanyDetails.departmentName"),
                                  })
                                : ""
                            }
                            onChange={({ target: { value } }) =>
                              handleDepartmentChange(index, value)
                            }
                          />
                          <IconButton
                            key={`icon_${companyId}`}
                            onClick={() => removeDepartment(index)}
                            className="error"
                            data-testid="deleteDepartmentButton"
                          >
                            <Cancel />
                          </IconButton>
                        </div>
                      ))}
                    </div>,
                  ]
                : null}

              {state.company && state.company.hasTitles
                ? [
                    <Typography variant="h6">
                      {t("CompanyDetails.titles")}
                    </Typography>,
                    <div
                      className="company-edit-category list"
                      data-testid="titleList"
                    >
                      {titles.map((title, index) => (
                        <div
                          className="list-item"
                          key={title.id}
                          data-testid="titleListItem"
                        >
                          <TextField
                            key={title.id}
                            value={title.name}
                            variant="outlined"
                            fullWidth
                            required
                            error={title.name === ""}
                            helperText={
                              title.name === ""
                                ? t("missingMandatoryInput", {
                                    field: t("CompanyDetails.titleName"),
                                  })
                                : ""
                            }
                            onChange={({ target: { value } }) =>
                              handlePositionChange(index, value)
                            }
                          />
                          <IconButton
                            onClick={() => removePosition(index)}
                            className="error"
                            data-testid="deleteTitleButton"
                          >
                            <Cancel />
                          </IconButton>
                        </div>
                      ))}
                    </div>,
                  ]
                : null}

              {state.company && state.company.hasBosses
                ? [
                    <Typography variant="h6" key="bosses">
                      {t("CompanyDetails.bosses")}
                    </Typography>,
                    <div
                      className="company-edit-category list"
                      key="bosses_list"
                      data-testid="bosses_list"
                    >
                      {bosses.map((boss, index) => (
                        <div className="list-item" key={boss.id}>
                          <TextField
                            key={boss.id}
                            name="boss"
                            value={boss.name}
                            variant="outlined"
                            fullWidth
                            required
                            error={boss.name === ""}
                            helperText={
                              boss.name === ""
                                ? t("missingMandatoryInput", {
                                    field: t("CompanyDetails.bossName"),
                                  })
                                : ""
                            }
                            onChange={({ target: { value } }) =>
                              handleBossChange(index, value)
                            }
                          />
                          <IconButton
                            key={`icon_${companyId}`}
                            onClick={() => removeBoss(index)}
                            className="error"
                            data-testid="delete_boss_button"
                          >
                            <Cancel />
                          </IconButton>
                        </div>
                      ))}
                    </div>,
                  ]
                : null}
              <Spacer />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={form.hasDepartments}
                    onChange={handleCheckboxChange}
                    color="primary"
                    name="hasDepartments"
                  />
                }
                label={t("CompanyDetails.departments")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={form.hasTitles}
                    onChange={handleCheckboxChange}
                    color="primary"
                    name="hasTitles"
                    data-testid="hasTitlesCheckbox"
                  />
                }
                label={t("CompanyDetails.titles")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={form.hasBosses}
                    onChange={handleCheckboxChange}
                    color="primary"
                    name="hasBosses"
                  />
                }
                label={t("CompanyDetails.bosses")}
              />
            </Paper>
          </div>
        </>
      );
    }
    return (
      <Loader text={t("CompanyDetails.loading")} style={{ marginTop: 40 }} />
    );
  };

  return <div className="companies-wrapper">{mainGui()}</div>;
};

export default CompanyEdit;
