import { useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import "./CounselingEmailModal.css";
import {
  Button,
  CircularProgress,
  FormControl,
  Modal,
  Paper,
  TextField,
} from "@material-ui/core";
import api from "../../../utils/api/v1";
import Spacer from "../../shared/Spacer";
import Alert from "../../shared/Alert";
import { emailValidation } from "../../../utils/validation/emailValidation";

interface Props {
  open: boolean;
  onClose: (email: string | null) => void;
  companyId: string;
}

const CounselingEmailModal: VFC<Props> = ({ open, onClose, companyId }) => {
  const { t } = useTranslation();
  const [form, setForm] = useState({
    counselingEmail: "",
  });
  const [state, setState] = useState({
    error: "",
    loading: false,
  });

  const handleClose = (counselingEmail: string | null) =>
    onClose && onClose(counselingEmail);

  const handleChange = ({
    target: { name, value },
  }: {
    target: { name: string; value: string };
  }) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSave = () => {
    if (
      !form.counselingEmail ||
      !form.counselingEmail.length ||
      !emailValidation(form.counselingEmail)
    ) {
      setState({ loading: false, error: t("CounselingModal.error.email") });
      return;
    }
    setState({ ...state, loading: true, error: "" });
    api
      .addCounselingEmailToCompany(companyId, form)
      .then(() => {
        setState({ ...state, loading: false, error: "" });
        handleClose(form.counselingEmail);
      })
      .catch((err) => {
        console.error(err);
        setState({
          ...state,
          loading: false,
          error: t("CounselingModal.error.default"),
        });
      });
  };

  return (
    <Modal
      open={open}
      onClose={() => handleClose(null)}
      aria-labelledby="counseling-modal-title"
      aria-describedby="counseling-modal-description"
    >
      <Paper className="counseling-modal-wrapper">
        <h2 id="counseling-modal-title">{t("CounselingModal.title")}</h2>
        <FormControl className="counseling-form">
          <TextField
            label={t("CounselingModal.email")}
            inputMode="text"
            value={form.counselingEmail}
            name="counselingEmail"
            onChange={handleChange}
          />
        </FormControl>
        {state.loading && <CircularProgress style={{ marginTop: 20 }} />}
        {state.error && <Alert style={{ marginTop: 20 }}>{state.error}</Alert>}
        <div className="counseling-modal-buttons">
          <Spacer />
          <Button
            disabled={state.loading}
            variant="outlined"
            className="cancel"
            onClick={() => handleClose(null)}
          >
            {t("CounselingModal.cancel")}
          </Button>
          <Button
            disabled={state.loading}
            color="primary"
            variant="contained"
            onClick={handleSave}
          >
            {t("CounselingModal.save")}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

export default CounselingEmailModal;
