import { useEffect, useState, VFC } from "react";
import "./CheckboxSelectionGrid.css";
import {
  DataGrid,
  GridCallbackDetails,
  GridSelectionModel,
  GridValidRowModel,
} from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import type { Theme } from "@mui/material/styles";

function customCheckbox(theme: Theme) {
  return {
    "& .MuiCheckbox-root svg": {
      width: 16,
      height: 16,
      border: `1px solid ${
        theme.palette.mode === "light" ? "#d9d9d9" : "rgb(67, 67, 67)"
      }`,
      borderRadius: 2,
    },
    "& .MuiCheckbox-root svg path": {
      display: "none",
    },
    "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
      backgroundColor: "#068466",
      borderColor: "#068466",
    },
    "& .MuiCheckbox-root.Mui-checked:after": {
      position: "absolute",
      display: "table",
      border: "2px solid #fff",
      borderTop: 0,
      borderLeft: 0,
      transform: "rotate(45deg) translate(-50%,-50%)",
      opacity: 1,
      transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
      content: '""',
      top: "49%",
      left: "38.5%",
      width: 4,
      height: 8,
    },
    "& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after":
      {
        width: 8,
        height: 8,
        backgroundColor: "#1890ff",
        transform: "none",
        top: "39%",
        border: 0,
      },
    "& .MuiDataGrid-row.Mui-selected": {
      backgroundColor: "rgba(6, 132, 102, 0.08)",
    },
  };
}

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  color:
    theme.palette.mode === "light"
      ? "rgba(0,0,0,.85)"
      : "rgba(255,255,255,0.85)",
  WebkitFontSmoothing: "auto",
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderRight: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
  },
  ...customCheckbox(theme),
}));

export type Columns<T extends string> = Array<{
  field: string;
  hide?: boolean;
  headerName?: string;
  width?: number;
  valueGetter?: (params: { row: { [category in T]: any } }) => string;
}>;

interface Props {
  rows: Array<GridValidRowModel>;
  columns: Columns<string>;
  pageSize: number;
  height?: number;
  loading: boolean;
  updateSelection: (
    selectionModel: GridSelectionModel,
    details: GridCallbackDetails
  ) => void;
}
const CheckboxSelectionGrid: VFC<Props> = ({
  rows,
  columns,
  pageSize,
  height,
  loading,
  updateSelection,
}) => {
  const [width, setWidth] = useState<string | number>("100%");

  const handleResize = () => {
    if (window.innerWidth >= 960) {
      setWidth(window.innerWidth - 260);
    } else {
      setWidth(window.innerWidth - 20);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  return (
    <div style={{ display: "flex", height, width }}>
      <StyledDataGrid
        className="data-grid-style"
        columns={columns}
        rows={rows}
        pageSize={pageSize}
        loading={loading}
        autoHeight
        checkboxSelection
        disableSelectionOnClick
        onSelectionModelChange={updateSelection}
      />
    </div>
  );
};

export default CheckboxSelectionGrid;
