import { ChangeEvent, useEffect, useState, VFC, FC } from "react";
import { useTranslation } from "react-i18next";
import "./Companies.css";
import {
  Typography,
  Tabs,
  Tab,
  Divider,
  Box,
  Button,
  Paper,
  CircularProgress,
  DialogTitle,
  Dialog,
  DialogActions,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from "react-router";
import { Delete, Edit, ArrowRightTwoTone } from "@material-ui/icons";
import Employees from "./employees/Employees";
import Spacer from "../shared/Spacer";
import api from "../../utils/api/v1";
import Loader from "../shared/loader/Loader";
import Cycles from "./cycles/Cycles";
import TitleModal from "./titles/TitleModal";
import DepartmentModal from "./departments/DepartmentModal";
import BossModal from "./bosses/BossModal";
import CounselingEmailModal from "./counselingEmail/CounselingEmailModal";
import CounselingTextModal from "./counselingText/CounselingTextModal";
import { BackButton } from "../../UI-Components/Buttons/Buttons";
import type {
  ApiCompanyResponse,
  ApiResponseCompanyBossModel,
  ApiResponseCompanyDepartmentModel,
  ApiResponseCompanyTitleModel,
} from "../../utils/api/apiInterfaces";
import { MissingRequiredParamError } from "../errorHandling/MissingRequiredParamError";

interface State {
  loading: boolean;
  error: boolean;
  company?: ApiResponseCompanyTitleModel | null;
}

let timer: NodeJS.Timeout | undefined;

const CompanyDetails: VFC = () => {
  const { t } = useTranslation();
  const { companyId } = useParams();
  const [tab, setTab] = useState(0);
  const [company, setCompany] = useState<ApiCompanyResponse | null>(null);
  const [departments, setDepartments] =
    useState<Array<ApiResponseCompanyDepartmentModel> | null>(null);
  const [titles, setTitles] =
    useState<Array<ApiResponseCompanyTitleModel> | null>(null);
  const [bosses, setBosses] =
    useState<Array<ApiResponseCompanyBossModel> | null>(null);
  const [departmentModal, setDepartmentModal] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [bossModal, setBossModal] = useState(false);
  const [counselingEmailModal, setCounselingEmailModal] = useState(false);
  const [counselingTextModal, setCounselingTextModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false);
  const [timerEnded, setTimerEnded] = useState(false);
  const [companyHasEmployees, setCompanyHasEmployees] = useState(true);
  const [companyHasCycles, setCompanyHasCycles] = useState(true);
  const [state, setState] = useState<State>({
    loading: false,
    error: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    getEmployeesAndCycles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!companyId) {
    return <MissingRequiredParamError missingParam="companyId" />;
  }

  const getEmployeesAndCycles = () => {
    setState({ ...state, loading: true });
    Promise.all([api.getEmployees(companyId), api.getCycles(companyId)])
      .then((res) => {
        /* We do this to check if we are allowed to delete a company, 
        which is when there are no employees or cycles connected to 
        the company */
        if (res[0].data.length === 0) {
          setCompanyHasEmployees(false);
        } else if (res[0].data.length > 0) setCompanyHasEmployees(true);

        if (res[1].data.length === 0) {
          setCompanyHasCycles(false);
        } else if (res[1].data.length > 0) {
          setCompanyHasCycles(true);
        }

        setState({ ...state, loading: false });
      })
      .catch((err) => {
        console.error(err);
        setState({ ...state, error: true, loading: false });
      });
  };

  const getDepartments = (hasDepartments: boolean) => {
    if (hasDepartments) {
      api.getDepartments(companyId).then(({ data }) => {
        setDepartments(data);
      });
    } else {
      setDepartments([]);
    }
  };

  const getTitles = (hasTitles: boolean) => {
    if (hasTitles) {
      api.getTitles(companyId).then(({ data }) => {
        setTitles(data);
      });
    } else {
      setTitles([]);
    }
  };

  const getBosses = (hasBosses: boolean) => {
    if (hasBosses) {
      api.getBosses(companyId ?? "").then(({ data }) => {
        setBosses(data);
      });
    } else {
      setBosses([]);
    }
  };

  const getCompany = () => {
    setState({ ...state, loading: true });
    api
      .getCompany(companyId)
      .then(({ data }) => {
        setCompany(data);
        setState({ ...state, loading: false });
        getDepartments(data.hasDepartments);
        getTitles(data.hasTitles);
        getBosses(data.hasBosses);
      })
      .catch(() => {
        setState({ ...state, error: true, loading: false });
      });
  };

  const handleChange = (
    _: ChangeEvent<{ [k: string | number | symbol]: never }>,
    newValue: number
  ) => {
    setTab(newValue);
  };

  const showDepartmentModal = () => {
    setDepartmentModal(true);
  };

  const showBossModal = () => {
    setBossModal(true);
  };

  const showCounselingEmailModal = () => {
    setCounselingEmailModal(true);
  };

  const showCounselingTextModal = () => {
    setCounselingTextModal(true);
  };

  const handleIsDeletable = () => {
    if (companyHasEmployees || companyHasCycles) {
      setShowDeleteWarningModal(true);
    } else if (!companyHasEmployees && !companyHasCycles) {
      setShowDeleteModal(true);
      timer = setTimeout(() => {
        setTimerEnded(true);
      }, 5000);
    }
  };

  const handleCloseWarningModal = () => {
    setShowDeleteWarningModal(false);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    clearTimeout(timer);
    setTimeout(() => {
      setTimerEnded(false);
    }, 250);
  };

  const closeDepartmentModal = (id: string | null) => {
    setDepartmentModal(false);
    if (id && company) {
      getDepartments(company.hasDepartments);
    }
  };

  const closeBossModal = (id: string | null) => {
    setBossModal(false);
    if (id && company) {
      getBosses(company.hasBosses);
    }
  };

  const showTitleModal = () => {
    setTitleModal(true);
  };

  const handleDeleteCompany = async (companyIdToDelete: string) => {
    setState({ ...state, loading: true });
    try {
      await api.deleteCompany(companyIdToDelete);
      navigate("/companies");
    } catch (err) {
      console.error(err);
      setState({ ...state, error: true, loading: false });
    }
  };

  const closeTitleModal = (id: string | null) => {
    setTitleModal(false);
    if (id && company) {
      getTitles(company.hasTitles);
    }
  };

  // WE NEED TO DO A CONDITIONAL CHECK HERE AND GET THE NEW COUNSELING EMAIL FOR THE COMPANY!
  const closeCounselingEmailModal = (email: string | null) => {
    setCounselingEmailModal(false);
    if (email && company) {
      setCompany({ ...company, counselingEmail: email });
    }
  };

  const closeCounselingTextModal = (textSv: string, textEn: string) => {
    setCounselingTextModal(false);
    if (textSv && textEn && company) {
      setCompany({
        ...company,
        counselingTextSv: textSv,
        counselingTextEn: textEn,
      });
    }
  };

  const mainGui = () => {
    if (state.loading)
      return (
        <Loader text={t("CompanyDetails.loading")} style={{ marginTop: 40 }} />
      );
    if (!state.loading && company) {
      return (
        <>
          <div className="section-toolbar">
            <BackButton navigateTo="/companies" />
            <Typography className="section-title" variant="h6">
              {company.name}
            </Typography>
            <Spacer />
            <Link to="edit" className="companies-link-style">
              <Button
                startIcon={<Edit />}
                color="primary"
                variant="contained"
                style={{ marginRight: "5px" }}
                data-testid="editCompanyDetailsButton"
              >
                {t("CompanyDetails.edit")}
              </Button>
            </Link>
            <Button
              startIcon={<Delete />}
              color="secondary"
              variant="contained"
              style={{ marginRight: "5px" }}
              onClick={handleIsDeletable}
            >
              {t("CompanyDetails.delete")}
            </Button>
            <Dialog
              onClose={handleCloseWarningModal}
              aria-labelledby="simple-dialog-title"
              open={showDeleteWarningModal}
            >
              <DialogTitle
                id="simple-dialog-title"
                className="companies-dialogTitle-warning-style"
              >
                {t("CompanyDetails.dialogWarningTitle")}
              </DialogTitle>
              <Typography className="companies-dialogText-warning-style">
                {t("CompanyDetails.dialogWarningText")}
              </Typography>
              <Button
                onClick={handleCloseWarningModal}
                color="primary"
                variant="outlined"
                fullWidth
              >
                {t("CompanyDetails.dialogWarningClose")}
              </Button>
            </Dialog>

            <Dialog
              open={showDeleteModal}
              onClose={handleCloseDeleteModal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <Typography className="reinforced-delete-alert-style">
                  {t("CompanyDetails.deleteCompanyTitle")}
                </Typography>
              </DialogTitle>

              <DialogActions>
                <Button
                  onClick={() => handleDeleteCompany(companyId)}
                  disabled={!timerEnded}
                  className={!timerEnded ? "deny-delete-accept-style" : ""}
                  color="primary"
                >
                  {t("CompanyDetails.acceptDeleteAlert")}
                </Button>
                <Button
                  onClick={handleCloseDeleteModal}
                  color="primary"
                  autoFocus
                >
                  {t("CompanyDetails.denyDeleteAlert")}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <Tabs className="company-tabs" value={tab} onChange={handleChange}>
            <LinkTab
              label={t("CompanyDetails.details")}
              href={`/companies/${companyId}/`}
            />
            <LinkTab
              label={t("CompanyDetails.employees")}
              href={`/companies/${companyId}/employees`}
              data-testid="employeeTab"
            />
            <LinkTab
              label={t("CompanyDetails.cycles")}
              href={`/companies/${companyId}/cycles`}
              data-testid="cyclesTab"
            />
          </Tabs>
          <Divider />
          <div>
            <TabPanel value={tab} index={0}>
              <Paper className="company-details-layout">
                <div className="company-details-section">
                  <Typography variant="h5" className="company-details-header">
                    {t("CompanyDetails.about")}
                  </Typography>
                  <span className="company-details-linebreak" />
                  {(company.description || "")
                    .split("\n")
                    .map((line, index) => (
                      <span
                        className="company-details-multiline"
                        // eslint-disable-next-line react/no-array-index-key
                        key={`description-${index}`}
                      >
                        {line}
                      </span>
                    ))}
                </div>
                <div className="company-details-section">
                  <Typography variant="h5" className="company-details-header">
                    {t("CompanyDetails.address")}
                  </Typography>
                  <span className="company-details-linebreak" />
                  {(company.address || "").split("\n").map((line, index) => (
                    <span
                      className="company-details-multiline"
                      // eslint-disable-next-line react/no-array-index-key
                      key={`address-${index}`}
                    >
                      {line}
                    </span>
                  ))}
                </div>
                <div className="company-details-section">
                  <div className="company-details-section-head">
                    <Typography variant="h5" className="company-details-header">
                      {t("CompanyDetails.departments")}
                    </Typography>
                    <Button
                      className="company-details-add_button"
                      color="primary"
                      variant="contained"
                      onClick={showDepartmentModal}
                      disabled={!company.hasDepartments}
                    >
                      {t("CompanyDetails.addDepartment")}
                    </Button>
                  </div>
                  <span className="company-details-linebreak" />
                  {!departments ? (
                    <CircularProgress variant="indeterminate" />
                  ) : null}
                  {departments && departments.length ? (
                    <ul className="company-details-ul-style">
                      {departments.map((department) => (
                        <div
                          className="company-details-list-alignment"
                          key={department.id}
                        >
                          <ArrowRightTwoTone />
                          <li
                            key={department.id}
                            className="company-details-titlesOrDepartments"
                          >
                            {department.name}
                          </li>
                        </div>
                      ))}
                    </ul>
                  ) : null}
                  {departments && !departments.length ? (
                    <span style={{ fontStyle: "italic" }}>
                      {company.hasDepartments
                        ? t("CompanyDetails.noDepartments")
                        : t("CompanyDetails.disabledDepartments")}
                    </span>
                  ) : null}
                  <DepartmentModal
                    open={departmentModal}
                    onClose={closeDepartmentModal}
                    companyId={companyId}
                  />
                </div>
                <div className="company-details-section">
                  <div className="company-details-section-head">
                    <Typography variant="h5" className="company-details-header">
                      {t("CompanyDetails.titles")}
                    </Typography>
                    <Button
                      className="company-details-add_button"
                      color="primary"
                      variant="contained"
                      onClick={showTitleModal}
                      disabled={!company.hasTitles}
                      data-testid="addTitleButton"
                    >
                      {t("CompanyDetails.addTitle")}
                    </Button>
                  </div>
                  <span className="company-details-linebreak" />
                  {!titles && <CircularProgress variant="indeterminate" />}
                  {titles && titles.length && (
                    <ul
                      className="company-details-ul-style"
                      data-testid="titlesList"
                    >
                      {titles.map((title) => (
                        <div
                          className="company-details-list-alignment"
                          key={title.id}
                        >
                          <ArrowRightTwoTone />
                          <li
                            key={title.id}
                            className="company-details-titlesOrDepartments"
                          >
                            {title.name}
                          </li>
                        </div>
                      ))}
                    </ul>
                  )}
                  {titles && !titles.length && (
                    <span style={{ fontStyle: "italic" }}>
                      {company.hasTitles
                        ? t("CompanyDetails.noTitles")
                        : t("CompanyDetails.disabledTitles")}
                    </span>
                  )}
                  <TitleModal
                    open={titleModal}
                    onClose={closeTitleModal}
                    companyId={companyId}
                  />
                </div>
                <div className="company-details-section">
                  <div className="company-details-section-head">
                    <Typography variant="h5" className="company-details-header">
                      {t("CompanyDetails.bosses")}
                    </Typography>
                    <Button
                      className="company-details-add_button"
                      color="primary"
                      variant="contained"
                      onClick={showBossModal}
                      disabled={!company.hasBosses}
                    >
                      {t("CompanyDetails.addBoss")}
                    </Button>
                  </div>
                  <span className="company-details-linebreak" />
                  {!bosses && <CircularProgress variant="indeterminate" />}
                  {bosses && bosses.length && (
                    <ul className="company-details-ul-style">
                      {bosses.map((boss) => (
                        <div
                          className="company-details-list-alignment"
                          key={boss.id}
                        >
                          <ArrowRightTwoTone />
                          <li
                            key={boss.id}
                            className="company-details-titlesOrDepartments"
                          >
                            {boss.name}
                          </li>
                        </div>
                      ))}
                    </ul>
                  )}
                  {bosses && !bosses.length && (
                    <span style={{ fontStyle: "italic" }}>
                      {company.hasBosses
                        ? t("CompanyDetails.noBosses")
                        : t("CompanyDetails.disabledBosses")}
                    </span>
                  )}
                  <BossModal
                    open={bossModal}
                    onClose={closeBossModal}
                    companyId={companyId}
                  />
                </div>
                <div className="company-details-section">
                  <div className="company-details-section-head">
                    <Typography variant="h5" className="company-details-header">
                      {t("CompanyDetails.counselingEmail")}
                    </Typography>
                    <Button
                      className="company-details-add-button"
                      color="primary"
                      variant="contained"
                      onClick={showCounselingEmailModal}
                    >
                      {t("CompanyDetails.editCounselingEmail")}
                    </Button>
                  </div>
                  <span className="company-details-linebreak" />
                  {company.counselingEmail === "" ? (
                    <span style={{ fontStyle: "italic" }}>
                      {t("CompanyDetails.noTitles")}
                    </span>
                  ) : (
                    <ul className="company-details-ul-style">
                      <li className="company-details-titlesOrDepartments">
                        {company.counselingEmail
                          ? company.counselingEmail
                          : "info@dearchange.se"}
                      </li>
                    </ul>
                  )}
                  <CounselingEmailModal
                    open={counselingEmailModal}
                    onClose={closeCounselingEmailModal}
                    companyId={companyId}
                  />
                </div>
                <div className="company-details-section">
                  <div className="company-details-section-head">
                    <Typography variant="h5" className="company-details-header">
                      {t("CompanyDetails.counselingText")}
                    </Typography>
                    <Button
                      className="company-details-add_button"
                      color="primary"
                      variant="contained"
                      onClick={showCounselingTextModal}
                      data-testid="addCounselingTextButton"
                    >
                      {t("CompanyDetails.editCounselingText")}
                    </Button>
                  </div>
                  <span className="company-details-linebreak" />
                  <ul className="company-details-ul-style">
                    <li className="company-details-counselingtext">
                      <span className="company-details-counselingtext-span">
                        {t("CompanyDetails.counselingTextSv")}
                      </span>
                      {company.counselingTextSv
                        ? company.counselingTextSv
                        : t("CompanyDetails.noCounselingText")}
                    </li>
                    <li className="company-details-counselingtext">
                      <span className="company-details-counselingtext-span">
                        {t("CompanyDetails.counselingTextEn")}
                      </span>
                      {company.counselingTextEn
                        ? company.counselingTextEn
                        : t("CompanyDetails.noCounselingText")}
                    </li>
                  </ul>
                  <CounselingTextModal
                    open={counselingTextModal}
                    onClose={closeCounselingTextModal}
                    companyId={companyId}
                    counselingTextEn={
                      company.counselingTextEn ? company.counselingTextEn : ""
                    }
                    counselingTextSv={
                      company.counselingTextSv ? company.counselingTextSv : ""
                    }
                  />
                </div>
              </Paper>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <Employees setCompanyHasEmployees={setCompanyHasEmployees} />
            </TabPanel>
            <TabPanel value={tab} index={2}>
              <Cycles setCompanyHasCycles={setCompanyHasCycles} />
            </TabPanel>
          </div>
        </>
      );
    }

    return (
      <Loader text={t("CompanyDetails.loading")} style={{ marginTop: 40 }} />
    );
  };

  return <div className="companies-wrapper">{mainGui()}</div>;
};

export default CompanyDetails;

interface LinkTabProps {
  label: string;
  href: string;
}

const LinkTab: VFC<LinkTabProps> = (props) => (
  <Tab
    component="a"
    onClick={(event) => event.preventDefault()}
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  />
);

interface TabPanelProps {
  value: number;
  index: number;
}

const TabPanel: FC<TabPanelProps> = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`nav-tabpanel-${index}`}
    aria-labelledby={`nav-tab-${index}`}
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...other}
  >
    {value === index && <Box p={0}>{children}</Box>}
  </div>
);
