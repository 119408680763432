import type { CSSProperties, VFC } from "react";
import { CircularProgress } from "@material-ui/core";
import "./Loader.css";

interface Props {
  text?: string;
  style?: CSSProperties;
}

const Loader: VFC<Props> = ({ text, style }) => (
  <div className="loading-wrapper" style={style}>
    <CircularProgress className="loading-indicator" variant="indeterminate" />
    {text && <h1 className="loading-text">{text}</h1>}
  </div>
);

export default Loader;
