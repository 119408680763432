import { View, Page, Text, Image } from "@react-pdf/renderer";
import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { introductionPageStyling } from "./IntroductionPageStyling";
import {
  getCycleSeason,
  getCycleYear,
  getCurrentDate,
} from "../../../../../utils/helpers/dateConverters";
import { getLanguage } from "../../../../../i18n";

interface Props {
  nameSv: string;
  nameEn: string;
  cycleDate: string;
}

export const IntroductionPage: FC<Props> = ({ nameSv, nameEn, cycleDate }) => {
  const { t } = useTranslation();
  const season = getCycleSeason(cycleDate);
  const currentLanguage = getLanguage();

  return (
    <Page size="A4" style={introductionPageStyling.page} object-fit="fill">
      <View style={introductionPageStyling.parentDiv}>
        <View>
          <Image
            style={introductionPageStyling.image}
            src="https://images.pexels.com/photos/2387866/pexels-photo-2387866.jpeg"
          />
        </View>

        <View style={introductionPageStyling.horizontalLineTop} />

        <Text style={introductionPageStyling.companyTextTop}>
          {currentLanguage === "sv" ? nameSv : nameEn}
        </Text>

        <Text style={introductionPageStyling.headlineText}>
          {t(`IntroductionPage.${season}`)} {getCycleYear(cycleDate)}
        </Text>

        <Text style={introductionPageStyling.smallHeadlineText}>
          {t("IntroductionPage.wellBeingAnalysisIndex")}
        </Text>

        <View style={introductionPageStyling.bottomInfoDiv}>
          <Text style={introductionPageStyling.bottomInfo}>
            www.dearchange.se
          </Text>
          <Text style={introductionPageStyling.bottomInfo}>
            info@dearchange.se
          </Text>
          <Text style={introductionPageStyling.bottomInfo}>
            {getCurrentDate()}
          </Text>
        </View>

        <View style={introductionPageStyling.horizontalLineBottom} />
      </View>
    </Page>
  );
};
