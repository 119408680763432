import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import api from "../../../utils/api/v1";
import Spacer from "../../shared/Spacer";
import Loader from "../../shared/loader/Loader";
import SingleSelectionGrid from "../../../UI-Components/SingleRowSelectionGrid/SingleRowSelectionGrid";

const Companies = () => {
  const { t } = useTranslation();
  const [, setWindowWidth] = useState(window.innerWidth);
  const [state, setState] = useState({
    loading: false,
    error: false,
    companies: [],
  });

  const getCompanies = () => {
    setState({ ...state, loading: true, error: false });
    api
      .getCompanies()
      .then(({ data }) => {
        if (data) {
          setState({
            ...state,
            companies: data.sort((a, b) => {
              const aName = a.name;
              const bName = b.name;

              if (!aName && !bName) return 0;
              if (!aName) return -1;
              if (!bName) return 1;
              return aName.localeCompare(bName);
            }),
            loading: false,
            error: false,
          });
        }
      })
      .catch((e) => {
        console.error(e);
        setState({ ...state, loading: false, error: true });
      });
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    getCompanies();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      field: "name",
      type: "string",
      headerName: t("Companies.table.name"),
      width:
        window.innerWidth >= 960
          ? window.innerWidth / 1.4
          : window.innerWidth - 50,
    },
  ];

  const mainGui = () => {
    if (state.loading)
      return <Loader text={t("Companies.loading")} style={{ marginTop: 40 }} />;
    if (!state.loading && state.companies) {
      return (
        <>
          <div className="section-toolbar">
            <Typography className="section-title" variant="h6">
              {t("Companies.title")}
            </Typography>
            <Spacer />
          </div>
          <div className="section-SingleSelectionGrid-layout">
            <SingleSelectionGrid
              columns={columns}
              rows={state.companies}
              height={500}
              pageSize={10}
              loading={state.loading}
              path="companymaildetails"
            />
          </div>
        </>
      );
    }

    return <Loader text={t("Companies.loading")} style={{ marginTop: 40 }} />;
  };

  return <div className="companies-wrapper">{mainGui()}</div>;
};

export default Companies;
