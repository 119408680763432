import { ChangeEvent, useEffect, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import "./TermDetails.css";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  Card,
  Grid,
} from "@material-ui/core";

import { useNavigate, useParams } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { Link } from "react-router-dom";
import { Add } from "@material-ui/icons";

import api from "../../../utils/api/v1";
import Loader from "../../shared/loader/Loader";
import ReusableTermTable, { DisassociationInput } from "./ReusableTermTable";
import { getLanguage } from "../../../i18n";
import Spacer from "../../shared/Spacer";
import { BackButton } from "../../../UI-Components/Buttons/Buttons";
import type {
  ApiResponseModuleWithTextDataModel,
  ApiResponseNestedModuleWithTermModule,
  ApiResponseNestedTermModule,
  ApiResponseTeacher,
} from "../../../utils/api/apiInterfaces";
import { MissingRequiredParamError } from "../../errorHandling/MissingRequiredParamError";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: "20px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
  },
  card: {
    maxWidth: 345,
    margin: "10px",
  },
}));

interface State {
  loading: boolean;
  error: boolean;
  errorMessage: string;
  term: null | ApiResponseNestedTermModule;
  modules: null | Array<ApiResponseModuleWithTextDataModel>;
  stressTermModules: Array<ApiResponseNestedModuleWithTermModule>;
  physicalTermModules: Array<ApiResponseNestedModuleWithTermModule>;
  developmentTermModules: Array<ApiResponseNestedModuleWithTermModule>;
}

interface CurrentCategoryLevelAndModuleId {
  category: string;
  color: string;
  moduleId: string;
}

const TermDetails: VFC = () => {
  const { t } = useTranslation();
  const { termId } = useParams();
  const [termTeachers, setTermTeachers] = useState<Array<ApiResponseTeacher>>(
    []
  );
  const [openModal, setOpenModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [state, setState] = useState<State>({
    loading: false,
    error: false,
    errorMessage: "",
    term: null,
    modules: null,
    stressTermModules: [],
    physicalTermModules: [],
    developmentTermModules: [],
  });
  const navigate = useNavigate();
  const classes = useStyles();

  useEffect(() => {
    getTermAndModules();
    getTermTeachers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const [currentCategoryLevelAndModuleId, setCurrentCategoryLevelAndModuleId] =
    useState<CurrentCategoryLevelAndModuleId>({
      category: "",
      color: "",
      moduleId: "",
    });

  if (!termId) {
    return <MissingRequiredParamError missingParam="termId" />;
  }

  const handleCloseModal = () => {
    setOpenModal(false);
    setState({ ...state, error: false, errorMessage: "" });
  };

  const handleSetModuleId = (
    e: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    setCurrentCategoryLevelAndModuleId({
      ...currentCategoryLevelAndModuleId,
      moduleId: e.target.value as string,
    });
  };

  const handleCurrentCategoryAndLevelAndModal = (
    category: string,
    color: string
  ) => {
    setOpenModal(true);
    setCurrentCategoryLevelAndModuleId({
      ...currentCategoryLevelAndModuleId,
      category,
      color,
    });
  };

  const getTermTeachers = () => {
    setState({ ...state, error: false, loading: true });
    api
      .getTeachersByTermId(termId)
      .then((res) => {
        setTermTeachers(res.data);
        setState({ ...state, loading: false });
      })
      .catch((err) => {
        console.error(err);
        setState({ ...state, error: true, loading: false });
      });
  };

  const getTermAndModules = () => {
    setState({ ...state, error: false, loading: true });
    Promise.all([api.getTerm(termId), api.getModules()])
      .then((res) => {
        const stressTermModules = res[0].data.modules.filter(
          (module) => module.questionCategories.isStress === true
        );
        const physicalTermModules = res[0].data.modules.filter(
          (module) => module.questionCategories.isPhysical === true
        );
        const developmentTermModules = res[0].data.modules.filter(
          (module) => module.questionCategories.isDevelopment === true
        );
        setState({
          ...state,
          term: res[0].data,
          modules: res[1].data,
          stressTermModules,
          physicalTermModules,
          developmentTermModules,
          loading: false,
        });
      })
      .catch((err) => {
        console.error(err);
        setState({ ...state, error: true, loading: false });
      });
  };

  const handleDissassociateModuleFromTermCategoryAndLevel = async (
    termModule: DisassociationInput
  ) => {
    setState({ ...state, error: false, loading: true });
    const dataToSend = { ...termModule };
    try {
      await api.dissociateTermWithModule(dataToSend);
      getTermAndModules();
      setState({ ...state, loading: false });
    } catch (err) {
      console.error(err);
      setState({ ...state, error: false, loading: false });
    }
  };

  const handleConnectModuleToTerm = async () => {
    setState({ ...state, error: false, loading: true });
    const termModuleNumbers: Array<number> = [];
    let highestTermModuleNumber: number;
    let currentCategoryColorModules:
      | Array<ApiResponseNestedModuleWithTermModule>
      | undefined = [];
    switch (currentCategoryLevelAndModuleId.category) {
      case "isStress":
        currentCategoryColorModules = state.stressTermModules.filter(
          (module) =>
            module.termModule.appliesToColor ===
            currentCategoryLevelAndModuleId.color
        );
        currentCategoryColorModules.forEach((module) =>
          termModuleNumbers.push(module.termModule.order)
        );
        highestTermModuleNumber = Math.max(...termModuleNumbers);
        break;
      case "isPhysical":
        currentCategoryColorModules = state.physicalTermModules?.filter(
          (module) =>
            module.termModule.appliesToColor ===
            currentCategoryLevelAndModuleId.color
        );
        currentCategoryColorModules.forEach((module) =>
          termModuleNumbers.push(module.termModule.order)
        );
        highestTermModuleNumber = Math.max(...termModuleNumbers);
        break;
      case "isDevelopment":
        currentCategoryColorModules = state.developmentTermModules.filter(
          (module) =>
            module.termModule.appliesToColor ===
            currentCategoryLevelAndModuleId.color
        );
        currentCategoryColorModules.forEach((module) =>
          termModuleNumbers.push(module.termModule.order)
        );
        highestTermModuleNumber = Math.max(...termModuleNumbers);
        break;
      default:
        highestTermModuleNumber = 0;
    }

    if (highestTermModuleNumber === -Infinity) {
      highestTermModuleNumber = 0;
    }

    const dataToSend = {
      termModules: [
        {
          termId: parseInt(termId, 10),
          appliesToColor: currentCategoryLevelAndModuleId.color,
          order: highestTermModuleNumber + 1,
          moduleId: currentCategoryLevelAndModuleId.moduleId,
        },
      ],
    };
    try {
      await api.associateTermWithModules(dataToSend);
      setState({ ...state, loading: false });
      getTermAndModules();
      setOpenModal(false);
    } catch (err) {
      setState({
        ...state,
        error: true,
        errorMessage: err instanceof Error ? err.message : "Unknown error",
        loading: false,
      });
      setOpenModal(false);
      handleOpenErrorModal();
    }
  };

  const mainGui = () => {
    if (state.loading)
      return (
        <Loader text={t("TermDetails.loading")} style={{ marginTop: 40 }} />
      );
    if (!state.loading && state.term) {
      return (
        <>
          <div className="section-toolbar">
            <BackButton navigateTo="/terms" />
            <Typography className="section-title" variant="h6">
              {t("TermDetails.term")} {state.term.termNumber}
            </Typography>
            <Spacer />
            <Link
              to={`/terms/${termId}/AddTermTeacher`}
              className="resources-link-style"
            >
              <Button startIcon={<Add />} color="primary" variant="contained">
                {t("TermTeacherDetails.AddTeacher")} {t("TermDetails.term")}{" "}
                {termId}
              </Button>
            </Link>
          </div>
          {termTeachers.length >= 1 && (
            <div>
              <div className="teachersheading">
                <Typography className="section-title" variant="h6">
                  {t("TermTeacherDetails.AllTermTeachers")}
                </Typography>
              </div>
              <div className={classes.root}>
                <Grid container spacing={3}>
                  <Grid item xs={12} />
                  {termTeachers.map((termteacher) => (
                    <Grid item xs={6} sm={3}>
                      <Card className={classes.card}>
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            alt="Contemplative Reptile"
                            height="280"
                            image={termteacher.imageUrl ?? ""}
                            title={termteacher.name ?? ""}
                          />
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="h2"
                            >
                              {termteacher.name}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="p"
                            >
                              {termteacher.descriptionEn}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                        <CardActions>
                          <Button
                            variant="contained"
                            size="medium"
                            color="secondary"
                            onClick={() =>
                              navigate(`/terms/${termId}/${termteacher.id}`)
                            }
                          >
                            {t("TermTeacherDetails.DeleteTermTeacher")}
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </div>
          )}
          {state.error && (
            <Dialog
              open={openErrorModal}
              onClose={handleCloseErrorModal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle
                id="alert-dialog-title"
                className="termdetails-errorDialog-title-style"
              >
                {t("TermDetails.errorModal.header")}
              </DialogTitle>
              <Card variant="outlined" className="dialog-card-style">
                <Typography className="termdetails-error-paragraph-style">
                  {state.errorMessage !== ""
                    ? state.errorMessage
                    : "Server error!"}
                </Typography>
              </Card>
              <DialogActions>
                <Button
                  onClick={handleCloseErrorModal}
                  color="primary"
                  autoFocus
                >
                  {t("TermDetails.errorModal.acceptButton")}
                </Button>
              </DialogActions>
            </Dialog>
          )}
          <Dialog
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            fullWidth
          >
            <DialogTitle id="alert-dialog-title">
              {t("TermDetails.modalHeader")}{" "}
              {t(
                `TermDetails.categories.${currentCategoryLevelAndModuleId.category}`
              )}{" "}
              {t(
                `TermDetails.levelsForModal.${currentCategoryLevelAndModuleId.color}`
              )}
            </DialogTitle>
            <FormControl variant="outlined" className="termDetails-formcontrol">
              <InputLabel htmlFor="outlined-age-native-simple">
                {t(
                  `TermDetails.categories.${currentCategoryLevelAndModuleId.category}`
                )}
              </InputLabel>
              <Select
                native
                value={currentCategoryLevelAndModuleId.moduleId}
                onChange={handleSetModuleId}
                label={t(
                  `TermDetails.categories.${currentCategoryLevelAndModuleId.category}`
                )}
              >
                <option aria-label="None" value="" />
                {state.modules &&
                  state.modules
                    .filter(
                      (module) =>
                        module[currentCategoryLevelAndModuleId.category] ===
                        true
                    )
                    .map((module) => (
                      <option key={module.id} value={module.id}>
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {getLanguage() === "sv"
                          ? module.nameSv
                          : getLanguage()
                          ? module.nameEn
                          : ""}
                      </option>
                    ))}
              </Select>
            </FormControl>
            <DialogActions>
              <div className="termDetails-modal-button-alignment">
                <Button
                  onClick={handleConnectModuleToTerm}
                  color="primary"
                  variant="contained"
                  className="termDetails-modal-button-style"
                >
                  {t("TermDetails.saveModal")}
                </Button>
                <Button
                  onClick={handleCloseModal}
                  color="secondary"
                  variant="contained"
                  className="termDetails-modal-button-style"
                >
                  {t("TermDetails.cancelSaveModal")}
                </Button>
              </div>
            </DialogActions>
          </Dialog>
          <div className="term-modules-wrapper">
            <Typography className="termModules-title" variant="h6">
              {t("TermDetails.modules")}
            </Typography>
            <div className="categories-tables-container">
              <p className="paragraph-category-style">
                {t("TermDetails.stress")}
              </p>
              <ReusableTermTable
                level="red"
                category="isStress"
                handleDissassociateModuleFromTermCategoryAndLevel={
                  handleDissassociateModuleFromTermCategoryAndLevel
                }
                handleCurrentCategoryAndLevelAndModal={
                  handleCurrentCategoryAndLevelAndModal
                }
                termCategoryModules={state.stressTermModules}
              />
              <ReusableTermTable
                level="yellow"
                category="isStress"
                handleDissassociateModuleFromTermCategoryAndLevel={
                  handleDissassociateModuleFromTermCategoryAndLevel
                }
                handleCurrentCategoryAndLevelAndModal={
                  handleCurrentCategoryAndLevelAndModal
                }
                termCategoryModules={state.stressTermModules}
              />
              <ReusableTermTable
                level="green"
                category="isStress"
                handleDissassociateModuleFromTermCategoryAndLevel={
                  handleDissassociateModuleFromTermCategoryAndLevel
                }
                handleCurrentCategoryAndLevelAndModal={
                  handleCurrentCategoryAndLevelAndModal
                }
                termCategoryModules={state.stressTermModules}
              />
            </div>
            <div className="categories-tables-container">
              <p className="paragraph-category-style">
                {t("TermDetails.physicalActivity")}
              </p>
              <ReusableTermTable
                level="red"
                category="isPhysical"
                handleDissassociateModuleFromTermCategoryAndLevel={
                  handleDissassociateModuleFromTermCategoryAndLevel
                }
                handleCurrentCategoryAndLevelAndModal={
                  handleCurrentCategoryAndLevelAndModal
                }
                termCategoryModules={state.physicalTermModules}
              />
              <ReusableTermTable
                level="yellow"
                category="isPhysical"
                handleDissassociateModuleFromTermCategoryAndLevel={
                  handleDissassociateModuleFromTermCategoryAndLevel
                }
                handleCurrentCategoryAndLevelAndModal={
                  handleCurrentCategoryAndLevelAndModal
                }
                termCategoryModules={state.physicalTermModules}
              />
              <ReusableTermTable
                level="green"
                category="isPhysical"
                handleDissassociateModuleFromTermCategoryAndLevel={
                  handleDissassociateModuleFromTermCategoryAndLevel
                }
                handleCurrentCategoryAndLevelAndModal={
                  handleCurrentCategoryAndLevelAndModal
                }
                termCategoryModules={state.physicalTermModules}
              />
            </div>
            <div className="categories-tables-container">
              <p className="paragraph-category-style">
                {t("TermDetails.personalDevelopment")}
              </p>
              <ReusableTermTable
                level="red"
                category="isDevelopment"
                handleDissassociateModuleFromTermCategoryAndLevel={
                  handleDissassociateModuleFromTermCategoryAndLevel
                }
                handleCurrentCategoryAndLevelAndModal={
                  handleCurrentCategoryAndLevelAndModal
                }
                termCategoryModules={state.developmentTermModules}
              />
              <ReusableTermTable
                level="yellow"
                category="isDevelopment"
                handleDissassociateModuleFromTermCategoryAndLevel={
                  handleDissassociateModuleFromTermCategoryAndLevel
                }
                handleCurrentCategoryAndLevelAndModal={
                  handleCurrentCategoryAndLevelAndModal
                }
                termCategoryModules={state.developmentTermModules}
              />
              <ReusableTermTable
                level="green"
                category="isDevelopment"
                handleDissassociateModuleFromTermCategoryAndLevel={
                  handleDissassociateModuleFromTermCategoryAndLevel
                }
                handleCurrentCategoryAndLevelAndModal={
                  handleCurrentCategoryAndLevelAndModal
                }
                termCategoryModules={state.developmentTermModules}
              />
            </div>
          </div>
        </>
      );
    }

    return <Loader text={t("TermDetails.loading")} style={{ marginTop: 40 }} />;
  };

  return <div className="companies-wrapper">{mainGui()}</div>;
};

export default TermDetails;
