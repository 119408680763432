import { StyleSheet } from "@react-pdf/renderer";

export const companyStatisticsStyling = StyleSheet.create({
  page: {
    backgroundColor: "white",
    width: "100%",
  },
  parentDiv: {
    position: "relative",
    objectFit: "cover",
    height: "100vh",
  },
  horizontalLineTop: {
    position: "absolute",
    top: 35,
    textAlign: "center",
    width: "500px",
    borderBottomColor: "#068466",
    borderBottomStyle: "solid",
    borderBottomWidth: "3px",
    marginLeft: "40px",
  },
  horizontalLineBottom: {
    position: "absolute",
    bottom: 50,
    textAlign: "center",
    width: "500px",
    borderBottomColor: "#068466",
    borderBottomStyle: "solid",
    borderBottomWidth: "3px",
    marginLeft: "40px",
  },
  parentDivStatistic: {
    display: "flex",
    top: 30,
    margin: 60,
  },
  indexDiv: {
    flexDirection: "column",
    justifyContent: "space-around",
    paddingRight: "20px",
    position: "absolute",
    width: 300,
  },
  descriptionsDiv: {
    paddingTop: "25px",
    width: 220,
  },
  header: {
    top: -25,
  },
  headerText: {
    fontSize: 13,
    fontFamily: "Times-Roman",
    letterSpacing: "1px",
  },
  indexHeader: {
    fontSize: 40,
    fontFamily: "Helvetica",
  },
  indexDescription: {
    fontSize: 13,
    fontStyle: "italic",
    fontFamily: "Times-Italic",
  },
  averageIndexDiv: {
    paddingTop: 35,
  },
  index: {
    fontSize: 100,
    color: "#068466",
  },
  indexFooter: {
    fontSize: 13,
    fontFamily: "Times-Roman",
    color: "#068466",
  },
  indexHorizontalLine: {
    borderBottom: "3px",
    borderBottomColor: "#068466",
    borderBottomStyle: "solid",
    borderBottomWidth: "3px",
    position: "absolute",
    width: 220,
    top: 180,
  },
  averageIndexDescription: {
    fontSize: 13,
    fontFamily: "Times-Roman",
    paddingTop: 30,
    width: 220,
    lineHeight: 1.5,
  },
  circleDiv: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "300px",
    position: "absolute",
    width: 490,
  },
  circleDivHeader: {
    fontSize: "11px",
  },
  circleHeader: {
    fontSize: "10px",
    marginTop: 20,
    marginBottom: 10,
    paddingLeft: 18,
  },
  developmentCircle: {
    paddingLeft: 10,
  },
  barchartHeaderDiv: {
    position: "relative",
    top: 470,
    marginLeft: 60,
    width: "100%",
  },
  barchartHeader: {
    fontSize: 13,
    marginTop: 8,
    marginBottom: 15,
  },
  barchartText: {
    fontSize: 10,
    marginBottom: 8,
    marginRight: 30,
    textAlign: "right",
  },
  barchartDiv: {
    display: "flex",
    flexDirection: "row",
    top: 480,
    marginLeft: 60,
  },
});
