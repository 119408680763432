import type { FC } from "react";
import { View, Text } from "@react-pdf/renderer";
import { companyPercentagesResultStyle } from "./CompanyPercentagesResultStyle";

interface Props {
  amountLowScore: number;
  amountMediumScore: number;
  amountHighScore: number;
  totalAmountOfAnswers: number;
}

export const TextAndColorPercent: FC<Props> = ({
  amountLowScore,
  amountMediumScore,
  amountHighScore,
  totalAmountOfAnswers,
}) => {
  const calculatePercent = (amount: number) =>
    `${Math.round((amount / totalAmountOfAnswers) * 100)}%`;

  return (
    <View style={companyPercentagesResultStyle.percentageContainer}>
      <View style={companyPercentagesResultStyle.lowCircle} />
      <Text style={companyPercentagesResultStyle.percentageNumber}>
        {calculatePercent(amountLowScore)}
      </Text>
      <View style={companyPercentagesResultStyle.mediumCircle} />
      <Text style={companyPercentagesResultStyle.percentageNumber}>
        {calculatePercent(amountMediumScore)}
      </Text>
      <View style={companyPercentagesResultStyle.highCircle} />
      <Text style={companyPercentagesResultStyle.percentageNumber}>
        {calculatePercent(amountHighScore)}
      </Text>
    </View>
  );
};
