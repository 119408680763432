import { useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import "./CounselingTextModal.css";
import {
  Button,
  CircularProgress,
  FormControl,
  Modal,
  Paper,
  TextField,
} from "@material-ui/core";
import api from "../../../utils/api/v1";
import Spacer from "../../shared/Spacer";
import Alert from "../../shared/Alert";

interface Props {
  open: boolean;
  onClose: (textSv: string, textEn: string) => void;
  companyId: string;
  counselingTextSv: string;
  counselingTextEn: string;
}

const CounselingTextModal: VFC<Props> = ({ open, onClose, companyId }) => {
  const { t } = useTranslation();
  const [form, setForm] = useState({
    counselingTextSv: "",
    counselingTextEn: "",
  });
  const [state, setState] = useState({
    error: "",
    loading: false,
  });

  /**
   * A function that invokes the onclose function.
   *
   * @param {string} textEn The english description.
   * @param {string} textSv The swedish description.
   *
   * @returns {Function} The onclose function comming in as props.
   */
  const handleClose = (textSv = "", textEn = "") => onClose(textSv, textEn);

  const handleChange = ({
    target: { name, value },
  }: {
    target: { name: string; value: string };
  }) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSave = () => {
    if (!form.counselingTextEn || !form.counselingTextSv) {
      setState({
        loading: false,
        error: t("CounselingTextModal.error.missingText"),
      });
      return;
    }
    setState({ ...state, loading: true, error: "" });
    api
      .addCounselingTextToCompany(companyId, form)
      .then((res) => {
        setState({ ...state, loading: false, error: "" });
        handleClose(res.data.counselingTextSv, res.data.counselingTextEn);
      })
      .catch((err) => {
        console.error(err);
        setState({
          loading: false,
          error: t("CounselingTextModal.error.default"),
        });
      });
  };

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="counseling-text-modal-title"
      aria-describedby="counseling-text-modal-description"
      data-testid="counselingTextModal"
    >
      <Paper className="counseling-text-modal-wrapper">
        <h2 id="counseling-text-modal-title">
          {t("CounselingTextModal.title")}
        </h2>
        <FormControl className="counseling-text-form">
          <TextField
            label={t("CounselingTextModal.textSv")}
            inputMode="text"
            value={form.counselingTextSv}
            name="counselingTextSv"
            onChange={handleChange}
            data-testid="counselingTextSvInput"
          />
          <TextField
            label={t("CounselingTextModal.textEn")}
            inputMode="text"
            value={form.counselingTextEn}
            name="counselingTextEn"
            onChange={handleChange}
            data-testid="counselingTextEnInput"
          />
        </FormControl>
        {state.loading ? <CircularProgress style={{ marginTop: 20 }} /> : null}
        {state.error ? (
          <Alert style={{ marginTop: 20 }}>{state.error}</Alert>
        ) : null}
        <div className="counseling-text-modal-buttons">
          <Spacer />
          <Button
            disabled={state.loading}
            variant="outlined"
            className="cancel"
            onClick={() => handleClose()}
          >
            {t("CounselingTextModal.cancel")}
          </Button>
          <Button
            disabled={state.loading}
            color="primary"
            variant="contained"
            onClick={handleSave}
            data-testid="saveCounselingTextButton"
          >
            {t("CounselingTextModal.save")}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

export default CounselingTextModal;
