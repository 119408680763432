import "./Resources.css";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Button } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Link } from "react-router-dom";
import Spacer from "../shared/Spacer";
import api from "../../utils/api/v1";
import Loader from "../shared/loader/Loader";
import { getLanguage } from "../../i18n";
import SingleSelectionGrid from "../../UI-Components/SingleRowSelectionGrid/SingleRowSelectionGrid";

interface ValueGetterProps {
  row: {
    type: string;
    date: number;
  };
}

interface RowProps {
  id: string;
  nameSv: string | null;
  nameEn: string | null;
  shortDescriptionSv: string | null;
  shortDescriptionEn: string | null;
  date: number;
  type: string | null;
}

const options = {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
} as const;

const Activities = () => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    loading: true,
    error: false,
  });
  const [resources, setResources] = useState<Array<RowProps>>([]);
  const [, setWindowWidth] = useState(window.innerWidth);

  const formatDateTime = (dateTime: number) => {
    const date = new Date(dateTime);
    return date.toLocaleDateString(getLanguage(), options);
  };

  const getResources = () => {
    setState({
      loading: true,
      error: false,
    });
    api
      .getResources()
      .then(({ data }) => {
        const rows: Array<RowProps> = [];
        const timezoneOffset = new Date().getTimezoneOffset();
        data.forEach((dataResource) => {
          const resourceObject = {
            id: dataResource.id,
            nameSv: dataResource.nameSv,
            nameEn: dataResource.nameEn,
            shortDescriptionSv: dataResource.shortDescriptionSv,
            shortDescriptionEn: dataResource.shortDescriptionEn,
            date:
              Date.parse(dataResource.createdAt) - timezoneOffset * 60 * 1000,
            type: dataResource.type,
          };
          rows.push(resourceObject);
        });
        setResources(rows);
        setState({
          ...state,
          loading: false,
        });
      })
      .catch((err) => {
        console.error(err);
        setState({
          ...state,
          loading: false,
          error: true,
        });
      });
  };

  const columns = [
    { field: "id", hide: true },
    {
      field: getLanguage() === "sv" ? "nameSv" : "nameEn",
      headerName: t("SingleRowSelectionGrid.resourceName"),
      width:
        window.innerWidth >= 960
          ? window.innerWidth / 6.5
          : window.innerWidth / 5.5,
    },
    {
      field:
        getLanguage() === "sv" ? "shortDescriptionSv" : "shortDescriptionEn",
      headerName: t("SingleRowSelectionGrid.shortDesc"),
      width:
        window.innerWidth >= 960
          ? window.innerWidth / 4
          : window.innerWidth / 3,
    },
    {
      field: "type",
      headerName: t("SingleRowSelectionGrid.type"),
      width:
        window.innerWidth >= 960
          ? window.innerWidth / 5.5
          : window.innerWidth / 4.5,
      valueGetter: (params: ValueGetterProps) =>
        `${
          // eslint-disable-next-line no-nested-ternary
          params.row.type === "Physical Activity"
            ? t("Resources.type.physicalActivity")
            : // eslint-disable-next-line no-nested-ternary
            params.row.type === "Relaxation"
            ? t("Resources.type.relaxation")
            : // eslint-disable-next-line no-nested-ternary
            params.row.type === "Nutrition"
            ? t("Resources.type.nutrition")
            : // eslint-disable-next-line no-nested-ternary
            params.row.type === "Personal Development"
            ? t("Resources.type.personalDevelopment")
            : // eslint-disable-next-line no-nested-ternary
            params.row.type === "Recovery"
            ? t("Resources.type.recovery")
            : // eslint-disable-next-line no-nested-ternary
            params.row.type === "Exercise"
            ? t("Resources.type.exercise")
            : "-"
        }`,
    },
    {
      field: "date",
      headerName: t("SingleRowSelectionGrid.dateAdded"),
      width:
        window.innerWidth >= 960
          ? window.innerWidth / 5.5
          : window.innerWidth / 4.5,
      valueGetter: (params: ValueGetterProps) =>
        formatDateTime(params.row.date) || "-",
    },
  ];

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    getResources();
    window.addEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mainGui = () => {
    if (state.loading)
      return <Loader text={t("Resources.loading")} style={{ marginTop: 40 }} />;
    if (!state.loading && resources) {
      return (
        <>
          <div className="section-toolbar">
            <Typography className="section-title" variant="h6">
              {t("Resources.title")}
            </Typography>
            <Spacer />
            <Link to="/resources/resource/new" className="resources-link-style">
              <Button startIcon={<Add />} color="primary" variant="contained">
                {t("Surveys.create")}
              </Button>
            </Link>
          </div>

          <div className="section-SingleSelectionGrid-layout">
            <SingleSelectionGrid
              columns={columns}
              rows={resources}
              pageSize={10}
              loading={state.loading}
              path="resources"
            />
          </div>
        </>
      );
    }

    return <Loader text={t("Resources.loading")} style={{ marginTop: 40 }} />;
  };

  return <div className="surveys-wrapper">{mainGui()}</div>;
};

export default Activities;
