import React, {
  useState,
  useEffect,
  useCallback,
  VFC,
  ChangeEvent,
} from "react";
import { compact } from "lodash";
import { useTranslation } from "react-i18next";
import "./FilteredStatisticsChart.css";
import { Grid, Paper } from "@material-ui/core";
import Filter from "./Filter";
import BarChart from "./BarChart";
import { CircleDiagram } from "./CircleDiagram";
import type { ApiResponseCompanyEmployeeDataModel } from "../../../../utils/api/apiInterfaces";

interface Props {
  totalAverage: number;
  stressAverage: number;
  physicalAverage: number;
  developmentAverage: number;
  employeeData: Array<ApiResponseCompanyEmployeeDataModel>;
}

interface CircleStatisticsState {
  stress: Array<number>;
  physical: Array<number>;
  development: Array<number>;
}

const FilteredStatisticsChart: VFC<Props> = ({
  totalAverage,
  stressAverage,
  physicalAverage,
  developmentAverage,
  employeeData,
}) => {
  const { t } = useTranslation();
  const [toFewEmployees, setToFewEmployees] = useState(false);
  const [selection, setSelection] = useState({
    department: "All",
    title: "All",
    boss: "All",
    ageSpan: "All",
    gender: "All",
  });
  const [stats, setStats] = useState({
    stressAverage,
    physicalAverage,
    developmentAverage,
    totalAverage,
  });
  const [circleStatistics, setCircleStatistics] =
    useState<CircleStatisticsState>({
      stress: [],
      physical: [],
      development: [],
    });

  const departments = employeeData
    .filter((e) => e.companyDepartment && e.companyDepartment.name !== "")
    .map((e) => e.companyDepartment.name);

  const uniqueDepartments = [...new Set(departments)].filter((dep) =>
    Boolean(dep)
  );

  const titles = employeeData
    .filter((e) => e.companyTitle && e.companyTitle.name !== "")
    .map((e) => e.companyTitle.name);

  const uniqueTitles = compact([...new Set(titles)]);

  const bosses = employeeData
    .filter((e) => e.companyBoss && e.companyBoss.name !== "")
    .map((e) => e.companyBoss.name);

  const uniqueBosses = compact([...new Set(bosses)]);

  const ageSpans = employeeData
    .filter((e) => e.userCycleResult.ageSpan)
    .map((e) => e.userCycleResult.ageSpan);

  const uniqueAgeSpansRaw = compact([...new Set(ageSpans)]).sort();

  const uniqueAgeSpans = uniqueAgeSpansRaw.map((age) =>
    t(`CycleResults.questions.data.${age}`)
  );

  const genders = employeeData
    .filter((e) => e.userCycleResult.gender)
    .map((e) => e.userCycleResult.gender);

  const uniqueGendersRaw = compact([...new Set(genders)]).sort();

  const uniqueGenders = uniqueGendersRaw.map((gender) =>
    t(`CycleResults.questions.data.${gender}`)
  );

  const filterByDepartments = (
    employee: ApiResponseCompanyEmployeeDataModel
  ) => {
    if (selection.department === "All" && !employee.companyDepartment) {
      return employee;
    }
    if (
      selection.department &&
      employee.companyDepartment &&
      (selection.department === "All" ||
        selection.department === employee.companyDepartment.name)
    ) {
      return employee;
    }
    return null;
  };

  const filterByTitles = (employee: ApiResponseCompanyEmployeeDataModel) => {
    if (selection.title === "All" && !employee.companyTitle) {
      return employee;
    }
    if (
      selection.title &&
      employee.companyTitle &&
      (selection.title === "All" ||
        selection.title === employee.companyTitle.name)
    ) {
      return employee;
    }
    return null;
  };

  const filterByBosses = (employee: ApiResponseCompanyEmployeeDataModel) => {
    if (selection.boss === "All" && !employee.companyBoss) {
      return employee;
    }
    if (
      selection.boss &&
      employee.companyBoss &&
      (selection.boss === "All" || selection.boss === employee.companyBoss.name)
    ) {
      return employee;
    }
    return null;
  };

  const filterByAgeSpans = (employee: ApiResponseCompanyEmployeeDataModel) => {
    if (selection.ageSpan === "All" && !employee.userCycleResult.ageSpan) {
      return employee;
    }
    if (
      selection.ageSpan &&
      employee.userCycleResult.ageSpan &&
      (selection.ageSpan === "All" ||
        selection.ageSpan === employee.userCycleResult.ageSpan)
    ) {
      return employee;
    }
    return null;
  };

  const filterByGenders = (employee: ApiResponseCompanyEmployeeDataModel) => {
    if (selection.gender === "All" && !employee.userCycleResult.gender) {
      return employee;
    }
    if (
      selection.gender &&
      employee.userCycleResult.gender &&
      (selection.gender === "All" ||
        selection.gender === employee.userCycleResult.gender)
    ) {
      return employee;
    }
    return null;
  };

  const filterFinishedOnly = (employee: ApiResponseCompanyEmployeeDataModel) =>
    employee.userCycleResult.state === "Finished";

  useEffect(() => {
    ["department", "title", "boss", "ageSpan", "gender"].map((filter) =>
      localStorage.removeItem(filter)
    );
  }, []);

  const applyFilter = useCallback(() => {
    const filteredEmployees = employeeData
      .filter(filterFinishedOnly)
      .filter(filterByDepartments)
      .filter(filterByTitles)
      .filter(filterByBosses)
      .filter(filterByAgeSpans)
      .filter(filterByGenders);

    if (filteredEmployees.length < 5) {
      setToFewEmployees(true);
    } else {
      setToFewEmployees(false);
    }

    const averageStressResult = parseFloat(
      (
        filteredEmployees.reduce(
          (a, b) => a + b.userCycleResult.stressResult,
          0
        ) / filteredEmployees.length
      ).toFixed(1)
    );

    const averagePhysicalResult = parseFloat(
      (
        filteredEmployees.reduce(
          (a, b) => a + b.userCycleResult.physicalResult,
          0
        ) / filteredEmployees.length
      ).toFixed(1)
    );

    const averageDevelopmentResult = parseFloat(
      (
        filteredEmployees.reduce(
          (a, b) => a + b.userCycleResult.developmentResult,
          0
        ) / filteredEmployees.length
      ).toFixed(1)
    );

    const totalAverageResult = parseFloat(
      (
        filteredEmployees.reduce(
          (a, b) => a + b.userCycleResult.averageResult,
          0
        ) / filteredEmployees.length
      ).toFixed(1)
    );

    const stressResults = filteredEmployees.map((employee) =>
      parseFloat(employee.userCycleResult.stressResult.toFixed(1))
    );

    const physicalActivityResults = filteredEmployees.map((employee) =>
      parseFloat(employee.userCycleResult.physicalResult.toFixed(1))
    );

    const personalDevelopmentResults = filteredEmployees.map((employee) =>
      parseFloat(employee.userCycleResult.developmentResult.toFixed(1))
    );

    setCircleStatistics({
      stress: stressResults,
      physical: physicalActivityResults,
      development: personalDevelopmentResults,
    });

    setStats({
      stressAverage: averageStressResult,
      physicalAverage: averagePhysicalResult,
      developmentAverage: averageDevelopmentResult,
      totalAverage: totalAverageResult,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection]);

  useEffect(() => {
    applyFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection]);

  const handleSelectFilter = ({
    target: { value, name },
  }: React.FocusEvent<HTMLSelectElement> | ChangeEvent<HTMLSelectElement>) => {
    setSelection({ ...selection, [name]: value });
    localStorage.setItem(name, value);
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      className="filtered-cards-grid-container"
    >
      <Paper className="filtered-analysis-details" square>
        <Grid
          container
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          className="filter-grid-container"
        >
          <Filter
            title={t("CycleResults.filter.department")}
            inputName="department"
            options={uniqueDepartments}
            values={uniqueDepartments}
            selection={selection.department}
            selectionHandler={handleSelectFilter}
          />
          <Filter
            title={t("CycleResults.filter.title")}
            inputName="title"
            options={uniqueTitles}
            values={uniqueTitles}
            selection={selection.title}
            selectionHandler={handleSelectFilter}
          />
          <Filter
            title={t("CycleResults.filter.boss")}
            inputName="boss"
            options={uniqueBosses}
            values={uniqueBosses}
            selection={selection.boss}
            selectionHandler={handleSelectFilter}
          />
          <Filter
            title={t("CycleResults.filter.age")}
            inputName="ageSpan"
            options={uniqueAgeSpans}
            values={uniqueAgeSpansRaw}
            selection={selection.ageSpan}
            selectionHandler={handleSelectFilter}
          />
          <Filter
            title={t("CycleResults.filter.gender")}
            inputName="gender"
            options={uniqueGenders}
            values={uniqueGendersRaw}
            selection={selection.gender}
            selectionHandler={handleSelectFilter}
          />
        </Grid>
        {toFewEmployees ? (
          <div className="filtered-chart-container">
            <div className="to-few-results">
              <p>{t("CycleResults.toFewPeople")}</p>
            </div>
          </div>
        ) : (
          <>
            <div className="filtered-chart-container">
              <p className="chart-details-title">
                {t("CycleResults.wellbeingIndex")}: {stats.totalAverage}
              </p>
              <BarChart
                wellbeingText={t("CycleResults.wellbeingIndex")}
                stressCategory={t("CycleResults.stressManagement")}
                physicsCategory={t("CycleResults.physicalActivity")}
                developmentCategory={t("CycleResults.personalDevelopment")}
                stress={stats.stressAverage}
                physics={stats.physicalAverage}
                development={stats.developmentAverage}
              />
            </div>

            <div className="circle-statistic-information">
              <p>
                {t(
                  "CycleResults.filteredStatisticsChart.circleStatisticsHeader"
                )}
              </p>
              <div className="circle-info-wrapper">
                <span className="circle-dot-legend">
                  <div className="red-dot" />
                  {t("CycleResults.filteredStatisticsChart.circleInfo.red")}
                </span>
                <span>
                  <div className="yellow-dot" />
                  {t("CycleResults.filteredStatisticsChart.circleInfo.yellow")}
                </span>
                <span>
                  <div className="green-dot" />
                  {t("CycleResults.filteredStatisticsChart.circleInfo.green")}
                </span>
              </div>
            </div>

            <div className="circles-wrapper">
              <CircleDiagram
                category="stressManagement"
                data={circleStatistics.stress}
              />
              <CircleDiagram
                category="physicalActivity"
                data={circleStatistics.physical}
              />
              <CircleDiagram
                category="personalDevelopment"
                data={circleStatistics.development}
              />
            </div>
          </>
        )}
      </Paper>
    </Grid>
  );
};

export default FilteredStatisticsChart;
