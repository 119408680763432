import { Rect, Svg, View } from "@react-pdf/renderer";
import type { FC } from "react";

interface Props {
  amountLowScore: number;
  amountMediumScore: number;
  amountHighScore: number;
  totalAmountOfAnswers: number;
}

export const QuestionBarChart: FC<Props> = ({
  amountLowScore,
  amountMediumScore,
  amountHighScore,
  totalAmountOfAnswers,
}) => (
  <View>
    <Svg viewBox="0 0 0 0">
      <Rect x="0" y="0" width="230" height="8" fill="#fff" />
      <Rect
        x="0"
        y="0"
        width={`${(amountLowScore / totalAmountOfAnswers) * 230}`}
        height="8"
        fill="#EE7362"
      />
      <Rect
        x={`${(amountLowScore / totalAmountOfAnswers) * 230}`}
        y="0"
        width={`${(amountMediumScore / totalAmountOfAnswers) * 230}`}
        height="8"
        fill="#FDCF5B"
      />
      <Rect
        x={`${
          (amountMediumScore / totalAmountOfAnswers +
            amountLowScore / totalAmountOfAnswers) *
          230
        }`}
        y="0"
        width={`${(amountHighScore / totalAmountOfAnswers) * 230}`}
        height="8"
        fill="#068466"
      />
    </Svg>
  </View>
);
