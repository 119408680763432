import { useState, useEffect, VFC } from "react";
import { useTranslation } from "react-i18next";
import "./Companies.css";
import { Typography, Button } from "@material-ui/core";
import { useNavigate } from "react-router";
import { Add } from "@material-ui/icons";
import CompanyModal from "./companyModal/CompanyModal";
import Spacer from "../shared/Spacer";
import Loader from "../shared/loader/Loader";
import api from "../../utils/api/v1";
import SingleSelectionGrid from "../../UI-Components/SingleRowSelectionGrid/SingleRowSelectionGrid";
import type { ApiCompanyResponse } from "../../utils/api/apiInterfaces";

interface State {
  loading: boolean;
  error: boolean;
  companies: Array<ApiCompanyResponse>;
}

const Companies: VFC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [companyModal, setCompanyModal] = useState(false);
  const [, setWindowWidth] = useState(window.innerWidth);
  const [state, setState] = useState<State>({
    loading: false,
    error: false,
    companies: [],
  });

  const getCompanies = () => {
    setState({ ...state, loading: true, error: false });
    api
      .getCompanies()
      .then(({ data }) => {
        if (data) {
          setState({
            ...state,
            companies: data.sort((a, b) => {
              const aName = a.name;
              const bName = b.name;

              if (!aName && !bName) return 0;
              if (!aName) return -1;
              if (!bName) return 1;
              return aName.localeCompare(bName);
            }),
            loading: false,
            error: false,
          });
        }
      })
      .catch((e) => {
        console.error(e);
        setState({ ...state, loading: false, error: true });
      });
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    getCompanies();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      field: "name",
      type: "string",
      headerName: t("Companies.table.name"),
      width:
        window.innerWidth >= 960
          ? window.innerWidth / 1.4
          : window.innerWidth - 50,
    },
  ];

  const showCompanyModal = () => {
    setCompanyModal(true);
  };

  const closeCompanyModal = (id: string | null) => {
    setCompanyModal(false);
    if (id) {
      navigate(`/companies/${id}`);
    }
  };

  const mainGui = () => {
    if (state.loading)
      return <Loader text={t("Companies.loading")} style={{ marginTop: 40 }} />;
    if (!state.loading && state.companies) {
      return (
        <>
          <div className="section-toolbar">
            <Typography className="section-title" variant="h6">
              {t("Companies.title")}
            </Typography>
            <Spacer />
            <Button
              startIcon={<Add />}
              color="primary"
              variant="contained"
              onClick={showCompanyModal}
            >
              {t("Companies.create")}
            </Button>
            <CompanyModal open={companyModal} onClose={closeCompanyModal} />
          </div>
          <div className="section-SingleSelectionGrid-layout">
            <SingleSelectionGrid
              columns={columns}
              rows={state.companies}
              pageSize={10}
              loading={state.loading}
              path="companies"
            />
          </div>
        </>
      );
    }

    return <Loader text={t("Companies.loading")} style={{ marginTop: 40 }} />;
  };

  return <div className="companies-wrapper">{mainGui()}</div>;
};

export default Companies;
