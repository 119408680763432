import { Button } from "@material-ui/core";
import "./CsvImportButton.css";
import { useTranslation } from "react-i18next";

const CsvImportButton = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Button color="primary" variant="contained">
        {t("ButtonGeneral.csvButton")}
      </Button>
    </div>
  );
};

export default CsvImportButton;
