import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./MicroCourses.css";
import {
  Paper,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Tabs,
  Tab,
  Box,
  Divider,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import { languages, getLanguage } from "../../i18n";
import Spacer from "../shared/Spacer";
import api from "../../utils/api/v1";
import Loader from "../shared/loader/Loader";
import { MicroCourseResources } from "./MicroCourseResources/MicroCourseResources";
import { BackButton } from "../../UI-Components/Buttons/Buttons";
import type {
  ApiResponseMicroCourse,
  ApiResponseMicroCourseResource,
} from "../../utils/api/apiInterfaces";
import { MissingRequiredParamError } from "../errorHandling/MissingRequiredParamError";

interface State {
  loading: boolean;
  error: boolean;
  microCourse: ApiResponseMicroCourse | null;
  microCourseResources: Array<ApiResponseMicroCourseResource>;
}

const microCourseTypes = [
  "Personal Development",
  "Nutrition",
  "Recovery",
  "Physical Activity",
  "Relaxation",
];

const resolveComplexityLevelOrContentType = (
  value: string | number | null,
  lang: string
) => {
  if (value === null) return lang === "sv" ? "Inte satt" : "Not set";
  return value;
};

export const MicroCourseDetails = () => {
  const { t } = useTranslation();
  const { microCourseId } = useParams();
  const [openAlert, setOpenAlert] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [tab, setTab] = useState(0);
  const currentLanguage = getLanguage();
  const navigate = useNavigate();

  const [state, setState] = useState<State>({
    loading: false,
    error: false,
    microCourse: null,
    microCourseResources: [],
  });

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleChange = (
    _: React.ChangeEvent<unknown | string>,
    newValue: number
  ) => {
    setTab(newValue);
  };

  useEffect(() => {
    (async () => {
      await getMicroCourse();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!microCourseId) {
    return <MissingRequiredParamError missingParam="microCourseId" />;
  }

  const getMicroCourse = async () => {
    setState({ ...state, loading: true });
    try {
      const {
        data: { microCourse, microCourseResources },
      } = await api.getMicroCourse(microCourseId);
      setState({ ...state, microCourse, microCourseResources, loading: false });
    } catch (err) {
      setState({ ...state, error: true, loading: false });
    }
  };

  const handleDeleteMicroCourse = async () => {
    setState({ ...state, error: false, loading: true });
    try {
      await api.deleteMicroCourse(microCourseId);
      setDeleting(false);
      setOpenAlert(false);
      navigate("/micro-courses");
    } catch (err) {
      console.error(err);
      setDeleting(false);
      setOpenAlert(false);
      setState({ ...state, error: true, loading: false });
    }
  };

  const mainGui = () => {
    if (state.loading || deleting)
      return (
        <Loader
          text={t("MicroCourses.details.loading")}
          style={{ marginTop: 40 }}
        />
      );
    if (!state.loading && state.microCourse && !deleting) {
      return (
        <>
          <div className="section-toolbar">
            <BackButton navigateTo="/micro-courses" />
            <Typography className="microCourse-detail-title" variant="h6">
              {getLanguage() === "sv"
                ? state.microCourse.textData.nameSv
                : state.microCourse.textData.nameEn}
            </Typography>
            <Spacer />
            <Link to="edit" className="microCourse-details-link-style">
              <Button startIcon={<Edit />} color="primary" variant="contained">
                {t("ButtonGeneral.edit")}
              </Button>
            </Link>

            <Button
              startIcon={<Delete />}
              color="secondary"
              variant="contained"
              onClick={handleOpenAlert}
              style={{ margin: 5 }}
            >
              {t("ButtonGeneral.delete")}
            </Button>

            <Dialog
              open={openAlert}
              onClose={handleCloseAlert}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {t("MicroCourses.details.deleteAlert")}
              </DialogTitle>

              <DialogActions>
                <Button onClick={handleDeleteMicroCourse} color="primary">
                  {t("MicroCourses.details.acceptDeleteAlert")}
                </Button>
                <Button onClick={handleCloseAlert} color="primary" autoFocus>
                  {t("MicroCourses.details.denyDeleteAlert")}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <Tabs
            className="micro-course-details-tabs"
            value={tab}
            onChange={handleChange}
          >
            <LinkTab
              label={t("MicroCourses.details.details")}
              href={`/micro-courses/${microCourseId}`}
            />
            <LinkTab
              label={t("MicroCourses.details.resources")}
              href={`/micro-courses/${microCourseId}/resources`}
            />
          </Tabs>
          <Divider />
          <div>
            <Paper className="section-detail-layout">
              <TabPanel value={tab} index={0}>
                <div>
                  <Typography className="section-detail-title" variant="h5">
                    {t("MicroCourses.details.type")}
                  </Typography>
                  {microCourseTypes.map((type) => (
                    <FormControlLabel
                      className="microCourse-form-control-style"
                      key={type}
                      control={
                        <Radio
                          checked={state.microCourse?.type === type}
                          color="primary"
                          value={type}
                          name="microCourseType"
                        />
                      }
                      label={t(`MicroCourses.details.types.${type}`)}
                    />
                  ))}
                </div>
                <div className="complexity-level-container">
                  <Typography className="complexity-level-title" variant="h5">
                    {t("MicroCourses.details.complexityLevel")}
                    {": "}
                    <span>
                      {resolveComplexityLevelOrContentType(
                        state.microCourse.complexityLevel,
                        currentLanguage
                      )}
                    </span>
                  </Typography>
                </div>
                <div className="content-type-container">
                  <Typography className="content-type-title" variant="h5">
                    {t("MicroCourses.details.contentType")}
                    {": "}
                    <span>
                      {resolveComplexityLevelOrContentType(
                        state.microCourse.contentType,
                        currentLanguage
                      )}
                    </span>
                  </Typography>
                </div>
                <div>
                  {languages.map((lang) => (
                    <div
                      key={lang}
                      className="text-description-container-microCourse-details"
                    >
                      <div className="text-container-microCourse-details">
                        <h4>
                          {t("MicroCourses.details.name")}{" "}
                          {t(`Language.languages.${lang}`)}
                        </h4>
                        <p>
                          {state.microCourse &&
                            state.microCourse.textData[
                              `name${
                                lang.charAt(0).toUpperCase() + lang.slice(1)
                              }`
                            ]}
                        </p>
                      </div>
                      <div className="text-container-microCourse-details">
                        <h4>
                          {t("MicroCourses.details.shortDescription")}{" "}
                          {t(`Language.languages.${lang}`)}
                        </h4>
                        <p>
                          {state.microCourse &&
                            state.microCourse.textData[
                              `shortDescription${
                                lang.charAt(0).toUpperCase() + lang.slice(1)
                              }`
                            ]}
                        </p>
                      </div>
                      <div className="text-container-microCourse-details text-container-overflow-microCourse-details">
                        <h4>
                          {t("MicroCourses.details.description")}{" "}
                          {t(`Language.languages.${lang}`)}
                        </h4>
                        <p>
                          {state.microCourse &&
                            state.microCourse.textData[
                              `description${
                                lang.charAt(0).toUpperCase() + lang.slice(1)
                              }`
                            ]}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
                <div>
                  <p className="section-detail-header">
                    {t("MicroCourses.details.image")}
                  </p>
                  {state.microCourse.imageUrl ? (
                    <img
                      src={state.microCourse.imageUrl}
                      alt="microCourse"
                      className="microCourse-details-image-style"
                    />
                  ) : (
                    t("MicroCourses.details.noImage")
                  )}
                </div>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <MicroCourseResources
                  microCourseResources={state.microCourseResources}
                />
              </TabPanel>
            </Paper>
          </div>
        </>
      );
    }

    return (
      <Loader
        text={t("MicroCourses.details.loading")}
        style={{ marginTop: 40 }}
      />
    );
  };

  return <div className="surveys-wrapper">{mainGui()}</div>;
};

interface LinkTabProps {
  href: string;
  label: string;
}

const LinkTab = (props: LinkTabProps) => (
  <Tab
    component="a"
    onClick={(event) => event.preventDefault()}
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  />
);

const TabPanel = (props: {
  children: JSX.Element | Array<JSX.Element>;
  value: number;
  index: number;
}) => {
  // eslint-disable-next-line react/prop-types
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
};
