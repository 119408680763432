import moment from "moment";
import React, { useEffect, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import "./Cycles.css";
import {
  Paper,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Typography,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { format } from "date-fns";
import MomentUtils from "@date-io/moment";
import { Save } from "@material-ui/icons";
import { useNavigate, useParams } from "react-router";
import type { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import Spacer from "../../shared/Spacer";
import api from "../../../utils/api/v1";
import Loader from "../../shared/loader/Loader";
import { getLanguage } from "../../../i18n";
import { BackButton } from "../../../UI-Components/Buttons/Buttons";
import { MissingRequiredParamError } from "../../errorHandling/MissingRequiredParamError";
import type {
  ApiResponseSurveyWithoutQuestionModel,
  CycleModel,
} from "../../../utils/api/apiInterfaces";

interface Props {
  create?: boolean;
}

interface State {
  loading: boolean;
  error: boolean | string;
  cycle: CycleModel | null;
  surveys: Array<ApiResponseSurveyWithoutQuestionModel>;
}

const CycleEdit: VFC<Props> = ({ create }) => {
  const { t } = useTranslation();
  const { companyId, cycleId } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useState<State>({
    loading: false,
    error: "",
    cycle: null,
    surveys: [],
  });
  const [startDatePassed, setStartDatePassed] = useState(false);

  useEffect(() => {
    if (!create) {
      getCycle();
    } else {
      api.getSurveys().then(({ data }) => {
        setState({
          ...state,
          cycle: {
            surveyId: "",
            startDate: String(moment()),
            endDate: String(moment().add(1, "days")),
          },
          surveys: data,
          loading: false,
          error: false,
        });
        setStartDatePassed(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!companyId) {
    return <MissingRequiredParamError missingParam="companyId" />;
  }

  const getCycle = () => {
    setState({ ...state, loading: true, error: "" });
    const newState = { ...state, loading: false };
    Promise.all([
      api
        .getCycle(companyId, cycleId ?? "")
        .then(({ data }) => ({ cycle: data }))
        .catch(() => ({ error: "get" })),
      api
        .getSurveys()
        .then(({ data }) => ({ surveys: data }))
        .catch(() => ({ error: "get" })),
    ]).then(([cycle, surveys]) => {
      if ("cycle" in cycle && "surveys" in surveys) {
        setState({ ...state, ...newState, ...cycle, ...surveys });
        setStartDatePassed(
          window.location.pathname.includes("edit") &&
            Date.parse(cycle.cycle.startDate) <= Date.now()
        );
      }
    });
  };

  const updateSurvey = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    if (state.cycle) {
      setState({
        ...state,
        cycle: { ...state.cycle, surveyId: String(event.target.value) },
      });
    }
  };

  const updateDate = (
    name: string,
    date: MaterialUiPickersDate | undefined
  ) => {
    if (date && state.cycle) {
      const formatDate = date.set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });

      const endDate = new Date(state.cycle.endDate);

      const afterStartDate =
        name === "startDate" && date.toDate().getTime() >= endDate.getTime()
          ? moment(date).add(1, "days")
          : date;

      setState({
        ...state,
        cycle: {
          ...state.cycle,
          [name]: formatDate.format(),
          endDate: String(afterStartDate),
        },
      });
    }
  };

  const handleSave = () => {
    if (
      state.surveys.findIndex(
        (survey) => state.cycle && state.cycle.surveyId === survey.id
      ) === -1
    ) {
      setState({ ...state, error: "survey" });
      return;
    }
    if (
      state.cycle &&
      (!state.cycle.startDate || !moment(state.cycle.startDate).isValid())
    ) {
      setState({ ...state, error: "startDate" });
      return;
    }
    if (
      state.cycle &&
      (!state.cycle.endDate || !moment(state.cycle.endDate).isValid())
    ) {
      setState({ ...state, error: "endDate" });
      return;
    }
    if (state.cycle) {
      setState({ ...state, loading: true, error: "" });

      const cycleData = state.cycle;
      const timezoneOffset = new Date().getTimezoneOffset();

      const parsedStartDate =
        Date.parse(cycleData.startDate) - timezoneOffset * 60 * 1000;
      const parsedEndDate =
        Date.parse(cycleData.endDate) - timezoneOffset * 60 * 1000;

      cycleData.startDate = format(parsedStartDate, "yyyy-MM-dd");

      cycleData.endDate = format(parsedEndDate, "yyyy-MM-dd");

      (create
        ? api.createCycle(companyId, cycleData)
        : api.updateCycle(companyId, cycleId ?? "", cycleData)
      )
        .then(({ data }) => {
          if (data) {
            navigate(`/companies/${companyId}/cycles/${data.id}`);
          }
        })
        .catch((error) => {
          console.error(error);
          setState({ ...state, loading: false, error: "saving" });
        });
    }
  };

  const mainGui = () => {
    if (state.loading)
      return <Loader text={t("CycleEdit.loading")} style={{ marginTop: 40 }} />;
    if (!state.loading && state.cycle) {
      return (
        <>
          <div className="cycles-toolbar">
            <BackButton />
            <Typography className="cycles-title" variant="h6">
              {t(create ? "CycleEdit.title.create" : "CycleEdit.title.edit")}
            </Typography>
            <Spacer />
            <Button
              color="primary"
              variant="contained"
              startIcon={<Save />}
              onClick={handleSave}
              data-testid="saveCycleButton"
            >
              {t("CycleEdit.save")}
            </Button>
          </div>
          <Paper className="section-detail-layout">
            <div className="cycle-edit-form">
              <FormControl className="cycle-edit-survey-select">
                <InputLabel
                  id="cycle-edit-survey-select-label"
                  className={startDatePassed ? "cycle-input-style" : ""}
                >
                  {t("CycleEdit.survey")}
                </InputLabel>
                <Select
                  labelId="cycle-edit-survey-select-label"
                  value={state.cycle.surveyId}
                  onChange={updateSurvey}
                  disabled={startDatePassed}
                  data-testid="cyclesSelect"
                >
                  {state.surveys.map((survey) => (
                    <MenuItem
                      key={survey.id}
                      value={survey.id}
                      data-testid="cyclesSelectItem"
                    >
                      {survey.name}
                    </MenuItem>
                  ))}
                </Select>
                {startDatePassed ? (
                  <span
                    style={{
                      color: "#f44336",
                      fontSize: "12px",
                      marginTop: "3px",
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    }}
                  >
                    {t("CycleEdit.surveyMessage")}
                  </span>
                ) : (
                  ""
                )}
                {state.error === "survey" ? (
                  <span
                    style={{ color: "red", fontSize: 14 }}
                    data-testid="inputErrorMessage"
                  >
                    {t("CycleEdit.error.survey")}
                  </span>
                ) : null}
              </FormControl>
              <MuiPickersUtilsProvider
                libInstance={moment}
                utils={MomentUtils}
                locale={getLanguage()}
              >
                <KeyboardDatePicker
                  autoOk
                  disableToolbar
                  variant="inline"
                  format="DD / MM / yyyy"
                  margin="normal"
                  data-testid="startDate"
                  label={t("CycleEdit.startDate")}
                  value={state.cycle.startDate}
                  disabled={startDatePassed}
                  minDate={Date.now()}
                  minDateMessage={t("CycleEdit.dateMessage")}
                  onChange={(date) => updateDate("startDate", date)}
                  KeyboardButtonProps={{
                    "aria-label": "change start date",
                  }}
                />
                <KeyboardDatePicker
                  autoOk
                  disableToolbar
                  variant="inline"
                  format="DD / MM / yyyy"
                  margin="normal"
                  data-testid="endDate"
                  label={t("CycleEdit.endDate")}
                  value={state.cycle.endDate}
                  minDate={moment(state.cycle.startDate).add(1, "days")}
                  minDateMessage={t("CycleEdit.dateMessage")}
                  onChange={(date) => updateDate("endDate", date)}
                  KeyboardButtonProps={{
                    "aria-label": "change end date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Paper>
        </>
      );
    }

    return <Loader text={t("CycleEdit.loading")} style={{ marginTop: 40 }} />;
  };

  return <div className="cycles-wrapper">{mainGui()}</div>;
};

export default CycleEdit;
