import type { VFC } from "react";
import { useTranslation } from "react-i18next";
import "./CircleDiagram.css";

interface Props {
  category: string;
  data: Array<number>;
}

export const CircleDiagram: VFC<Props> = ({ category, data }) => {
  const { t } = useTranslation();

  const redCount = () => data.filter((cat) => cat <= 4).length;
  const yellowCount = () => data.filter((cat) => cat > 4 && cat < 7).length;
  const greenCount = () => data.filter((cat) => cat >= 7).length;

  const getProgressColorStatus = () => {
    const greenAmount = data.filter((cat) => cat >= 7).length;
    const total = data.length;

    const greenEnd = (greenAmount / total) * 100;
    const yellowStart = greenEnd;
    const yellowEnd = (yellowCount() / total) * 100 + yellowStart;
    const redStart = yellowEnd;
    const redEnd = (redCount() / total) * 100 + redStart;

    return `conic-gradient(rgb(6, 132, 102) 0%, rgba(6, 132, 102, 0.75) ${greenEnd}%, #FDCF5B ${yellowStart}%, rgba(238, 184, 98, 1) ${yellowEnd}%, rgb(238, 115, 98) ${redStart}%, rgba(255, 65, 65, 0.75) ${redEnd}%)`;
  };

  const getPercentage = (color: string) => {
    if (color === "red") {
      return parseFloat(((redCount() / data.length) * 100).toFixed(1));
    }
    if (color === "yellow")
      return parseFloat(((yellowCount() / data.length) * 100).toFixed(1));
    if (color === "green")
      return parseFloat(((greenCount() / data.length) * 100).toFixed(1));
    return 0;
  };

  const gui = () => (
    <div className="circle-diagram-wrapper">
      <h4>{t(`CycleResults.${category}`)}</h4>

      <div className="circles-container">
        <div
          className="distribution-circle-outer"
          style={{ background: getProgressColorStatus() }}
        />
        <div className="distribution-circle-center" />
        <div className="span-wrapper">
          <span style={{ top: 73, right: 92 }}>
            <div
              className="percentage"
              style={{ backgroundColor: "rgb(6, 132, 102)" }}
            />{" "}
            {getPercentage("green")} %
          </span>
          <span style={{ top: 93, right: 92 }}>
            <div
              className="percentage"
              style={{ backgroundColor: "#FDCF5B" }}
            />{" "}
            {getPercentage("yellow")} %
          </span>
          <span style={{ top: 113, right: 92 }}>
            <div
              className="percentage"
              style={{ backgroundColor: "rgb(238, 115, 98)" }}
            />{" "}
            {getPercentage("red")} %
          </span>
        </div>
      </div>
    </div>
  );

  return data ? gui() : <p>loading</p>;
};
