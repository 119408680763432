import React, { useState, useEffect } from "react";
import { Typography, Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import { CloudinaryContext } from "cloudinary-react";
import { useParams } from "react-router";
import Select from "@material-ui/core/Select";
import { useTranslation } from "react-i18next";
import Loader from "../../../../shared/loader/Loader";
import Spacer from "../../../../shared/Spacer";
import { openUploadWidget } from "../../../../../utils/cloudinary/cloudinaryService";
import { createGuid } from "../../../../../utils/helpers/createGuid";
import api from "../../../../../utils/api/v1";
import { baseGuid } from "../../../../../common/constants/guid";

function MotivationCompanyMail() {
  const { t } = useTranslation();
  const { companyId } = useParams();

  const [state, setState] = useState({
    loading: false,
    error: false,
  });

  const [requiredInfo, setRequiredInfo] = useState({
    year: 0,
    week: 0,
  });

  const [englishPdfDocument, setEnglishPdfDocument] = useState({
    Id: baseGuid.empty,
    CompanyId: companyId,
    Year: requiredInfo.year,
    Week: requiredInfo.week,
    Language: "english",
    PublicId: baseGuid.empty,
    PublicUrl: "",
  });

  const [svenskaPdfDocument, setSvenskaPdfDocument] = useState({
    Id: baseGuid.empty,
    CompanyId: companyId,
    Year: requiredInfo.year,
    Week: requiredInfo.week,
    Language: "svenska",
    PublicId: baseGuid.empty,
    PublicUrl: "",
  });

  const [fetched, setFetched] = useState(false);

  // TODO - FIX THIS.
  const years = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= 20; i++) {
    years.push(i);
  }

  const weeks = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= 26; i++) {
    weeks.push(i * 2);
  }

  const uploadImageToCloudinary = async (week, language) => {
    const thePDFPublicId = createGuid();

    const uploadOptions = {
      cloudName: process.env.REACT_APP_CLOUD_NAME,
      uploadPreset: process.env.REACT_APP_CLOUD_UPLOAD_PRESET,
      publicId: `companyMotivationEmailsPDF/${companyId}/${language}/${week}/${thePDFPublicId}`,
      maxFiles: 1,
      sources: ["local"],
      preBatch: (cb, data) => {
        if (
          !data.files[0].name.endsWith("pdf") &&
          !data.files[0].name.endsWith("jpeg") &&
          !data.files[0].name.endsWith("png") &&
          !data.files[0].name.endsWith("jfif")
        ) {
          console.log("Wrong file type.");
          // TODO: Add a modal to inform user about the error.
          cb({ cancel: true });
        } else {
          cb();
        }
      },
    };

    openUploadWidget(uploadOptions, async (error, res) => {
      if (res.event === "success") {
        setState({ ...state, loading: true, error: false });
        if (language === "english") {
          setEnglishPdfDocument({
            ...englishPdfDocument,
            PublicId: thePDFPublicId,
            PublicUrl: res.info.secure_url,
          });
        } else {
          setSvenskaPdfDocument({
            ...svenskaPdfDocument,
            PublicId: thePDFPublicId,
            PublicUrl: res.info.secure_url,
          });
        }
        const createdMotivationMail = {
          CompanyId: companyId,
          Year: requiredInfo.year,
          Week: requiredInfo.week,
          Language: language,
          PublicId: thePDFPublicId,
          PublicUrl: res.info.secure_url,
        };
        if (
          (language === "english" &&
            englishPdfDocument.PublicId !== baseGuid.empty) ||
          (language === "svenska" &&
            svenskaPdfDocument.PublicId !== baseGuid.empty)
        ) {
          if (language === "english") {
            createdMotivationMail.Id = englishPdfDocument.Id;
          } else {
            createdMotivationMail.Id = svenskaPdfDocument.Id;
          }
          try {
            await api
              .updateCompanyMotivationMail(createdMotivationMail)
              .then(({ data }) => {
                if (data.language === "english") {
                  setEnglishPdfDocument({
                    ...englishPdfDocument,
                    PublicId: data.publicId,
                    PublicUrl: data.publicUrl,
                  });
                } else {
                  setSvenskaPdfDocument({
                    ...svenskaPdfDocument,
                    PublicId: data.publicId,
                    PublicUrl: data.publicUrl,
                  });
                }
                setState({ ...state, loading: false });
              });
          } catch (err) {
            console.log(err);
            setState({ ...state, error: true, loading: false });
          }
        } else {
          try {
            await api
              .createCompanyMotivationMail(createdMotivationMail)
              .then(({ data }) => {
                if (data.language === "english") {
                  setEnglishPdfDocument({
                    ...englishPdfDocument,
                    Id: data.id,
                    PublicUrl: data.publicUrl,
                    PublicId: data.publicId,
                  });
                } else {
                  setSvenskaPdfDocument({
                    ...svenskaPdfDocument,
                    Id: data.id,
                    PublicUrl: data.publicUrl,
                    PublicId: data.publicId,
                  });
                }
                setState({ ...state, loading: false });
              });
          } catch (err) {
            console.log(err);
            setState({ ...state, error: true, loading: false });
          }
          setState({ ...state, loading: false, error: false });
        }
      } else {
        console.log(error);
        setState({ ...state, error: true, loading: false });
      }
    });
  };

  const getMotivationMailForYearAndWeek = () => {
    if (requiredInfo.year !== 0 && requiredInfo.week !== 0 && !fetched) {
      const formData = new FormData();
      formData.append("requiredInfo", JSON.stringify(requiredInfo));
      try {
        api
          .getCompanyMotivationEmailsByCompanyIdAndYear(companyId, formData, {
            "content-type": "multipart/form-data",
          })
          .then(({ data }) => {
            if (data.length !== 0 && data !== []) {
              setFetched(true);
              data.forEach((pdf) => {
                if (pdf.language === "english") {
                  setEnglishPdfDocument({
                    ...englishPdfDocument,
                    Id: pdf.id,
                    PublicId: pdf.publicId,
                    PublicUrl: pdf.publicUrl,
                  });
                } else {
                  setSvenskaPdfDocument({
                    ...svenskaPdfDocument,
                    Id: pdf.id,
                    PublicId: pdf.publicId,
                    PublicUrl: pdf.publicUrl,
                  });
                }
              });
            } else {
              setEnglishPdfDocument({
                ...englishPdfDocument,
                PublicUrl: "",
              });
              setSvenskaPdfDocument({
                ...svenskaPdfDocument,
                PublicUrl: "",
              });
            }
            setFetched(true);
            setState({ ...state, loading: false });
          });
      } catch (error) {
        console.log(error);
        setState({ ...state, error: true, loading: false });
      }
    }
  };

  const handleDelete = async (language) => {
    if (language === "english") {
      setEnglishPdfDocument({
        ...englishPdfDocument,
        PublicUrl: "",
      });
    } else {
      setSvenskaPdfDocument({
        ...svenskaPdfDocument,
        PublicUrl: "",
      });
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    setRequiredInfo({ ...requiredInfo, [name]: value });
    if (requiredInfo.week !== 0 && requiredInfo.year !== 0 && fetched) {
      setFetched(false);
    }
  };

  useEffect(() => {
    getMotivationMailForYearAndWeek();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleChange, setEnglishPdfDocument, setSvenskaPdfDocument]);

  const useStyles = makeStyles((theme) => ({
    formControl: {
      background: "#ffffff",
      margin: theme.spacing(2),
      width: 500,
      minWidth: 500,
    },
    buttonrow: {
      display: "flex",
      flexDirection: "row",
      margin: "15px 0 15px 0",
    },
    root: {
      flexGrow: 1,
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    headlines: {
      margin: theme.spacing(2),
    },
    button: {
      margin: theme.spacing(1),
      width: "200px",
    },
    row: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: "100%",
      float: "left",
      padding: "20px",
      borderBottom: "5px inset #eeb862",
    },
  }));

  const classes = useStyles();

  if (state.loading) {
    return (
      <Loader
        text={t("DefaultCompanyMail.SavingChanges")}
        style={{ marginTop: 40 }}
      />
    );
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {Object.keys(requiredInfo).map((field) => (
          <Grid key={field} item xs={12} style={{ textAlign: "center" }}>
            <Typography className={classes.headlines} variant="h6">
              {t("MotivationCompanyMail.Choose")}{" "}
              {t(`MotivationCompanyMail.${field}`)}
            </Typography>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">
                {t(`MotivationCompanyMail.${field}`)}
              </InputLabel>
              {field === "year" ? (
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name={field}
                  value={requiredInfo.year}
                  onChange={handleChange}
                  placeholder={t(`MotivationCompanyMail.${field}`)}
                  label={t(`MotivationCompanyMail.${field}`)}
                >
                  <MenuItem key={field} value={0}>
                    {t("MotivationCompanyMail.Choose")}{" "}
                    {t(`MotivationCompanyMail.${field}`)}
                  </MenuItem>
                  {years.map((yearnumber) => (
                    <MenuItem key={yearnumber * 10} value={yearnumber}>
                      {yearnumber}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name={field}
                  value={requiredInfo.week}
                  onChange={handleChange}
                  placeholder={t(`MotivationCompanyMail.${field}`)}
                  label={t(`MotivationCompanyMail.${field}`)}
                >
                  <MenuItem key={field} value={0}>
                    {t("MotivationCompanyMail.Choose")}{" "}
                    {t(`MotivationCompanyMail.${field}`)}
                  </MenuItem>
                  {weeks.map((weeknumber) => (
                    <MenuItem key={weeknumber * 11} value={weeknumber}>
                      {weeknumber}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormControl>
            <Spacer />
          </Grid>
        ))}
        {requiredInfo.year !== 0 && requiredInfo.week !== 0 ? (
          <>
            <Grid item xs={4} sm={6}>
              <div className={classes.row}>
                <Typography className={classes.headlines} variant="h6">
                  {t("MotivationCompanyMail.week")} {requiredInfo.week} English
                </Typography>
                {englishPdfDocument.PublicUrl !== "" ? (
                  <iframe
                    scrolling="no"
                    title="pdf display"
                    src={englishPdfDocument.PublicUrl}
                    width="fit-content"
                    height="300px"
                  />
                ) : null}
                <div className={classes.buttonrow}>
                  <CloudinaryContext
                    cloudName={process.env.REACT_APP_CLOUD_NAME}
                  >
                    <Button
                      variant="contained"
                      color="default"
                      className={classes.button}
                      startIcon={<CloudUploadIcon />}
                      onClick={() =>
                        uploadImageToCloudinary(requiredInfo.week, "english")
                      }
                    >
                      {t("MotivationCompanyMail.Upload")}
                    </Button>
                  </CloudinaryContext>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    startIcon={<DeleteIcon />}
                    onClick={() => handleDelete("english")}
                  >
                    {t("MotivationCompanyMail.Delete")}
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid item xs={4} sm={6}>
              <div className={classes.row}>
                <Typography className={classes.headlines} variant="h6">
                  {t("MotivationCompanyMail.week")} {requiredInfo.week} Svenska
                </Typography>
                {svenskaPdfDocument.PublicUrl !== "" ? (
                  <iframe
                    scrolling="no"
                    title="pdf display"
                    src={svenskaPdfDocument.PublicUrl}
                    width="fit-content"
                    height="300px"
                  />
                ) : null}
                <div className={classes.buttonrow}>
                  <CloudinaryContext
                    cloudName={process.env.REACT_APP_CLOUD_NAME}
                  >
                    <Button
                      variant="contained"
                      color="default"
                      className={classes.button}
                      startIcon={<CloudUploadIcon />}
                      onClick={() =>
                        uploadImageToCloudinary(requiredInfo.week, "svenska")
                      }
                    >
                      {t("MotivationCompanyMail.Upload")}
                    </Button>
                  </CloudinaryContext>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    startIcon={<DeleteIcon />}
                    onClick={() => handleDelete("svenska")}
                  >
                    {t("MotivationCompanyMail.Delete")}
                  </Button>
                </div>
              </div>
            </Grid>
          </>
        ) : null}
      </Grid>
    </div>
  );
}

export default MotivationCompanyMail;
