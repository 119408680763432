import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "./Modules.css";
import {
  Typography,
  Divider,
  Button,
  Paper,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  FormHelperText,
} from "@material-ui/core";
import { useNavigate, useParams } from "react-router";
import { Save, Delete } from "@material-ui/icons";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Spacer from "../shared/Spacer";
import api from "../../utils/api/v1";
import Loader from "../shared/loader/Loader";
import { languages } from "../../i18n";
import { BackButton } from "../../UI-Components/Buttons/Buttons";

const ModuleEdit = ({ create }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { moduleId } = useParams();
  const [form, setForm] = useState({
    textData: {
      nameEn: "",
      nameSv: "",
    },
    questionCategories: {
      isPhysical: false,
      isStress: false,
      isDevelopment: false,
    },
  });
  const [state, setState] = useState({
    loading: false,
    error: false,
    module: form,
    file: null,
  });
  const [createForm, setCreateForm] = useState({
    category: "",
    nameEn: "",
    nameSv: "",
    descriptionSv: "",
    descriptionEn: "",
    shortDescriptionSv: "",
    shortDescriptionEn: "",
  });
  const [fileForDisplay, setFileForDisplay] = useState({
    fileForImageUrl: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showErrorAll, setShowErrorAll] = useState(false);
  const [showError, setShowError] = useState({
    category: false,
    nameEn: false,
    nameSv: false,
    descriptionSv: false,
    descriptionEn: false,
    shortDescriptionSv: false,
    shortDescriptionEn: false,
  });

  const categories = ["isPhysical", "isStress", "isDevelopment"];

  const onSelectImageHandler = (files) => {
    setState({ ...state, file: files[0] });
    setFileForDisplay({
      ...fileForDisplay,
      fileForImageUrl: files[0].name,
    });
  };

  const handleOpenDeleteModalImage = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDeleteImageAsset = () => {
    setState({ ...state, file: null, loading: true, error: false });

    api
      .deleteModuleAsset(state.module.imagePublicId)
      .then(() => {
        setFileForDisplay({
          ...fileForDisplay,
          fileForImageUrl: "",
        });
        setState({ ...state, loading: false });
        handleCloseModal();
      })
      .catch((err) => {
        console.error(err);
        setState({ ...state, loading: false, error: true });
      });
  };

  const getModule = () => {
    setState({ ...state, loading: true, error: false });
    api
      .getModule(moduleId)
      .then((result) => {
        setForm(result.data);
        setState({
          ...state,
          module: result.data,
          loading: false,
          error: false,
        });
        setFileForDisplay({
          fileForImageUrl: result.data.imageUrl,
        });
      })
      .catch((err) => {
        console.error(err);
        setState({ ...state, error: true, loading: false });
      });
  };

  const handleSave = () => {
    if (
      Object.values(form.textData).some((value) => value === "") ||
      Object.values(form.questionCategories).every((value) => value === false)
    ) {
      setShowErrorAll(true);
      return;
    }
    setState({ ...state, loading: true });

    if (create) {
      const formData = new FormData();
      formData.append("file", state.file);
      formData.append("form", JSON.stringify(createForm));

      setState({ ...state, loading: true });
      api
        .createModule(formData, { "content-type": "multipart/form-data" })
        .then(() => {
          setState({ ...state, loading: false, error: false });
          navigate(`/modules`);
        })
        .catch((err) => {
          console.error(err);
          setState({ loading: false, error: true });
        });
    } else if (!create) {
      const formData = new FormData();
      formData.append("file", state.file);
      formData.append("form", JSON.stringify(form));
      api
        .updateModule(moduleId, formData)
        .then(() => {
          navigate(`/modules/${moduleId}`);
        })
        .catch((err) => {
          console.error(err);
          setState({ loading: false, error: err });
        });
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    setShowError({ ...showError, [name]: true });
    setForm({
      ...form,
      textData: {
        ...form.textData,
        [name]: value,
      },
    });
    setCreateForm({
      ...createForm,
      [name]: value,
    });
  };

  const handleCheckboxChange = (event) => {
    setForm({
      ...form,
      questionCategories: {
        isPhysical: false,
        isStress: false,
        isDevelopment: false,
        [event.target.name]: !!event.target.checked,
      },
    });
    setCreateForm({
      ...createForm,
      category: event.target.value.slice(2),
    });
  };

  useEffect(() => {
    if (!create) {
      getModule();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkRequiredInput = (input) =>
    (showError[input] || showErrorAll) &&
    (form.textData[input] === "" || form.textData[input] === undefined);

  const generateHelperText = (input, fieldName) =>
    checkRequiredInput(input)
      ? t("missingMandatoryInput", { field: fieldName })
      : "";

  const mainGui = () => {
    if (state.loading) {
      return (
        <Loader text={t("ModuleEdit.loading")} style={{ marginTop: 40 }} />
      );
    }
    if (!state.loading && state.module) {
      return (
        <>
          <div className="section-toolbar">
            <BackButton />
            <Typography className="section-title" variant="h6">
              {create ? t("ModuleEdit.titleCreate") : t("ModuleEdit.titleEdit")}
            </Typography>
            <Spacer />
            <Button
              color="primary"
              variant="contained"
              startIcon={<Save />}
              onClick={handleSave}
            >
              {t("CompanyDetails.save")}
            </Button>
          </div>

          <Divider />

          <div className="companies-content">
            <Paper className="company-details">
              <div>
                <div className="module-edit-category-container">
                  {t("SurveyDetails.categories.categories")} <br />
                  {categories.map((category) => (
                    <FormControlLabel
                      key={category}
                      control={
                        <Radio
                          checked={form.questionCategories[category] === true}
                          onChange={handleCheckboxChange}
                          color="primary"
                          value={category}
                          name={category}
                        />
                      }
                      label={t(`ModuleEdit.categories.${category}`)}
                    />
                  ))}
                  <FormHelperText style={{ color: "red" }}>
                    {(showError.category || showErrorAll) &&
                    Object.values(form.questionCategories).every(
                      (value) => value === false
                    )
                      ? t("missingMandatoryInput", {
                          field: t("Modules.category"),
                        })
                      : ""}
                  </FormHelperText>
                </div>

                {languages.map((lang) => (
                  <div key={lang}>
                    <div className="survey-form-text">
                      <TextField
                        name={`name${
                          lang.charAt(0).toUpperCase() + lang.slice(1)
                        }`}
                        label={`${t(`ResourceEdit.name`)} ${t(
                          `Language.languages.${lang}`
                        )}`}
                        variant="outlined"
                        fullWidth
                        multiline
                        required
                        value={
                          form.textData[
                            `name${
                              lang.charAt(0).toUpperCase() + lang.slice(1)
                            }`
                          ] || ""
                        }
                        error={checkRequiredInput(
                          `name${lang.charAt(0).toUpperCase() + lang.slice(1)}`
                        )}
                        helperText={generateHelperText(
                          `name${lang.charAt(0).toUpperCase() + lang.slice(1)}`,
                          t("ResourceEdit.name")
                        )}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="survey-form-text">
                      <TextField
                        name={`shortDescription${
                          lang.charAt(0).toUpperCase() + lang.slice(1)
                        }`}
                        label={`${t(`ResourceEdit.shortDesc`)} ${t(
                          `Language.languages.${lang}`
                        )}`}
                        variant="outlined"
                        fullWidth
                        multiline
                        required
                        rows={2}
                        rowsMax={15}
                        value={
                          form.textData[
                            `shortDescription${
                              lang.charAt(0).toUpperCase() + lang.slice(1)
                            }`
                          ] || ""
                        }
                        error={checkRequiredInput(
                          `shortDescription${
                            lang.charAt(0).toUpperCase() + lang.slice(1)
                          }`
                        )}
                        helperText={generateHelperText(
                          `shortDescription${
                            lang.charAt(0).toUpperCase() + lang.slice(1)
                          }`,
                          t("ResourceEdit.shortDesc")
                        )}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="survey-form-text">
                      <TextField
                        name={`description${
                          lang.charAt(0).toUpperCase() + lang.slice(1)
                        }`}
                        label={`${t(`ResourceEdit.desc`)} ${t(
                          `Language.languages.${lang}`
                        )}`}
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}
                        rowsMax={25}
                        required
                        value={
                          form.textData[
                            `description${
                              lang.charAt(0).toUpperCase() + lang.slice(1)
                            }`
                          ] || ""
                        }
                        error={checkRequiredInput(
                          `description${
                            lang.charAt(0).toUpperCase() + lang.slice(1)
                          }`
                        )}
                        helperText={generateHelperText(
                          `description${
                            lang.charAt(0).toUpperCase() + lang.slice(1)
                          }`,
                          t("ResourceEdit.desc")
                        )}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                ))}
              </div>

              <div className="module-file-upload-wrapper">
                <div className="module-edit-image-header">
                  {t("ModuleEdit.image")} (jpg, jpeg, png)
                </div>
                <label htmlFor="moduleImage" className="module-input-style">
                  {t(`ResourceEdit.upload`)}
                  <input
                    id="moduleImage"
                    type="file"
                    name="image"
                    accept=".jpeg,.jpg,.png"
                    style={{ visibility: "hidden" }}
                    className="hidden-input-style"
                    onChange={(e) => onSelectImageHandler(e.target.files)}
                  />
                </label>

                {fileForDisplay.fileForImageUrl && (
                  <div className="module-edit-image-filename">
                    {t(`ResourceEdit.uploaded`)}
                    <br />
                    {fileForDisplay.fileForImageUrl}
                  </div>
                )}
              </div>

              {!create && (
                <Button
                  color="secondary"
                  variant="contained"
                  name="deleteImage"
                  startIcon={<Delete />}
                  disabled={
                    !fileForDisplay.fileForImageUrl ||
                    fileForDisplay.fileForImageUrl === ""
                  }
                  className="module-delete-button-style"
                  onClick={handleOpenDeleteModalImage}
                >
                  {t("ResourceEdit.delete")}
                </Button>
              )}

              <div className="button-text-wrapper">
                <Dialog
                  open={isModalOpen}
                  onClose={handleCloseModal}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    <Typography className="reinforced-delete-alert-style">
                      {t("ResourceEdit.deleteAlertReinforced")}
                    </Typography>
                  </DialogTitle>

                  <DialogActions>
                    <Button
                      onClick={() => handleDeleteImageAsset()}
                      color="primary"
                    >
                      {t("ButtonGeneral.acceptDeleteAlert")}
                    </Button>
                    <Button onClick={handleCloseModal} color="primary">
                      {t("ButtonGeneral.denyDeleteAlert")}
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </Paper>
          </div>
        </>
      );
    }
    return <Loader text={t("ModuleEdit.loading")} style={{ marginTop: 40 }} />;
  };

  ModuleEdit.propTypes = {
    create: PropTypes.bool,
  };

  ModuleEdit.defaultProps = {
    create: false,
  };

  return <div className="companies-wrapper">{mainGui()}</div>;
};

export default ModuleEdit;
