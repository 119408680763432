export const lowestCategoryName = (
  physicalResult: number,
  stressResult: number,
  developmentResult: number
) => {
  const lowestCategoryNames = [];
  const lowestResult = Math.min(
    physicalResult,
    stressResult,
    developmentResult
  );
  if (lowestResult === stressResult) lowestCategoryNames.push("stressResult");
  if (lowestResult === physicalResult)
    lowestCategoryNames.push("physicalResult");
  if (lowestResult === developmentResult)
    lowestCategoryNames.push("developmentResult");
  return lowestCategoryNames;
};
