import { useState, useEffect, VFC, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import "./Employees.css";
import {
  Typography,
  Button,
  Card,
  Dialog,
  DialogTitle,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import { Add, Delete, ToggleOn } from "@material-ui/icons";
import { useParams } from "react-router";
import type { GridSelectionModel } from "@mui/x-data-grid";
import { getLanguage } from "../../../i18n";
import Spacer from "../../shared/Spacer";
import api from "../../../utils/api/v1";
import Loader from "../../shared/loader/Loader";
import InviteEmployeeModal from "./InviteEmployeeModal";
import CheckboxSelectionGrid, {
  Columns,
} from "../../../UI-Components/CheckboxSelectionGrid/CheckboxSelectionGrid";
import { EditEmployeeDepartmentRightsModal } from "./EditEmployeeDepartmentRightsModal";
import type { ApiResponseEmployeeWithUserData } from "../../../utils/api/apiInterfaces";
import { MissingRequiredParamError } from "../../errorHandling/MissingRequiredParamError";

export interface EmployeeState {
  loading: boolean;
  error: boolean;
  toggleClientLoading: boolean;
  employees?: Array<ApiResponseEmployeeWithUserData>;
}

interface Props {
  setCompanyHasEmployees: Dispatch<SetStateAction<boolean>>;
}

let employeesToBeDeleted: GridSelectionModel = [];

const Employees: VFC<Props> = ({ setCompanyHasEmployees }) => {
  const { t } = useTranslation();
  const { companyId } = useParams();
  const [inviteModal, setInviteModal] = useState<boolean>(false);
  const [
    employeeDepartmentRightsModalOpen,
    setEmployeeDepartmentRightsModalOpen,
  ] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [selection, setSelection] = useState<GridSelectionModel>([]);
  const [, setWindowWidth] = useState(window.innerWidth);
  const [state, setState] = useState<EmployeeState>({
    loading: false,
    toggleClientLoading: false,
    error: false,
    employees: [],
  });

  useEffect(() => {
    (async () => {
      await getEmployees();
    })();

    window.addEventListener("resize", handleResize);

    window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!companyId) {
    return <MissingRequiredParamError missingParam="companyId" />;
  }

  const getEmployees = async () => {
    setState({ ...state, loading: true, error: false });
    try {
      const { data } = await api.getEmployees(companyId);
      const timezoneOffset = new Date().getTimezoneOffset();
      data.forEach((employee) => {
        /* eslint no-param-reassign: ["error", { "props": false }] */
        employee.updatedAt = String(
          Date.parse(employee.updatedAt) - timezoneOffset * 60 * 1000
        );

        employee.lastLogin = String(
          Date.parse(employee.lastLogin) - timezoneOffset * 60 * 1000
        );

        if (!employee.lastLogin) {
          employee.lastLogin = "-";
        }

        return employee;
      });

      if (data.length > 0) {
        setCompanyHasEmployees(true);
      } else {
        setCompanyHasEmployees(false);
      }

      setSelection([]);
      setState({
        ...state,
        employees: (data || []).sort((a, b) => {
          const aEmail = a.email;
          const bEmail = b.email;

          if (!aEmail && !bEmail) return 0;
          if (!aEmail) return -1;
          if (!bEmail) return 1;
          return aEmail.localeCompare(bEmail);
        }),
        loading: false,
        error: false,
      });
    } catch (err) {
      console.error(err);
      setState({ ...state, loading: false, error: true });
    }
  };

  // --- MODAL HANDLING --- //
  const showInviteModal = () => {
    setInviteModal(true);
  };

  const closeInviteModal = async (reloadEmployees: boolean) => {
    setInviteModal(false);
    if (reloadEmployees) {
      await getEmployees();
    }
  };

  const closeEmployeeDepartmentRightsModalOpen = async (
    reloadEmployees: boolean
  ) => {
    setEmployeeDepartmentRightsModalOpen(false);
    if (reloadEmployees) {
      await getEmployees();
    }
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const formatDateTime = (dateTime: number) => {
    const date = new Date(dateTime);
    if (date.getTime() < 0) return "-";
    return date.toLocaleString(getLanguage());
  };

  const displayClientPrivileges = (roleId: number) => {
    if (roleId === 2) {
      return t("Employees.table.isClientAdmin");
    }
    return t("Employees.table.standardUser");
  };

  const columns: Columns<string> = [
    { field: "id", hide: true },
    {
      field: "name",
      headerName: t("Employees.table.name"),
      width:
        window.innerWidth >= 960
          ? window.innerWidth / 5.5
          : window.innerWidth / 5,
      valueGetter: (params) =>
        `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    },
    {
      field: "email",
      headerName: t("Employees.table.email"),
      width:
        window.innerWidth >= 960
          ? window.innerWidth / 5.5
          : window.innerWidth / 5,
      valueGetter: (params) => params.row.email || "-",
    },
    {
      field: "createdAt",
      headerName: t("Employees.table.createdAt"),
      width:
        window.innerWidth >= 960
          ? window.innerWidth / 6.5
          : window.innerWidth / 5.5,
      valueGetter: (params) => formatDateTime(params.row.createdAt),
    },
    {
      field: "lastLogin",
      headerName: t("Employees.table.lastLogin"),
      width:
        window.innerWidth >= 960
          ? window.innerWidth / 6.5
          : window.innerWidth / 5.5,
      valueGetter: (params) =>
        formatDateTime(Number(params.row.lastLogin)) || "-",
    },
    {
      field: "roleId",
      headerName: t("Employees.table.clientAdmin"),
      width:
        window.innerWidth >= 960
          ? window.innerWidth / 6.5
          : window.innerWidth / 5.5,
      valueGetter: (params) => `${displayClientPrivileges(params.row.roleId)}`,
    },
  ];

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const handleDeleteEmployees = async (
    selectedEmployees: GridSelectionModel,
    companyDeletionId: string
  ) => {
    setOpenAlert(false);
    setState({ ...state, loading: true, error: false });
    try {
      await Promise.all(
        selectedEmployees.map(async (e) => {
          await api.deleteCompanyEmployee(companyDeletionId, String(e));
        })
      );
      const updatedEmployees = state.employees?.filter(
        (e) => !selectedEmployees.includes(e.id)
      );
      setSelection([]);
      setState({
        ...state,
        loading: false,
        employees: updatedEmployees,
      });
    } catch (err) {
      setState({ ...state, loading: false, error: true });
      console.error(err);
    }
  };

  const updateSelection = (newSelection: GridSelectionModel) => {
    setSelection(newSelection);

    if (newSelection.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }

    // Array of IDs to be deleted
    employeesToBeDeleted = [...newSelection];
  };

  const toggleClientRights = async () => {
    const currentEmployee = state.employees?.find(
      (emp) => emp.id === selection[0]
    );
    if (currentEmployee?.roleId !== 2) {
      setState({ ...state, toggleClientLoading: true, error: false });
      try {
        await api.AddClientRoleToUser(currentEmployee?.id ?? "");
        const { data: departmentsData } = await api.getDepartments(companyId);
        const updatedEmployees = state.employees?.map((emp) => {
          if (emp.id === currentEmployee?.id) {
            emp.roleId = 2;
            emp.allowedDepartmentsToFilter = departmentsData.map((dd) => dd.id);
            return emp;
          }
          return emp;
        });
        setState({
          ...state,
          employees: updatedEmployees,
          toggleClientLoading: false,
          error: false,
        });
      } catch (err) {
        console.error(err);
        setState({ ...state, toggleClientLoading: false, error: true });
      }
    } else {
      setState({ ...state, toggleClientLoading: true, error: false });
      try {
        await api.RemoveClientRoleToUser(currentEmployee.id);
        const updatedEmployees = state.employees?.map((emp) => {
          if (emp.id === currentEmployee.id) {
            emp.roleId = 0;
            return emp;
          }
          return emp;
        });
        setState({
          ...state,
          employees: updatedEmployees,
          toggleClientLoading: false,
          error: false,
        });
      } catch (err) {
        console.error(err);
        setState({ ...state, toggleClientLoading: false, error: true });
      }
    }
  };

  const mainGui = () => {
    if (state.loading)
      return <Loader text={t("Employees.loading")} style={{ marginTop: 40 }} />;
    if (!state.loading && state.employees) {
      return (
        <>
          <div className="employees-toolbar">
            <Typography className="employees-title" variant="h6">
              {t("Employees.title")}
            </Typography>
            <Spacer />

            {state.toggleClientLoading ? (
              <CircularProgress className="client-rights-circular-progress-style" />
            ) : (
              <Button
                className="add-button-style"
                startIcon={<ToggleOn />}
                disabled={selection.length !== 1}
                color="primary"
                variant="contained"
                onClick={toggleClientRights}
                data-testid="toggleEmployeeRightsButton"
              >
                {t("Employees.editRights")}
              </Button>
            )}

            <Button
              className="add-button-style"
              color="primary"
              disabled={
                selection.length !== 1 ||
                state.employees.find((e) => e.id === selection[0])?.roleId !== 2
              }
              variant="contained"
              onClick={() => setEmployeeDepartmentRightsModalOpen(true)}
              data-testid="editDepartmentRightsButton"
            >
              {t("Employees.editDepartmentRights")}
            </Button>

            <Button
              className="add-button-style"
              startIcon={<Add />}
              color="primary"
              variant="contained"
              onClick={showInviteModal}
              data-testid="newEmployeeButton"
            >
              {t("Employees.create")}
            </Button>

            <InviteEmployeeModal
              open={inviteModal}
              onClose={closeInviteModal}
            />

            {selection && selection.length === 1 && (
              <EditEmployeeDepartmentRightsModal
                open={employeeDepartmentRightsModalOpen}
                onClose={closeEmployeeDepartmentRightsModalOpen}
                employeeDepartments={
                  state.employees.find((e) => e.id === selection[0])
                    ?.allowedDepartmentsToFilter
                }
                employeeId={selection[0] ?? ""}
                employeeState={state}
                setEmployeeState={setState}
              />
            )}

            <Button
              startIcon={<Delete />}
              disabled={disabled}
              color="secondary"
              variant="contained"
              onClick={handleOpenAlert}
              data-testid="deleteEmployeeButton"
            >
              {t("Employees.delete")}
            </Button>

            {/* Dialog for "REMOVE EMPLOYEES" */}
            <Dialog
              open={openAlert}
              onClose={handleCloseAlert}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle
                id="alert-dialog-title"
                className="dialog-title-style"
              >
                {t("Employees.modal.message")}
              </DialogTitle>
              <Card variant="outlined" className="dialog-card-style">
                <Typography className="dialog-name-style">
                  {selection.length} {t("Employees.toBeDeleted")}
                </Typography>
              </Card>
              <DialogActions>
                <Button
                  onClick={() =>
                    handleDeleteEmployees(employeesToBeDeleted, companyId)
                  }
                  color="primary"
                  data-testid="acceptDeleteButton"
                >
                  {t("Employees.modal.acceptDeleteAlert")}
                </Button>
                <Button onClick={handleCloseAlert} color="primary" autoFocus>
                  {t("Employees.modal.denyDeleteAlert")}
                </Button>
              </DialogActions>
            </Dialog>
          </div>

          <div
            className="section-SingleSelectionGrid-layout"
            data-testid="employeesTableGrid"
          >
            <CheckboxSelectionGrid
              columns={columns}
              rows={state.employees}
              pageSize={10}
              loading={state.loading}
              updateSelection={updateSelection}
            />
          </div>
        </>
      );
    }

    return <Loader text={t("Surveys.loading")} style={{ marginTop: 40 }} />;
  };

  return <div className="employees-wrapper">{mainGui()}</div>;
};

export default Employees;
