import type {
  ApiResponseQuestionResult,
  SortedQuestion,
} from "../../../../utils/api/apiInterfaces";

export const lowestQuestionIndex = (
  questions: Array<ApiResponseQuestionResult>,
  currentLanguage: string,
  numberOfQuestions: number
) => {
  const sortedQuestions = questions.sort(
    (a, b) => a.averageAnswerValue - b.averageAnswerValue
  );
  const lowestQuestions = sortedQuestions.slice(0, numberOfQuestions);
  return lowestQuestions.map((lq) =>
    currentLanguage === "sv" ? lq.nameSv : lq.nameEn
  );
};

export const lowestQuestionIndexObjectReturn = (
  questions: Array<ApiResponseQuestionResult>,
  currentLanguage: string,
  numberOfQuestions: number
) => {
  const sortedQuestions = questions.sort(
    (a, b) => a.averageAnswerValue - b.averageAnswerValue
  );
  const lowestQuestions = sortedQuestions.slice(0, numberOfQuestions);
  return lowestQuestions.map((lq) =>
    currentLanguage === "sv"
      ? {
          name: lq.nameSv,
          action: lq.actionSv,
          recommendation: lq.recommendationSv,
        }
      : {
          name: lq.nameEn,
          action: lq.actionEn,
          recommendation: lq.recommendationEn,
        }
  );
};

export const sortedLowestQuestionIndex = (
  questions: Array<ApiResponseQuestionResult>,
  currentLanguage: string
) => {
  const sortedQuestions = questions.sort(
    (a, b) => a.averageAnswerValue - b.averageAnswerValue
  );

  return sortedQuestions.map((lq) =>
    currentLanguage === "sv"
      ? {
          name: lq.nameSv,
          averageAnswerValue: lq.averageAnswerValue,
        }
      : {
          name: lq.nameEn,
          averageAnswerValue: lq.averageAnswerValue,
        }
  );
};
export const compareAverageAnswerValue = (
  questions: SortedQuestion[],
  questionsToCompare: SortedQuestion[]
) => {
  const compareQuestions = questionsToCompare.reduce(
    (acc: { [key: string]: SortedQuestion }, q) => {
      acc[q.name] = q;
      return acc;
    },
    {}
  );

  const lowestGenderQuestions = questions.filter(
    (q) =>
      q.averageAnswerValue < (compareQuestions[q.name]?.averageAnswerValue ?? 0)
  );

  if (lowestGenderQuestions.length === 0) {
    return null;
  }

  return lowestGenderQuestions.reduce(
    (prev, curr) =>
      curr.averageAnswerValue <= prev.averageAnswerValue ? curr : prev,
    { name: "", averageAnswerValue: 10 }
  );
};
