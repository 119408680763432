import type React from "react";
import type { VFC } from "react";
import "./BarChart.css";

interface Props {
  wellbeingText: string | null;
  stressCategory: string;
  physicsCategory: string;
  developmentCategory: string;
  stress: number;
  physics: number;
  development: number;
}

const BarChart: VFC<Props> = ({
  wellbeingText,
  stressCategory,
  physicsCategory,
  developmentCategory,
  stress,
  physics,
  development,
}) => {
  const xAxis = () => (
    <line
      x1="10"
      x2="110"
      y1="15"
      y2="15"
      stroke="#ECF0F4"
      vectorEffect="non-scaling-stroke"
    />
  );

  const xAxisValue = (value?: number | null, xPosition?: string | number) => (
    <text
      x={xPosition}
      y="17"
      fontSize="1"
      fill="#666"
      fontFamily="Arial Narrow"
      textAnchor="middle"
      lengthAdjust="spacingAndGlyphs"
    >
      {value}
    </text>
  );

  const yAxisValue = (
    category?:
      | boolean
      | React.ReactChild
      | React.ReactFragment
      | React.ReactPortal
      | null,
    yPosition?: string | number
  ) => (
    <text
      x="8"
      y={yPosition}
      fontSize="0.06rem"
      fill="#666"
      fontFamily="Arial Narrow"
      textAnchor="end"
      lengthAdjust="spacingAndGlyphs"
    >
      {category}
    </text>
  );

  const xGridLine = (xPosition: string | number | undefined) => (
    <line
      x1={xPosition}
      x2={xPosition}
      y1="3"
      y2="15"
      stroke="#ECF0F4"
      vectorEffect="non-scaling-stroke"
      strokeDasharray="4 4"
    />
  );

  const base = (yPosition: string | number | undefined) => (
    <rect
      x="10"
      y={yPosition}
      rx="0.3"
      ry="0.3"
      width="90.8%"
      height="1.6"
      fill="#38c976"
      opacity="0.2"
    />
  );

  const bar = (yPosition: string | number | undefined, value: number) => (
    <rect
      x="10"
      y={yPosition}
      rx="0.3"
      ry="0.3"
      width={value * 10}
      height="1.6"
      fill="#41aa88"
      className="shadow"
    />
  );

  const barValue = (value: number, yPosition: string | number | undefined) => (
    <text x={value * 10 + 8} y={yPosition} fontSize="0.9" fill="white">
      {value}
    </text>
  );

  return (
    <div className="filtered-chart">
      <div className="legend-dot">
        <p>{wellbeingText}</p>
        <div className="dot" />
      </div>
      <svg
        width="100%"
        height="212px"
        viewBox="0 0 110 20"
        fill="yellow"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
      >
        {xAxis()}

        {xGridLine(10.1)}
        {xGridLine(30)}
        {xGridLine(50)}
        {xGridLine(70)}
        {xGridLine(90)}
        {xGridLine(109.9)}

        {xAxisValue(0, 10)}
        {xAxisValue(2, 30)}
        {xAxisValue(4, 50)}
        {xAxisValue(6, 70)}
        {xAxisValue(8, 90)}
        {xAxisValue(10, 109.5)}

        {yAxisValue(stressCategory, 6.1)}
        {yAxisValue(physicsCategory, 8.6)}
        {yAxisValue(developmentCategory, 11.1)}

        {base(5)}
        {base(7.5)}
        {base(10)}

        {bar(5, stress)}
        {bar(7.5, physics)}
        {bar(10, development)}

        {barValue(stress, 6.1)}
        {barValue(physics, 8.6)}
        {barValue(development, 11.1)}
      </svg>
    </div>
  );
};

export default BarChart;
