import type { FC } from "react";
import { Navigate } from "react-router";
import { getToken } from "../utils/sessionhandler";

interface Props {
  children: JSX.Element;
}

const AuthRoute: FC<Props> = ({ children }) => {
  const token = getToken();

  return token && token.accessToken ? (
    <div style={{ width: "100%" }}>{children}</div>
  ) : (
    <Navigate
      to={{
        pathname: "/login",
      }}
    />
  );
};

export default AuthRoute;
