import { View, Text } from "@react-pdf/renderer";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import type {
  ApiResponseAutomaticAnalysisResult,
  ApiResponseResultCategory,
} from "../../../../../utils/api/apiInterfaces";
import { automaticAnalysisNarrowPageStyle } from "../styles/AutomaticAnalysisNarrowPageStyle";
import { getPercentageResults } from "../UseAutomaticAnalysisCalculationHelper";

interface Props {
  ageResults: ApiResponseAutomaticAnalysisResult[];
}

interface SortedResults {
  underTwenty?: {
    physicalPercentages: ApiResponseResultCategory;
    developmentPercentages: ApiResponseResultCategory;
    stressPercentages: ApiResponseResultCategory;
  };
  twentyToForty?: {
    physicalPercentages: ApiResponseResultCategory;
    developmentPercentages: ApiResponseResultCategory;
    stressPercentages: ApiResponseResultCategory;
  };
  fortyToSixty?: {
    physicalPercentages: ApiResponseResultCategory;
    developmentPercentages: ApiResponseResultCategory;
    stressPercentages: ApiResponseResultCategory;
  };
  overSixty?: {
    physicalPercentages: ApiResponseResultCategory;
    developmentPercentages: ApiResponseResultCategory;
    stressPercentages: ApiResponseResultCategory;
  };
}

export const AgeCircleResult: FC<Props> = ({ ageResults }) => {
  const { t } = useTranslation();
  const [sortedResults, setSortedResults] = useState<SortedResults | null>(
    null
  );

  useEffect(() => {
    if (ageResults) {
      const youngest = ageResults
        .filter((ar) => ar.totalAnswers >= 5)
        .find((ar) => ar.nameSv === "1");
      const twenties = ageResults
        .filter((ar) => ar.totalAnswers >= 5)
        .find((ar) => ar.nameSv === "20");
      const forties = ageResults
        .filter((ar) => ar.totalAnswers >= 5)
        .find((ar) => ar.nameSv === "40");
      const sixties = ageResults
        .filter((ar) => ar.totalAnswers >= 5)
        .find((ar) => ar.nameSv === "60");

      setSortedResults({
        underTwenty: youngest ? getPercentageResults(youngest) : undefined,
        twentyToForty: twenties ? getPercentageResults(twenties) : undefined,
        fortyToSixty: forties ? getPercentageResults(forties) : undefined,
        overSixty: sixties ? getPercentageResults(sixties) : undefined,
      });
    }
  }, [ageResults]);

  return (
    <View style={automaticAnalysisNarrowPageStyle.threeCirclesContainer}>
      {sortedResults?.fortyToSixty && (
        <View style={automaticAnalysisNarrowPageStyle.circle}>
          <Text style={automaticAnalysisNarrowPageStyle.circleHeadline}>
            {" "}
            {sortedResults.fortyToSixty.physicalPercentages.high}%
          </Text>
          <Text style={automaticAnalysisNarrowPageStyle.circleText}>
            {t("AgeResult.physicalAverageHigh1")}{" "}
            {t("AgeResult.physicalAverageHigh2")}
          </Text>
        </View>
      )}
      {sortedResults?.twentyToForty && (
        <View style={automaticAnalysisNarrowPageStyle.circle}>
          <Text style={automaticAnalysisNarrowPageStyle.circleHeadline}>
            {" "}
            {sortedResults.twentyToForty.developmentPercentages.high}%
          </Text>
          <Text style={automaticAnalysisNarrowPageStyle.circleText}>
            {t("AgeResult.ofTheJuniors")}
          </Text>
          <Text style={automaticAnalysisNarrowPageStyle.circleText}>
            {t("AgeResult.wellBeingJuniorHigh")}
          </Text>
        </View>
      )}
      {sortedResults?.fortyToSixty && (
        <View style={automaticAnalysisNarrowPageStyle.circle}>
          <Text style={automaticAnalysisNarrowPageStyle.circleHeadline}>
            {" "}
            {sortedResults.fortyToSixty.stressPercentages.high}%
          </Text>
          <Text style={automaticAnalysisNarrowPageStyle.circleText}>
            {t("AgeResult.wellBeingSeniorHigh1")}
          </Text>
          <Text style={automaticAnalysisNarrowPageStyle.circleText}>
            {t("AgeResult.wellBeingSeniorHigh2")}
          </Text>
        </View>
      )}
    </View>
  );
};
