import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import "./CycleResultsQuestions.css";
import { Paper } from "@material-ui/core";
import api from "../../../../utils/api/v1";
import QuestionsInformationChart from "./QuestionsInformationChart";
import { MissingRequiredParamError } from "../../../errorHandling/MissingRequiredParamError";
import type { ApiResponseCompanyCycleQuestionsResultModel } from "../../../../utils/api/apiInterfaces";

const CycleResultsQuestions = () => {
  const { t } = useTranslation();
  const { companyId, cycleId } = useParams();
  const [questionsData, setQuestionsData] =
    useState<ApiResponseCompanyCycleQuestionsResultModel>({
      companyCycleQuestionResults: [],
    });
  const [selection, setSelection] = useState({
    currentCategory: "all",
    currentDataToDisplay: "averageDepartmentResult",
  });

  useEffect(() => {
    getCycleQuestionData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelection({
      currentCategory: localStorage.getItem("categories") || "all",
      currentDataToDisplay:
        localStorage.getItem("currentData") || "averageDepartmentResult",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!companyId || !cycleId) {
    return <MissingRequiredParamError missingParam="companyId" />;
  }

  const getCycleQuestionData = () => {
    api
      .getCycleQuestionData(companyId, cycleId)
      .then(({ data }) => {
        setQuestionsData(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateSelection = ({
    target: { value, name },
  }: {
    target: { value: string; name: string };
  }) => {
    localStorage.setItem(name, value);
    setSelection({ ...selection, [name]: value });
  };

  const areasFilter = () => (
    <div className="select-container">
      <p>{t("CycleResults.questions.areas.title")}:</p>
      <select
        className="filtering-select"
        value={selection.currentCategory}
        name="currentCategory"
        style={{ width: `${18 * selection.currentCategory.length}px` }}
        onChange={updateSelection}
        onBlur={updateSelection}
      >
        <option className="filtering-option" value="all">
          {t("CycleResults.questions.areas.all")}
        </option>
        <option className="filtering-option" value="stress">
          {t("CycleResults.questions.areas.stress")}
        </option>
        <option className="filtering-option" value="physical">
          {t("CycleResults.questions.areas.physical")}
        </option>
        <option className="filtering-option" value="development">
          {t("CycleResults.questions.areas.development")}
        </option>
        <option className="filtering-option" value="stressPhysical">
          {t("CycleResults.questions.areas.stressPhysical")}
        </option>
        <option className="filtering-option" value="stressDevelopment">
          {t("CycleResults.questions.areas.stressDevelopment")}
        </option>
        <option className="filtering-option" value="physicalDevelopment">
          {t("CycleResults.questions.areas.physicalDevelopment")}
        </option>
      </select>
    </div>
  );

  const columnsFilter = () => (
    <div className="select-container">
      <p>{t("CycleResults.questions.columns.title")}:</p>
      <select
        className="filtering-select"
        value={selection.currentDataToDisplay}
        name="currentDataToDisplay"
        style={{ width: `${18 + 5 * selection.currentDataToDisplay.length}px` }}
        onChange={updateSelection}
        onBlur={updateSelection}
      >
        <option className="filtering-option" value="averageDepartmentResult">
          {t("CycleResults.questions.columns.departments")}
        </option>
        <option className="filtering-option" value="averageTitleResult">
          {t("CycleResults.questions.columns.titles")}
        </option>
        <option className="filtering-option" value="averageBossResult">
          {t("CycleResults.questions.columns.bosses")}
        </option>
        <option className="filtering-option" value="averageGenderResult">
          {t("CycleResults.questions.columns.genders")}
        </option>
        <option className="filtering-option" value="averageAgeSpanResult">
          {t("CycleResults.questions.columns.ages")}
        </option>
      </select>
    </div>
  );

  return (
    <Paper className="questions-container" square>
      <div className="questions-container-inner-content">
        <Paper className="questions-overview-container" square>
          <div className="dropdown-container">
            {areasFilter()}
            {columnsFilter()}
          </div>
          <QuestionsInformationChart
            currentCategory={selection.currentCategory}
            currentData={selection.currentDataToDisplay}
            questionsData={questionsData}
          />
        </Paper>
      </div>
    </Paper>
  );
};

export default CycleResultsQuestions;
