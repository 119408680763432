import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import "./CycleResults.css";
import { Grid } from "@material-ui/core";
import api from "../../../../utils/api/v1";
import CycleResultCard from "./CycleResultCard";
import StatisticsChart from "./StatisticsChart";
import FilteredStatisticsChart from "./FilteredStatisticsChart";
import Loader from "../../../shared/loader/Loader";
import { MissingRequiredParamError } from "../../../errorHandling/MissingRequiredParamError";
import type { ApiResponseResultsData } from "../../../../utils/api/apiInterfaces";

interface State {
  loading: boolean;
  resultsData: ApiResponseResultsData | null;
}

const CycleResults = () => {
  const { t } = useTranslation();
  const { companyId, cycleId } = useParams();
  const [state, setState] = useState<State>({
    loading: false,
    resultsData: null,
  });
  const [surveyStateCount, setSurveyStateCount] = useState({
    gotSurvey: 0,
    startedSurvey: 0,
    startedProgram: 0,
  });

  useEffect(() => {
    getCycleResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!companyId || !cycleId) {
    return <MissingRequiredParamError missingParam="companyId" />;
  }

  const getCycleResults = () => {
    setState({ ...state, loading: true });

    api
      .getCycleResultsData(companyId, cycleId)
      .then(({ data }) => {
        setState({ ...state, resultsData: data, loading: false });

        const gotSurveyCount = data.companyEmployeeData.length;

        const startedSurveyCount = data.companyEmployeeData.filter(
          (employee) =>
            employee.userCycleResult.state === "Started" ||
            employee.userCycleResult.state === "Finished"
        ).length;

        const startedProgramCount = data.companyEmployeeData.filter(
          (employee) => employee.userCycleResult.state === "Finished"
        ).length;

        setSurveyStateCount({
          gotSurvey: gotSurveyCount,
          startedSurvey: startedSurveyCount,
          startedProgram: startedProgramCount,
        });
      })
      .catch((error) => {
        console.error(error);
        setState({ ...state, loading: false });
      });
  };

  const getPercentage = (count: number) =>
    `${(count / surveyStateCount.gotSurvey) * 100}%`;

  const mainGui = () => (
    <div style={{ height: "" }}>
      <div className="company-result-inner-content">
        <Grid
          container
          spacing={4}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <CycleResultCard
            title={t("CycleResults.gotSurvey")}
            width="100%"
            value={surveyStateCount.gotSurvey}
          />
          <CycleResultCard
            title={t("CycleResults.startedSurvey")}
            width={getPercentage(surveyStateCount.startedSurvey)}
            value={surveyStateCount.startedSurvey}
          />
          <CycleResultCard
            title={t("CycleResults.startedProgram")}
            width={getPercentage(surveyStateCount.startedProgram)}
            value={surveyStateCount.startedProgram}
          />
        </Grid>
        {state.resultsData && (
          <>
            <StatisticsChart
              totalAverage={state.resultsData.companyTotalAverage}
              stressAverage={state.resultsData.companyStressAverage}
              physicalAverage={state.resultsData.companyPhysicalAverage}
              developmentAverage={state.resultsData.companyDevelopmentAverage}
              finishedSurveyCount={surveyStateCount.startedProgram}
            />
            <FilteredStatisticsChart
              totalAverage={state.resultsData.companyTotalAverage}
              stressAverage={state.resultsData.companyStressAverage}
              physicalAverage={state.resultsData.companyPhysicalAverage}
              developmentAverage={state.resultsData.companyDevelopmentAverage}
              employeeData={state.resultsData.companyEmployeeData}
            />
          </>
        )}
      </div>
    </div>
  );

  return (
    <div className="cycles-wrapper">
      {!state.loading && state.resultsData ? (
        mainGui()
      ) : (
        <Loader text={t("CycleResults.loading")} style={{ marginTop: 40 }} />
      )}
    </div>
  );
};

export default CycleResults;
