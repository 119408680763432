import type { VFC } from "react";
import { useTranslation } from "react-i18next";
import "./TermDetails.css";
import {
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { getLanguage } from "../../../i18n";
import type { ApiResponseNestedModuleWithTermModule } from "../../../utils/api/apiInterfaces";

export interface DisassociationInput {
  termId: number;
  moduleId: string;
  appliesToColor: string;
  order: number;
}

interface Props {
  level: string;
  category: string;
  termCategoryModules: Array<ApiResponseNestedModuleWithTermModule> | null;
  handleCurrentCategoryAndLevelAndModal: (
    category: string,
    level: string
  ) => void;
  handleDissassociateModuleFromTermCategoryAndLevel: (
    disassociationInput: DisassociationInput
  ) => void;
}

const ReusableTermTable: VFC<Props> = ({
  level,
  category,
  handleCurrentCategoryAndLevelAndModal,
  termCategoryModules,
  handleDissassociateModuleFromTermCategoryAndLevel,
}) => {
  const { t } = useTranslation();

  const currentCategoryChecker = (
    module: ApiResponseNestedModuleWithTermModule,
    currentCategory: string
  ) => {
    if (currentCategory === "isStress") {
      return module.questionCategories.isStress;
    }
    if (currentCategory === "isPhysical") {
      return module.questionCategories.isPhysical;
    }
    if (currentCategory === "isDevelopment") {
      return module.questionCategories.isDevelopment;
    }
    return false;
  };

  return (
    <div>
      <div className="term-module-category-button-container">
        <Typography className="termCategory-title" variant="h6">
          {t(`TermDetails.levels.${level}Level`)}
        </Typography>
        <Button
          variant="contained"
          className="termDetails-add-new-module"
          color="primary"
          onClick={() => handleCurrentCategoryAndLevelAndModal(category, level)}
        >
          <Add className="term-add-icon-style" />
          {t("TermDetails.addModule")}
        </Button>
      </div>
      <TableContainer className="term-modules-table-container">
        <Table>
          <colgroup>
            <col width="20%" />
            <col width="40%" />
            <col width="40%" />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell className="table-head-cell-style table-order-style">
                <p>{t("TermDetails.order")}</p>
              </TableCell>
              <TableCell className="table-head-cell-style">
                <p>{t("TermDetails.name")}</p>
              </TableCell>
              <TableCell className="table-head-cell-style term-module-delete-button">
                <p>{t("TermDetails.removeModule")}</p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {termCategoryModules &&
              termCategoryModules
                .sort((a, b) => a.termModule.order - b.termModule.order)
                .filter(
                  (module) =>
                    module.termModule.appliesToColor === level &&
                    currentCategoryChecker(module, category)
                )
                .map((moduleForMapping) => (
                  <TableRow>
                    <TableCell className="table-cell-order-style table-order-style">
                      {moduleForMapping.termModule.order}
                    </TableCell>
                    <TableCell className="table-cell-module-name">
                      {getLanguage() === "sv"
                        ? moduleForMapping.textData.nameSv
                        : moduleForMapping.textData.nameEn}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="secondary"
                        className="termDetails-delete-button-style"
                        onClick={() =>
                          handleDissassociateModuleFromTermCategoryAndLevel({
                            termId: parseInt(
                              String(moduleForMapping.termModule.termId),
                              10
                            ),
                            moduleId: moduleForMapping.termModule.moduleId,
                            appliesToColor:
                              moduleForMapping.termModule.appliesToColor,
                            order: parseInt(
                              String(moduleForMapping.termModule.order),
                              10
                            ),
                          })
                        }
                      >
                        {t("TermDetails.deleteModule")}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ReusableTermTable;
