import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Navigate, Routes } from "react-router";
import errorHandler from "../../utils/errorHandler/errorHandler";
import Navigation from "../navigation/Navigation";
import AuthRoute from "../AuthRoute";
import SurveyEdit from "../surveys/SurveyEdit";
import SurveyDetails from "../surveys/SurveyDetails";
import Surveys from "../surveys/Surveys";
import Modules from "../modules/Modules";
import Terms from "../terms/Terms";
import TermDetails from "../terms/termDetails/TermDetails";
import TermTeacherCreate from "../terms/termDetails/TermTeacherCreate";
import TermTeacherDetails from "../terms/termDetails/TermTeacherDetails";
import ActivityDetails from "../modules/activities/ActivityDetails";
import ActivityEdit from "../modules/activities/ActivityEdit";
import ModuleDetails from "../modules/ModuleDetails";
import ModuleEdit from "../modules/ModuleEdit";
import Resources from "../library/Resources";
import ResourceDetails from "../library/ResourceDetails";
import ResourceEdit from "../library/ResourceEdit";
import CycleEdit from "../companies/cycles/CycleEdit";
import CycleDetails from "../companies/cycles/CycleDetails";
import CompanyEdit from "../companies/CompanyEdit";
import CompanyDetails from "../companies/CompanyDetails";
import CompanyMailModal from "../reminders/ReminderParts/CompanyMail/CompanyMailModal";
import Companies from "../companies/Companies";
import CounselingResources from "../counseling/CounselingResources";
import CounselingResourceDetails from "../counseling/CounselingResourceDetails";
import CounselingResourceEdit from "../counseling/CounselingResourceEdit";
import Teachers from "../teachers/Teachers";
import TeacherDetails from "../teachers/TeacherDetails";
import TeacherEdit from "../teachers/TeacherEdit";
import AdminDetails from "../admins/AdminDetails";
import Reminders from "../reminders/Reminders";
import Admins from "../admins/Admins";
import SignInPage from "../signin/SignInPage";
import { MicroCourses } from "../microCourses/MicroCourses";
import { MicroCourseDetails } from "../microCourses/MicroCourseDetails";
import { MicroCourseCreateEdit } from "../microCourses/MicroCourseCreateEdit";
import { MicroCourseResourceCreateEdit } from "../microCourses/MicroCourseResources/MicroCourseResourceCreateEdit";
import { MicroCourseResourceDetails } from "../microCourses/MicroCourseResources/MicroCourseResourceDetails";

const AppRoutes = () => (
  <Router>
    <div className="container">
      <Routes>
        <Route path="/login" element={<SignInPage />} />
        <Route
          key="SurveyCreate"
          exact
          path="/surveys/new"
          element={
            <Navigation>
              <AuthRoute>
                <SurveyEdit create />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="SurveyEdit"
          exact
          path="/surveys/:surveyId/edit"
          element={
            <Navigation>
              <AuthRoute>
                <SurveyEdit />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="SurveyDetails"
          exact
          path="/surveys/:surveyId"
          element={
            <Navigation>
              <AuthRoute>
                <SurveyDetails />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="Surveys"
          exact
          path="/surveys"
          element={
            <Navigation>
              <AuthRoute>
                <Surveys />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="Modules"
          exact
          path="/modules"
          element={
            <Navigation>
              <AuthRoute>
                <Modules />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="Terms"
          exact
          path="/terms"
          element={
            <Navigation>
              <AuthRoute>
                <Terms />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="TermDetails"
          exact
          path="/terms/:termId"
          element={
            <Navigation>
              <AuthRoute>
                <TermDetails />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          exact
          path="/terms/:termId/AddTermTeacher"
          element={
            <Navigation>
              <AuthRoute>
                <TermTeacherCreate />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="TermTeacherDetails"
          exact
          path="/terms/:termId/:termTeacherId"
          element={
            <Navigation>
              <AuthRoute>
                <TermTeacherDetails />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="ModuleActivityDetails"
          exact
          path="/activities/:moduleActivityId"
          element={
            <Navigation>
              <AuthRoute>
                <ActivityDetails />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="ModuleActivityCreate"
          exact
          path="/modules/:moduleId/activities/new"
          element={
            <Navigation>
              <AuthRoute>
                <ActivityEdit create />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="ModuleActivityEdit"
          exact
          path="/activities/:moduleActivityId/edit"
          element={
            <Navigation>
              <AuthRoute>
                <ActivityEdit />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="ModuleDetails"
          exact
          path="/modules/:moduleId"
          element={
            <Navigation>
              <AuthRoute>
                <ModuleDetails />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="ModuleCreate"
          exact
          path="/modules/module/new"
          element={
            <Navigation>
              <AuthRoute>
                <ModuleEdit create />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="ModuleEdit"
          exact
          path="/modules/:moduleId/edit"
          element={
            <Navigation>
              <AuthRoute>
                <ModuleEdit />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="Resources"
          exact
          path="/resources"
          element={
            <Navigation>
              <AuthRoute>
                <Resources />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="ResourceDetails"
          exact
          path="/resources/:resourceId"
          element={
            <Navigation>
              <AuthRoute>
                <ResourceDetails />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="ResourceCreate"
          exact
          path="/resources/resource/new"
          element={
            <Navigation>
              <AuthRoute>
                <ResourceEdit create />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="ResourceEdit"
          exact
          path="/resources/:resourceId/edit"
          component={ResourceEdit}
          element={
            <Navigation>
              <AuthRoute>
                <ResourceEdit />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="MicroCourses"
          exact
          path="/micro-courses"
          element={
            <Navigation>
              <AuthRoute>
                <MicroCourses />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="MicroCourseDetails"
          exact
          path="/micro-courses/:microCourseId"
          element={
            <Navigation>
              <AuthRoute>
                <MicroCourseDetails />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="MicroCourseCreate"
          exact
          path="/micro-courses/micro-course/new"
          element={
            <Navigation>
              <AuthRoute>
                <MicroCourseCreateEdit create />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="MicroCourseEdit"
          exact
          path="/micro-courses/:microCourseId/edit"
          element={
            <Navigation>
              <AuthRoute>
                <MicroCourseCreateEdit />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="MicroCourseResourceCreate"
          exact
          path="/micro-courses/:microCourseId/micro-course-resources/new"
          element={
            <Navigation>
              <AuthRoute>
                <MicroCourseResourceCreateEdit create />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="MicroCourseResourceDetails"
          exact
          path="/micro-courses/micro-course-resource/:microCourseResourceId"
          element={
            <Navigation>
              <AuthRoute>
                <MicroCourseResourceDetails />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="MicroCourseResourceEdit"
          exact
          path="/micro-courses/micro-course-resource/:microCourseResourceId/edit"
          element={
            <Navigation>
              <AuthRoute>
                <MicroCourseResourceCreateEdit />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="CycleCreate"
          exact
          path="/companies/:companyId/cycles/new"
          element={
            <Navigation>
              <AuthRoute>
                <CycleEdit create />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="CycleEdit"
          exact
          path="/companies/:companyId/cycles/:cycleId"
          element={
            <Navigation>
              <AuthRoute>
                <CycleDetails />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="CycleEdit"
          exact
          path="/companies/:companyId/cycles/:cycleId/edit"
          element={
            <Navigation>
              <AuthRoute>
                <CycleEdit />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="CompanyEdit"
          exact
          path="/companies/:companyId/edit"
          element={
            <Navigation>
              <AuthRoute>
                <CompanyEdit />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="CompanyDetails"
          exact
          path="/companies/:companyId"
          element={
            <Navigation>
              <AuthRoute>
                <CompanyDetails />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="CompanyMailDetails"
          exact
          path="/companymaildetails/:companyId"
          element={
            <Navigation>
              <AuthRoute>
                <CompanyMailModal />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="Companies"
          exact
          path="/companies"
          element={
            <Navigation>
              <AuthRoute>
                <Companies />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="CounselingResources"
          exact
          path="/counseling-resources"
          element={
            <Navigation>
              <AuthRoute>
                <CounselingResources />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="CounselingResourceDetails"
          exact
          path="/counseling-resources/:counselingResourceId"
          element={
            <Navigation>
              <AuthRoute>
                <CounselingResourceDetails />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="CounselingResourceCreate"
          exact
          path="/counseling-resources/counseling-resource/new"
          element={
            <Navigation>
              <AuthRoute>
                <CounselingResourceEdit create />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="CounselingResourceEdit"
          exact
          path="/counseling-resources/:counselingResourceId/edit"
          element={
            <Navigation>
              <AuthRoute>
                <CounselingResourceEdit />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="teachers"
          exact
          path="/teachers"
          element={
            <Navigation>
              <AuthRoute>
                <Teachers />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="TeacherDetails"
          exact
          path="/teachers/:teacherId"
          element={
            <Navigation>
              <AuthRoute>
                <TeacherDetails />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="TeacherCreate"
          exact
          path="/teachers/teacher/new"
          element={
            <Navigation>
              <AuthRoute>
                <TeacherEdit create />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="TeacherEdit"
          exact
          path="/teachers/:teacherId/edit"
          element={
            <Navigation>
              <AuthRoute>
                <TeacherEdit />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="AdminDetails"
          exact
          path="/admins/:adminId"
          element={
            <Navigation>
              <AuthRoute>
                <AdminDetails />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="Reminders"
          exact
          path="/reminders"
          element={
            <Navigation>
              <AuthRoute>
                <Reminders />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="Admins"
          exact
          path="/admins"
          element={
            <Navigation>
              <AuthRoute>
                <Admins />
              </AuthRoute>
            </Navigation>
          }
        />
        <Route
          key="landingRedirect"
          exact
          path="/"
          element={<Navigate to="/companies" />}
        />
        <Route element={<h1>404</h1>} />
      </Routes>
    </div>
  </Router>
);

export default errorHandler(AppRoutes);
