import type { CSSProperties, VFC } from "react";
import { CircularProgress } from "@material-ui/core";
import "./Loader.css";

interface Props {
  text?: string;
  style?: CSSProperties;
  size: number;
}

export const FlexibleSizeLoader: VFC<Props> = ({ text, style, size }) => (
  <div className="loading-wrapper" style={style}>
    <CircularProgress
      className="loading-indicator"
      variant="indeterminate"
      size={size * 1.2}
    />
    {text && (
      <h3 className="small-loading-text" style={{ fontSize: size }}>
        {text}
      </h3>
    )}
  </div>
);
