import type { VFC } from "react";
import "./QuestionDistributionBar.css";
import { getLanguage } from "../../../../i18n";
import type { ApiResponseCompanyCycleQuestionResultModel } from "../../../../utils/api/apiInterfaces";

interface Props {
  questionData: ApiResponseCompanyCycleQuestionResultModel;
}

const QuestionDistributionBar: VFC<Props> = ({ questionData }) => {
  const getCurrentLanguage = () =>
    getLanguage().charAt(0).toUpperCase() + getLanguage().slice(1);

  const getRedPercentage = () =>
    questionData.isPositive
      ? (questionData.numberOfLowAnswers / questionData.totalAnswers) * 100
      : (questionData.numberOfHighAnswers / questionData.totalAnswers) * 100;

  const getYellowPercentage = () =>
    (questionData.numberOfMediumAnswers / questionData.totalAnswers) * 100;

  const getGreenPercentage = () =>
    questionData.isPositive
      ? (questionData.numberOfHighAnswers / questionData.totalAnswers) * 100
      : (questionData.numberOfLowAnswers / questionData.totalAnswers) * 100;

  const getColor = () => {
    const redEnd = getRedPercentage();
    const yellowStart = redEnd;
    const yellowEnd = yellowStart + getYellowPercentage();
    const greenStart = yellowEnd;

    return `linear-gradient(to right, rgba(255, 65, 65, 0.75) 0%,
    rgba(255, 65, 65, 0.75) ${redEnd}%,
    rgba(238, 184, 98, 1) ${yellowStart}%, 
    rgba(238, 184, 98, 1) ${yellowEnd}%, 
    rgba(6, 132, 102, 0.75) ${greenStart}%, 
    rgba(6, 132, 102, 0.75) 100%)`;
  };

  const roundToOneDecimal = (value: number) => parseFloat(value.toFixed(1));

  return (
    <div className="distribution-bar-wrapper">
      <p>{questionData[`questionText${getCurrentLanguage()}`]}</p>
      <div className="distribution-bar-info-wrapper">
        <div className="distribution-bar" style={{ background: getColor() }} />
        <div className="distribution-bar-percentage-wrapper">
          <span>
            <div className="distribution-circle-red" />
            {roundToOneDecimal(getRedPercentage())}%
          </span>
          <span>
            <div className="distribution-circle-yellow" />
            {roundToOneDecimal(getYellowPercentage())}%
          </span>
          <span>
            <div className="distribution-circle-green" />
            {roundToOneDecimal(getGreenPercentage())}%
          </span>
        </div>
      </div>
    </div>
  );
};

export default QuestionDistributionBar;
