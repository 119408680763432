import type { ChangeEvent, VFC } from "react";
import "./AutomaticAnalysis.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Button, FormControl, Paper, Select } from "@material-ui/core";
import { MenuItem } from "@mui/material";
import { PDFDownloadLink } from "@react-pdf/renderer";
import api from "../../../utils/api/v1";
import { MissingRequiredParamError } from "../../errorHandling/MissingRequiredParamError";
import type {
  ApiResponseEmployeeWithClientRights,
  ApiResponseAutomaticAnalysisModel,
  ApiResponseCompanyCycle,
} from "../../../utils/api/apiInterfaces";
import Loader from "../../shared/loader/Loader";
import Alert from "../../shared/Alert";
import { FlexibleSizeLoader } from "../../shared/loader/FlexibleSizeLoader";
import { AutomaticAnalysisPdf } from "./automatic-analysis-pdf/AutomaticAnalysisPdf";
import CycleUpload from "./CycleUpload";

interface State {
  loading: boolean;
  error: boolean;
  analysisLoading: boolean;
  analysisError: boolean;
}

export interface PdfInfo {
  pdfPublicId: string | null;
  pdfUrl: string | null;
}

enum MenuAlternative {
  Initial = "Initial",
}

interface Props {
  cycle: ApiResponseCompanyCycle;
}

export const AutomaticAnalysis: VFC<Props> = ({ cycle }) => {
  const { companyId, cycleId } = useParams();
  const { t } = useTranslation();
  const [state, setState] = useState<State>({
    loading: false,
    error: false,
    analysisLoading: false,
    analysisError: false,
  });
  const [selectedEmployee, setSelectedEmployee] = useState<string>("Initial");
  const [employees, setEmployees] = useState<
    Array<ApiResponseEmployeeWithClientRights>
  >([]);
  const [automaticAnalysisResult, setAutomaticAnalysisResult] =
    useState<ApiResponseAutomaticAnalysisModel | null>(null);
  const [totalAverage, setTotalAverage] = useState<number | null>(null);
  const [pdfInfo, setPdfInfo] = useState<PdfInfo>({
    pdfPublicId: null,
    pdfUrl: null,
  });

  useEffect(() => {
    (async () => {
      await getEmployeesWithClientRights();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      await getCycleTotalAverage();
    })();
  }, []);

  if (!companyId || !cycleId) {
    return <MissingRequiredParamError missingParam="companyId or cycleId" />;
  }

  const getEmployeesWithClientRights = async () => {
    setState((prevState) => ({ ...prevState, loading: true, error: false }));
    try {
      const {
        data: { employeesWithClientRights },
      } = await api.getEmployeesWithClientRights(companyId);
      setEmployees(employeesWithClientRights);
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (err) {
      console.log(err);
      setState((prevState) => ({ ...prevState, loading: false, error: true }));
    }
  };

  const getAnalysisForEmployeeWithClientRights = async (userId: string) => {
    setState((prevState) => ({
      ...prevState,
      analysisLoading: true,
      analysisError: false,
    }));
    try {
      const { data: automaticAnalysisResultData } =
        await api.getAnalysisForEmployeeWithClientRights(
          companyId,
          cycleId,
          userId
        );
      setAutomaticAnalysisResult(automaticAnalysisResultData);

      setPdfInfo({
        pdfPublicId:
          automaticAnalysisResultData.automaticAnalyzePdfData.pdfPublicId,
        pdfUrl: automaticAnalysisResultData.automaticAnalyzePdfData.pdfUrl,
      });
      setState((prevState) => ({ ...prevState, analysisLoading: false }));
    } catch (err) {
      console.log(err);
      setState((prevState) => ({
        ...prevState,
        analysisLoading: false,
        analysisError: true,
      }));
    }
  };

  const handleEmployeeSelect = async (
    e: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    setSelectedEmployee(e.target.value as string);
    if (e.target.value === MenuAlternative.Initial) {
      setAutomaticAnalysisResult(null);
      return;
    }
    await getAnalysisForEmployeeWithClientRights(e.target.value as string);
  };

  const getCycleTotalAverage = async () => {
    setState((prevState) => ({ ...prevState, loading: true, error: false }));

    try {
      const { data } = await api.getCycleTotalAverage();
      setTotalAverage(data.overallAverageCycleResult.overallCycleAverageResult);

      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (err) {
      console.log(err);
      setState((prevState) => ({ ...prevState, loading: false, error: true }));
    }
  };

  const resolvePdfName = () => {
    const employee = employees.find((emp) => emp.id === selectedEmployee);
    if (!employee) return "Analysis";
    return `${employee.firstName}-${employee.lastName}-analysis`;
  };

  const pdfIsDisabled = () =>
    !automaticAnalysisResult ||
    !automaticAnalysisResult.companyResult ||
    state.analysisLoading ||
    !cycle ||
    !totalAverage;

  return (
    <div className="automatic-analysis-wrapper">
      {state.error && <Alert style={{ marginTop: 20 }}>{state.error}</Alert>}
      {state.loading && !state.error && (
        <Loader
          text={t("AutomaticAnalysis.loading")}
          style={{ marginTop: 40 }}
        />
      )}
      {!state.loading && !state.error && (
        <Paper className="automatic-analysis-content-container">
          <div className="automatic-analysis-employee-dropdown-container">
            <FormControl
              size="small"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p className="automatic-analysis-dropdown-label">
                {t("AutomaticAnalysis.dropdownLabel")}
              </p>
              <Select
                style={{ minWidth: 200 }}
                variant="outlined"
                labelId="employee-with-client-rights-dropdown"
                value={selectedEmployee}
                onChange={handleEmployeeSelect}
              >
                <MenuItem value={MenuAlternative.Initial}>
                  {t("AutomaticAnalysis.employeeNotSelected")}
                </MenuItem>
                {employees.map((e) => (
                  <MenuItem key={e.id} value={e.id}>
                    {e.firstName && e.lastName
                      ? `${e.firstName} ${e.lastName}`
                      : `${e.email}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {state.analysisLoading && (
            <FlexibleSizeLoader size={25} style={{ marginTop: 15 }} />
          )}
          {automaticAnalysisResult &&
            !automaticAnalysisResult.companyResult && (
              <p className="automatic-analysis-insufficient-search-paragraph">
                {t("AutomaticAnalysis.insufficientData")}
              </p>
            )}
          <div
            className="automatic-analysis-export-container"
            style={pdfIsDisabled() ? { pointerEvents: "none" } : {}}
          >
            <PDFDownloadLink
              document={
                <AutomaticAnalysisPdf
                  automaticAnalysisResult={automaticAnalysisResult}
                  cycle={cycle}
                  totalAverage={totalAverage}
                />
              }
              fileName={resolvePdfName()}
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                color="primary"
                disabled={pdfIsDisabled()}
              >
                {t("AutomaticAnalysis.print")}
              </Button>
            </PDFDownloadLink>
          </div>
          {selectedEmployee !== "Initial" && (
            <CycleUpload
              companyId={companyId}
              cycleId={cycleId}
              userId={selectedEmployee}
              pdfPublicId={pdfInfo.pdfPublicId}
              pdfUrl={pdfInfo.pdfUrl}
            />
          )}
        </Paper>
      )}
    </div>
  );
};
