import { Page, View, Text } from "@react-pdf/renderer";
import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { actionProgramStyle } from "./ActionProgramStyle";
import { strategyPlanKPIStyling } from "./StrategyPlanKPIStyling";

interface Props {
  sortedQuestion: {
    name: string;
    action: string | undefined;
    recommendation: string | undefined;
  };
  questionNumber: number;
}

export const ActionProgram: FC<Props> = ({
  sortedQuestion,
  questionNumber,
}) => {
  const { t } = useTranslation();

  return (
    <Page size="A4" style={strategyPlanKPIStyling.page} object-fit="fill">
      <View style={strategyPlanKPIStyling.strategyPlanKPIMainContainer}>
        <View style={strategyPlanKPIStyling.strategyPlanKPIHorizontalLine} />
        <Text style={strategyPlanKPIStyling.strategyPlanKPIRecommendation}>
          {t("KPI.recommendations")}
        </Text>
        <Text style={actionProgramStyle.atctionProgram}>
          {t("Recommendations.actionProgram")}
        </Text>
        <View style={actionProgramStyle.questionWrapper}>
          <View style={actionProgramStyle.subTitleWrapper}>
            <View style={actionProgramStyle.circle}>
              <Text style={actionProgramStyle.circleText}>
                {questionNumber}
              </Text>
            </View>
            <Text>{sortedQuestion.action}</Text>
          </View>
          <Text style={actionProgramStyle.paragraph}>
            {sortedQuestion.recommendation}
          </Text>
        </View>
      </View>
    </Page>
  );
};
