import { StyleSheet } from "@react-pdf/renderer";

export const automaticAnalysisNarrowPageStyle = StyleSheet.create({
  page: {
    flexDirection: "column",
  },
  section: {
    margin: 30,
    padding: 10,
  },
  mainSection: {
    height: "180px",
    display: "flex",
    flexDirection: "column",
  },
  sectionDescription: {
    position: "absolute",
  },
  text: {
    fontSize: "12px",
    fontFamily: "Times-Roman",
    marginBottom: 12,
  },
  barChartContainer: {
    position: "absolute",
    flexDirection: "row",
    marginTop: "100px",
    height: "60px",
  },
  heading: {
    marginBottom: "12px",
    fontFamily: "Helvetica",
    fontSize: "12px",
  },
  barChartText: {
    position: "relative",
    width: "20%",
  },
  chartText: {
    fontSize: "8px",
    textAlign: "right",
    marginBottom: "12px",
    paddingRight: "5px",
  },
  barChart: {
    width: "80%",
  },
  horizontalLine: {
    textAlign: "center",
    width: "500px",
    borderBottom: "3px",
    borderBottomColor: "#068466",
    borderBottomStyle: "solid",
    borderBottomWidth: "3px",
  },
  statistics: {
    fontSize: "13px",
    fontFamily: "Times-Roman",
    letterSpacing: "1px",
    marginTop: "10px",
    marginBottom: "10px",
  },
  title: {
    fontFamily: "Helvetica",
    fontSize: "13px",
    marginTop: "10px",
    marginBottom: "10px",
    letterSpacing: "2px",
  },
  header: {
    marginBottom: "10px",
  },
  threeCirclesContainer: {
    height: "100px",
    position: "relative",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  oneCircleContainer: {
    position: "relative",
    height: "100px",
    display: "flex",
    flexDirection: "row",
  },
  circle: {
    backgroundColor: "#068466",
    width: "130px",
    height: "130px",
    bottom: 0,
    borderRadius: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  circleGender: {
    position: "relative",
    bottom: 30,
    right: -415,
    backgroundColor: "#068466",
    width: "150px",
    height: "150px",
    borderRadius: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  circleHeadline: {
    fontSize: "25px",
    fontFamily: "Helvetica-Bold",
    color: "white",
    marginBottom: "5px",
  },
  circleText: {
    fontSize: "9px",
    fontFamily: "Helvetica",
    color: "white",
    width: "120px",
    textAlign: "center",
    lineHeight: "1.2px",
  },
});
