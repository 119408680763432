import { StyleSheet } from "@react-pdf/renderer";

export const introductionPageStyling = StyleSheet.create({
  page: {
    backgroundColor: "#000",
    width: "100%",
    orientation: "portrait",
    backgroundSize: "cover",
  },
  parentDiv: {
    position: "relative",
    objectFit: "cover",
    alignItems: "center",
  },
  image: {
    minWidth: "100%",
    minHeight: "100%",
    opacity: 0.5,
  },
  horizontalLineTop: {
    position: "absolute",
    top: 35,
    textAlign: "center",
    width: "500px",
    borderBottom: "3px",
    borderBottomColor: "white",
    borderBottomStyle: "solid",
    borderBottomWidth: "3px",
  },
  horizontalLineBottom: {
    position: "absolute",
    bottom: 35,
    textAlign: "center",
    width: "500px",
    borderBottom: "3px",
    borderBottomColor: "white",
    borderBottomStyle: "solid",
    borderBottomWidth: "3px",
  },
  companyTextTop: {
    color: "white",
    position: "absolute",
    top: 70,
    fontSize: "14px",
    fontFamily: "Helvetica-Bold",
    letterSpacing: "3px",
    textTransform: "uppercase",
  },
  headlineText: {
    color: "white",
    position: "absolute",
    textAlign: "center",
    top: 350,
    fontSize: "40px",
    letterSpacing: "5px",
    fontFamily: "Helvetica-Bold",
  },
  smallHeadlineText: {
    color: "white",
    position: "absolute",
    textAlign: "center",
    top: 410,
    fontSize: "11.5px",
    letterSpacing: "5px",
    fontFamily: "Helvetica-Bold",
  },
  bottomInfoDiv: {
    bottom: 50,
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    paddingLeft: "90px",
    paddingRight: "90px",
    width: "100%",
  },
  bottomInfo: {
    color: "white",
    fontSize: "10px",
    letterSpacing: "1px",
    fontFamily: "Helvetica",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
});
