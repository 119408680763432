import type {
  ApiResponseAutomaticAnalysisResult,
  ApiResponseQuestionResult,
  ApiResponseResultCategory,
} from "../../../../utils/api/apiInterfaces";

const getRoundedPercentageForCategory = (
  category: ApiResponseResultCategory,
  totalAnswers: number
) => {
  const { low, medium, high } = category;

  return {
    low: Math.round(Number((low / totalAnswers) * 100)),
    medium: Math.round(Number((medium / totalAnswers) * 100)),
    high: Math.round(Number((high / totalAnswers) * 100)),
  };
};

const getExactPercentageForCategory = (
  category: ApiResponseResultCategory,
  totalAnswers: number
) => {
  const { low, medium, high } = category;

  return {
    low: Number((low / totalAnswers) * 100),
    medium: Number((medium / totalAnswers) * 100),
    high: Number((high / totalAnswers) * 100),
  };
};

const getAmountOfTenForCategory = (
  category: ApiResponseResultCategory,
  totalAnswers: number
) => {
  const { low, medium, high } = category;
  return {
    low: Math.round((low / totalAnswers) * 10),
    medium: Math.round((medium / totalAnswers) * 10),
    high: Math.round((high / totalAnswers) * 10),
  };
};

export const getPercentageResults = (
  data: ApiResponseAutomaticAnalysisResult
) => {
  const { physicalResults, developmentResults, stressResults, totalAnswers } =
    data;

  return {
    physicalPercentages: getRoundedPercentageForCategory(
      physicalResults,
      totalAnswers
    ),
    developmentPercentages: getRoundedPercentageForCategory(
      developmentResults,
      totalAnswers
    ),
    stressPercentages: getRoundedPercentageForCategory(
      stressResults,
      totalAnswers
    ),
  };
};

export const getExactPercentageResults = (
  data: ApiResponseAutomaticAnalysisResult
) => {
  const { physicalResults, developmentResults, stressResults, totalAnswers } =
    data;

  return {
    physicalPercentages: getExactPercentageForCategory(
      physicalResults,
      totalAnswers
    ),
    developmentPercentages: getExactPercentageForCategory(
      developmentResults,
      totalAnswers
    ),
    stressPercentages: getExactPercentageForCategory(
      stressResults,
      totalAnswers
    ),
  };
};

export const getAmountOfTenResults = (
  data: ApiResponseAutomaticAnalysisResult
) => {
  const { physicalResults, developmentResults, stressResults, totalAnswers } =
    data;

  return {
    physicalAmount: getAmountOfTenForCategory(physicalResults, totalAnswers),
    developmentAmount: getAmountOfTenForCategory(
      developmentResults,
      totalAnswers
    ),
    stressAmount: getAmountOfTenForCategory(stressResults, totalAnswers),
  };
};

export const getLowestHighScore = (highScores: number[]) =>
  Math.min(...highScores);

export const getAmountOfTenForSingleQuestion = (
  questions: ApiResponseQuestionResult[],
  swedishQuestion: string
) => {
  const matchingQuestion = questions.find((q) => q.nameSv === swedishQuestion);

  if (!matchingQuestion) {
    return null;
  }
  const { amountLowScore, amountMediumScore, amountHighScore } =
    matchingQuestion;
  const totalAnswers = amountLowScore + amountMediumScore + amountHighScore;

  return {
    low: Math.round((amountLowScore / totalAnswers) * 10),
    medium: Math.round((amountMediumScore / totalAnswers) * 10),
    high: Math.round((amountHighScore / totalAnswers) * 10),
  };
};

export const divideQuestionsToFitPage = (
  questions: ApiResponseQuestionResult[],
  size: number
) => {
  const chunks = [];
  questions.concat(...questions);

  while (questions.length) {
    chunks.push(questions.splice(0, size));
  }

  return chunks;
};
