import { useEffect, useState } from "react";
import { Button, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Add } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { getLanguage } from "../../i18n";
import Spacer from "../shared/Spacer";
import api from "../../utils/api/v1";
import Loader from "../shared/loader/Loader";
import SingleSelectionGrid from "../../UI-Components/SingleRowSelectionGrid/SingleRowSelectionGrid";
import "./MicroCourses.css";

interface ValueGetterProps {
  row: {
    type: string;
    date: number;
  };
}

interface RowProps {
  id: string;
  nameSv: string | null;
  nameEn: string | null;
  shortDescriptionSv: string | null;
  shortDescriptionEn: string | null;
  date: number;
  type: string | null;
}

const formatDateTime = (dateTime: number) => {
  const date = new Date(dateTime);
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  } as const;
  return date.toLocaleDateString(getLanguage(), options);
};

export const MicroCourses = () => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    loading: true,
    error: false,
  });
  const [microCourses, setMicroCourses] = useState<Array<RowProps>>([]);
  const [, setWindowWidth] = useState(window.innerWidth);

  const getMicroCourses = async () => {
    setState({
      loading: true,
      error: false,
    });
    try {
      const {
        data: { microCourses: microCourseData },
      } = await api.getMicroCourses();
      const timezoneOffset = new Date().getTimezoneOffset();
      const rows = microCourseData.map((mc) => ({
        id: mc.id,
        nameSv: mc.textData.nameSv,
        nameEn: mc.textData.nameEn,
        shortDescriptionSv: mc.textData.shortDescriptionSv,
        shortDescriptionEn: mc.textData.shortDescriptionEn,
        date: Date.parse(mc.createdAt) - timezoneOffset * 60 * 1000,
        type: mc.type,
      }));
      setMicroCourses(rows);
      setState({ ...state, loading: false });
    } catch (err) {
      console.error(err);
      setState({
        ...state,
        loading: false,
        error: false,
      });
    }
  };

  const columns = [
    { field: "id", hide: true },
    {
      field: getLanguage() === "sv" ? "nameSv" : "nameEn",
      headerName: t("SingleRowSelectionGrid.resourceName"),
      width:
        window.innerWidth >= 960
          ? window.innerWidth / 6.5
          : window.innerWidth / 5.5,
    },
    {
      field:
        getLanguage() === "sv" ? "shortDescriptionSv" : "shortDescriptionEn",
      headerName: t("SingleRowSelectionGrid.shortDesc"),
      width:
        window.innerWidth >= 960
          ? window.innerWidth / 4
          : window.innerWidth / 3,
    },
    {
      field: "type",
      headerName: t("SingleRowSelectionGrid.type"),
      width:
        window.innerWidth >= 960
          ? window.innerWidth / 5.5
          : window.innerWidth / 4.5,
      valueGetter: (params: ValueGetterProps) =>
        `${
          // eslint-disable-next-line no-nested-ternary
          params.row.type === "Physical Activity"
            ? t("Resources.type.physicalActivity")
            : // eslint-disable-next-line no-nested-ternary
            params.row.type === "Relaxation"
            ? t("Resources.type.relaxation")
            : // eslint-disable-next-line no-nested-ternary
            params.row.type === "Nutrition"
            ? t("Resources.type.nutrition")
            : // eslint-disable-next-line no-nested-ternary
            params.row.type === "Personal Development"
            ? t("Resources.type.personalDevelopment")
            : // eslint-disable-next-line no-nested-ternary
            params.row.type === "Recovery"
            ? t("Resources.type.recovery")
            : // eslint-disable-next-line no-nested-ternary
            params.row.type === "Exercise"
            ? t("Resources.type.exercise")
            : "-"
        }`,
    },
    {
      field: "date",
      headerName: t("SingleRowSelectionGrid.dateAdded"),
      width:
        window.innerWidth >= 960
          ? window.innerWidth / 5.5
          : window.innerWidth / 4.5,
      valueGetter: (params: ValueGetterProps) =>
        formatDateTime(params.row.date) || "-",
    },
  ];

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    (async () => {
      await getMicroCourses();
    })();

    window.addEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mainGui = () => {
    if (state.loading)
      return (
        <Loader text={t("MicroCourses.loading")} style={{ marginTop: 40 }} />
      );
    if (!state.loading && microCourses) {
      return (
        <>
          <div className="section-toolbar">
            <Typography className="section-title" variant="h6">
              {t("MicroCourses.title")}
            </Typography>
            <Spacer />
            <Link
              to="/micro-courses/micro-course/new"
              className="micro-courses-link-style"
            >
              <Button startIcon={<Add />} color="primary" variant="contained">
                {t("MicroCourses.create")}
              </Button>
            </Link>
          </div>

          <div className="section-SingleSelectionGrid-layout">
            <SingleSelectionGrid
              columns={columns}
              rows={microCourses}
              pageSize={10}
              loading={state.loading}
              path="micro-courses"
            />
          </div>
        </>
      );
    }

    return <Loader text={t("Resources.loading")} style={{ marginTop: 40 }} />;
  };

  return <div className="surveys-wrapper">{mainGui()}</div>;
};
