import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import client from "../httpClient";
import type {
  ApiCompanyResponse,
  ApiResponseTerm,
  ApiResponseCompanyBossModel,
  ApiResponseCompanyCycle,
  ApiResponseCompanyCycleQuestionsResultModel,
  ApiResponseCompanyDepartmentModel,
  ApiResponseCompanyTitleModel,
  ApiResponseCycleResult,
  ApiResponseModuleWithTextDataModel,
  ApiResponseNestedModuleActivitiesModel,
  ApiResponseNestedSurveyModel,
  ApiResponseResultsData,
  ApiResponseSurveyModel,
  ApiResponseSurveyWithoutQuestionModel,
  CompanyBossListModel,
  CompanyBossModel,
  CompanyDepartmentListModel,
  CompanyDepartmentModel,
  CompanyTitleListModel,
  CompanyTitleModel,
  CounselingEmailModel,
  CounselingTextModel,
  CycleModel,
  ApiResponseEmployeeWithUserData,
  InvitedUserModel,
  InviteUserListModel,
  NestedSurveyModel,
  NewClientDepartmentFilteringRightsModel,
  SurveyModel,
  SurveyQuestionModel,
  ApiResponseNestedTermModule,
  TermModules,
  TermModule,
  ApiResponseTermTeacher,
  ApiResponseTeacher,
  TermTeacher,
  ApiResponseResourceWithTextDataModel,
  UploadAssetModel,
  ApiResponseModuleActivityWithTextDataModel,
  ActivitesLinkedResourceModel,
  ApiResponseCounselingResourceModel,
  ApiResponseEmailReminderTimeModel,
  EmailReminderTimeModel,
  ApiResponseCompanyEmailBasicReminderModel,
  CompanyEmailBasicReminderModel,
  CompanyMotivationEmailModel,
  ApiResponseCompanyMotivationEmailModel,
  ApiResponseMicroCoursesVm,
  ApiResponseMicroCourseWithResourcesVm,
  MicroCourseResourcesOrderModel,
  ApiResponseMicroCourseResourcesModel,
  ApiResponseMicroCourseResourceModel,
  CompanyFormDataModel,
  ApiResponseAccessTokenResource,
  ApiResponseEmployeesWithClientRightsModel,
  ApiResponseAutomaticAnalysisModel,
  ApiResponseCycleTotalAverage,
} from "./apiInterfaces";

const API = `${process.env.REACT_APP_API_URL}/api`;
const API_V1 = `${API}/v1`;

/* Auth */
const AUTH = `${API_V1}/login`;
const REVOKE = `${API_V1}/token`;

const signIn = (
  email: string,
  password: string
): Promise<AxiosResponse<ApiResponseAccessTokenResource>> =>
  axios.post(`${AUTH}/admin`, { email, password });

const logout = (): Promise<AxiosResponse<void>> =>
  client.get(`${AUTH}/admin/logout`);

const resetPassword = (email: string): Promise<AxiosResponse<void>> =>
  client.post(`${API_V1}/admin/password-reset`, { email });

const refreshToken = (
  token: string,
  email: string
): Promise<AxiosResponse<ApiResponseAccessTokenResource>> =>
  axios.post(`${REVOKE}/admin/refresh`, { Token: token, UserEmail: email });

/* Companies */
const COMPANY_RESOURCE_PATH = `${API_V1}/admin/companies`;

const getCompanies = (): Promise<AxiosResponse<Array<ApiCompanyResponse>>> =>
  client.get(COMPANY_RESOURCE_PATH);

const createCompany = (
  form: CompanyFormDataModel
): Promise<AxiosResponse<ApiCompanyResponse>> =>
  client.post(`${COMPANY_RESOURCE_PATH}`, form);

const getCompany = (
  companyId: string
): Promise<AxiosResponse<ApiCompanyResponse>> =>
  client.get(`${COMPANY_RESOURCE_PATH}/${companyId}`);

const updateCompany = (
  companyId: string,
  form: CompanyFormDataModel
): Promise<AxiosResponse<ApiCompanyResponse>> =>
  client.put(`${COMPANY_RESOURCE_PATH}/${companyId}`, form);

const deleteCompany = (
  companyId: string
): Promise<AxiosResponse<ApiCompanyResponse>> =>
  client.delete(`${COMPANY_RESOURCE_PATH}/${companyId}`);

const updateClientDepartmentsFilteringRights = (
  employeeId: string,
  newClientDepartmentFilteringRights: NewClientDepartmentFilteringRightsModel
): Promise<AxiosResponse<void>> =>
  client.post(
    `${COMPANY_RESOURCE_PATH}/employees/${employeeId}/update-client-departments-filtering-rights`,
    newClientDepartmentFilteringRights
  );

const getEmployees = (
  companyId: string
): Promise<AxiosResponse<Array<ApiResponseEmployeeWithUserData>>> =>
  client.get(`${COMPANY_RESOURCE_PATH}/${companyId}/employees`);

const inviteEmployees = (
  employees: InviteUserListModel,
  companyId: string
): Promise<AxiosResponse<Array<InvitedUserModel>>> =>
  client.post(
    `${COMPANY_RESOURCE_PATH}/${companyId}/employees/invite`,
    employees
  );

const deleteCompanyEmployee = (
  companyId: string,
  employeeId: string
): Promise<AxiosResponse<void>> =>
  client.delete(
    `${COMPANY_RESOURCE_PATH}/${companyId}/employees/${employeeId}`
  );

const addCounselingEmailToCompany = (
  companyId: string,
  data: CounselingEmailModel
): Promise<AxiosResponse<string>> =>
  client.put(`${COMPANY_RESOURCE_PATH}/${companyId}/counselingemail`, data);

const addCounselingTextToCompany = (
  companyId: string,
  data: CounselingTextModel
): Promise<AxiosResponse<CounselingTextModel>> =>
  client.put(`${COMPANY_RESOURCE_PATH}/${companyId}/counselingtext`, data);

const getEmployeesWithClientRights = (
  companyId: string
): Promise<AxiosResponse<ApiResponseEmployeesWithClientRightsModel>> =>
  client.get(`${COMPANY_RESOURCE_PATH}/${companyId}/employeesWithClientRights`);

/* Titles */
const getTitles = (
  companyId: string
): Promise<AxiosResponse<Array<ApiResponseCompanyTitleModel>>> =>
  client.get(`${COMPANY_RESOURCE_PATH}/${companyId}/titles`);

const createTitle = (
  companyId: string,
  title: CompanyTitleModel
): Promise<AxiosResponse<ApiResponseCompanyTitleModel>> =>
  client.post(`${COMPANY_RESOURCE_PATH}/${companyId}/titles`, title);

const getTitle = async (
  companyId: string,
  titleId: string
): Promise<AxiosResponse<ApiResponseCompanyTitleModel>> =>
  client.get(`${COMPANY_RESOURCE_PATH}/${companyId}/titles/${titleId}`);

const updateTitles = (
  companyId: string,
  titles: CompanyTitleListModel
): Promise<AxiosResponse<Array<ApiResponseCompanyTitleModel>>> =>
  client.put(`${COMPANY_RESOURCE_PATH}/${companyId}/titles`, titles);

const deleteTitles = (
  companyId: string,
  titles: CompanyTitleListModel
): Promise<AxiosResponse<void>> =>
  client.delete(`${COMPANY_RESOURCE_PATH}/${companyId}/titles`, {
    data: titles,
  });

/* Departments */
const getDepartments = (
  companyId: string
): Promise<AxiosResponse<Array<ApiResponseCompanyDepartmentModel>>> =>
  client.get(`${COMPANY_RESOURCE_PATH}/${companyId}/departments`);

const createDepartment = (
  companyId: string,
  department: CompanyDepartmentModel
): Promise<AxiosResponse<ApiResponseCompanyDepartmentModel>> =>
  client.post(`${COMPANY_RESOURCE_PATH}/${companyId}/departments`, department);

const deleteDepartments = (
  companyId: string,
  departments: CompanyDepartmentListModel
): Promise<AxiosResponse<void>> =>
  client.delete(`${COMPANY_RESOURCE_PATH}/${companyId}/departments`, {
    data: departments,
  });

const getDepartment = async (
  companyId: string,
  departmentId: string
): Promise<AxiosResponse<ApiResponseCompanyDepartmentModel>> =>
  client.get(
    `${COMPANY_RESOURCE_PATH}/${companyId}/departments/${departmentId}`
  );

const updateDepartments = (
  companyId: string,
  departments: CompanyDepartmentListModel
): Promise<AxiosResponse<Array<ApiResponseCompanyDepartmentModel>>> =>
  client.put(`${COMPANY_RESOURCE_PATH}/${companyId}/departments`, departments);

/* Cycles */
const getCycles = (
  companyId: string
): Promise<AxiosResponse<Array<ApiResponseCompanyCycle>>> =>
  client.get(`${COMPANY_RESOURCE_PATH}/${companyId}/cycles`);

const getCycleResults = (
  companyId: string,
  cycleId: string
): Promise<AxiosResponse<ApiResponseCycleResult>> =>
  client.get(`${COMPANY_RESOURCE_PATH}/${companyId}/cycles/${cycleId}/results`);

const getCycleResultsData = (
  companyId: string,
  cycleId: string
): Promise<AxiosResponse<ApiResponseResultsData>> =>
  client.get(
    `${COMPANY_RESOURCE_PATH}/${companyId}/cycles/${cycleId}/results-data`
  );

const getCycleQuestionData = (
  companyId: string,
  cycleId: string
): Promise<AxiosResponse<ApiResponseCompanyCycleQuestionsResultModel>> =>
  client.get(
    `${COMPANY_RESOURCE_PATH}/${companyId}/cycles/${cycleId}/questionanswers`
  );

const createCycle = (
  companyId: string,
  cycle: CycleModel
): Promise<AxiosResponse<void>> =>
  client.post(`${COMPANY_RESOURCE_PATH}/${companyId}/cycles`, cycle);

const getCycle = (
  companyId: string,
  cycleId: string
): Promise<AxiosResponse<ApiResponseCompanyCycle>> =>
  client.get(`${COMPANY_RESOURCE_PATH}/${companyId}/cycles/${cycleId}`);

const updateCycle = (
  companyId: string,
  cycleId: string,
  cycle: CycleModel
): Promise<AxiosResponse<ApiResponseCompanyCycle>> =>
  client.put(`${COMPANY_RESOURCE_PATH}/${companyId}/cycles/${cycleId}`, cycle);

const deleteCycle = (
  companyId: string,
  cycleId: string
): Promise<AxiosResponse<ApiResponseCompanyCycle>> =>
  client.delete(`${COMPANY_RESOURCE_PATH}/${companyId}/cycles/${cycleId}`);

const sendCycleEmail = (
  companyId: string,
  cycleId: string
): Promise<AxiosResponse<void>> =>
  client.post(
    `${COMPANY_RESOURCE_PATH}/${companyId}/cycles/${cycleId}/send-email`
  );

/* Bosses */
const getBosses = (
  companyId: string
): Promise<AxiosResponse<Array<ApiResponseCompanyBossModel>>> =>
  client.get(`${COMPANY_RESOURCE_PATH}/${companyId}/bosses`);

const getBoss = async (
  companyId: string,
  bossId: string
): Promise<AxiosResponse<ApiResponseCompanyBossModel>> =>
  client.get(`${COMPANY_RESOURCE_PATH}/${companyId}/bosses/${bossId}`);

const createBoss = (
  companyId: string,
  boss: CompanyBossModel
): Promise<AxiosResponse<ApiResponseCompanyBossModel>> =>
  client.post(`${COMPANY_RESOURCE_PATH}/${companyId}/bosses`, boss);

const updateBosses = (
  companyId: string,
  bosses: CompanyBossListModel
): Promise<AxiosResponse<Array<ApiResponseCompanyBossModel>>> =>
  client.put(`${COMPANY_RESOURCE_PATH}/${companyId}/bosses`, bosses);

const deleteBosses = (
  companyId: string,
  bosses: CompanyBossListModel
): Promise<AxiosResponse<void>> =>
  client.delete(`${COMPANY_RESOURCE_PATH}/${companyId}/bosses`, {
    data: bosses,
  });

/* Surveys */
const SURVEY_RESOURCE_PATH = `${API_V1}/admin/surveys`;
const getSurveys = (): Promise<
  AxiosResponse<Array<ApiResponseSurveyWithoutQuestionModel>>
> => client.get(`${SURVEY_RESOURCE_PATH}`);

const createSurvey = (survey: SurveyModel): Promise<AxiosResponse<string>> =>
  client.post(`${SURVEY_RESOURCE_PATH}`, survey);

const getSurvey = (
  surveyId: string
): Promise<AxiosResponse<ApiResponseSurveyModel>> =>
  client.get(`${SURVEY_RESOURCE_PATH}/${surveyId}`);

const updateSurvey = (
  survey: NestedSurveyModel
): Promise<AxiosResponse<ApiResponseNestedSurveyModel>> =>
  client.put(`${SURVEY_RESOURCE_PATH}`, survey);

const deleteSurvey = (surveyId: string): Promise<AxiosResponse<void>> =>
  client.delete(`${SURVEY_RESOURCE_PATH}/${surveyId}`);

const deleteSurveyQuestion = (
  surveyId: string,
  questionId: string
): Promise<AxiosResponse<string>> =>
  client.delete(`${SURVEY_RESOURCE_PATH}/${surveyId}/question/${questionId}`);

const addSurveyQuestion = (
  surveyId: string,
  question: SurveyQuestionModel
): Promise<AxiosResponse<string>> =>
  client.post(`${SURVEY_RESOURCE_PATH}/${surveyId}/question`, question);

/* Modules */
const MODULE_RESOURCE_PATH = `${API_V1}/admin/modules`;
const getModules = (): Promise<
  AxiosResponse<Array<ApiResponseModuleWithTextDataModel>>
> => client.get(`${MODULE_RESOURCE_PATH}`);

const createModule = (
  module: FormData,
  config: AxiosRequestConfig
): Promise<AxiosResponse<string>> =>
  client.post(`${MODULE_RESOURCE_PATH}`, module, config);

const getModule = (
  moduleId: string
): Promise<AxiosResponse<ApiResponseNestedModuleActivitiesModel>> =>
  client.get(`${MODULE_RESOURCE_PATH}/${moduleId}`);

const updateModule = (
  moduleId: string,
  module: FormData
): Promise<AxiosResponse<ApiResponseNestedModuleActivitiesModel>> =>
  client.put(`${MODULE_RESOURCE_PATH}/${moduleId}`, module);

const deleteModule = (moduleId: string): Promise<AxiosResponse<void>> =>
  client.delete(`${MODULE_RESOURCE_PATH}/${moduleId}`);

const deleteModuleAsset = (
  imagePublicId: string
): Promise<AxiosResponse<string>> =>
  client.delete(`${MODULE_RESOURCE_PATH}/image/${imagePublicId}`);

/* Terms */
const TERM_RESOURCE_PATH = `${API_V1}/admin/terms`;

const getTerms = (): Promise<AxiosResponse<Array<ApiResponseTerm>>> =>
  client.get(`${TERM_RESOURCE_PATH}`);

const getTerm = (
  termId: string
): Promise<AxiosResponse<ApiResponseNestedTermModule>> =>
  client.get(`${TERM_RESOURCE_PATH}/${termId}`);

const createTerm = (): Promise<AxiosResponse<Array<ApiResponseTerm>>> =>
  client.post(`${TERM_RESOURCE_PATH}`);

const associateTermWithModules = (
  termModulesModel: TermModules
): Promise<AxiosResponse<ApiResponseNestedTermModule>> =>
  client.post(`${TERM_RESOURCE_PATH}/create-term-modules`, termModulesModel);

const dissociateTermWithModule = (
  termModuleModel: TermModule
): Promise<AxiosResponse<string>> =>
  client.put(`${TERM_RESOURCE_PATH}/modules`, termModuleModel);

// TermTeachers
const getTermTeacherByTermId = (
  termId: string
): Promise<AxiosResponse<Array<ApiResponseTermTeacher>>> =>
  client.get(`${TERM_RESOURCE_PATH}/get-term-teacher-for-term-id/${termId}`);

const getTermTeachersByTeacherId = (
  teacherId: string
): Promise<AxiosResponse<Array<ApiResponseTermTeacher>>> =>
  client.get(
    `${TERM_RESOURCE_PATH}/get-term-teachers-by-teacherid/${teacherId}`
  );

const getTeachersByTermId = (
  termId: string
): Promise<AxiosResponse<Array<ApiResponseTeacher>>> =>
  client.get(`${TERM_RESOURCE_PATH}/get-teachers-by-termId/${termId}`);

const createTermTeacher = (
  termTeacher: TermTeacher
): Promise<AxiosResponse<Array<ApiResponseTermTeacher>>> =>
  client.post(`${TERM_RESOURCE_PATH}/create-term-teacher`, termTeacher);

const deleteTermTeacher = (
  termteacherId: string
): Promise<AxiosResponse<void>> =>
  client.delete(`${TERM_RESOURCE_PATH}/${termteacherId}`);

/* Resources */
const RESOURCE_RESOURCE_PATH = `${API_V1}/admin/resources`;

const getResources = (): Promise<
  AxiosResponse<Array<ApiResponseResourceWithTextDataModel>>
> => client.get(`${RESOURCE_RESOURCE_PATH}`);

const getResource = (
  resourceId: string
): Promise<AxiosResponse<ApiResponseResourceWithTextDataModel>> =>
  client.get(`${RESOURCE_RESOURCE_PATH}/${resourceId}`);

const deleteResource = (
  resourceId: string
): Promise<AxiosResponse<ApiResponseResourceWithTextDataModel>> =>
  client.delete(`${RESOURCE_RESOURCE_PATH}/${resourceId}`);

const createResource = (
  resource: FormData,
  config: AxiosRequestConfig
): Promise<AxiosResponse<string>> =>
  client.post(`${RESOURCE_RESOURCE_PATH}`, resource, config);

const updateResource = (
  resourceId: string,
  resource: FormData,
  config: AxiosRequestConfig
): Promise<AxiosResponse<string>> =>
  client.put(`${RESOURCE_RESOURCE_PATH}/${resourceId}`, resource, config);

const uploadResourceImageAsset = (
  data: UploadAssetModel,
  resourceId: string
): Promise<AxiosResponse<string>> =>
  client.post(`${RESOURCE_RESOURCE_PATH}/imageasset/${resourceId}`, data);

const uploadResourceImagePdfOrVideoAsset = (
  data: UploadAssetModel,
  resourceId: string
): Promise<AxiosResponse<string>> =>
  client.post(`${RESOURCE_RESOURCE_PATH}/resourceasset/${resourceId}`, data);

const uploadEnglishResourceImagePdfOrVideoAsset = (
  data: UploadAssetModel,
  resourceId: string
): Promise<AxiosResponse<string>> =>
  client.post(
    `${RESOURCE_RESOURCE_PATH}/englishresourceasset/${resourceId}`,
    data
  );

const deleteResourceAsset = (
  resourcePublicId: string
): Promise<AxiosResponse<string>> =>
  client.delete(`${RESOURCE_RESOURCE_PATH}/resource/${resourcePublicId}`);

const deleteEnglishResourceAsset = (
  resourcePublicIdEn: string
): Promise<AxiosResponse<string>> =>
  client.delete(
    `${RESOURCE_RESOURCE_PATH}/englishresource/${resourcePublicIdEn}`
  );

const deleteImageAsset = (
  imagePublicId: string
): Promise<AxiosResponse<string>> =>
  client.delete(`${RESOURCE_RESOURCE_PATH}/image/${imagePublicId}`);

/* Moduleactivities */
const ACTIVITY_RESOURCE_PATH = `${API_V1}/admin/activities`;

const getModuleActivities = (
  moduleId: string
): Promise<AxiosResponse<Array<ApiResponseModuleActivityWithTextDataModel>>> =>
  client.get(`${ACTIVITY_RESOURCE_PATH}/modules/${moduleId}`);

const getModuleActivity = (
  moduleActivityId: string
): Promise<AxiosResponse<ApiResponseModuleActivityWithTextDataModel>> =>
  client.get(`${ACTIVITY_RESOURCE_PATH}/${moduleActivityId}`);

const deleteModuleActivity = (
  moduleActivityId: string
): Promise<AxiosResponse<void>> =>
  client.delete(`${ACTIVITY_RESOURCE_PATH}/${moduleActivityId}`);

const createModuleActivity = (
  moduleId: string,
  activity: FormData,
  config: AxiosRequestConfig
): Promise<AxiosResponse<ApiResponseModuleActivityWithTextDataModel>> =>
  client.post(`${ACTIVITY_RESOURCE_PATH}/${moduleId}`, activity, config);

const updateModuleActivity = (
  moduleActivityId: string,
  activity: FormData,
  config: AxiosRequestConfig
): Promise<AxiosResponse<ApiResponseModuleActivityWithTextDataModel>> =>
  client.put(`${ACTIVITY_RESOURCE_PATH}/${moduleActivityId}`, activity, config);

const deleteActivityResourceAsset = (
  resourcePublicId: string
): Promise<AxiosResponse<string>> =>
  client.delete(`${ACTIVITY_RESOURCE_PATH}/resource/${resourcePublicId}`);

const deleteEnglishActivityResourceAsset = (
  resourcePublicIdEn: string
): Promise<AxiosResponse<string>> =>
  client.delete(
    `${ACTIVITY_RESOURCE_PATH}/englishresource/${resourcePublicIdEn}`
  );

const deleteActivityImageAsset = (
  imagePublicId: string
): Promise<AxiosResponse<string>> =>
  client.delete(`${ACTIVITY_RESOURCE_PATH}/image/${imagePublicId}`);

const uploadActivityImageAsset = (
  data: UploadAssetModel,
  activityId: string
): Promise<AxiosResponse<string>> =>
  client.post(`${ACTIVITY_RESOURCE_PATH}/imageasset/${activityId}`, data);

const uploadActivityImagePdfOrVideoAsset = (
  data: UploadAssetModel,
  activityId: string
): Promise<AxiosResponse<string>> =>
  client.post(`${ACTIVITY_RESOURCE_PATH}/resourceasset/${activityId}`, data);

const uploadEnglishActivityImagePdfOrVideoAsset = (
  data: UploadAssetModel,
  activityId: string
): Promise<AxiosResponse<string>> =>
  client.post(
    `${ACTIVITY_RESOURCE_PATH}/englishresourceasset/${activityId}`,
    data
  );

const connectResourceToActivity = (
  data: ActivitesLinkedResourceModel,
  activityId: string
): Promise<AxiosResponse<void>> =>
  client.put(`${ACTIVITY_RESOURCE_PATH}/connectResource/${activityId}`, data);

const connectMicroCourseToActivity = (
  data: ActivitesLinkedResourceModel,
  activityId: string
): Promise<AxiosResponse<void>> =>
  client.put(
    `${ACTIVITY_RESOURCE_PATH}/connectMicroCourse/${activityId}`,
    data
  );

/* Counselingresources */
const COUNSELING_RESOURCE_PATH = `${API_V1}/admin/counselingresources`;

const getCounselingResources = (): Promise<
  AxiosResponse<Array<ApiResponseCounselingResourceModel>>
> => client.get(`${COUNSELING_RESOURCE_PATH}`);

const getCounselingResource = (
  counselingResourceId: string
): Promise<AxiosResponse<ApiResponseCounselingResourceModel>> =>
  client.get(`${COUNSELING_RESOURCE_PATH}/${counselingResourceId}`);

const deleteCounselingResource = (
  counselingResourceId: string
): Promise<AxiosResponse<string>> =>
  client.delete(`${COUNSELING_RESOURCE_PATH}/${counselingResourceId}`);

const createCounselingResource = (
  counselingResource: FormData,
  config: AxiosRequestConfig
): Promise<AxiosResponse<ApiResponseCounselingResourceModel>> =>
  client.post(`${COUNSELING_RESOURCE_PATH}/create`, counselingResource, config);

const updateCounselingResource = (
  counselingResourceId: string,
  data: FormData,
  config: AxiosRequestConfig
): Promise<AxiosResponse<ApiResponseCounselingResourceModel>> =>
  client.put(
    `${COUNSELING_RESOURCE_PATH}/update/${counselingResourceId}`,
    data,
    config
  );

const uploadCounselingResourceImageAsset = (
  data: UploadAssetModel,
  counselingResourceId: string
): Promise<AxiosResponse<string>> =>
  client.post(
    `${COUNSELING_RESOURCE_PATH}/imageasset/${counselingResourceId}`,
    data
  );

const uploadCounselingResourceImagePdfOrVideoAsset = (
  data: UploadAssetModel,
  counselingResourceId: string
): Promise<AxiosResponse<string>> =>
  client.post(
    `${COUNSELING_RESOURCE_PATH}/resourceasset/${counselingResourceId}`,
    data
  );

const uploadEnglishCounselingResourceImagePdfOrVideoAsset = (
  data: UploadAssetModel,
  counselingResourceId: string
): Promise<AxiosResponse<string>> =>
  client.post(
    `${COUNSELING_RESOURCE_PATH}/englishresourceasset/${counselingResourceId}`,
    data
  );

const deleteCounselingResourceAsset = (
  resourcePublicId: string
): Promise<AxiosResponse<string>> =>
  client.delete(`${COUNSELING_RESOURCE_PATH}/resource/${resourcePublicId}`);

const deleteEnglishCounselingResourceAsset = (
  resourcePublicIdEn: string
): Promise<AxiosResponse<string>> =>
  client.delete(
    `${COUNSELING_RESOURCE_PATH}/englishresource/${resourcePublicIdEn}`
  );

const deleteCounselingResourceImageAsset = (
  imagePublicId: string
): Promise<AxiosResponse<string>> =>
  client.delete(`${COUNSELING_RESOURCE_PATH}/image/${imagePublicId}`);

// Teachers
const TEACHERS_RESOURCE_PATH = `${API_V1}/admin/teachers`;
const getTeachers = (): Promise<AxiosResponse<Array<ApiResponseTeacher>>> =>
  client.get(`${TEACHERS_RESOURCE_PATH}`);

const getTeacher = (
  teacherId: string
): Promise<AxiosResponse<ApiResponseTeacher>> =>
  client.get(`${TEACHERS_RESOURCE_PATH}/${teacherId}`);

const createTeacher = (
  teacher: FormData,
  config: AxiosRequestConfig
): Promise<AxiosResponse<ApiResponseTeacher>> =>
  client.post(`${TEACHERS_RESOURCE_PATH}`, teacher, config);

const updateTeacher = (
  teacherId: string,
  teacher: FormData
): Promise<AxiosResponse<ApiResponseTeacher>> =>
  client.put(`${TEACHERS_RESOURCE_PATH}/${teacherId}`, teacher);

const deleteTeacher = (teacherId: string): Promise<AxiosResponse<string>> =>
  client.delete(`${TEACHERS_RESOURCE_PATH}/${teacherId}`);

const uploadTeacherImageAsset = (
  data: UploadAssetModel,
  teacherId: string
): Promise<AxiosResponse<string>> =>
  client.put(`${TEACHERS_RESOURCE_PATH}/imageasset/${teacherId}`, data);

const deleteTeacherImageAsset = (
  imagePublicId: string
): Promise<AxiosResponse<string>> =>
  client.delete(`${TEACHERS_RESOURCE_PATH}/image/${imagePublicId}`);

// Email Reminder Time endpoints
const EMAILREMINDERTIME_PATH = `${API_V1}/admin/emailremindertime`;
const getEmailReminderTime = (): Promise<
  AxiosResponse<ApiResponseEmailReminderTimeModel>
> => client.get(`${EMAILREMINDERTIME_PATH}`);

const createEmailReminderTime = (
  reminderData: EmailReminderTimeModel
): Promise<AxiosResponse<ApiResponseEmailReminderTimeModel>> =>
  client.post(`${EMAILREMINDERTIME_PATH}`, reminderData);

const editEmailReminderTime = (
  reminderData: EmailReminderTimeModel
): Promise<AxiosResponse<ApiResponseEmailReminderTimeModel>> =>
  client.put(`${EMAILREMINDERTIME_PATH}`, reminderData);

// Basic Email Reminders for Company
const COMPANY_BASIC_EMAIL_REMINDERS_PATH = `${API_V1}/admin/companyemailbasicreminders`;

const createCompanyEmailBasicReminders = (
  companyEmailBasicReminders: CompanyEmailBasicReminderModel
): Promise<AxiosResponse<ApiResponseCompanyEmailBasicReminderModel>> =>
  client.post(
    `${COMPANY_BASIC_EMAIL_REMINDERS_PATH}`,
    companyEmailBasicReminders
  );

const updateCompanyEmailBasicReminders = (
  companyEmailBasicReminders: CompanyEmailBasicReminderModel
): Promise<AxiosResponse<ApiResponseCompanyEmailBasicReminderModel>> =>
  client.put(
    `${COMPANY_BASIC_EMAIL_REMINDERS_PATH}`,
    companyEmailBasicReminders
  );

const getCompanyEmailBasicReminders = (
  companyId: string
): Promise<AxiosResponse<ApiResponseCompanyEmailBasicReminderModel>> =>
  client.get(`${COMPANY_BASIC_EMAIL_REMINDERS_PATH}/${companyId}`);

// Company Motivation Emails
const COMPANY_MOTIVATION_EMAILS_PATH = `${API_V1}/admin/companymotivationemail`;

const createCompanyMotivationMail = (
  companyMotivationMail: CompanyMotivationEmailModel
): Promise<AxiosResponse<ApiResponseCompanyMotivationEmailModel>> =>
  client.post(`${COMPANY_MOTIVATION_EMAILS_PATH}`, companyMotivationMail);

const updateCompanyMotivationMail = (
  companyMotivationMail: CompanyMotivationEmailModel
): Promise<AxiosResponse<ApiResponseCompanyMotivationEmailModel>> =>
  client.put(`${COMPANY_MOTIVATION_EMAILS_PATH}`, companyMotivationMail);

const getCompanyMotivationEmailsByCompanyIdAndYear = (
  companyId: string,
  requiredInfo: FormData,
  config: AxiosRequestConfig
): Promise<AxiosResponse<ApiResponseCompanyMotivationEmailModel>> =>
  client.post(
    `${COMPANY_MOTIVATION_EMAILS_PATH}/${companyId}`,
    requiredInfo,
    config
  );

// Client Role related endpoints
const CLIENT_ROLE_PATH = `${API_V1}/admin/companies/employees`;

const AddClientRoleToUser = (
  employeeId: string
): Promise<AxiosResponse<void>> =>
  client.post(`${CLIENT_ROLE_PATH}/${employeeId}/add-client-role`);

const RemoveClientRoleToUser = (
  employeeId: string
): Promise<AxiosResponse<void>> =>
  client.delete(`${CLIENT_ROLE_PATH}/${employeeId}/remove-client-role`);

// Cycle Pdf for client related.
const CYCLE_ASSET_PATH = `${API_V1}/admin/companies`;

const uploadPdfCycleAsset = (
  data: UploadAssetModel,
  cycleId: string,
  userId: string
): Promise<AxiosResponse<string>> =>
  client.post(
    `${CYCLE_ASSET_PATH}/cycles/${cycleId}/users/${userId}/upload-pdf-asset`,
    data
  );

const deleteCyclePdfAsset = (
  pdfPublicId: string
): Promise<AxiosResponse<string>> =>
  client.delete(`${CYCLE_ASSET_PATH}/cycles/upload/${pdfPublicId}`);

const getCycleTotalAverage = (): Promise<
  AxiosResponse<ApiResponseCycleTotalAverage>
> => client.get(`${CYCLE_ASSET_PATH}/cycles/total-average-result`);

// Micro Course related
const MICRO_COURSE_PATH = `${API_V1}/admin/MicroCourse`;

const getMicroCourses = (): Promise<AxiosResponse<ApiResponseMicroCoursesVm>> =>
  client.get(`${MICRO_COURSE_PATH}`);

const createMicroCourse = (
  microCourse: FormData,
  config: AxiosRequestConfig
): Promise<AxiosResponse<string>> =>
  client.post(`${MICRO_COURSE_PATH}`, microCourse, config);

const updateMicroCourse = (
  microCourseId: string,
  microCourse: FormData,
  config: AxiosRequestConfig
): Promise<AxiosResponse<void>> =>
  client.put(`${MICRO_COURSE_PATH}/${microCourseId}`, microCourse, config);

const uploadMicroCourseImageAsset = (
  data: UploadAssetModel,
  microCourseId: string
): Promise<AxiosResponse<void>> =>
  client.post(`${MICRO_COURSE_PATH}/${microCourseId}/image`, data);

const deleteMicroCourseImageAsset = (
  microCourseId: string,
  imagePublicId: string
): Promise<AxiosResponse<void>> =>
  client.delete(`${MICRO_COURSE_PATH}/${microCourseId}/image/${imagePublicId}`);

const getMicroCourse = (
  microCourseId: string
): Promise<AxiosResponse<ApiResponseMicroCourseWithResourcesVm>> =>
  client.get(`${MICRO_COURSE_PATH}/${microCourseId}`);

const deleteMicroCourse = (
  microCourseId: string
): Promise<AxiosResponse<void>> =>
  client.delete(`${MICRO_COURSE_PATH}/${microCourseId}`);

// MicroCourseResource related
const MICRO_COURSE_RESOURCE_PATH = `${API_V1}/admin/MicroCourseResource`;
const updateMicroCourseResourcesSortOrder = (
  microCourseId: string,
  microCourseResourcesSortOrderData: MicroCourseResourcesOrderModel
): Promise<AxiosResponse<ApiResponseMicroCourseResourcesModel>> =>
  client.put(
    `${MICRO_COURSE_RESOURCE_PATH}/${microCourseId}/updateMicroCourseSortOrder`,
    microCourseResourcesSortOrderData
  );

const uploadMicroCourseResourceImageAsset = (
  data: UploadAssetModel,
  microCourseResourceId: string
): Promise<AxiosResponse<void>> =>
  client.post(
    `${MICRO_COURSE_RESOURCE_PATH}/${microCourseResourceId}/imageAsset/`,
    data
  );

const uploadMicroCourseResourceSwedishVideoAsset = (
  data: UploadAssetModel,
  microCourseResourceId: string
): Promise<AxiosResponse<void>> =>
  client.post(
    `${MICRO_COURSE_RESOURCE_PATH}/${microCourseResourceId}/swedishVideoResource/`,
    data
  );

const uploadMicroCourseResourceEnglishVideoAsset = (
  data: UploadAssetModel,
  microCourseResourceId: string
): Promise<AxiosResponse<void>> =>
  client.post(
    `${MICRO_COURSE_RESOURCE_PATH}/${microCourseResourceId}/englishVideoResource/`,
    data
  );

const uploadMicroCourseResourceSwedishPdfAsset = (
  data: UploadAssetModel,
  microCourseResourceId: string
): Promise<AxiosResponse<void>> =>
  client.post(
    `${MICRO_COURSE_RESOURCE_PATH}/${microCourseResourceId}/swedishPdfResource/`,
    data
  );

const uploadMicroCourseResourceEnglishPdfAsset = (
  data: UploadAssetModel,
  microCourseResourceId: string
): Promise<AxiosResponse<void>> =>
  client.post(
    `${MICRO_COURSE_RESOURCE_PATH}/${microCourseResourceId}/englishPdfResource/`,
    data
  );

const getMicroCourseResource = (
  microCourseResourceId: string
): Promise<AxiosResponse<ApiResponseMicroCourseResourceModel>> =>
  client.get(`${MICRO_COURSE_RESOURCE_PATH}/${microCourseResourceId}`);

const createMicroCourseResource = (
  microCourseId: string,
  microCourseResource: FormData,
  config: AxiosRequestConfig
): Promise<AxiosResponse<string>> =>
  client.post(
    `${MICRO_COURSE_RESOURCE_PATH}/${microCourseId}`,
    microCourseResource,
    config
  );

const updateMicroCourseResource = (
  microCourseId: string,
  microCourseResource: FormData,
  config: AxiosRequestConfig
): Promise<AxiosResponse<string>> =>
  client.put(
    `${MICRO_COURSE_RESOURCE_PATH}/${microCourseId}`,
    microCourseResource,
    config
  );

const deleteMicroCourseResourceImageAsset = (
  microCourseResourceId: string,
  imagePublicId: string
): Promise<AxiosResponse<void>> =>
  client.delete(
    `${MICRO_COURSE_RESOURCE_PATH}/${microCourseResourceId}/image/${imagePublicId}`
  );

const deleteMicroCourseResourceSwedishVideoAsset = (
  microCourseResourceId: string,
  videoPublicId: string
): Promise<AxiosResponse<void>> =>
  client.delete(
    `${MICRO_COURSE_RESOURCE_PATH}/${microCourseResourceId}/swedishVideo/${videoPublicId}`
  );

const deleteMicroCourseResourceEnglishVideoAsset = (
  microCourseResourceId: string,
  videoPublicId: string
): Promise<AxiosResponse<void>> =>
  client.delete(
    `${MICRO_COURSE_RESOURCE_PATH}/${microCourseResourceId}/englishVideo/${videoPublicId}`
  );

const deleteMicroCourseResourceSwedishPdfAsset = (
  microCourseResourceId: string,
  pdfPublicId: string
): Promise<AxiosResponse<void>> =>
  client.delete(
    `${MICRO_COURSE_RESOURCE_PATH}/${microCourseResourceId}/swedishPdf/${pdfPublicId}`
  );

const deleteMicroCourseResourceEnglishPdfAsset = (
  microCourseResourceId: string,
  pdfPublicId: string
): Promise<AxiosResponse<void>> =>
  client.delete(
    `${MICRO_COURSE_RESOURCE_PATH}/${microCourseResourceId}/englishPdf/${pdfPublicId}`
  );

const deleteMicroCourseResource = (
  microCourseResourceId: string
): Promise<AxiosResponse<void>> =>
  client.delete(`${MICRO_COURSE_RESOURCE_PATH}/${microCourseResourceId}`);

// Automatic analysis
const COMPANY_AUTOMATIC_ANALYSIS_PATH = `${API_V1}/admin/companyAutomaticAnalysis`;

const getAnalysisForEmployeeWithClientRights = (
  companyId: string,
  cycleId: string,
  userId: string
): Promise<AxiosResponse<ApiResponseAutomaticAnalysisModel>> =>
  client.get(
    `${COMPANY_AUTOMATIC_ANALYSIS_PATH}/${companyId}/cycles/${cycleId}/params?userId=${userId}`
  );

export default {
  signIn,
  logout,
  resetPassword,
  getCompanies,
  createCompany,
  getCompany,
  updateCompany,
  deleteCompany,
  addCounselingEmailToCompany,
  getEmployees,
  inviteEmployees,
  deleteCompanyEmployee,
  getTitles,
  createTitle,
  getTitle,
  updateTitles,
  deleteTitles,
  getDepartments,
  createDepartment,
  getDepartment,
  updateDepartments,
  deleteDepartments,
  getCycles,
  getCycleResults,
  getCycleResultsData,
  getCycleQuestionData,
  getCycle,
  sendCycleEmail,
  createCycle,
  updateCycle,
  deleteCycle,
  getBosses,
  getBoss,
  createBoss,
  updateBosses,
  deleteBosses,
  getSurveys,
  createSurvey,
  getSurvey,
  updateSurvey,
  deleteSurvey,
  getModules,
  createModule,
  getModule,
  updateModule,
  deleteModule,
  deleteSurveyQuestion,
  addSurveyQuestion,
  getTerms,
  getTerm,
  createTerm,
  associateTermWithModules,
  dissociateTermWithModule,
  getResources,
  getResource,
  deleteResource,
  createResource,
  updateResource,
  deleteResourceAsset,
  deleteImageAsset,
  deleteEnglishResourceAsset,
  getModuleActivities,
  getModuleActivity,
  deleteModuleActivity,
  createModuleActivity,
  updateModuleActivity,
  uploadActivityImageAsset,
  uploadActivityImagePdfOrVideoAsset,
  uploadEnglishActivityImagePdfOrVideoAsset,
  deleteActivityResourceAsset,
  deleteEnglishActivityResourceAsset,
  deleteActivityImageAsset,
  deleteModuleAsset,
  refreshToken,
  uploadResourceImageAsset,
  uploadResourceImagePdfOrVideoAsset,
  uploadEnglishResourceImagePdfOrVideoAsset,
  getCounselingResources,
  getCounselingResource,
  deleteCounselingResource,
  createCounselingResource,
  updateCounselingResource,
  uploadCounselingResourceImageAsset,
  uploadCounselingResourceImagePdfOrVideoAsset,
  uploadEnglishCounselingResourceImagePdfOrVideoAsset,
  deleteCounselingResourceAsset,
  deleteEnglishCounselingResourceAsset,
  deleteCounselingResourceImageAsset,
  getTeachers,
  getTeacher,
  createTeacher,
  updateTeacher,
  deleteTeacher,
  uploadTeacherImageAsset,
  deleteTeacherImageAsset,
  AddClientRoleToUser,
  RemoveClientRoleToUser,
  addCounselingTextToCompany,
  uploadPdfCycleAsset,
  deleteCyclePdfAsset,
  getTermTeachersByTeacherId,
  getTeachersByTermId,
  createTermTeacher,
  deleteTermTeacher,
  getTermTeacherByTermId,
  getEmailReminderTime,
  createEmailReminderTime,
  editEmailReminderTime,
  createCompanyEmailBasicReminders,
  updateCompanyEmailBasicReminders,
  getCompanyEmailBasicReminders,
  createCompanyMotivationMail,
  updateCompanyMotivationMail,
  getCompanyMotivationEmailsByCompanyIdAndYear,
  getCycleTotalAverage,
  getMicroCourses,
  createMicroCourse,
  updateMicroCourse,
  uploadMicroCourseImageAsset,
  deleteMicroCourseImageAsset,
  getMicroCourse,
  updateMicroCourseResourcesSortOrder,
  uploadMicroCourseResourceImageAsset,
  uploadMicroCourseResourceSwedishVideoAsset,
  uploadMicroCourseResourceEnglishVideoAsset,
  uploadMicroCourseResourceSwedishPdfAsset,
  uploadMicroCourseResourceEnglishPdfAsset,
  getMicroCourseResource,
  createMicroCourseResource,
  updateMicroCourseResource,
  deleteMicroCourseResourceImageAsset,
  deleteMicroCourseResourceSwedishVideoAsset,
  deleteMicroCourseResourceEnglishVideoAsset,
  deleteMicroCourseResourceSwedishPdfAsset,
  deleteMicroCourseResourceEnglishPdfAsset,
  deleteMicroCourseResource,
  deleteMicroCourse,
  connectResourceToActivity,
  connectMicroCourseToActivity,
  updateClientDepartmentsFilteringRights,
  getEmployeesWithClientRights,
  getAnalysisForEmployeeWithClientRights,
};
