import { clearToken, getToken, setToken } from "../utils/sessionhandler";

const signIn = (store, values) => {
  setToken(values);

  store.setState({
    ...store.state,
    token: values.accessToken,
  });
};

const signOut = (store) => {
  clearToken();

  store.setState({
    ...store.state,
    token: null,
  });
};

const accessTokenExist = () => {
  const token = getToken();
  return token && token.accessToken;
};

export default {
  signIn,
  signOut,
  accessTokenExist,
};
