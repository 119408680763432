import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
  VFC,
} from "react";
import { useTranslation } from "react-i18next";
import "./Employees.css";
import {
  Button,
  CircularProgress,
  FormControl,
  Modal,
  Paper,
} from "@material-ui/core";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useParams } from "react-router";
import type { GridRowId } from "@mui/x-data-grid";
import api from "../../../utils/api/v1";
import Spacer from "../../shared/Spacer";
import Alert from "../../shared/Alert";
import type { EmployeeState } from "./Employees";
import type { ApiResponseCompanyDepartmentModel } from "../../../utils/api/apiInterfaces";

interface Props {
  open: boolean;
  onClose: (reloadEmployees: boolean) => Promise<void>;
  employeeDepartments?: Array<string>;
  employeeId: GridRowId;
  setEmployeeState: Dispatch<SetStateAction<EmployeeState>>;
  employeeState: EmployeeState;
}

export const EditEmployeeDepartmentRightsModal: VFC<Props> = ({
  open,
  onClose,
  employeeDepartments,
  employeeId,
  setEmployeeState,
  employeeState,
}) => {
  const { t } = useTranslation();
  const { companyId } = useParams();
  const [departments, setDepartments] =
    useState<null | Array<ApiResponseCompanyDepartmentModel>>(null);
  const [state, setState] = useState({
    error: false,
    loading: false,
  });

  const getCompanyDepartments = useCallback(async () => {
    setState((prevState) => ({ ...prevState, loading: true, error: false }));
    try {
      const { data: companyData } = await api.getCompany(companyId ?? "");
      if (companyData.hasDepartments) {
        const { data: departmentsData } = await api.getDepartments(
          companyId ?? ""
        );
        setDepartments(departmentsData);
      } else {
        setDepartments([]);
      }
      setState((prevState) => ({ ...prevState, loading: false, error: false }));
    } catch (err) {
      console.error(err);
      setState((prevState) => ({ ...prevState, loading: false, error: true }));
    }
  }, [companyId]);

  const handleClose = (reloadEmployees: boolean) =>
    onClose && onClose(reloadEmployees);

  const handleChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const updatedEmployees = employeeState.employees?.map((emp) => {
      if (emp.id === employeeId) {
        if (emp.allowedDepartmentsToFilter.includes(value)) {
          emp.allowedDepartmentsToFilter =
            emp.allowedDepartmentsToFilter.filter((v) => v !== value);
        } else {
          emp.allowedDepartmentsToFilter = [
            ...emp.allowedDepartmentsToFilter,
            value,
          ];
        }
      }
      return emp;
    });
    setEmployeeState({
      ...employeeState,
      employees: updatedEmployees,
      toggleClientLoading: false,
      error: false,
    });
  };

  const handleSave = async () => {
    setState({ ...state, loading: true, error: false });
    try {
      await api.updateClientDepartmentsFilteringRights(String(employeeId), {
        companyDepartmentIds: employeeDepartments ?? [],
      });
      await handleClose(true);
    } catch (err) {
      setState({ ...state, loading: true, error: true });
    }
  };

  useEffect(() => {
    (async () => {
      await getCompanyDepartments();
    })();
  }, [getCompanyDepartments]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="employee-modal-title"
      aria-describedby="employee-modal-description"
    >
      <Paper className="employee-modal-wrapper">
        <h2 id="employee-modal-title">
          {t("EditClientDepartmentRights.title")}
        </h2>
        {departments && departments.length > 0 ? (
          <FormControl className="employee-modal-form">
            {departments.map((d) => (
              <FormControlLabel
                key={d.id}
                control={
                  <Checkbox
                    value={d.id}
                    name={d.name}
                    checked={employeeDepartments?.includes(d.id)}
                    onChange={handleChange}
                    color="primary"
                    sx={{
                      color: "#068466 !important;",
                    }}
                  />
                }
                label={d.name}
              />
            ))}
          </FormControl>
        ) : (
          !state.loading && (
            <p>{t("EditClientDepartmentRights.noDepartments")}</p>
          )
        )}

        {state.loading && <CircularProgress style={{ marginTop: 20 }} />}
        {state.error && (
          <Alert style={{ marginTop: 20 }}>
            {t("EditClientDepartmentRights.failedToLoadDepartments")}
          </Alert>
        )}
        <div className="buttons">
          <Spacer />
          <Button
            disabled={state.loading || !departments}
            variant="outlined"
            className="cancel"
            onClick={() => handleClose(false)}
          >
            {t("InviteEmployeeModal.cancel")}
          </Button>
          <Button
            disabled={state.loading}
            color="primary"
            variant="contained"
            onClick={handleSave}
            data-testid="newEmployeeSaveButton"
          >
            {t("InviteEmployeeModal.save")}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};
