import type { FC } from "react";
import { View, Text } from "@react-pdf/renderer";
import type { ApiResponseQuestionResult } from "../../../../../../utils/api/apiInterfaces";
import { getLanguage } from "../../../../../../i18n";
import { QuestionBarChart } from "./QuestionsBarChart";
import { companyPercentagesResultStyle } from "./CompanyPercentagesResultStyle";
import { TextAndColorPercent } from "./TextAndColorPercent";

interface Props {
  questions: ApiResponseQuestionResult[];
}

export const CompanyPercentagesResult: FC<Props> = ({ questions }) => {
  const lng = getLanguage();

  return (
    <View style={companyPercentagesResultStyle.mainContainer}>
      {questions.map(
        ({
          nameSv,
          nameEn,
          amountLowScore,
          amountMediumScore,
          amountHighScore,
          questionId,
        }) => {
          const totalAmountOfAnswers =
            amountLowScore + amountMediumScore + amountHighScore;
          return (
            <View
              key={questionId}
              style={companyPercentagesResultStyle.barChartContainer}
            >
              <View style={companyPercentagesResultStyle.barChartText}>
                <Text style={companyPercentagesResultStyle.chartText}>
                  {lng === "sv" ? nameSv : nameEn}
                </Text>
              </View>

              <View style={companyPercentagesResultStyle.barChart}>
                <QuestionBarChart
                  amountLowScore={amountLowScore}
                  amountMediumScore={amountMediumScore}
                  amountHighScore={amountHighScore}
                  totalAmountOfAnswers={totalAmountOfAnswers}
                />
                <TextAndColorPercent
                  amountLowScore={amountLowScore}
                  amountMediumScore={amountMediumScore}
                  amountHighScore={amountHighScore}
                  totalAmountOfAnswers={totalAmountOfAnswers}
                />
              </View>
            </View>
          );
        }
      )}
    </View>
  );
};
