import { useState, useEffect, VFC } from "react";
import { useTranslation } from "react-i18next";
import "./Terms.css";
import { Typography, Button } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import Spacer from "../shared/Spacer";
import api from "../../utils/api/v1";
import Loader from "../shared/loader/Loader";
import SingleSelectionGrid from "../../UI-Components/SingleRowSelectionGrid/SingleRowSelectionGrid";
import type { ApiResponseTerm } from "../../utils/api/apiInterfaces";

interface State {
  loading: boolean;
  error: boolean;
}

const Terms: VFC = () => {
  const { t } = useTranslation();
  const [state, setState] = useState<State>({
    loading: true,
    error: false,
  });
  const [terms, setTerms] = useState<Array<ApiResponseTerm>>([]);

  const handleAddNewTerm = () => {
    setState({ ...state, error: false, loading: true });
    api
      .createTerm()
      .then(({ data }) => {
        setTerms([...data]);
        setState({ ...state, error: false, loading: false });
      })
      .catch((e) => {
        console.error(e);
        setState({ ...state, error: true, loading: false });
      });
  };

  const getTerms = () => {
    setState({ ...state, error: false, loading: true });
    api
      .getTerms()
      .then((res) => {
        setTerms(res.data);
        setState({ ...state, error: false, loading: false });
      })
      .catch((e) => {
        console.error(e);
        setState({ ...state, error: true, loading: false });
      });
  };

  const columns = [
    {
      field: "id",
      headerName: t("SingleRowSelectionGrid.termNumber"),
      width: window.innerWidth / 6,
    },
  ];

  useEffect(() => {
    getTerms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mainGui = () => {
    if (state.loading)
      return <Loader text={t("Terms.loading")} style={{ marginTop: 40 }} />;
    if (!state.loading) {
      return (
        <>
          <div className="section-toolbar">
            <Typography className="section-title" variant="h6">
              {t("Terms.terms")}
            </Typography>
            <Spacer />
            <Button
              startIcon={<Add />}
              color="primary"
              variant="contained"
              onClick={handleAddNewTerm}
            >
              {t("Terms.create")}
            </Button>
          </div>
          <div className="section-SingleSelectionGrid-layout">
            <SingleSelectionGrid
              columns={columns}
              rows={terms}
              pageSize={10}
              loading={state.loading}
              path="terms"
            />
          </div>
        </>
      );
    }

    return <Loader text={t("Terms.loading")} style={{ marginTop: 40 }} />;
  };

  return <div className="terms-wrapper">{mainGui()}</div>;
};

export default Terms;
