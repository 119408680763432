import { Page, Text, View, Image } from "@react-pdf/renderer";
import type { VFC } from "react";
import { useTranslation } from "react-i18next";
import type { ApiResponseAutomaticAnalysisModel } from "../../../../../utils/api/apiInterfaces";
import {
  getAmountOfTenResults,
  getPercentageResults,
} from "../UseAutomaticAnalysisCalculationHelper";
import { companyResultStyling } from "./CompanyResultStyling";
import { lowestQuestionIndex } from "../LowestQuestionIndex";
import { getLanguage } from "../../../../../i18n";
import {
  getCycleSeason,
  getCycleYear,
} from "../../../../../utils/helpers/dateConverters";

interface Props {
  automaticAnalysisResult: ApiResponseAutomaticAnalysisModel;
  cycleDate: string;
  totalAverage: number;
}

export const CompanyResult: VFC<Props> = ({
  automaticAnalysisResult,
  cycleDate,
  totalAverage,
}) => {
  const { t } = useTranslation();
  const { companyResult } = automaticAnalysisResult;
  const season = getCycleSeason(cycleDate);
  const companyPercentageResults = getPercentageResults(companyResult);
  const companyOutOfTenResults = getAmountOfTenResults(companyResult);

  const lowestStressQuestions = lowestQuestionIndex(
    companyResult.automaticAnalyzeQuestions.stressQuestions,
    getLanguage(),
    3
  );

  const lowestDevelopmentQuestion = lowestQuestionIndex(
    companyResult.automaticAnalyzeQuestions.developmentQuestions,
    getLanguage(),
    1
  );

  return (
    <Page size="A4" style={companyResultStyling.page} object-fit="fill">
      <View style={companyResultStyling.companyResultParentDiv}>
        <Text style={companyResultStyling.companyResultHeadlineText}>
          {t("CompanyResult.analysis")} {t(`CompanyResult.${season}`)}{" "}
          {getCycleYear(cycleDate)}
        </Text>

        <View style={companyResultStyling.companyResultHorizontalLine} />
      </View>

      <View style={companyResultStyling.companyResultTextParentDiv}>
        <View style={companyResultStyling.companyResultTextColumn}>
          <Text style={companyResultStyling.companyResultParagraphs}>
            {t("CompanyResult.totalAverageP1")}{" "}
            {automaticAnalysisResult.companyResult.totalAverage}.{" "}
            {t("CompanyResult.totalAverageP2")} {totalAverage}.
          </Text>
          <Text style={companyResultStyling.companyResultParagraphs}>
            {t("CompanyResult.developmentAverageP1")}{" "}
            {automaticAnalysisResult.companyResult.developmentAverage}.{" "}
            {companyPercentageResults.developmentPercentages.high === 0
              ? `${t(`CompanyResult.developmentAverageP2v2`)}`
              : `${companyPercentageResults.developmentPercentages.high} ${t(
                  `CompanyResult.developmentAverageP2`
                )}`}
            {t("CompanyResult.developmentAverageP2")}
            {companyOutOfTenResults.developmentAmount.medium +
              companyOutOfTenResults.developmentAmount.low}{" "}
            {t("CompanyResult.developmentAverageP3")} &quot;
            {lowestDevelopmentQuestion[0]}&quot;.
          </Text>
          <Text style={companyResultStyling.companyResultParagraphs}>
            {t("CompanyResult.physicalAverageP1")}{" "}
            {automaticAnalysisResult.companyResult.physicalAverage}.{" "}
            {companyPercentageResults.physicalPercentages.high === 0
              ? `${t(`CompanyResult.physicalAverageP2v2`)}`
              : `${companyPercentageResults.physicalPercentages.high} ${t(
                  `CompanyResult.physicalAverageP2`
                )}`}
            {companyPercentageResults.physicalPercentages.low === 0
              ? `${t(`CompanyResult.physicalAverageP3v2`)}`
              : `${companyPercentageResults.physicalPercentages.low} ${t(
                  `CompanyResult.physicalAverageP3`
                )}`}
          </Text>
        </View>

        <View style={companyResultStyling.companyResultTextColumn}>
          <Text style={companyResultStyling.companyResultParagraphs}>
            {t("CompanyResult.stressAverageP1")}
            {automaticAnalysisResult.companyResult.stressAverage}.{" "}
            {companyPercentageResults.stressPercentages.medium +
              companyPercentageResults.stressPercentages.low ===
            0
              ? `${t(`CompanyResult.stressAverageP2v2`)}`
              : `${
                  companyPercentageResults.stressPercentages.medium +
                  companyPercentageResults.stressPercentages.low
                }
                ${t(`CompanyResult.stressAverageP2`)}`}{" "}
            {t("CompanyResult.questionsStandingOut")}
            <View style={companyResultStyling.companyResultList}>
              {lowestStressQuestions.map((lsq, i) =>
                i === 0 ? (
                  <Text key="1">&quot;{lsq.slice(0, -1)}&quot;, </Text>
                ) : i === 1 ? (
                  <Text key="2">
                    &quot;{lsq.slice(0, -1)}&quot; {t("CompanyResult.and")}
                  </Text>
                ) : (
                  <Text key="3">&quot;{lsq}&quot;.</Text>
                )
              )}
            </View>
          </Text>
        </View>
      </View>

      <View style={companyResultStyling.companyResultCircleContainer}>
        <View style={companyResultStyling.companyResultCircleDiv}>
          <Text style={companyResultStyling.companyResultCircleHeadline}>
            {companyOutOfTenResults.developmentAmount.high}{" "}
            {t("CompanyResult.ofTen")}
          </Text>
          <Text style={companyResultStyling.companyResultCircleText}>
            {t("CompanyResult.companyResultCircleTextP1")}
          </Text>
          <Text style={companyResultStyling.companyResultCircleText}>
            {t("CompanyResult.companyResultCircleTextP2")}
          </Text>
        </View>
      </View>

      <View style={companyResultStyling.companyResultImageContainer}>
        <Image
          style={companyResultStyling.companyResultImage}
          src="https://www.groovypost.com/wp-content/uploads/2018/02/happy-man-laptop-feature.jpg"
        />
        <Text style={companyResultStyling.companyResultImageText}>
          {t("CompanyResult.companyResultImageText")}
        </Text>
      </View>
    </Page>
  );
};
