import type React from "react";
import type { ChangeEvent, VFC } from "react";
import { useTranslation } from "react-i18next";
import "./Filter.css";

interface Props {
  title: string;
  inputName: string;
  options: Array<string>;
  values: Array<string>;
  selection: string;
  selectionHandler: (
    e: React.FocusEvent<HTMLSelectElement> | ChangeEvent<HTMLSelectElement>
  ) => void;
}

const Filter: VFC<Props> = ({
  title,
  inputName,
  options,
  values,
  selection,
  selectionHandler,
}) => {
  const { t } = useTranslation();

  return (
    <div className="filtering-container">
      <p>{title}:</p>
      <select
        className="filter-select"
        value={selection}
        name={inputName}
        onChange={selectionHandler}
        onBlur={selectionHandler}
      >
        <option className="filter-select-option" value="All">
          {t("CycleResults.filter.all")}
        </option>
        {options.map((opt, index) => (
          <option
            key={opt}
            className="filter-select-option"
            value={values[index]}
          >
            {opt}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Filter;
