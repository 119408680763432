import { StyleSheet } from "@react-pdf/renderer";

export const questionAverageResultStyling = StyleSheet.create({
  page: {
    backgroundColor: "white",
    width: "100%",
    height: "100vh",
    orientation: "portrait",
    backgroundSize: "cover",
    position: "relative",
  },
  questionAverageMainContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "40px",
    paddingRight: "40px",
  },
  questionAverageHorizontalLine: {
    textAlign: "center",
    marginTop: "35px",
    width: "500px",
    borderBottom: "3px",
    borderBottomColor: "#068466",
    borderBottomStyle: "solid",
    borderBottomWidth: "3px",
  },
  questionAverageStatistics: {
    fontSize: "13px",
    fontFamily: "Times-Roman",
    letterSpacing: "1px",
    marginTop: "20px",
    marginBottom: "20px",
  },
  heading: {
    fontSize: "14px",
    fontFamily: "Helvetica",
    letterSpacing: "1px",
    marginBottom: "5px",
  },
  aboutText: {
    fontSize: "12px",
    fontFamily: "Times-Roman",
  },
  parentContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    marginTop: "35px",
  },
  questionContainer: {
    width: "400px",
    marginRight: "20px",
  },
  indexContainer: {
    width: "70px",
    justifyContent: "center",
  },
  mappedList: {
    height: "25px",
    margin: "0.5",
  },
  mappedText: {
    fontSize: "10px",
  },
  indexText: {
    fontFamily: "Helvetica-Bold",
    fontSize: "9px",
    textAlign: "center",
    color: "#2B2B2B",
    marginTop: "6px",
  },
  indexHeader: {
    position: "relative",
    left: "433",
    top: "20",
  },
  indexHeaderText: {
    fontSize: "8px",
    fontFamily: "Helvetica-Bold",
  },
});
