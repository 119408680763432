import React, { useCallback, useEffect, useState, Fragment } from "react";
import "./QuestionsInformationChart.css";
import { useTranslation } from "react-i18next";
import { getLanguage } from "../../../../i18n";
import QuestionDistributionBar from "./QuestionDistributionBar";

/* 
  To protect the anynomity of the users, we have setup this rule for when response data is displayed:
  If a column has less than 5 answers we don't display for that column. 
  If only one column exist with less than 5 answers and that column has 1 answer only
  and there are less than 3 columns in  total, no data is displayed in any of those columns.
*/
const dataMeetsAnonymityRequirements = (columnData, entireRowData) => {
  if (
    columnData.countOfQuestionAnswers < 5 ||
    (entireRowData.questionDataForDisplay.filter(
      (x) => x.countOfQuestionAnswers === 1
    ).length === 1 &&
      entireRowData.questionDataForDisplay.filter(
        (x) => x.countOfQuestionAnswers < 5
      ).length === 1 &&
      entireRowData.questionDataForDisplay.length < 3)
  ) {
    return false;
  }
  return true;
};

const QuestionsInformationChart = ({
  currentCategory,
  currentData,
  questionsData,
}) => {
  const { t } = useTranslation();
  const [filteredData, setFilteredData] = useState([]);
  const [uniqueData, setUniqueData] = useState([]);

  const filterByCategory = (data) => {
    if (currentCategory === "all") return data;
    if (currentCategory === "stress" && data.isStress) return data;
    if (currentCategory === "physical" && data.isPhysical) return data;
    if (currentCategory === "development" && data.isDevelopment) return data;
    if (
      currentCategory === "stressPhysical" &&
      (data.isStress || data.isPhysical)
    )
      return data;
    if (
      currentCategory === "stressDevelopment" &&
      (data.isStress || data.isDevelopment)
    )
      return data;
    if (
      currentCategory === "physicalDevelopment" &&
      (data.isPhysical || data.isDevelopment)
    )
      return data;
    return null;
  };

  const filterByCurrentData = (data) => {
    if (currentData === "averageDepartmentResult")
      return {
        id: data.id,
        generalQuestionAverage: data.questionAverage,
        questionTextSv: data.questionTitleSv,
        questionTextEn: data.questionTitleEn,
        questionDataForDisplay: data.averageDepartmentResult,
        isPositive: data.isPositive,
        numberOfHighAnswers: data.numberOfHighAnswers,
        numberOfMediumAnswers: data.numberOfMediumAnswers,
        numberOfLowAnswers: data.numberOfLowAnswers,
        totalAnswers: data.totalAnswers,
      };
    if (currentData === "averageTitleResult")
      return {
        id: data.id,
        generalQuestionAverage: data.questionAverage,
        questionTextSv: data.questionTitleSv,
        questionTextEn: data.questionTitleEn,
        questionDataForDisplay: data.averageTitleResult,
        isPositive: data.isPositive,
        numberOfHighAnswers: data.numberOfHighAnswers,
        numberOfMediumAnswers: data.numberOfMediumAnswers,
        numberOfLowAnswers: data.numberOfLowAnswers,
        totalAnswers: data.totalAnswers,
      };
    if (currentData === "averageBossResult")
      return {
        id: data.id,
        generalQuestionAverage: data.questionAverage,
        questionTextSv: data.questionTitleSv,
        questionTextEn: data.questionTitleEn,
        questionDataForDisplay: data.averageBossResult,
        isPositive: data.isPositive,
        numberOfHighAnswers: data.numberOfHighAnswers,
        numberOfMediumAnswers: data.numberOfMediumAnswers,
        numberOfLowAnswers: data.numberOfLowAnswers,
        totalAnswers: data.totalAnswers,
      };
    if (currentData === "averageGenderResult")
      return {
        id: data.id,
        generalQuestionAverage: data.questionAverage,
        questionTextSv: data.questionTitleSv,
        questionTextEn: data.questionTitleEn,
        questionDataForDisplay: data.averageGenderResult,
        isPositive: data.isPositive,
        numberOfHighAnswers: data.numberOfHighAnswers,
        numberOfMediumAnswers: data.numberOfMediumAnswers,
        numberOfLowAnswers: data.numberOfLowAnswers,
        totalAnswers: data.totalAnswers,
      };
    if (currentData === "averageAgeSpanResult")
      return {
        id: data.id,
        generalQuestionAverage: data.questionAverage,
        questionTextSv: data.questionTitleSv,
        questionTextEn: data.questionTitleEn,
        questionDataForDisplay: data.averageAgeSpanResult,
        isPositive: data.isPositive,
        numberOfHighAnswers: data.numberOfHighAnswers,
        numberOfMediumAnswers: data.numberOfMediumAnswers,
        numberOfLowAnswers: data.numberOfLowAnswers,
        totalAnswers: data.totalAnswers,
      };
    return null;
  };

  const getUniqueData = (data) => data.questionDataForDisplay;

  const getDataAsStringsArray = (data) => {
    if (currentData === "averageDepartmentResult") return data.departmentName;
    if (currentData === "averageTitleResult") return data.titleName;
    if (currentData === "averageBossResult") return data.bossName;
    if (currentData === "averageGenderResult") return data.genderName;
    if (currentData === "averageAgeSpanResult") return data.ageName;
    return null;
  };

  const filterUniqueValues = (value, index, self) =>
    self.indexOf(value) === index;

  const filterData = useCallback(() => {
    const filteredQuestionsData = (
      questionsData.companyCycleQuestionResults || []
    )
      .filter(filterByCategory)
      .map(filterByCurrentData);

    const uniqueValues = [...filteredQuestionsData]
      .map(getUniqueData)
      .flat()
      .map(getDataAsStringsArray)
      .filter(filterUniqueValues);

    if (
      currentData === "averageGenderResult" ||
      currentData === "averageAgeSpanResult"
    ) {
      const modifiedUniqueValues = uniqueValues.map((value) =>
        t(`CycleResults.questions.data.${value}`)
      );

      setUniqueData(modifiedUniqueValues);
      setFilteredData(filteredQuestionsData);
      return;
    }

    setFilteredData(filteredQuestionsData);
    setUniqueData(uniqueValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCategory, currentData, questionsData]);

  useEffect(() => {
    filterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCategory, currentData, questionsData]);

  const getColor = (averageScore, isPositive) => {
    const score = isPositive ? averageScore : (10 - averageScore).toFixed(1);

    if (score <= 4) return "rgb(238, 115, 98)";
    if (score > 4 && score < 7) return "#FDCF5B";
    return "rgb(6, 132, 102)";
  };

  const getCurrentLanguage = () =>
    getLanguage().charAt(0).toUpperCase() + getLanguage().slice(1);

  const getCurrentName = () => {
    if (currentData === "averageDepartmentResult") return "departmentName";
    if (currentData === "averageTitleResult") return "titleName";
    if (currentData === "averageBossResult") return "bossName";
    if (currentData === "averageGenderResult") return "genderName";
    if (currentData === "averageAgeSpanResult") return "ageName";
    return "departmentName";
  };

  const getCurrentAverage = () => {
    if (currentData === "averageDepartmentResult") return "departmentAverage";
    if (currentData === "averageTitleResult") return "titleAverage";
    if (currentData === "averageBossResult") return "bossAverage";
    if (currentData === "averageGenderResult") return "genderAverage";
    if (currentData === "averageAgeSpanResult") return "ageAverage";
    return "departmentAverage";
  };

  return (
    <div className="question-information-chart-wrapper">
      {filteredData.length === 0 ? (
        <div className="no-data-display-container">
          <h2>{t("CycleResults.questions.noQuestionData")}</h2>
        </div>
      ) : (
        <>
          <div className="question-chart-header">
            <h4>{t("CycleResults.questions.questionAverage")}</h4>
          </div>
          <table>
            <tbody>
              <tr>
                <th />
                <th className="table-head">
                  {t("CycleResults.questions.average")}
                </th>
                {uniqueData.sort().map((data) => (
                  <th className="table-head" key={data}>
                    {data}
                  </th>
                ))}
              </tr>
              {filteredData.map((fdata) => (
                <Fragment key={fdata.id}>
                  <tr className="page-break">
                    <td className="table-question-data">
                      {fdata[`questionText${getCurrentLanguage()}`]}
                    </td>
                    <td
                      className="table-data"
                      style={{
                        backgroundColor: `${getColor(
                          fdata.generalQuestionAverage,
                          fdata.isPositive
                        )}`,
                      }}
                    >
                      {fdata.isPositive
                        ? fdata.generalQuestionAverage
                        : (10 - fdata.generalQuestionAverage).toFixed(1)}
                    </td>
                    {fdata.questionDataForDisplay
                      .sort((a, b) => {
                        const firstCompareName = a[getCurrentName()];
                        const secondCompareName = b[getCurrentName()];
                        if (firstCompareName < secondCompareName) return -1;
                        if (firstCompareName > secondCompareName) return 1;
                        return 0;
                      })
                      .map((sortedQuestion) => {
                        if (
                          dataMeetsAnonymityRequirements(sortedQuestion, fdata)
                        ) {
                          return (
                            <td
                              key={sortedQuestion[getCurrentName()]}
                              className="table-data"
                              style={{
                                backgroundColor: `${getColor(
                                  sortedQuestion[getCurrentAverage()],
                                  fdata.isPositive
                                )}`,
                              }}
                            >
                              {fdata.isPositive
                                ? sortedQuestion[getCurrentAverage()]
                                : (
                                    10 - sortedQuestion[getCurrentAverage()]
                                  ).toFixed(1)}
                            </td>
                          );
                        }
                        return (
                          <td
                            key={sortedQuestion[getCurrentName()]}
                            className="table-data"
                            style={{
                              backgroundColor: "rgb(197 197 197)",
                            }}
                          >
                            {t("CycleResults.questions.fewAnswers")}
                          </td>
                        );
                      })}
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </table>
        </>
      )}
      {filteredData.length === 0 ? (
        ""
      ) : (
        <div className="question-data-wrapper">
          <div className="question-chart-header question-chart-bar-header">
            <h4>{t("CycleResults.questions.questionAnswersDistribution")}</h4>
            <div className="question-statistics-info-wrapper">
              <span className="circle-dot-legend">
                <div className="red-dot" />
                {t("CycleResults.filteredStatisticsChart.circleInfo.red")}
              </span>
              <span>
                <div className="yellow-dot" />
                {t("CycleResults.filteredStatisticsChart.circleInfo.yellow")}
              </span>
              <span>
                <div className="green-dot" />
                {t("CycleResults.filteredStatisticsChart.circleInfo.green")}
              </span>
            </div>
          </div>
          {filteredData.map((fData) => (
            <QuestionDistributionBar key={fData.id} questionData={fData} />
          ))}
        </div>
      )}
    </div>
  );
};

export default QuestionsInformationChart;
