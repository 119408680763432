import type { VFC } from "react";
import { useTranslation } from "react-i18next";
import { Text, View } from "@react-pdf/renderer";
import { AutomaticAnalysisBarChart } from "../AutomaticAnalysisBarChart";
import type { ApiResponseAutomaticAnalysisResult } from "../../../../../utils/api/apiInterfaces";
import { automaticAnalysisNarrowPageStyle } from "../styles/AutomaticAnalysisNarrowPageStyle";
import { lowestCategoryName } from "../LowestCategoryName";
import { getPercentageResults } from "../UseAutomaticAnalysisCalculationHelper";
import { lowestQuestionIndex } from "../LowestQuestionIndex";
import { getLanguage } from "../../../../../i18n";

interface Props {
  ageResult: ApiResponseAutomaticAnalysisResult;
  allAgeResults: ApiResponseAutomaticAnalysisResult[];
}

enum Age {
  twenties = "20",
  forties = "40",
}

export const AgeResult: VFC<Props> = ({ ageResult, allAgeResults }) => {
  const { t } = useTranslation();
  const lowestCategoryNames = lowestCategoryName(
    ageResult.physicalAverage,
    ageResult.stressAverage,
    ageResult.developmentAverage
  );
  const agePercantageResults = getPercentageResults(ageResult);
  const filterAgeResultsByAgeGroups = allAgeResults.filter(
    (ageGroup) =>
      ageGroup.nameEn === Age.forties || ageGroup.nameEn === Age.twenties
  );

  const sortPhysicalAverageByAgeGroup = filterAgeResultsByAgeGroups.sort(
    (a, b) => a.physicalAverage - b.physicalAverage
  );

  const lowestPhysicalQuestion = lowestQuestionIndex(
    ageResult.automaticAnalyzeQuestions.physicalQuestions,
    getLanguage(),
    1
  );

  return (
    <View style={automaticAnalysisNarrowPageStyle.mainSection} wrap={false}>
      <View style={automaticAnalysisNarrowPageStyle.sectionDescription}>
        <Text style={automaticAnalysisNarrowPageStyle.heading}>
          {t(`AgeResult.${ageResult.nameEn}`)}
        </Text>
        <View style={automaticAnalysisNarrowPageStyle.text}>
          <Text>
            {t("AgeResult.wellBeingIndex")} {ageResult.totalAverage}.{" "}
            {agePercantageResults.developmentPercentages.high === 0
              ? `${t(`AgeResult.developmentAveragev2`)}`
              : `${agePercantageResults.developmentPercentages.high} ${t(
                  `AgeResult.developmentAverage`
                )}`}{" "}
            {t("AgeResult.biggestChallenge")}{" "}
            {lowestCategoryNames.map((lcn, i, { length }) => {
              if (length - 1 === i) {
                return <span key={lcn}>{t(`CompanyStatistics.${lcn}`)}.</span>;
              }
              return (
                <span key={lcn}>
                  {t(`CompanyStatistics.${lcn}`)}
                  {t(`CompanyStatistics.statisticsP3`)}
                </span>
              );
            })}
            {sortPhysicalAverageByAgeGroup.length === 2 &&
              sortPhysicalAverageByAgeGroup[0]?.nameEn === ageResult.nameEn && (
                <Text style={automaticAnalysisNarrowPageStyle.text}>
                  {t("AgeResult.differenceFromOtherAges")} &quot;
                  {lowestPhysicalQuestion[0]}&quot;
                </Text>
              )}
          </Text>
        </View>
      </View>
      <View style={automaticAnalysisNarrowPageStyle.barChartContainer}>
        <View style={automaticAnalysisNarrowPageStyle.barChartText}>
          <Text style={automaticAnalysisNarrowPageStyle.chartText}>
            {t("CompanyStatistics.wellBeingDevelopmentDistribution")}
          </Text>
          <Text style={automaticAnalysisNarrowPageStyle.chartText}>
            {t("CompanyStatistics.wellBeingPhysicalDistribution")}
          </Text>
          <Text style={automaticAnalysisNarrowPageStyle.chartText}>
            {t("CompanyStatistics.wellBeingStressDistribution")}
          </Text>
        </View>
        <View style={automaticAnalysisNarrowPageStyle.barChart}>
          <AutomaticAnalysisBarChart
            developmentAverage={ageResult.developmentAverage}
            stressAverage={ageResult.stressAverage}
            physicalAverage={ageResult.physicalAverage}
          />
        </View>
      </View>
    </View>
  );
};
