import React from "react";
import "./App.css";
import { StylesProvider, ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import AppRoutes from "./components/routes/AppRoutes";

const App = () => (
  <ThemeProvider theme={theme}>
    <StylesProvider injectFirst>
      <AppRoutes />
    </StylesProvider>
  </ThemeProvider>
);

export default App;
