import { Text, View } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import type { VFC } from "react";
import { AutomaticAnalysisBarChart } from "../AutomaticAnalysisBarChart";
import type { ApiResponseAutomaticAnalysisResult } from "../../../../../utils/api/apiInterfaces";
import { automaticAnalysisPageStyle } from "../styles/AutomaticAnalysisPageStyle";
import {
  getAmountOfTenResults,
  getPercentageResults,
} from "../UseAutomaticAnalysisCalculationHelper";
import { getLanguage } from "../../../../../i18n";
import { lowestQuestionIndex } from "../LowestQuestionIndex";

interface Props {
  departmentResult: ApiResponseAutomaticAnalysisResult;
}

export const DepartmentResult: VFC<Props> = ({ departmentResult }) => {
  const { t } = useTranslation();
  const currentLanguage = getLanguage();
  const companyOutOfTenResults = getAmountOfTenResults(departmentResult);
  const lowestDevelopmentQuestion = lowestQuestionIndex(
    departmentResult.automaticAnalyzeQuestions.developmentQuestions,
    getLanguage(),
    1
  );
  const lowestPhysicalQuestions = lowestQuestionIndex(
    departmentResult.automaticAnalyzeQuestions.physicalQuestions,
    getLanguage(),
    3
  );
  const lowestStressQuestions = lowestQuestionIndex(
    departmentResult.automaticAnalyzeQuestions.stressQuestions,
    getLanguage(),
    2
  );
  const departmentPercantageResults = getPercentageResults(departmentResult);

  return (
    <View style={automaticAnalysisPageStyle.mainContainer}>
      <View style={automaticAnalysisPageStyle.textParentContainer}>
        <Text style={automaticAnalysisPageStyle.heading}>
          {currentLanguage === "sv"
            ? departmentResult.nameSv
            : departmentResult.nameEn}
        </Text>
        <Text style={automaticAnalysisPageStyle.resultText}>
          {t("DepartmentResult.averageResult")} {departmentResult.totalAverage}.
        </Text>
        <Text style={automaticAnalysisPageStyle.resultText}>
          {departmentPercantageResults.developmentPercentages.high === 0
            ? `${t(`DepartmentResult.developmentAverageP1v2`)}`
            : `${departmentPercantageResults.developmentPercentages.high} ${t(
                `DepartmentResult.developmentAverageP1`
              )}`}{" "}
          {t("DepartmentResult.developmentAverageP2")}
          &quot;{lowestDevelopmentQuestion[0]}&quot;.
        </Text>

        <Text style={automaticAnalysisPageStyle.resultText}>
          {departmentPercantageResults.physicalPercentages.high === 0
            ? `${t(`DepartmentResult.physicalAverageP1v2`)}`
            : `${departmentPercantageResults.physicalPercentages.high} ${t(
                `DepartmentResult.physicalAverageP1`
              )}`}{" "}
          {companyOutOfTenResults.physicalAmount.high}
          {t("DepartmentResult.physicalAverageP2")}
          {t("DepartmentResult.physicalAverageP3")}{" "}
          {lowestPhysicalQuestions.map((lpq, i) =>
            i === 0 ? (
              <Text key="1">&quot;{lpq.slice(0, -1)}&quot;, </Text>
            ) : i === 1 ? (
              <Text key="2">
                &quot;{lpq.slice(0, -1)}&quot; {t("CompanyResult.and")}
              </Text>
            ) : (
              <Text key="3">&quot;{lpq}&quot;.</Text>
            )
          )}
        </Text>

        <Text style={automaticAnalysisPageStyle.resultText}>
          {departmentPercantageResults.stressPercentages.medium +
            departmentPercantageResults.stressPercentages.low ===
          0
            ? `${t(`DepartmentResult.stressAverageP1v2`)}`
            : `${
                departmentPercantageResults.stressPercentages.medium +
                departmentPercantageResults.stressPercentages.low
              } ${t(`DepartmentResult.stressAverageP1`)}`}{" "}
          {lowestStressQuestions.map((lsq, i) =>
            i === 0 ? (
              <Text key="1">
                &quot;{lsq.slice(0, -1)}&quot; {t("CompanyResult.and")}
              </Text>
            ) : (
              <Text key="2">&quot;{lsq}&quot;.</Text>
            )
          )}
          {departmentResult.stressResults.low > 0 && (
            <Text style={automaticAnalysisPageStyle.resultText}>
              {t("DepartmentResult.highStressP1")}{" "}
              {departmentResult.stressResults.low}{" "}
              {t("DepartmentResult.highStressP2")}
            </Text>
          )}
        </Text>
      </View>
      <View style={automaticAnalysisPageStyle.barChartContainer}>
        <View style={automaticAnalysisPageStyle.barchartTextContainer}>
          <Text style={automaticAnalysisPageStyle.barchartText}>
            {t("CompanyStatistics.wellBeingDevelopmentDistribution")}
          </Text>
          <Text style={automaticAnalysisPageStyle.barchartText}>
            {t("CompanyStatistics.wellBeingPhysicalDistribution")}
          </Text>
          <Text style={automaticAnalysisPageStyle.barchartText}>
            {t("CompanyStatistics.wellBeingStressDistribution")}
          </Text>
        </View>
        <View style={automaticAnalysisPageStyle.barchartChildDiv}>
          <AutomaticAnalysisBarChart
            developmentAverage={departmentResult.developmentAverage}
            stressAverage={departmentResult.stressAverage}
            physicalAverage={departmentResult.physicalAverage}
          />
        </View>
      </View>
    </View>
  );
};
