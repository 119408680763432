import { useState, useEffect, VFC } from "react";
import "./Teachers.css";
import { useTranslation } from "react-i18next";
import { Typography, Button } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { getLanguage } from "../../i18n";
import Spacer from "../shared/Spacer";
import api from "../../utils/api/v1";
import Loader from "../shared/loader/Loader";
import SingleSelectionGrid from "../../UI-Components/SingleRowSelectionGrid/SingleRowSelectionGrid";
import type { ApiResponseTeacher } from "../../utils/api/apiInterfaces";

interface State {
  loading: boolean;
  error: boolean;
}

const Teachers: VFC = () => {
  const { t } = useTranslation();
  const [state, setState] = useState<State>({ loading: true, error: false });
  const [teachers, setTeachers] = useState<Array<ApiResponseTeacher>>([]);

  const getTeachers = () => {
    setState({ ...state, loading: true, error: false });
    api
      .getTeachers()
      .then(({ data }) => {
        setTeachers(data);
        setState({ ...state, loading: false });
      })
      .catch((error) => {
        console.error(error);
        setState({ ...state, loading: false, error: true });
      });
  };

  useEffect(() => {
    getTeachers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    { field: "id", hide: true },
    /* TODO: implement avatars
      {
        field: "imageUrl",
        headerName: " ",
        width: window.innerWidth / 15,
      }, 
    */
    {
      field: "name",
      headerName: t("Teachers.columns.name"),
      width: window.innerWidth / 6,
    },
    {
      field: getLanguage() === "sv" ? "titleSv" : "titleEn",
      headerName: t("Teachers.columns.title"),
      width: window.innerWidth / 6,
    },
    {
      field: getLanguage() === "sv" ? "descriptionSv" : "descriptionEn",
      headerName: t("Teachers.columns.description"),
      width: window.innerWidth / 2,
    },
  ];

  if (state.loading) {
    return <Loader text={t("Teachers.loading")} style={{ marginTop: 40 }} />;
  }

  return (
    <div className="teachers-wrapper">
      <div className="section-toolbar">
        <Typography className="section-title" variant="h6">
          {t("Teachers.title")}
        </Typography>
        <Spacer />
        <Link to="/teachers/teacher/new" className="resources-link-style">
          <Button startIcon={<Add />} color="primary" variant="contained">
            {t("Teachers.create")}
          </Button>
        </Link>
      </div>
      <div className="table-wrapper">
        <SingleSelectionGrid
          columns={columns}
          rows={teachers}
          pageSize={10}
          loading={state.loading}
          path="teachers"
        />
      </div>
    </div>
  );
};

export default Teachers;
