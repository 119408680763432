import { StyleSheet } from "@react-pdf/renderer";

export const companyPercentagesResultStyle = StyleSheet.create({
  mainContainer: {
    width: "100%",
    height: "680px",
    display: "flex",
    flexDirection: "column",
  },
  barChartContainer: {
    display: "flex",
    flexDirection: "row",
    width: "500px",
    height: "30px",
  },
  barChartText: {
    width: "50%",
  },
  barChart: {
    width: "50%",
    height: "30px",
    display: "flex",
    flexDirection: "column",
  },
  chartText: {
    fontSize: "9px",
    paddingRight: "8px",
  },
  percentageContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "230px",
    position: "relative",
    top: -14,
  },
  percentageNumber: {
    fontSize: "6px",
    padding: "0 5px",
  },
  lowCircle: {
    backgroundColor: "#EE7362",
    width: "6px",
    height: "6px",
    borderRadius: "50%",
  },
  mediumCircle: {
    backgroundColor: "#FDCF5B",
    width: "6px",
    height: "6px",
    borderRadius: "50%",
  },
  highCircle: {
    backgroundColor: "#068466",
    width: "6px",
    height: "6px",
    borderRadius: "50%",
  },
});
