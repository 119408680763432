import { StyleSheet } from "@react-pdf/renderer";

export const automaticAnalysisPageStyle = StyleSheet.create({
  page: {
    flexDirection: "column",
  },
  section: {
    marginTop: 30,
    marginRight: 30,
    marginBottom: 0,
    marginLeft: 30,
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 0,
    paddingLeft: 10,
  },
  mainContainer: {
    height: "650px",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    marginBottom: "12px",
  },
  horizontalLine: {
    textAlign: "center",
    width: "500px",
    borderBottom: "3px",
    borderBottomColor: "#068466",
    borderBottomStyle: "solid",
    bordborderBottomWidth: "3px",
  },
  textParentContainer: {
    position: "absolute",
  },
  statistic: {
    fontSize: "13px",
    fontFamily: "Times-Roman",
    letterSpacing: "1px",
    marginTop: "10px",
    marginBottom: "10px",
  },
  positionHeadline: {
    marginBottom: "10px",
    fontFamily: "Helvetica",
    fontSize: "13px",
  },
  heading: {
    marginTop: "4px",
    fontFamily: "Helvetica",
    fontSize: "12px",
    letterSpacing: "2px",
    textTransform: "uppercase",
  },
  resultText: {
    fontSize: "12px",
    fontFamily: "Times-Roman",
    marginBottom: "10px",
    marginTop: "10px",
    lineHeight: "1.2px",
  },
  textChart: {
    fontSize: "8px",
    color: "white",
    textAlign: "right",
    marginRight: "5px",
  },
  barChartContainer: {
    position: "absolute",
    flexDirection: "row",
    marginTop: "270px",
    height: "80px",
  },
  barchartTextContainer: {
    position: "relative",
    width: "20%",
  },
  barchartText: {
    fontSize: "8px",
    textAlign: "right",
    marginBottom: "12px",
    paddingRight: "5px",
  },
  barchartChildDiv: {
    width: "80%",
  },
});
