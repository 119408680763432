import React, { useState, useEffect } from "react";
import axios from "axios";
import ErrorModal from "../../components/errorHandling/ErrorModal";

const errorHandler = (WrappedComponent) => (props) => {
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    let isMounted = true;

    const getErrorMessage = (err) =>
      err.response && err.response.data.message
        ? err.response.data.message
        : null;

    const requestInterceptor = axios.interceptors.request.use((request) => {
      if (isMounted) {
        setError(null);
        return request;
      }

      return null;
    });

    const responseInterceptor = axios.interceptors.response.use(
      (response) => response,
      (err) => {
        setError(err);
        const message = getErrorMessage(err);
        setErrorMessage(message);
        return Promise.reject(err);
      }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.request.eject(responseInterceptor);
      isMounted = false;
    };
  });

  const resetError = () => {
    setError(null);
    window.location.reload(window.location.pathname);
  };

  return (
    <>
      <ErrorModal
        open={!!error}
        onClose={resetError}
        errorMessage={errorMessage}
      />
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <WrappedComponent {...props} />
    </>
  );
};

export default errorHandler;
