import type { VFC } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Dialog,
} from "@material-ui/core";
import "./Error.css";
import { useTranslation } from "react-i18next";
import type React from "react";

interface Props {
  open: boolean;
  onClose: React.MouseEventHandler<HTMLButtonElement> | undefined;
  errorMessage: string | null;
}

const ErrorModal: VFC<Props> = ({ open, onClose, errorMessage }) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid="errorModal"
    >
      <DialogTitle id="alert-dialog-title">Something went wrong!</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          className="error-modal-message"
        >
          {errorMessage ?? t("ErrorModal.tryAgain")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          {t("ErrorModal.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorModal;
