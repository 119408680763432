import { View, Text } from "@react-pdf/renderer";
import type { FC } from "react";
import { useTranslation } from "react-i18next";
import type { ApiResponseAutomaticAnalysisResult } from "../../../../../utils/api/apiInterfaces";
import { automaticAnalysisNarrowPageStyle } from "../styles/AutomaticAnalysisNarrowPageStyle";
import { getAmountOfTenResults } from "../UseAutomaticAnalysisCalculationHelper";

interface Props {
  genderResults: ApiResponseAutomaticAnalysisResult[];
}

export const GenderCircleResult: FC<Props> = ({ genderResults }) => {
  const { t } = useTranslation();
  const femaleResults = genderResults.find((res) => res.nameSv === "female");

  if (!femaleResults) return null;

  const genderOutOfTenResults = getAmountOfTenResults({ ...femaleResults });

  if (!genderOutOfTenResults) return null;

  return (
    <View style={automaticAnalysisNarrowPageStyle.oneCircleContainer}>
      <View style={automaticAnalysisNarrowPageStyle.circleGender}>
        <Text style={automaticAnalysisNarrowPageStyle.circleHeadline}>
          {genderOutOfTenResults.stressAmount.low +
            genderOutOfTenResults.stressAmount.medium}{" "}
          {t("GenderResult.outOfTen")}
        </Text>
        <Text style={automaticAnalysisNarrowPageStyle.circleText}>
          {t("GenderResult.outOfTenTextOne")}{" "}
          {t("GenderResult.outOfTenTextTwo")}
        </Text>
      </View>
    </View>
  );
};
