import { Text, View } from "@react-pdf/renderer";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AutomaticAnalysisBarChart } from "../AutomaticAnalysisBarChart";
import type {
  ApiResponseAutomaticAnalysisResult,
  Genders,
  SortedQuestion,
} from "../../../../../utils/api/apiInterfaces";
import { automaticAnalysisNarrowPageStyle } from "../styles/AutomaticAnalysisNarrowPageStyle";
import { lowestCategoryName } from "../LowestCategoryName";
import {
  compareAverageAnswerValue,
  lowestQuestionIndex,
  sortedLowestQuestionIndex,
} from "../LowestQuestionIndex";
import { getLanguage } from "../../../../../i18n";
import { getPercentageResults } from "../UseAutomaticAnalysisCalculationHelper";

interface SortedQuestionsPerCategory {
  male: SortedQuestion[];
  female: SortedQuestion[];
  other: SortedQuestion[];
}

interface LowestQuestions {
  male: SortedQuestion[];
  female: SortedQuestion[];
  other: string[];
}

interface LowestCategories {
  male: string;
  female: string;
  other: string;
}

interface Props {
  genderResult: ApiResponseAutomaticAnalysisResult;
  allValidResults: ApiResponseAutomaticAnalysisResult[];
}

enum Gender {
  female = "female",
  male = "male",
  other = "other",
}

export const GenderResult: FC<Props> = ({ genderResult, allValidResults }) => {
  const { t } = useTranslation();
  const gender = genderResult.nameEn as Genders;
  const [lowestQuestions, setLowestQuestions] = useState<LowestQuestions>({
    male: [],
    female: [],
    other: [],
  });

  const [lowestCategoryNames, setLowestCategoryNames] =
    useState<LowestCategories>({
      male: "",
      female: "",
      other: "",
    });

  const currentLanguage = getLanguage();

  const allResultsPerGender = useMemo(
    () =>
      allValidResults.reduce(
        (acc, curr) => {
          acc[curr.nameEn as Gender] = sortedLowestQuestionIndex(
            [
              ...curr.automaticAnalyzeQuestions.stressQuestions,
              ...curr.automaticAnalyzeQuestions.physicalQuestions,
              ...curr.automaticAnalyzeQuestions.developmentQuestions,
            ].flat(),
            currentLanguage
          );
          return acc;
        },
        { male: [], female: [], other: [] } as SortedQuestionsPerCategory
      ),
    [allValidResults, currentLanguage]
  );

  useEffect(() => {
    const lowestCategoryPerGender = lowestCategoryName(
      genderResult.physicalAverage,
      genderResult.stressAverage,
      genderResult.developmentAverage
    );

    setLowestCategoryNames((prevVal) => ({
      ...prevVal,
      [gender]: lowestCategoryPerGender[0],
    }));

    const questionCategoryQuestions =
      lowestCategoryPerGender[0] === "stressResult"
        ? genderResult.automaticAnalyzeQuestions.stressQuestions
        : lowestCategoryPerGender[0] === "physicalResult"
        ? genderResult.automaticAnalyzeQuestions.physicalQuestions
        : lowestCategoryPerGender[0] === "developmentResult"
        ? genderResult.automaticAnalyzeQuestions.developmentQuestions
        : null;

    if (!questionCategoryQuestions) {
      return;
    }

    setLowestQuestions((prevVal) => ({
      ...prevVal,
      [gender]: sortedLowestQuestionIndex(
        questionCategoryQuestions,
        currentLanguage
      ),
      other: lowestQuestionIndex(questionCategoryQuestions, currentLanguage, 3),
    }));
  }, [genderResult, gender, currentLanguage]);

  const lowestIndexQuestionAfterComparison = compareAverageAnswerValue(
    genderResult.nameEn === Gender.male
      ? lowestQuestions.male
      : lowestQuestions.female,
    genderResult.nameEn === Gender.male
      ? allResultsPerGender.female
      : allResultsPerGender.male
  );

  const genderPercantageResults = getPercentageResults(genderResult);

  return (
    <View style={automaticAnalysisNarrowPageStyle.mainSection} wrap={false}>
      <View style={automaticAnalysisNarrowPageStyle.sectionDescription}>
        <Text style={automaticAnalysisNarrowPageStyle.heading}>
          {t(`GenderResult.${genderResult.nameEn}`).toUpperCase()}
        </Text>
        <View style={automaticAnalysisNarrowPageStyle.text}>
          <Text>
            {t("GenderResult.wellBeingIndex")} {genderResult.totalAverage}.{" "}
            {genderPercantageResults.developmentPercentages.high === 0
              ? `${t(`GenderResult.developmentAveragev2`)}`
              : `${genderPercantageResults.developmentPercentages.high} ${t(
                  `GenderResult.developmentAverage`
                )}`}
            {t("GenderResult.areaThatStandsOut")}{" "}
            {t(`CompanyStatistics.${lowestCategoryNames[gender]}`)}.{" "}
            {genderResult.nameEn === Gender.other &&
              lowestQuestions.other.map((lpq, i) =>
                i === 0 ? (
                  <span key="1">
                    {t(`GenderResult.${genderResult.nameEn}`)
                      .charAt(0)
                      .toUpperCase() +
                      t(`GenderResult.${genderResult.nameEn}`).slice(1)}{" "}
                    {t("GenderResult.resultLowIndex")} &quot;
                    {lpq.slice(0, -1)}&quot;,{" "}
                  </span>
                ) : i === 1 ? (
                  <span key="2">
                    &quot;{lpq.slice(0, -1)}&quot; {t("CompanyResult.and")}
                  </span>
                ) : (
                  <span key="3">&quot;{lpq}&quot;.</span>
                )
              )}
            {(genderResult.nameEn === Gender.male ||
              genderResult.nameEn === Gender.female) &&
              lowestIndexQuestionAfterComparison && (
                <Text>
                  {t(`GenderResult.${genderResult.nameEn}`)
                    .charAt(0)
                    .toUpperCase() +
                    t(`GenderResult.${genderResult.nameEn}`).slice(1)}{" "}
                  {t("GenderResult.resultLowIndex")} &quot;
                  {lowestIndexQuestionAfterComparison.name}&quot;.
                </Text>
              )}
          </Text>
        </View>
      </View>
      <View style={automaticAnalysisNarrowPageStyle.barChartContainer}>
        <View style={automaticAnalysisNarrowPageStyle.barChartText}>
          <Text style={automaticAnalysisNarrowPageStyle.chartText}>
            {t("CompanyStatistics.wellBeingDevelopmentDistribution")}
          </Text>
          <Text style={automaticAnalysisNarrowPageStyle.chartText}>
            {t("CompanyStatistics.wellBeingPhysicalDistribution")}
          </Text>
          <Text style={automaticAnalysisNarrowPageStyle.chartText}>
            {t("CompanyStatistics.wellBeingStressDistribution")}
          </Text>
        </View>
        <View style={automaticAnalysisNarrowPageStyle.barChart}>
          <AutomaticAnalysisBarChart
            developmentAverage={genderResult.developmentAverage}
            physicalAverage={genderResult.physicalAverage}
            stressAverage={genderResult.stressAverage}
          />
        </View>
      </View>
    </View>
  );
};
