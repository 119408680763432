import type { VFC } from "react";
import { Paper } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import ProgBar from "./ProgBar";
import "./CycleResultCard.css";

interface Props {
  title: string;
  value: number;
  width: string;
}

const CycleResultCard: VFC<Props> = ({ title, value, width }) => (
  <Grid item xs={4}>
    <Paper square className="card-paper">
      <p className="card-title">{title}</p>
      <p className="card-body"> {value} </p>
      <ProgBar width={width} />
    </Paper>
  </Grid>
);

export default CycleResultCard;
