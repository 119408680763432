/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CloudinaryContext } from "cloudinary-react";
import "./MicroCourseResources.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { useNavigate, useParams } from "react-router";
import PropTypes from "prop-types";
import { Delete, Save } from "@material-ui/icons";
import Spacer from "../../shared/Spacer";
import api from "../../../utils/api/v1";
import Loader from "../../shared/loader/Loader";
import { languages } from "../../../i18n";
import { createGuid } from "../../../utils/helpers/createGuid";
import { openUploadWidget } from "../../../utils/cloudinary/cloudinaryService";
import { BackButton } from "../../../UI-Components/Buttons/Buttons";

export const MicroCourseResourceCreateEdit = ({ create }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { microCourseResourceId, microCourseId } = useParams();
  const [isNotNumber, setIsNotNumber] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [publicIdForDelete, setPublicIdForDelete] = useState("");
  const [state, setState] = useState({
    loading: false,
    error: false,
    microCourseResource: null,
  });

  const [form, setForm] = useState({
    nameEn: "",
    nameSv: "",
    timeRequired: 1,
  });

  const [requiredFields, setRequiredFields] = useState({
    nameEn: "",
    nameSv: "",
    timeRequired: 1,
  });
  const [showErrorAll, setShowErrorAll] = useState(false);
  const [showError, setShowError] = useState({
    nameEn: false,
    nameSv: false,
    timeRequired: false,
  });

  const [attributesForUpdate, setAttributesForUpdate] = useState({
    imagePublicId: null,
    imageUrl: null,
    resourceVideoPublicId: null,
    resourceVideoUrl: null,
    resourceVideoPublicIdEn: null,
    resourceVideoUrlEn: null,
    resourcePdfPublicIdEn: null,
    resourcePdfUrlEn: null,
    resourcePdfPublicId: null,
    resourcePdfUrl: null,
  });

  const beginCloudinaryImageUpload = async () => {
    const guidForPublicId = createGuid();
    const uploadOptions = {
      cloudName: process.env.REACT_APP_CLOUD_NAME,
      uploadPreset: process.env.REACT_APP_CLOUD_UPLOAD_PRESET,
      publicId: `microCourseResources/${microCourseResourceId}/${
        attributesForUpdate.imagePublicId !== null
          ? attributesForUpdate.imagePublicId
          : guidForPublicId
      }`,
      maxFiles: 1,
      preBatch: (cb, data) => {
        if (
          !data.files[0].name.includes("jpg") &&
          !data.files[0].name.includes("jpeg") &&
          !data.files[0].name.includes("png")
        ) {
          console.error("Only images are allowed!");
          cb({ cancel: true });
        } else {
          cb();
        }
      },
    };

    openUploadWidget(uploadOptions, async (error, res) => {
      if (error) {
        console.error(error);
        return;
      }

      if (!error) {
        console.log(res);
      }

      if (res.event === "success") {
        setState({ ...state, loading: true, error: false });
        const data = {
          assetPublicId:
            attributesForUpdate.imagePublicId !== null
              ? attributesForUpdate.imagePublicId
              : guidForPublicId,
          resourceOrActivityUrl: res.info.secure_url,
        };

        try {
          await api.uploadMicroCourseResourceImageAsset(
            data,
            microCourseResourceId
          );
          setAttributesForUpdate({
            ...attributesForUpdate,
            imagePublicId: data.assetPublicId,
            imageUrl: res.info.secure_url,
          });
          setState({ ...state, loading: false, error: false });
        } catch (err) {
          console.error(err);
          setState({ ...state, loading: false, error: true });
        }
      }
    });
  };

  const beginCloudinaryVideoUpload = async () => {
    const guidForPublicId = createGuid();
    const uploadOptions = {
      cloudName: process.env.REACT_APP_CLOUD_NAME,
      uploadPreset: process.env.REACT_APP_CLOUD_UPLOAD_PRESET,
      publicId: `microCourseResources/${microCourseResourceId}/${
        attributesForUpdate.resourceVideoPublicId !== null
          ? attributesForUpdate.resourceVideoPublicId
          : guidForPublicId
      }`,
      maxVideoFileSize: 500000000,
      maxFiles: 1,
      preBatch: (cb, data) => {
        if (!data.files[0].name.includes("mp4")) {
          console.error("Only upload of file type mp4 is allowed");
          cb({ cancel: true });
        } else {
          cb();
        }
      },
    };

    openUploadWidget(uploadOptions, async (error, res) => {
      if (error) {
        console.error(error);
        return;
      }

      if (!error) {
        console.log(res);
      }

      if (res.event === "success") {
        setState({ ...state, loading: true, error: false });
        const data = {
          assetPublicId:
            attributesForUpdate.resourceVideoPublicId !== null
              ? attributesForUpdate.resourceVideoPublicId
              : guidForPublicId,
          resourceOrActivityUrl: res.info.secure_url,
        };

        try {
          await api.uploadMicroCourseResourceSwedishVideoAsset(
            data,
            microCourseResourceId
          );
          setAttributesForUpdate({
            ...attributesForUpdate,
            resourceVideoPublicId: data.assetPublicId,
            resourceVideoUrl: res.info.secure_url,
          });
          setState({ ...state, loading: false, error: false });
        } catch (err) {
          console.error(err);
          setState({ ...state, loading: false, error: true });
        }
      }
    });
  };

  const beginEnglishCloudinaryVideoUpload = async () => {
    const guidForPublicId = createGuid();
    const uploadOptions = {
      cloudName: process.env.REACT_APP_CLOUD_NAME,
      uploadPreset: process.env.REACT_APP_CLOUD_UPLOAD_PRESET,
      publicId: `microCourseResources/${microCourseResourceId}/${
        attributesForUpdate.resourceVideoPublicIdEn !== null
          ? attributesForUpdate.resourceVideoPublicIdEn
          : guidForPublicId
      }`,
      maxVideoFileSize: 500000000,
      maxFiles: 1,
      preBatch: (cb, data) => {
        if (!data.files[0].name.includes("mp4")) {
          console.error("Only upload of file type mp4 is allowed");
          cb({ cancel: true });
        } else {
          cb();
        }
      },
    };

    openUploadWidget(uploadOptions, async (error, res) => {
      if (error) {
        console.error(error);
        return;
      }

      if (!error) {
        console.log(res);
      }

      if (res.event === "success") {
        setState({ ...state, loading: true, error: false });
        const data = {
          assetPublicId:
            attributesForUpdate.resourceVideoPublicIdEn !== null
              ? attributesForUpdate.resourceVideoPublicIdEn
              : guidForPublicId,
          resourceOrActivityUrl: res.info.secure_url,
        };

        try {
          await api.uploadMicroCourseResourceEnglishVideoAsset(
            data,
            microCourseResourceId
          );
          setAttributesForUpdate({
            ...attributesForUpdate,
            resourceVideoPublicIdEn: data.assetPublicId,
            resourceVideoUrlEn: res.info.secure_url,
          });
          setState({ ...state, loading: false, error: false });
        } catch (err) {
          console.error(err);
          setState({ ...state, loading: false, error: true });
        }
      }
    });
  };

  const beginCloudinaryPdfUpload = async () => {
    const guidForPublicId = createGuid();
    const uploadOptions = {
      cloudName: process.env.REACT_APP_CLOUD_NAME,
      uploadPreset: process.env.REACT_APP_CLOUD_UPLOAD_PRESET,
      publicId: `microCourseResources/${microCourseResourceId}/${
        attributesForUpdate.resourcePdfPublicId !== null
          ? attributesForUpdate.resourcePdfPublicId
          : guidForPublicId
      }`,
      maxVideoFileSize: 500000000,
      maxFiles: 1,
      preBatch: (cb, data) => {
        if (!data.files[0].name.includes("pdf")) {
          console.error("Only upload of pdfs are allowed");
          cb({ cancel: true });
        } else {
          cb();
        }
      },
    };

    openUploadWidget(uploadOptions, async (error, res) => {
      if (error) {
        console.error(error);
        return;
      }

      if (!error) {
        console.log(res);
      }

      if (res.event === "success") {
        setState({ ...state, loading: true, error: false });
        const data = {
          assetPublicId:
            attributesForUpdate.resourcePdfPublicId !== null
              ? attributesForUpdate.resourcePdfPublicId
              : guidForPublicId,
          resourceOrActivityUrl: res.info.secure_url,
        };

        try {
          await api.uploadMicroCourseResourceSwedishPdfAsset(
            data,
            microCourseResourceId
          );
          setAttributesForUpdate({
            ...attributesForUpdate,
            resourcePdfPublicId: data.assetPublicId,
            resourcePdfUrl: res.info.secure_url,
          });
          setState({ ...state, loading: false, error: false });
        } catch (err) {
          console.error(err);
          setState({ ...state, loading: false, error: true });
        }
      }
    });
  };

  const beginEnglishCloudinaryPdfUpload = async () => {
    const guidForPublicId = createGuid();
    const uploadOptions = {
      cloudName: process.env.REACT_APP_CLOUD_NAME,
      uploadPreset: process.env.REACT_APP_CLOUD_UPLOAD_PRESET,
      publicId: `microCourseResources/${microCourseResourceId}/${
        attributesForUpdate.resourcePdfPublicIdEn !== null
          ? attributesForUpdate.resourcePdfPublicIdEn
          : guidForPublicId
      }`,
      maxVideoFileSize: 500000000,
      maxFiles: 1,
      preBatch: (cb, data) => {
        if (!data.files[0].name.includes("pdf")) {
          console.error("Only upload of pdfs are allowed");
          cb({ cancel: true });
        } else {
          cb();
        }
      },
    };

    openUploadWidget(uploadOptions, async (error, res) => {
      if (error) {
        console.error(error);
        return;
      }

      if (!error) {
        console.log(res);
      }

      if (res.event === "success") {
        setState({ ...state, loading: true, error: false });
        const data = {
          assetPublicId:
            attributesForUpdate.resourcePdfPublicIdEn !== null
              ? attributesForUpdate.resourcePdfPublicIdEn
              : guidForPublicId,
          resourceOrActivityUrl: res.info.secure_url,
        };

        try {
          await api.uploadMicroCourseResourceEnglishPdfAsset(
            data,
            microCourseResourceId
          );
          setAttributesForUpdate({
            ...attributesForUpdate,
            resourcePdfPublicIdEn: data.assetPublicId,
            resourcePdfUrlEn: res.info.secure_url,
          });
          setState({ ...state, loading: false, error: false });
        } catch (err) {
          console.error(err);
          setState({ ...state, loading: false, error: true });
        }
      }
    });
  };

  const handleOpenDeleteModalVideoResource = () => {
    setPublicIdForDelete(attributesForUpdate.resourceVideoPublicId);
    setIsModalOpen(true);
  };

  const handleOpenDeleteModalEnglishVideoResource = () => {
    setPublicIdForDelete(attributesForUpdate.resourceVideoPublicIdEn);
    setIsModalOpen(true);
  };

  const handleOpenDeleteModalPdfResource = () => {
    setPublicIdForDelete(attributesForUpdate.resourcePdfPublicId);
    setIsModalOpen(true);
  };

  const handleOpenDeleteModalEnglishPdfResource = () => {
    setPublicIdForDelete(attributesForUpdate.resourcePdfPublicIdEn);
    setIsModalOpen(true);
  };

  const handleOpenDeleteModalImage = () => {
    setPublicIdForDelete(attributesForUpdate.imagePublicId);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const getMicroCourseResource = async () => {
    setState({ ...state, loading: true, error: false });
    try {
      const {
        data: { microCourseResource: retrievedMicroCourseResource },
      } = await api.getMicroCourseResource(microCourseResourceId);
      setState({
        ...state,
        microCourseResource: retrievedMicroCourseResource,
        loading: false,
        error: false,
      });
      setForm({
        nameEn: retrievedMicroCourseResource.nameEn,
        nameSv: retrievedMicroCourseResource.nameSv,
        timeRequired: retrievedMicroCourseResource.timeRequired,
      });
      setRequiredFields({
        nameEn: retrievedMicroCourseResource.nameEn,
        nameSv: retrievedMicroCourseResource.nameSv,
        timeRequired: retrievedMicroCourseResource.timeRequired,
      });
      setAttributesForUpdate({
        imagePublicId: retrievedMicroCourseResource.imagePublicId,
        imageUrl: retrievedMicroCourseResource.imageUrl,
        resourceVideoPublicId:
          retrievedMicroCourseResource.resourceVideoPublicId,
        resourceVideoPublicIdEn:
          retrievedMicroCourseResource.resourceVideoPublicIdEn,
        resourcePdfPublicId: retrievedMicroCourseResource.resourcePdfPublicId,
        resourcePdfPublicIdEn:
          retrievedMicroCourseResource.resourcePdfPublicIdEn,
        resourceVideoUrl: retrievedMicroCourseResource.resourceVideoUrl,
        resourceVideoUrlEn: retrievedMicroCourseResource.resourceVideoUrlEn,
        resourcePdfUrl: retrievedMicroCourseResource.resourcePdfUrl,
        resourcePdfUrlEn: retrievedMicroCourseResource.resourcePdfUrlEn,
      });
    } catch (err) {
      console.error(err);
      setState({ ...state, error: true, loading: false });
    }
  };

  useEffect(() => {
    if (!create) {
      (async () => {
        await getMicroCourseResource();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = async () => {
    if (Object.values(requiredFields).some((value) => value === "")) {
      setShowErrorAll(true);
      return;
    }
    setState({ ...state, loading: true, error: false });

    // Check if activity should be created or edited
    if (create) {
      if (form.timeRequired === "" || null) {
        form.timeRequired = 0;
      }

      const formData = new FormData();
      formData.append("form", JSON.stringify(form));

      try {
        const { data: createdMicroCourseResourceId } =
          await api.createMicroCourseResource(microCourseId, formData, {
            "content-type": "multipart/form-data",
          });

        setState({ ...state, loading: false, error: false });
        navigate(
          `/micro-courses/micro-course-resource/${createdMicroCourseResourceId}`
        );
      } catch (err) {
        console.error(err);
        setState({ ...state, loading: false, error: true });
      }
    } else if (!create) {
      const formData = new FormData();
      const formStateCopy = { ...form };

      if (formStateCopy.timeRequired === "" || null) {
        formStateCopy.timeRequired = 0;
      }

      const formDataForEdit = Object.assign(formStateCopy, attributesForUpdate);

      formData.append("form", JSON.stringify(formDataForEdit));

      try {
        await api.updateMicroCourseResource(microCourseResourceId, formData, {
          "content-type": "multipart/form-data",
        });
        setState({ ...state, loading: false, error: false });
        navigate(
          `/micro-courses/micro-course-resource/${microCourseResourceId}`
        );
      } catch (err) {
        console.error(err);
        setState({ ...state, loading: false, error: true });
      }
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    setShowError({ ...showError, [name]: true });
    if (
      name === "timeRequired" &&
      // eslint-disable-next-line no-restricted-globals
      (isNaN(parseInt(value, 10)) || !/^\d+$/.test(value))
    ) {
      setIsNotNumber(true);
      if (value.length > 0) {
        return;
      }
    } else {
      setIsNotNumber(false);
    }

    setForm({
      ...form,
      [name]: value,
    });
    setRequiredFields({ ...requiredFields, [name]: value });
  };

  const handleDeleteResourceAsset = async () => {
    setState({ ...state, loading: true, error: false });
    if (publicIdForDelete === attributesForUpdate.resourceVideoPublicId) {
      try {
        await api.deleteMicroCourseResourceSwedishVideoAsset(
          microCourseResourceId,
          publicIdForDelete
        );
        setAttributesForUpdate({
          ...attributesForUpdate,
          resourceVideoPublicId: null,
          resourceVideoUrl: null,
        });
        setState({ ...state, loading: false });
        handleCloseModal();
      } catch (err) {
        console.error(err);
        setState({ ...state, loading: false, error: true });
        handleCloseModal();
      }

      return;
    }

    if (publicIdForDelete === attributesForUpdate.imagePublicId) {
      try {
        await api.deleteMicroCourseResourceImageAsset(
          microCourseResourceId,
          publicIdForDelete
        );
        setAttributesForUpdate({
          ...attributesForUpdate,
          imagePublicId: null,
          imageUrl: null,
        });
        setState({ ...state, loading: false });
        handleCloseModal();
      } catch (err) {
        console.error(err);
        setState({ ...state, loading: false, error: true });
      }

      return;
    }

    if (publicIdForDelete === attributesForUpdate.resourceVideoPublicIdEn) {
      try {
        await api.deleteMicroCourseResourceEnglishVideoAsset(
          microCourseResourceId,
          publicIdForDelete
        );
        setAttributesForUpdate({
          ...attributesForUpdate,
          resourceVideoPublicIdEn: null,
          resourceVideoUrlEn: null,
        });
        setState({ ...state, loading: false });
        handleCloseModal();
      } catch (err) {
        console.error(err);
        setState({ ...state, loading: false, error: true });
        handleCloseModal();
      }

      return;
    }

    if (publicIdForDelete === attributesForUpdate.resourcePdfPublicIdEn) {
      try {
        await api.deleteMicroCourseResourceEnglishPdfAsset(
          microCourseResourceId,
          publicIdForDelete
        );
        setAttributesForUpdate({
          ...attributesForUpdate,
          resourcePdfPublicIdEn: null,
          resourcePdfUrlEn: null,
        });
        setState({ ...state, loading: false });
        handleCloseModal();
      } catch (err) {
        console.error(err);
        setState({ ...state, loading: false, error: true });
        handleCloseModal();
      }

      return;
    }

    if (publicIdForDelete === attributesForUpdate.resourcePdfPublicId) {
      try {
        await api.deleteMicroCourseResourceSwedishPdfAsset(
          microCourseResourceId,
          publicIdForDelete
        );
        setAttributesForUpdate({
          ...attributesForUpdate,
          resourcePdfPublicId: null,
          resourcePdfUrl: null,
        });
        setState({ ...state, loading: false });
        handleCloseModal();
      } catch (err) {
        console.error(err);
        setState({ ...state, loading: false, error: true });
        handleCloseModal();
      }
    }
  };

  const checkRequiredInput = (input) =>
    (showError[input] || showErrorAll) &&
    (form[input] === "" || form[input] === undefined);

  const generateHelperText = (input, fieldName) =>
    checkRequiredInput(input)
      ? t("missingMandatoryInput", { field: fieldName })
      : "";

  const mainGui = () => {
    if (state.loading)
      return create ? (
        <Loader
          text={t("MicroCourses.microCourseResourceCreateEdit.loadingCreate")}
          style={{ marginTop: 40 }}
        />
      ) : (
        <Loader
          text={t("MicroCourses.microCourseResourceCreateEdit.loadingEdit")}
          style={{ marginTop: 40 }}
        />
      );
    if (!state.loading && !state.error) {
      return (
        <>
          <div className="section-toolbar">
            <BackButton />
            <Typography className="section-title" variant="h6">
              {create
                ? t("MicroCourses.microCourseResourceCreateEdit.titleCreate")
                : t("MicroCourses.microCourseResourceCreateEdit.titleEdit")}
            </Typography>
            <Spacer />
            <Button
              color="primary"
              variant="contained"
              startIcon={<Save />}
              onClick={handleSave}
            >
              {t("ButtonGeneral.save")}
            </Button>
          </div>
          <Divider />
          <div>
            <Paper className="micro-course-resources-edit-create-details">
              <div>
                {languages.map((lang) => [
                  <div key={lang}>
                    <div className="micro-course-resources-edit-create-form-text">
                      <TextField
                        name={`name${
                          lang.charAt(0).toUpperCase() + lang.slice(1)
                        }`}
                        label={`${t(
                          `MicroCourses.microCourseResourceCreateEdit.name`
                        )} ${t(`Language.languages.${lang}`)}`}
                        variant="outlined"
                        fullWidth
                        multiline
                        required
                        value={
                          form[
                            `name${
                              lang.charAt(0).toUpperCase() + lang.slice(1)
                            }`
                          ]
                        }
                        error={checkRequiredInput(
                          `name${lang.charAt(0).toUpperCase() + lang.slice(1)}`
                        )}
                        helperText={generateHelperText(
                          `name${lang.charAt(0).toUpperCase() + lang.slice(1)}`,
                          t("MicroCourses.microCourseResourceCreateEdit.name")
                        )}
                        onChange={handleChange}
                      />
                    </div>
                  </div>,
                ])}
                <div className="micro-course-resources-edit-create-form-text">
                  <TextField
                    error={isNotNumber}
                    name="timeRequired"
                    label={`${t(
                      `MicroCourses.microCourseResourceCreateEdit.timeRequired`
                    )}`}
                    variant="outlined"
                    fullWidth
                    multiline
                    required
                    helperText={
                      isNotNumber
                        ? t(
                            `MicroCourses.microCourseResourceCreateEdit.numberErrorText`
                          )
                        : ""
                    }
                    value={form.timeRequired}
                    onChange={handleChange}
                  />
                </div>
              </div>

              {!create && (
                <div className="micro-course-resources-edit-create-file-input-wrapper">
                  <div className="micro-course-resources-edit-create-file-upload-wrapper">
                    <CloudinaryContext
                      cloudName={process.env.REACT_APP_CLOUD_NAME}
                    >
                      <p className="micro-course-resources-edit-create-upload-paragraph-header">
                        {t(
                          `MicroCourses.microCourseResourceCreateEdit.presentationImage`
                        )}
                      </p>
                      <Button
                        color="primary"
                        className="micro-course-resources-edit-create-asset-upload-button-style"
                        variant="contained"
                        onClick={beginCloudinaryImageUpload}
                        disabled={!!attributesForUpdate.imagePublicId}
                      >
                        {t(`MicroCourses.microCourseResourceCreateEdit.upload`)}
                      </Button>
                    </CloudinaryContext>
                    {t(`MicroCourses.microCourseResourceCreateEdit.uploaded`)}
                    {attributesForUpdate.imageUrl && (
                      <div className="micro-course-resources-edit-create-edit-upload-wrapper">
                        <img
                          src={attributesForUpdate.imageUrl}
                          height="150px"
                          width="200px"
                          alt="imageUrl"
                        />
                      </div>
                    )}

                    <div className="micro-course-resources-edit-create-edit-button-text-wrapper">
                      <Dialog
                        open={isModalOpen}
                        onClose={handleCloseModal}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">
                          <Typography className="micro-course-resources-edit-create-edit-reinforced-delete-alert-style">
                            {t(
                              "MicroCourses.microCourseResourceCreateEdit.deleteAlertReinforced"
                            )}
                          </Typography>
                        </DialogTitle>
                        <DialogActions>
                          <Button
                            onClick={handleDeleteResourceAsset}
                            color="primary"
                          >
                            {t("ButtonGeneral.acceptDeleteAlert")}
                          </Button>
                          <Button onClick={handleCloseModal} color="primary">
                            {t("ButtonGeneral.denyDeleteAlert")}
                          </Button>
                        </DialogActions>
                      </Dialog>
                      <Button
                        color="secondary"
                        variant="contained"
                        name="deleteImage"
                        startIcon={<Delete />}
                        disabled={!attributesForUpdate.imagePublicId}
                        className="micro-course-resources-edit-create-edit-delete-button-style"
                        onClick={handleOpenDeleteModalImage}
                      >
                        {t("ButtonGeneral.delete")}
                      </Button>
                    </div>
                  </div>

                  <div className="micro-course-resources-edit-create-file-upload-wrapper">
                    <CloudinaryContext
                      cloudName={process.env.REACT_APP_CLOUD_NAME}
                    >
                      <p className="micro-course-resources-edit-create-upload-paragraph-header">
                        {t(
                          `MicroCourses.microCourseResourceCreateEdit.resourceVideoFile`
                        )}
                      </p>
                      <Button
                        color="primary"
                        className="micro-course-resources-edit-create-asset-upload-button-style"
                        variant="contained"
                        onClick={beginCloudinaryVideoUpload}
                        disabled={!!attributesForUpdate.resourceVideoPublicId}
                      >
                        {t(`MicroCourses.microCourseResourceCreateEdit.upload`)}
                      </Button>
                    </CloudinaryContext>
                    {t(`MicroCourses.microCourseResourceCreateEdit.uploaded`)}
                    <div className="micro-course-resources-edit-create-edit-button-text-wrapper">
                      {
                        // eslint-disable-next-line no-nested-ternary
                        attributesForUpdate.resourceVideoUrl &&
                        (attributesForUpdate.resourceVideoUrl.includes(
                          "video"
                        ) ||
                          attributesForUpdate.resourceVideoUrl.includes(
                            "mp4"
                          )) ? (
                          <div className="micro-course-resources-edit-create-edit-upload-wrapper">
                            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                            <video controls width="250">
                              <source
                                src={attributesForUpdate.resourceVideoUrl}
                                type="video/webm"
                              />
                              Sorry, your browser doesn&apos;t support embedded
                              videos.
                            </video>
                          </div>
                        ) : (
                          attributesForUpdate.resourceVideoUrl &&
                          attributesForUpdate.resourceVideoUrl.includes(
                            "youtube"
                          ) && (
                            <iframe
                              width="250"
                              height="140"
                              title="youtube-video"
                              src={attributesForUpdate.resourceVideoUrl}
                            />
                          )
                        )
                      }
                      <Button
                        color="secondary"
                        variant="contained"
                        name="deleteResource"
                        startIcon={<Delete />}
                        disabled={!attributesForUpdate.resourceVideoPublicId}
                        onClick={handleOpenDeleteModalVideoResource}
                        className="micro-course-resources-edit-create-edit-delete-button-style"
                      >
                        {t("ButtonGeneral.delete")}
                      </Button>
                    </div>
                  </div>
                  <div className="micro-course-resources-edit-create-file-upload-wrapper">
                    <CloudinaryContext
                      cloudName={process.env.REACT_APP_CLOUD_NAME}
                    >
                      <p className="micro-course-resources-edit-create-upload-paragraph-header">
                        {t(
                          `MicroCourses.microCourseResourceCreateEdit.resourceVideoFileEn`
                        )}
                      </p>
                      <Button
                        color="primary"
                        className="micro-course-resources-edit-create-asset-upload-button-style"
                        variant="contained"
                        onClick={beginEnglishCloudinaryVideoUpload}
                        disabled={!!attributesForUpdate.resourceVideoPublicIdEn}
                      >
                        {t(`MicroCourses.microCourseResourceCreateEdit.upload`)}
                      </Button>
                    </CloudinaryContext>
                    {t(`MicroCourses.microCourseResourceCreateEdit.uploaded`)}
                    <div className="micro-course-resources-edit-create-edit-button-text-wrapper">
                      {
                        // eslint-disable-next-line no-nested-ternary
                        attributesForUpdate.resourceVideoUrlEn &&
                        (attributesForUpdate.resourceVideoUrlEn.includes(
                          "video"
                        ) ||
                          (attributesForUpdate.resourceVideoUrlEn &&
                            attributesForUpdate.resourceVideoUrlEn.includes(
                              "mp4"
                            ))) ? (
                          <div className="micro-course-resources-edit-create-edit-upload-wrapper">
                            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                            <video controls width="250">
                              <source
                                src={attributesForUpdate.resourceVideoUrlEn}
                                type="video/webm"
                              />
                              Sorry, your browser doesn&apos;t support embedded
                              videos.
                            </video>
                          </div>
                        ) : (
                          attributesForUpdate.resourceVideoUrlEn &&
                          attributesForUpdate.resourceVideoUrlEn.includes(
                            "youtube"
                          ) && (
                            <iframe
                              width="250"
                              height="140"
                              title="youtube-video"
                              src={attributesForUpdate.resourceVideoUrlEn}
                            />
                          )
                        )
                      }
                      <Button
                        color="secondary"
                        variant="contained"
                        name="deleteResourceEn"
                        startIcon={<Delete />}
                        disabled={!attributesForUpdate.resourceVideoPublicIdEn}
                        onClick={handleOpenDeleteModalEnglishVideoResource}
                        className="micro-course-resources-edit-create-edit-delete-button-style"
                      >
                        {t("ButtonGeneral.delete")}
                      </Button>
                    </div>
                  </div>
                  <div className="micro-course-resources-edit-create-file-upload-wrapper">
                    <CloudinaryContext
                      cloudName={process.env.REACT_APP_CLOUD_NAME}
                    >
                      <p className="micro-course-resources-edit-create-upload-paragraph-header">
                        {t(
                          `MicroCourses.microCourseResourceCreateEdit.resourcePdfFile`
                        )}
                      </p>
                      <Button
                        color="primary"
                        className="micro-course-resources-edit-create-asset-upload-button-style"
                        variant="contained"
                        onClick={beginCloudinaryPdfUpload}
                        disabled={!!attributesForUpdate.resourcePdfPublicId}
                      >
                        {t(`MicroCourses.microCourseResourceCreateEdit.upload`)}
                      </Button>
                    </CloudinaryContext>
                    {t(`MicroCourses.microCourseResourceCreateEdit.uploaded`)}
                    <div className="micro-course-resources-edit-create-edit-button-text-wrapper">
                      {attributesForUpdate.resourcePdfUrl &&
                        attributesForUpdate.resourcePdfUrl.endsWith(".pdf") && (
                          <div className="micro-course-resources-edit-create-edit-upload-wrapper">
                            <iframe
                              scrolling="no"
                              title="pdf display"
                              src={attributesForUpdate.resourcePdfUrl}
                              className="micro-course-resources-edit-create-edit-pdf-display-style"
                            />
                          </div>
                        )}
                      <Button
                        color="secondary"
                        variant="contained"
                        name="deleteResource"
                        startIcon={<Delete />}
                        disabled={!attributesForUpdate.resourcePdfPublicId}
                        onClick={handleOpenDeleteModalPdfResource}
                        className="micro-course-resources-edit-create-edit-delete-button-style"
                      >
                        {t("ButtonGeneral.delete")}
                      </Button>
                    </div>
                  </div>
                  <div className="micro-course-resources-edit-create-file-upload-wrapper">
                    <CloudinaryContext
                      cloudName={process.env.REACT_APP_CLOUD_NAME}
                    >
                      <p className="micro-course-resources-edit-create-upload-paragraph-header">
                        {t(
                          `MicroCourses.microCourseResourceCreateEdit.resourcePdfFileEn`
                        )}
                      </p>
                      <Button
                        color="primary"
                        className="micro-course-resources-edit-create-asset-upload-button-style"
                        variant="contained"
                        onClick={beginEnglishCloudinaryPdfUpload}
                        disabled={!!attributesForUpdate.resourcePdfPublicIdEn}
                      >
                        {t(`MicroCourses.microCourseResourceCreateEdit.upload`)}
                      </Button>
                    </CloudinaryContext>
                    {t(`MicroCourses.microCourseResourceCreateEdit.uploaded`)}
                    <div className="micro-course-resources-edit-create-edit-button-text-wrapper">
                      {attributesForUpdate.resourcePdfUrlEn &&
                        attributesForUpdate.resourcePdfUrlEn.endsWith(
                          ".pdf"
                        ) && (
                          <div className="micro-course-resources-edit-create-edit-upload-wrapper">
                            <iframe
                              scrolling="no"
                              title="pdf display"
                              src={attributesForUpdate.resourcePdfUrlEn}
                              className="micro-course-resources-edit-create-edit-pdf-display-style"
                            />
                          </div>
                        )}
                      <Button
                        color="secondary"
                        variant="contained"
                        name="deleteResource"
                        startIcon={<Delete />}
                        disabled={!attributesForUpdate.resourcePdfPublicIdEn}
                        onClick={handleOpenDeleteModalEnglishPdfResource}
                        className="micro-course-resources-edit-create-edit-delete-button-style"
                      >
                        {t("ButtonGeneral.delete")}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </Paper>
          </div>
        </>
      );
    }
    return (
      <Loader text={t("ResourceEdit.loading")} style={{ marginTop: 40 }} />
    );
  };

  return <div className="companies-wrapper">{mainGui()}</div>;
};

MicroCourseResourceCreateEdit.propTypes = {
  create: PropTypes.bool,
};

MicroCourseResourceCreateEdit.defaultProps = {
  create: false,
};
