import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { defaultColors } from "../../theme";

const useStyles = makeStyles({
  alert: {
    borderRadius: 4,
    padding: 10,
    backgroundColor: defaultColors.error,
    border: "none",
    color: "white",
  },
});

// eslint-disable-next-line react/prop-types
const Alert = ({ children, style }) => {
  const classes = useStyles();
  return (
    <div className={classes.alert} style={style} data-testid="alertModule">
      {children}
    </div>
  );
};

export default Alert;
